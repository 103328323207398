<div class="recommended-container">

	<app-home-title title="More recommendations"></app-home-title>
	<ion-grid>
		<ion-row class="ion-justify-content-start">
			<ion-col *ngFor="let data of recommendedData">
				<p class="titleCss">{{data.type}} </p>
				<div class="slide-container" routerLink="/product-detail" *ngFor="let data of data.data" size-xs="1"
					size-sm="1" size-md="1" size-lg="1" size-xl="1">
					<div class="product-image-container">
						<img src="{{ data.product_img }}" alt="Jazeera">
					</div>
					<div class="product-detial-container pl-20">
						<div class="product-description ion-text-left">
							{{ data.product_description }}
						</div>
						<div class="product-price ion-text-left">
							<div class="from">
								from
							</div>
							<div class="product-price-data">
								<span class="currency">{{ data.product_currency }}</span><span class="price">{{
									data.final_price }}</span><span class="unit">{{ data.product_unit }}</span>
							</div>
						</div>
					</div>

				</div>
			</ion-col>
		</ion-row>

	</ion-grid>

</div>