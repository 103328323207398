import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { DomSanitizer, Meta, SafeResourceUrl, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ToastService } from "../../services/toast.service";
import { latestNewsData } from "./news-details-data";

@Component({
	selector: "app-news-details",
	templateUrl: "./news-details.page.html",
	styleUrls: ["./news-details.page.scss"],
})
export class NewsDetailsPage implements OnInit {

	public subscription: Subscription = new Subscription();
	isLoading: boolean = false;
	newsData: any = [];
	latestNewsData: any = latestNewsData
	searchText: any;

	fromDate: any;
	todaysDate = new Date();
	maxFromDate: any;
	selectedSortBy: any;
	btnStatus: any = true;
	stateData: any;
	id: any;
	media_type: any;

	public enableNext = false;
	@ViewChild("playerDetails") playerDetails: ElementRef;
	sanitizedUrl: SafeResourceUrl;

	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title,
		protected sanitizer: DomSanitizer,
		private datePipe: DatePipe,
		private router: Router,
		private route: ActivatedRoute

	) {

	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.id = params.id;
			this.getData();
		});
	}



	getData() {
		this.isLoading = true;
		const params = {
			dropdown: true,
		}
		this.subscription.add(
			this.dataService.get(URLS.pressList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					if (data['data']) {
						let pressGridDataInfinte = data['data']['result'];
						pressGridDataInfinte.forEach(element => {
							if (element.press_release_document_id == +this.id) {
								this.media_type = element.media_url ? element.media_url.substring(element.media_url.lastIndexOf('.') + 1) : null;
								if (this.media_type && element.media_url.includes('/www.youtube.com/watch')) {
									this.media_type = element.media_url ? element.media_url.includes('/www.youtube.com/watch') : undefined
									if (this.media_type == true) {
										const embedUrl = element.media_url.replace('watch?v=', 'embed/');
										this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
									}
								}
								this.newsData = element;
							}
						});
					}

				}
				else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}




	goBack() {
		this.router.navigate(['/home']);
	}

	gomobileBack() {
		this.navCtrl.back({ animated: false });
	}


	handleRefresh(event) {
		setTimeout(() => {
			this.getData();
			event.target.complete();
		}, 1000);
	};

	ngOnDestroy() {
		if (this.playerDetails) {
			this.playerDetails.nativeElement.pause();
		}
	}

	ionViewWillLeave() {
		if (this.playerDetails) {
			this.playerDetails.nativeElement.pause();
		}
	}
}
