import { DOCUMENT, isPlatformServer } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ToastService } from "../../services/toast.service";

@Component({
	selector: "app-about-us",
	templateUrl: "./about-us.page.html",
	styleUrls: ["./about-us.page.scss"],
})
export class AboutUsPage implements OnInit {
	isVideoPlay: boolean = false;
	pageData: any = {};
	sliderOptions = {
		autoplay: true,
	};
	public subscription: Subscription = new Subscription();
	isLoading: boolean = false;
	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title,
		protected sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.getData();
	}

	ionViewWillEnter() {
		this.getData();
	}

	playVideo() {
		this.isVideoPlay = true;
	}

	getData() {


		this.isLoading = true;
		const params = {
			content_type: "ABOUT_US",
		};
		this.subscription.add(
			this.dataService.get(URLS.getCMSdata, params).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.pageData = data["data"] ? data["data"] : {};
						this.isLoading = false;
						if (this.pageData.content_title_en) {
							this.metaService.updateTag({
								property: "og:title",
								content: "Jazeera Paints | " + this.pageData.content_title_en,
							});
							let title = 'Jazeera Paints | ' + this.pageData.content_title_en;
							this.setTitleService.setTitle(title);
						}
						if (this.pageData.content_en) {
							let bodyContent = this.pageData.content_en.replace(/<[^>]+>/g, '');

							this.metaService.updateTag({
								name: "description",
								content: "Jazeera Paints | " + bodyContent,
							});
							this.metaService.updateTag({
								property: "og:description",
								content: "Jazeera Paints | " + bodyContent,
							});
						}
					}
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}

	elementClicked($event: any) {
		const target = $event.target || $event.srcElement || $event.currentTarget;
		if (!target) return;
		const href = target.attributes.href;
		if (!href) return;
		if (!isPlatformServer(this.platformId)) {
			if (href.value.includes("#")) {
				$event.stopPropagation();
				$event.preventDefault();
				const element = this.document.getElementById(
					href.value.replace("#", "")
				);
				element.scrollIntoView();
			}
		}
	}

	goBack() {
		this.navCtrl.back({ animated: false });
	}
}
