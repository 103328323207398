<div class="account-information" *ngIf="!isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.CustomerGroupCode" | translate}}
						</div>
						<div class="information-container">
							<di.v class="account-name">
								{{customerGroupInfo.customer_group_code || '--'}}
							</di.v>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.CustomerGroupName" | translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{customerGroupInfo.customer_group_name || '--'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{customField.CustomerGroupCode" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{customerGroupInfo.customer_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.CustomerGroupName" | translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{customerGroupInfo.customer_group_name || '--'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>