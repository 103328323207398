<div class="account-information" *ngIf="!isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"statementComponent.creditLimit" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{commonService.currencySymbol}} {{financialInfo.credit_limit || '0'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.CustomerBalance" | translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>
									{{commonService.currencySymbol}} {{financialInfo.balance || '0'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.RemainingBalance" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{commonService.currencySymbol}} {{financialInfo.remaining_balance || '0'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"statementComponent.creditLimit" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{commonService.currencySymbol}} {{financialInfo.credit_limit || '0'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.CustomerBalance" | translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>
									{{commonService.currencySymbol}} {{financialInfo.balance || '0'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.RemainingBalance" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{commonService.currencySymbol}} {{financialInfo.remaining_balance || '0'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>