<div *ngIf="getPlatformService.isDesktop">
	<div class="modal-close text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="add-edit-address-container" [ngClass]="getPlatformService.isDesktop ? 'paddingHeader':'p-25'">
		<div class="add-edit-address-desktop-header" *ngIf="getPlatformService.isDesktop">
			{{ address_id ? ('Editaddress' | translate) :('Newaddress' | translate) }}
		</div>
	</div>
</div>
<ion-header *ngIf="!getPlatformService.isDesktop" class="address-header" mode="md">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="address-header">
				{{ address_id ? ('Editaddress' | translate) :('Newaddress' | translate) }}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content id="addEditAddressContent">
	<div class="add-edit-address-container" [ngClass]="getPlatformService.isDesktop ? 'p-24-30':'ph-24'">
		<form [formGroup]="registerFromGroup">
			<div class="account-info">

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name">
							<!-- <ion-item lines="none" mode="md"> -->
							<!-- <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
								alt="Jazeera">
							<ion-label position="floating" *ngIf="getPlatformService.isDesktop" class="texUpperCase">{{
								'field.Firstname'
								| translate }}
								<span class="required-text">*</span>
							</ion-label>
							<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
								'field.Firstname' |
								translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
							<ion-input label="{{ 'field.Firstname' | translate }}" label-placement="floating"
								fill="outline" formControlName="firstname">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'firstname', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name">
							<!-- <ion-item lines="none" mode="md"> -->
							<!-- <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop"
									class="texUpperCase">{{
									'field.Lastname' |
									translate }}
									<span class="required-text">*</span>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'field.Lastname' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
							<ion-input label="{{ 'field.Lastname' | translate }}" label-placement="floating"
								fill="outline" formControlName="lastname">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'lastname', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="name">
							<!-- <ion-item lines="none" mode="md"> -->
							<!-- <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop"
									class="texUpperCase">{{
									'field.Address' |
									translate }}
									<span class="required-text">*</span>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'field.Address' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
							<ion-input label="{{ 'field.Address' | translate }}" label-placement="floating"
								fill="outline" formControlName="addressline1">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'addressline1', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name">
							<!-- <ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop"
									class="texUpperCase">{{
									'field.Zipcode' | translate }}
									<span class="required-text">*</span>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'field.Zipcode' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
							<ion-input label="{{ 'field.Zipcode' | translate }}" label-placement="floating"
								fill="outline" minlength="1" maxlength="5" formControlName="zipcode">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'zipcode', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name"
							[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic']">
							<!-- <ion-item lines="none" mode="md" class="new-address-modal-dropdowns"
								*ngIf="countryData.length > 0">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="isCountrySelected" class="texUpperCase">{{
									'field.Country' |
									translate }}
									<span class="required-text">*</span>
								</ion-label>
								<ion-label *ngIf="!isCountrySelected"
									[ngClass]="[ getPlatformService.isDesktop ? 'static-label' : 'static-label-mobile']">{{
									'field.Country' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->

							<ion-select label="{{ 'field.Country' | translate }}" label-placement="floating"
								fill="outline" cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'"
								formControlName="country" [interfaceOptions]="optionSelect"
								(ionChange)="getRegionList($event)"
								[ngClass]="[ isCountrySelected ? 'option-selected' : '']">
								<ion-select-option *ngFor="let data of countryData"
									value="{{data.country_id}}">{{commonService.domDirectionLTR
									? data.country_en :
									data.country_ar}}</ion-select-option>
							</ion-select>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'country', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name"
							[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic']">
							<!-- <ion-item lines="none" mode="md" class="new-address-modal-dropdowns"
								[disabled]="this.registerFromGroup.controls['country'].value ? false:true">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="isRegionSelected" class="texUpperCase">{{
									'field.Region' |
									translate }}
									<span class="required-text">*</span>
								</ion-label>
								<ion-label *ngIf="!isRegionSelected"
									[ngClass]="[ getPlatformService.isDesktop ? 'static-label' : 'static-label-mobile']">{{
									'field.Region' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->


							<ion-select label="{{ 'field.Region' | translate }}" label-placement="floating"
								fill="outline" cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'" formControlName="state"
								[interfaceOptions]="optionSelect" (ionChange)="getCityList()"
								[ngClass]="[ isRegionSelected ? 'option-selected' : '']">
								<ion-select-option *ngFor="let data of regionData"
									value="{{data.region_id}}">{{commonService.domDirectionLTR
									? data.region_en : data.region_ar}}</ion-select-option>
							</ion-select>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'state', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name"
							[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic']">
							<!-- <ion-item lines="none" mode="md" class="new-address-modal-dropdowns"
								[disabled]="this.registerFromGroup.controls['state'].value ? false:true">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="isCitySelected" class="texUpperCase">{{
									'field.City'
									| translate }}
									<span class="required-text">*</span>
								</ion-label>
								<ion-label *ngIf="!isCitySelected"
									[ngClass]="[ getPlatformService.isDesktop ? 'static-label' : 'static-label-mobile']">{{
									'field.City' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
							<ion-select label="{{ 'field.City' | translate }}" label-placement="floating" fill="outline"
								cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'" formControlName="city"
								[interfaceOptions]="optionSelect" (ionChange)="valueSelected($event)"
								[ngClass]="[ isCitySelected ? 'option-selected' : '']">
								<ion-select-option *ngFor="let data of cityData"
									value="{{data.city_id}}">{{commonService.domDirectionLTR
									?
									data.city_en : data.city_ar}}</ion-select-option>
							</ion-select>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'city', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="name">
							<!-- <ion-item class="mobile-phone" lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop"
									class="texUpperCase">{{ 'MOBILE_PHONE' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'mobile_phone'| translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
							<ion-input label="{{ 'MOBILE_PHONE' | translate }}" label-placement="floating"
								fill="outline" maxlength="9" inputmode="numeric" #mobileInput
								[ngClass]="commonService.domDirectionLTR ? 'mobilephone-input' : 'mobilephone-input-rtl'"
								formControlName="mobile_no" (ionFocus)="onMobilePhoneFocus()"
								(keypress)="phoneClick($event)" (ionBlur)="onMobilePhoneBlur()">
								<ion-label slot="start" for="" *ngIf="countryCodeLabel" class="country-code mr-8">
									+966
								</ion-label>
							</ion-input>

							<div class="tooltip"
								[ngClass]="commonService.domDirectionLTR ? 'text-align-en' : 'text-align-ar'"
								*ngIf="getPlatformService.isDesktop"><img src="./assets/icon/more_info.svg"
									alt="Jazeera">
								<span class="tooltiptext">
									{{commonService.domDirectionLTR
									? helpEnText : helpArText}}
								</span>
							</div>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'mobile_no', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>
				<br>
				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="name check-box-container">
							<ion-item lines="none" mode="md">
								<ion-checkbox slot="start" [(ngModel)]="is_default_billing"
									[ngModelOptions]="{standalone: true}"></ion-checkbox>
								<ion-label>{{'DefaultBillingAddress' | translate}}</ion-label>
							</ion-item>
						</div>
					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="name check-box-container">
							<ion-item lines="none" mode="md">
								<ion-checkbox slot="start" [(ngModel)]="is_default_shipping"
									[ngModelOptions]="{standalone: true}"></ion-checkbox>
								<ion-label>{{'DefaultShippingAddress' | translate}}</ion-label>
							</ion-item>
						</div>
					</ion-col>
				</ion-row>
			</div>

		</form>
	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName];">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
				{{validation.message |translate }}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>

<div class="login-bottom-container ph-30" *ngIf="getPlatformService.isDesktop">
	<div class="cancel mr-40" (click)="closeModal()">
		{{ 'Cancel' | translate }}
	</div>
	<div *ngIf="!address_id" class="login-button" (click)="onSubmit()">
		<ion-button>
			{{'Save' | translate}}
		</ion-button>
	</div>
	<div *ngIf="address_id" class="login-button" (click)="onUpdate()">
		<ion-button>
			{{'Update' | translate}}
		</ion-button>
	</div>
</div>

<ion-footer *ngIf="!getPlatformService.isDesktop">
	<div class="ph-20 pb-30">
		<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
			(click)="address_id ? onUpdate() : onSubmit()"
			title="{{address_id ? ('Editaddress'|translate) : ('Addaddress'|translate)}}" [isIcon]="false"
			[isHideInMobile]="false" [isFullWidth]="true" [specificFontSize]="'1rem'">
		</app-common-button>
	</div>
</ion-footer>