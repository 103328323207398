import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import Swiper from 'swiper';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, AfterViewInit {

	public pager: boolean = true;
	public slideIndex = 0;
	@ViewChild('topSlides', { static: false }) topSlider!: ElementRef;
	topSliderEl: any;
	// @ViewChild('slides', { static: false }) slider: IonSlides;
	// @ViewChild('topSlides', { static: false }) topSlider: IonSlides;
	// @ViewChild('topArSlides', { static: false }) topArSlider: IonSlides;
	// @ViewChild('slidesAr', { static: false }) slidesAr: IonSlides;
	// @ViewChild('slidesMobile', { static: false }) slidesMobile: IonSlides;
	// @ViewChild('slidesMobileAr', { static: false }) slidesMobileAr: IonSlides;
	// @ViewChild('slidesGrid', { static: false }) slidesGrid: IonSlides;
	// @ViewChild('slidesGridAr', { static: false }) slidesGridAr: IonSlides;
	// @ViewChild('slidesGridMobile', { static: false }) slidesGridMobile: IonSlides;
	// @ViewChild('slidesGridMobileAr', { static: false }) slidesGridMobileAr: IonSlides;

	topBannerOpts = {
		initialSlide: 0,
		slidesPerView: 1,
		spaceBetween: 10,
		speed: 200,
		autoplay: true,
		loop: true,
		pagination: false,
	};

	middleSlideOpts = {
		initialSlide: 0,
		slidesPerView: 1,
		speed: 200,
		autoplay: true,
		loop: false,
		spaceBetween: 20,
	};

	gridSlideOpts = {
		initialSlide: 0,
		slidesPerView: 1,
		speed: 200,
		autoplay: true,
		loop: false,
		spaceBetween: 20,
	};

	@Input() gridBanner: boolean = false;
	@Input() topBanner;
	@Input() multiBanner;
	@Input() TopBannerData;
	@Input() bottomBannerData;
	@Input() productlistBanner: boolean = false;
	@Input() productTitle: any;
	@Input() gridBannerData: any;
	@Input() bannerImage: any;
	@Input() bannerTitle: any;
	@Input() bannerDescription: any;
	currentLang;
	isLoggedIn = false
	constructor(
		public router: Router,
		private route: ActivatedRoute,
		public getPlatformService: GetPlatformService,
		public commonService: CommonService,
		public navCtrl: NavController,
		@Inject(PLATFORM_ID) private platformId: Object,
		public translate: TranslateService,
		private renderer: Renderer2) {
		this.currentLang = this.commonService.currentLang;
	}


	ngOnInit() {
		this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;

		if (this.productTitle == 'products') {
			this.productTitle = this.commonService.domDirectionLTR ? "Products" : "منتجات"
		}


		setTimeout(() => {
			if (this.TopBannerData && this.TopBannerData.length < 2) {
				this.pager = false;
				// this.topBannerOpts = {
				// 	initialSlide: 0,
				// 	slidesPerView: 1,
				// 	spaceBetween: 10,
				// 	breakpoints: {
				// 		768: {
				// 			slidesPerView: 1,
				// 		},
				// 	},
				// 	speed: 200,
				// 	autoplay: true,
				// 	loop: true,
				// };
			}
			if (this.bottomBannerData && this.bottomBannerData.length < 2) {
				this.middleSlideOpts = {
					initialSlide: 0,
					slidesPerView: 1,
					speed: 200,
					autoplay: true,
					loop: true,
					spaceBetween: 20,
				};
			}
			if (this.gridBannerData && this.gridBannerData.length < 2) {
				this.gridSlideOpts = {
					initialSlide: 0,
					slidesPerView: 1,
					speed: 200,
					autoplay: true,
					loop: true,
					spaceBetween: 20,
				};
			}
			// this.startAutoPlay()
		}, 500)
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			setTimeout(() => {
				this.topSliderEl = this.topSlider.nativeElement;
				Object.assign(this.topSliderEl, this.topBannerOpts);
				this.topSliderEl.swiper = new Swiper(this.topSliderEl, this.topBannerOpts);
			}, 500)
		}
	}

	slideTo(direction) {
		if (direction === 'next') {
			this.topSliderEl.swiper.slideNext();
		} else if (direction === 'prev') {
			this.topSliderEl.swiper.slidePrev();
		}
	}

	// slideTo(index, event?) {
	//     if (event) {
	//         event.preventDefault();
	//         event.stopPropagation();
	//     }
	//     if (this.slider) {
	//         this.slider.slideTo(index);
	//     }
	//     if (this.topSlider) {
	//         let nextslide = index;
	//         if (nextslide >= this.TopBannerData.length) {
	//             nextslide = 0;
	//         } else if (nextslide < 0) {
	//             nextslide = this.TopBannerData.length
	//         } else {
	//             nextslide = index;
	//         }
	//         this.topSlider.slideTo(nextslide);
	//     }
	//     if (this.topArSlider) {
	//         let nextslide = index;
	//         if (nextslide >= this.TopBannerData.length) {
	//             nextslide = 0;
	//         } else if (nextslide < 0) {
	//             nextslide = this.TopBannerData.length
	//         } else {
	//             nextslide = index;
	//         }
	//         this.topArSlider.slideTo(nextslide);
	//     }
	//     if (this.slidesAr) {
	//         this.slidesAr.slideTo(index);
	//     }
	//     if (this.slidesGrid) {
	//         this.slidesGrid.slideTo(index);
	//     }
	//     if (this.slidesGridAr) {
	//         this.slidesGridAr.slideTo(index);
	//     }
	// }

	// async slideChanged() {
	//     if (this.slider && this.slider.getActiveIndex()) {
	//         this.slideIndex = await this.slider.getActiveIndex();
	//     }
	//     if (this.slidesAr && this.slidesAr.getActiveIndex()) {
	//         this.slideIndex = await this.slidesAr.getActiveIndex();
	//     }
	//     if (this.slidesGrid && this.slidesGrid.getActiveIndex()) {
	//         this.slideIndex = await this.slidesGrid.getActiveIndex();
	//     }
	//     if (this.slidesGridAr && this.slidesGridAr.getActiveIndex()) {
	//         this.slideIndex = await this.slidesGridAr.getActiveIndex();
	//     }
	// }

	// startAutoPlay() {
	//     if (this.topSlider) {
	//         this.topSlider.startAutoplay();
	//     }
	//     if (this.topArSlider) {
	//         this.topArSlider.startAutoplay();
	//     }
	//     if (this.slider) {
	//         this.slider.startAutoplay();
	//     }
	//     if (this.slidesAr) {
	//         this.slidesAr.startAutoplay();
	//     }
	//     if (this.slidesMobile) {
	//         this.slidesMobile.startAutoplay();
	//     }
	//     if (this.slidesMobileAr) {
	//         this.slidesMobileAr.startAutoplay();
	//     }
	//     if (this.slidesGrid) {
	//         this.slidesGrid.startAutoplay();
	//     }
	//     if (this.slidesGridAr) {
	//         this.slidesGridAr.startAutoplay();
	//     }
	//     if (this.slidesGridMobile) {
	//         this.slidesGridMobile.startAutoplay();
	//     }
	//     if (this.slidesGridMobileAr) {
	//         this.slidesGridMobileAr.startAutoplay();
	//     }

	// }

	bannerToProduct(bannerData, redirecton_details, banner_id) {
		let sub_class_id = bannerData.redirecton_details ? bannerData.redirecton_details.sub_class_id : null;
		if (bannerData.is_default_banner) {
			return;
		}
		if (redirecton_details && redirecton_details.product_name) {
			this.commonService.localStorageRemove("sub_class_id");
			if (this.commonService.domDirectionLTR && redirecton_details.product_name_ar) {
				this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + "products", { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
			} else {
				this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + "products", { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
			}
		}
		else if (!this.commonService.domDirectionLTR && redirecton_details && redirecton_details.display_sub_class_name_ar && sub_class_id) {
			this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_sub_class_name_ar) + "/" + sub_class_id, { animated: false, "state": { "breadcrumb": redirecton_details.display_sub_class_name_ar, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_sub_class_name_ar), id: sub_class_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
		}
		else if (this.commonService.domDirectionLTR && redirecton_details && redirecton_details.display_sub_class_name_en && sub_class_id) {
			this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_sub_class_name_en) + "/" + sub_class_id, { animated: false, "state": { "breadcrumb": redirecton_details.display_sub_class_name_en, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_sub_class_name_en), id: sub_class_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
		}
		else if (!this.commonService.domDirectionLTR && redirecton_details && redirecton_details.display_class_name_ar) {
			this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_class_name_ar), { animated: false, "state": { "breadcrumb": redirecton_details.display_class_name_ar, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_class_name_ar), banner_data: banner_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
		}
		else if (this.commonService.domDirectionLTR && redirecton_details && redirecton_details.display_class_name_en) {
			this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_class_name_en), { animated: false, "state": { "breadcrumb": redirecton_details.display_class_name_en, "url": '/product-listing/' + this.commonService.trimUrl(redirecton_details.display_class_name_en), banner_data: banner_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
		}
		else {
			this.commonService.localStorageRemove("sub_class_id");
			if (this.commonService.domDirectionLTR) {
				this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/products', { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_en, banner_title: bannerData.banner_title_en, banner_description: bannerData.banner_description_en } });
			} else {
				this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/products', { animated: false, "state": { "breadcrumb": "products", "url": '/product-listing/' + "products", banner_data: banner_id, banner_img: bannerData.image_url_ar, banner_title: bannerData.banner_title_ar, banner_description: bannerData.banner_description_ar } });
			}
		}
	}

}
