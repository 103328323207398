import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AccountDashboardComponent } from "src/app/components/account-dashboard/account-dashboard.component";
import { AddressComponent } from "../../components/address/address.component";
import { AccountDetailsPage } from "./account-details.page";

const routes: Routes = [
	{
		path: "",
		component: AccountDetailsPage,
		children: [
			{
				path: "",
				redirectTo: "account-dashboard",
				pathMatch: "full",
				data: {
					title: "Dashboard",
					breadcrumb: "dashboard",
				},
			},
			{
				path: "account-dashboard",
				component: AccountDashboardComponent,
				data: {
					title: "Dashboard",
					breadcrumb: "dashboard",
					url: "/home/account-details/account-dashboard",
				},
			},
			{
				path: "my-account",
				data: {
					title: "My account",
					breadcrumb: "myAccount",
					url: "/home/account-details/my-account",
				},
				loadChildren: () => import("../../components/my-account/my-account.module").then((m) => m.MyAccountModule),
			},
			{
				path: "my-orders",
				loadChildren: () => import("../../components/orders/orders.module").then((m) => m.OrdersModule),
				data: {
					breadcrumb: "myOrders",
					title: "Orders",
					url: "/home/account-details/my-orders",
				},
			},
			{
				path: "my-invoice",
				loadChildren: () => import("../../components/invoices/invoices.module").then((m) => m.InvoicesModule),
				data: {
					breadcrumb: "myInvoices",
					title: "Invoices",
					url: "/home/account-details/my-invoice",
				},
			},
			{
				path: "my-statement",
				loadChildren: () => import("../../components/statement/statement.module").then((m) => m.StatementModule),
				data: {
					breadcrumb: "myStatement",
					title: "Statement",
					url: "/home/account-details/my-statement",
				},
			},
			{
				path: "voucher",
				loadChildren: () => import("../../components/voucher/voucher.module").then((m) => m.VoucherModule),
				data: {
					breadcrumb: "voucherRedemption",
					title: "Voucher Redemption",
					url: "/home/account-details/voucher",
				},
			},
			{
				path: "my-addresses",
				component: AddressComponent,
				data: {
					title: "Address",
					breadcrumb: "myAddresses",
					url: "/home/account-details/my-addresses",
				},
			},
			{
				path: "my-wishlist",
				data: {
					title: "Wishlist",
					breadcrumb: "myWishlist",
					url: "/home/account-details/my-wishlist",
				},
				loadChildren: () => import("../../pages/my-wishlist/my-wishlist.module").then((m) => m.MyWishlistPageModule),
			},
			{
				path: "notification",
				data: {
					title: "Notifications",
					breadcrumb: "notification",
					url: "/home/account-details/notification",
				},
				loadChildren: () => import("../../components/notification-category/notification-category.module").then((m) => m.NotificationCategoryModule),
			},
			{
				path: "setting",
				data: {
					title: "Settings",
					breadcrumb: "settings",
					url: "/home/account-details/setting",
				},
				loadChildren: () => import("../../components/setting/setting.module").then((m) => m.SettingModule),
			},
			{
				path: "return-order-listing",
				data: {
					title: "Return orders",
					breadcrumb: "returnOrder",
					url: "/home/account-details/return-order-listing",
				},
				loadChildren: () => import("../../components/return-order-listing/return-order-listing.module").then((m) => m.ReturnOrderListingModule),
			},
			{
				path: "cancel-order-listing",
				data: {
					title: "Cancel orders",
					breadcrumb: "cancelOrder",
					url: "/home/account-details/cancel-order-listing",
				},
				loadChildren: () => import("../../components/return-order-listing/return-order-listing.module").then((m) => m.ReturnOrderListingModule),
			},
			{
				path: "my-quote-request",
				loadChildren: () => import("../../components/my-quote-request/my-quote-request.module").then((m) => m.MyQuoteRequestModule),
				data: {
					breadcrumb: "myOrders",
					title: "My Quote Request",
					url: "/home/account-details/my-quote-request",
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AccountDetailsPageRoutingModule { }
