import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CheckOutStep1Page } from './checkout-step1';

const routes: Routes = [
    {
        path: '',
        component: CheckOutStep1Page,

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CheckOutStep1PageRoutingModule { }
