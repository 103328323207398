<ion-header class="account-menu-header" mode="md">
    <ion-toolbar mode="md">
        <img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
            [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
        <ion-title mode="ios">
            <div class="account-menu-header">
                {{"otherMenuComponent.others" | translate}}
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>

<div class="mobile-menu">
    <div class="menu-list">
        <ion-list>
            <ng-container *ngFor="let menu of menuOptions;index as i">
                <ion-item class="outer-item" lines='none' *ngIf="menu.is_enable && menuDisplay(menu)"
                    (click)="openModal(menu.id)">
                    <img *ngIf="menu.icon_url" [src]="menu.icon_url" alt="right-arrow" slot="start" class="menu-icon">
                    <ion-item class="inner-item" lines="none"
                        [ngClass]="i !=  menuOptions.length -1 ? 'bottom-border' : ''">
                        <ion-label class="menu-list-label pl-10">
                            {{menu.menu_name | translate}}
                        </ion-label>
                        <img src="./assets/icon/rightArrow.svg" alt="right-arrow" slot="end"
                            [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
                    </ion-item>
                </ion-item>
            </ng-container>
        </ion-list>
    </div>
</div>