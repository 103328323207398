import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderDetailsMobileRoutingModule } from './order-details-mobile-routing.module';

import { SharedModule } from '../../common/shared.module';
import { OrderDetailsMobileComponent } from './order-details-mobile.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        OrderDetailsMobileRoutingModule,
        SharedModule
    ],
    declarations: [OrderDetailsMobileComponent]
})
export class OrderDetailsMobilePageModule { }
