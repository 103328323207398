<ion-grid class="order-detail-grid" *ngIf="	!this.isLoading">
	<ion-row class="order-detail-header-row">
		<ion-col class="order-detail-header-col">
			<div class="order-detail-header">
				<div class="order-number">
					{{"orderDetailsComponent.orders" | translate}}{{ orderData['order_no']}}
				</div>
				<div class="order-detail-actions">

					<ion-button (click)="reorder()" class="filter-apply-button">
						<div class="re-order">
							<div class="mr-10">
								<img src="assets/icon/white-reorder.svg" alt="Jazeera">
							</div>
							<div>
								{{"orderDetailsComponent.reorder" | translate}}
							</div>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="order-detail-info pt-20">
				<div class="order-detail-date">
					<div class="common-detail-header">
						{{"orderDate" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['order_date'] | date : 'MMM dd, YYYY'}}
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-total">
					<div class="common-detail-header">
						{{"orderDetailsComponent.totalOrder" | translate}}
					</div>
					<div class="common-detail">
						{{commonService.currencySymbol}} {{orderData['total_price'] ? (orderData['total_price'] |
						number) :'0'}}
					</div>
				</div>
				<div class="vertical-line"></div>

				<div class="ship-to">
					<div class="common-detail-header">
						{{"orderDetailsComponent.shipTo" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['address']}} {{orderData['city_en'] ? ',':''}} {{orderData['city_en'] ?
						orderData['city_en'] :''}}{{orderData['region_en'] ? ',':''}}
						{{orderData['region_en'] ? orderData['region_en'] :''}} {{orderData['country_en'] ? ',':''}}
						{{orderData['country_en'] ?
						orderData['country_en'] :''}} {{orderData['zipcode'] ? ',':''}} {{orderData['zipcode'] ?
						orderData['zipcode'] :'--'}}
						<p class="m-0 p-0">
							{{orderData['mobile_no']}}
						</p>
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-total">
					<div class="common-detail-header">
						{{"customField.shippingNo" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['shipping_number'] ? orderData['shipping_number'] :'--'}}
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-detail-status pl-70">
					<div class="common-detail-header">
						{{"customField.paymentMode" | translate}}
					</div>
					<div>
						<div fill="clear" class="order-status" [ngClass]="orderStatus(orderData)">
							{{orderData['payment_type'] ? orderData['payment_type'] :'--'}}
						</div>
					</div>
				</div>
			</div>

			<div class="order-detail-info pt-20 justify-start">
				<div class="order-total max-col-200">
					<div class="common-detail-header">
						{{"customField.ReferenceNo" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['cx_reference_no'] ? orderData['cx_reference_no'] :'--'}}
					</div>
				</div>
				<div class="vertical-line"></div>
				<div class="order-detail-status max-col-200 pl-70">
					<div class="common-detail-header">
						{{"customField.ReferenceNo1" | translate}}
					</div>
					<div>
						<div fill="clear" class="order-status" [ngClass]="orderStatus(orderData)">
							{{orderData['sap_order_no'] ? orderData['sap_order_no'] :'--'}}
						</div>
					</div>
				</div>
			</div>

		</ion-col>
	</ion-row>
	<div class="scrollable-container">
		<ion-row class="order-detail-row row-header">
			<ion-col size-md="12" size-lg="8" size-xl="8" class="order-detail-left-col">
				<div class="order-summery-header pb-20">
					{{"customField.itemsList" | translate}}
				</div>
				<ion-grid class="order-detail-list">
					<ion-grid class="order-detail-inner-grid " *ngFor="let detailData of supplierData">
						<div class="bg-white mb-25 br-5 ph-16 pv-20">

							<div class="product-description">
								<div class="product-name pb-5" (click)="goToProductDetail(detailData)">
									{{ commonService.domDirectionLTR ? detailData.product_name_en :
									detailData.product_name_ar}}

									<span class="fw-500"><br>
										{{detailData.product_sku}}
									</span>
									<span class="fw-500 fs-14"><br>
										{{commonService.currencySymbol}} {{
										detailData.is_discount_applied ? detailData.discounted_price_per_product ?
										(detailData.discounted_price_per_product | number) :'0':
										detailData.price_per_unit ? (detailData.price_per_unit | number) :'0' }}<span
											class="product-unit">/{{
											commonService.domDirectionLTR ? detailData.unit_name_en :
											detailData.unit_name_ar}}</span>
									</span>
								</div>


								<div class="ion-text-center product-details-col pl-0">
									<div class="product-status">
										<ion-button *ngIf="detailData.order_item_status" fill="clear"
											class="order-status-btn"
											[ngClass]="statusMap[detailData.order_item_status]?.class || 'product-status-btn btn-red'">
											{{ detailData.order_item_status | translate }}
										</ion-button>

										<div *ngIf="detailData.is_cancellation_allowed || detailData.is_return_allowed"
											class="cancel-product">
											<ion-button fill="clear"
												class="order-status-btn product-status-btn return-request-btn"
												*ngIf="detailData.is_cancellation_allowed">
												{{"orderDetailsComponent.cancelProduct" | translate}}
											</ion-button>
											<span *ngIf="detailData.is_return_allowed"
												(click)="openCancelProductModal(detailData, true)">
												{{"orderDetailsComponent.returnProduct" | translate}}
											</span>
										</div>
									</div>
								</div>

							</div>
							<ion-row class="product-details-row pt-15 pb-10 scrollable-row">
								<div class="product-details-col pl-0">
									<div class="product-container">
										<div class="product-image" (click)="goToProductDetail(detailData)">
											<img [src]="detailData.cover_image" *ngIf="detailData.cover_image"
												alt="Jazeera">
											<img src="./assets/images/empty-img.png" *ngIf="!detailData.cover_image"
												alt="Jazeera">
										</div>

									</div>
								</div>


								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{ "orderDetailsComponent.orderQty" | translate}}
									</div>
									<div class="product-price">
										{{ detailData.ordered_quantity || "--" }}
									</div>
								</div>
								<div class="vertical-line"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{"orderDetailsComponent.deliverQty" | translate}}
									</div>
									<div class="product-price">
										{{ detailData.delivered_quantity || "--" }}
									</div>
								</div>

								<div class="vertical-line"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{"orderDetailsComponent.invoiceQty" | translate}}
									</div>
									<div class="product-price">
										{{ detailData.invoiced_quantity || "--" }}
									</div>
								</div>
								<div class="vertical-line"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{"orderDetailsComponent.balanceQty" | translate}}
									</div>
									<div class="product-price">
										{{ detailData.balance_quantity || "--" }}
									</div>
								</div>
								<div class="vertical-line"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{"orderDetailsComponent.subTotal" | translate}}
									</div>
									<div class="product-price">
										{{commonService.currencySymbol}} {{
										detailData.is_discount_applied ?
										detailData.discounted_price_per_product
										?
										(detailData.discounted_price_per_product | number) :'0':
										detailData.sub_total ? (detailData.sub_total | number) :
										'0'}}


									</div>
								</div>
							</ion-row>

							<ion-row *ngIf="detailData.isvisible"
								class="product-details-row pt-15 pb-10 scrollable-row">
								<div class="product-details-col pl-0">
									<div class="product-container">
										<div class="product-image">

										</div>

									</div>
								</div>


								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{ "customField.warehouseName" | translate}}
									</div>
									<div class="product-price">
										{{ detailData.warehouse_name || "--" }}
									</div>
								</div>
								<div class="vertical-line"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
										{{ "customField.warehouseID" | translate}}
									</div>
									<div class="product-price">
										{{ detailData.warehouse_id || "--" }}
									</div>
								</div>

								<div class="vertical-line bg-transparent"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
									</div>
									<div class="product-price">
									</div>
								</div>

								<div class="vertical-line bg-transparent"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
									</div>
									<div class="product-price">
									</div>
								</div>

								<div class="vertical-line bg-transparent"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
									</div>
									<div class="product-price">
									</div>
								</div>

								<div class="vertical-line bg-transparent"></div>

								<div class="product-details-col pl-0">
									<div class="order-detail-header">
									</div>
									<div class="product-price">
									</div>
								</div>

							</ion-row>

							<ion-row class="align-center justify-center">
								<div class="section-block cursor-pointer" (click)="expandProductDetails(detailData)">
									<p class=" m-0 p-0 color-primary text-bold">
										{{detailData.isvisible ? ('ViewLessDetails'|translate)
										:('ViewMoreDetails'|translate) }}
									</p>
									<ion-icon *ngIf="!detailData.isvisible" name="chevron-down-outline"
										class="color-primary ml-10 fs-18"></ion-icon>
									<ion-icon *ngIf="detailData.isvisible" name="chevron-up-outline"
										class="color-primary ml-10 fs-18"></ion-icon>
								</div>

							</ion-row>
						</div>
					</ion-grid>
				</ion-grid>
			</ion-col>
			<ion-col size-md="12" size-lg="4" size-xl="4" class="order-detail-right-col"
				[ngClass]="commonService.domDirectionLTR ? '' : 'order-detail-right-col-rtl'">
				<div class="order-summery-header pb-20">
					{{"orderDetailsComponent.orderSummery" | translate}}
				</div>

				<div>
					<app-order-summary [currency]="commonService.currencySymbol"
						[orderTotal]="orderData['total_order_amount']"
						[orderSubTotal]="orderData['total_items_sub_total']"
						[orderDiscount]="orderData['discount_applied']" [orderVAT]="orderData['total_vat_amount']"
						[orderShipping]="orderData.shipping_charges" isTotalSummary="false" [background]="'white'">
					</app-order-summary>

					<!-- [orderDiscount]="orderData.discount_price"
					[vatPercent]="orderData.vat_percent"
					[discountCode]="orderData.discount_code"
					[shippingVat]="orderData.shipping_vat_charges"
					[orderShippingWithoutVat]="orderData.shipping_charges_without_vat"
					[orderSummeryData]="orderData.grand_total" -->
				</div>
			</ion-col>
		</ion-row>
	</div>

</ion-grid>


<app-loader *ngIf="isLoading"></app-loader>