import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyQuoteRequestComponent } from './my-quote-request.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: MyQuoteRequestComponent,
    },
    // {
    //     path: ":id",
    //     component: OrderDetailsComponent,
    // }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyQuoteRequestRoutingModule { }
