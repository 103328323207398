<ion-header class="mobile-visibility" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBack()">
			<ion-title>{{ fromPage == "fromSupplierFaq" ? ("cmsComponent.sellerFaq" | translate) : ("cmsComponent.faq" |
				translate)}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content>
	<div class="detail-page" id="scrollId">
		<div class="cms-wrapper min-height-content">
			<div class="page-heading" *ngIf="getPlatformService.isDesktop">
				{{ fromPage == "fromSupplierFaq" ? ("cmsComponent.sellerFaq" | translate) : ("cmsComponent.faq" |
				translate)}}
			</div>
			<div *ngIf="faqsData.length > 0  && !isLoading">
				<ng-container *ngFor="let category of faqsData">
					<h1 class="main-heading textCapitilize">
						{{commonService.domDirectionLTR ? category.category_name_en :category.category_name_ar}}
					</h1>
					<hr>
					<ng-container *ngIf="category.faqs && category.faqs.length">
						<ion-accordion-group>
							<ion-accordion toggle-icon="" [value]="faq.faq_question_id"
								*ngFor="let faq of category.faqs">
								<ion-item slot="header" color="light">
									<ion-label>{{commonService.domDirectionLTR ? faq.faq_question_en +' ?':
										faq.faq_question_ar + ' ؟'}}
									</ion-label>
								</ion-item>
								<div class="ion-padding" slot="content" (click)="elementClicked($event)"
									[innerHTML]="commonService.domDirectionLTR ? (faq.faq_answer_en | safe :'html') : (faq.faq_answer_ar | safe :'html')">
								</div>
							</ion-accordion>
						</ion-accordion-group>
					</ng-container>
				</ng-container>
			</div>
			<div class="no-record width-100" *ngIf="noData && !isLoading">
				{{'noRecordFound' | translate}}
			</div>
		</div>
	</div>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>