<div class="home-page app-header bg-grey">
	<app-header></app-header>


	<ion-row class="row-header page-container">
		<div>
			<p class="header">{{"categoriesComponent.title" | translate}}</p>
		</div>

		<div class="display-flex">
			<div class="sort-by-dropdown" *ngIf="classData.length">
				<div class="sort-by-header">
					{{"filterBy" | translate}}
				</div>
				<ion-list>
					<ion-item class="sort-by-item">
						<ion-icon class="ml-3" src="assets/icon/relevancy_down_arrow.svg" slot="end"
							alt="Jazeera"></ion-icon>
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							(ionChange)="selectGroup($event)" interface="popover" [value]="classId"
							[interfaceOptions]="inerfaceOption" [multiple]="true">
							<ion-select-option *ngFor="let data of classData" [value]="data.class_id">
								{{commonService.domDirectionLTR ? data.display_class_name_en :
								data.display_class_name_ar}}
							</ion-select-option>

						</ion-select>
					</ion-item>
				</ion-list>
			</div>

			<div class="sort-by-dropdown">
				<div class="sort-by-header">
					{{"sortBy" | translate}}
				</div>
				<ion-list>
					<ion-item class="sort-by-item">
						<ion-icon class="ml-3" src="assets/icon/relevancy_down_arrow.svg" slot="end"
							alt="Jazeera"></ion-icon>
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							(ionChange)="selectSorting($event)" interface="popover" value="A-Z"
							[interfaceOptions]="inerfaceOption">
							<ion-select-option value="A-Z">{{'A-Z' |
								translate}}</ion-select-option>
							<ion-select-option value="Z-A">{{'Z-A' |
								translate}}</ion-select-option>
							<!-- <ion-select-option value="New_To_Old">{{'New To Old' |
								translate}}</ion-select-option>
							<ion-select-option value="Old_To_New">{{'Old To New' |
								translate}}</ion-select-option> -->
						</ion-select>
					</ion-item>
				</ion-list>
			</div>
		</div>

	</ion-row>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="getPlatformService.isDesktop && !isLoading" [ngClass]="[ showAll ? 'ion-content-scoll' : '']">
	<div class="recommended-container home-page pv-0" *ngIf="categoryData.length > 0">
		<ion-grid #content class="page-container min-height-500">
			<ion-row class="ion-justify-content-start">
				<ng-container *ngFor="let data of categoryData;index as i">
					<ion-col size-xs="6" size-sm="4" size-md="4" size-lg="4" size-xl="4">
						<div class="slide-container" (click)="goToProductListing(data)">
							<div class="product-image-container">
								<img src="{{ data.product_img }}" alt="jazeera" height="100%" width="100%">
							</div>
							<div class="product-detial-container">
								<div class="product-title ion-text-center">
									{{ commonService.domDirectionLTR ? data.product_title : data.product_title_ar }}
								</div>
								<div class="product-description ion-text-center" *ngIf="commonService.domDirectionLTR">
									{{data.product_description}}
								</div>
								<div class="product-description ion-text-center" *ngIf="!commonService.domDirectionLTR">
									{{data.product_descrption_ar}}
								</div>

							</div>

						</div>
					</ion-col>
				</ng-container>
			</ion-row>

		</ion-grid>

		<ion-row class="load-more-row ion-justify-content-center mb-50" *ngIf="categoryData.length > 0">
			<app-common-button *ngIf="enableNext" (click)="loadMoreData()" [btnStatus]="btnStatus" color="white"
				background="var(--ion-app-primary)" type="solid"
				title="{{ 'productGridComponent.Load more' | translate }}" [isIcon]="true"
				iconSrc="assets/icon/down-arrow.svg" [specificPaddingStart]="'40px'" [specificPaddingEnd]="'10px'"
				[isHideInMobile]="true" [specificWidth]="'190px'" [specificHeight]="'46px'"
				[specificFontWeight]="'bold'"></app-common-button>
		</ion-row>

	</div>

	<div *ngIf="categoryData && categoryData.length == 0 && !isLoading" class="norecord_height"
		[ngClass]="getPlatformService.isDesktop ? 'recommended-container':''">
		<span>{{'noRecordFound' | translate}}</span>
	</div>

	<div class="wrapper-block home-page">
		<app-footer *ngIf="getPlatformService.isDesktop"></app-footer>
	</div>
</ion-content>