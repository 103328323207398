import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RFQCartComponent } from 'src/app/components/rfq-cart/rfq-cart.component';
import { RFQConfirmationComponent } from 'src/app/components/rfq-confirmation/rfq-confirmation.component';
import { ModalService } from 'src/app/services/modal.service';
import { TranslateConfigService } from '../../services/translate-config.service';
import { URLS } from './../../_config/api.config';
import { OfferDialogComponent } from './../../components/offer-dialog/offer-dialog.component';
import { ApiService } from './../../services/api-data.service';
import { CartService } from './../../services/cart.service';
import { CommonService } from './../../services/common.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { ToastService } from './../../services/toast.service';

@Component({
	selector: 'app-cart-page',
	templateUrl: './cart-page.page.html',
	styleUrls: ['./cart-page.page.scss'],
})
export class CartPage implements OnInit, OnDestroy {
	closed$ = new Subject<any>();
	public cartData: any = [];
	public isShowDiscount: boolean = true;
	isLoading: boolean = false;
	isLoggedIn: boolean = false;
	showGetQuote: boolean = true;

	private subscription: Subscription = new Subscription();
	appliedCouponCode: any;
	appliedCouponCodeValue: any;
	codeApplied: any = false;
	isQuoteExist: boolean = false;
	currentLang;
	constructor(private translateConfigService: TranslateConfigService,
		public getPlatformService: GetPlatformService,
		public modalService: ModalService,
		public router: Router,
		public cartService: CartService,
		private dataService: ApiService,
		public navCtrl: NavController,
		private translate: TranslateService,
		private alertController: AlertController,
		private toastService: ToastService,
		public zone: NgZone,
		public commonService: CommonService) {
		this.currentLang = this.translate.currentLang;
		this.translateConfigService.getDefaultLanguage();

		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'profileData' || data.event == 'logoutCall') {
				const token = this.commonService.localStorageGet('accessToken');
				if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
				if (this.isLoggedIn) {
					this.isLoading = true;

					let params = this.commonService.localStorageGet('fromCartHeader');
					if (params != 'true' && this.router.url.includes("cart")) {
						this.removeAppliedCode();
					}
					else {
						this.cartService.getCart().then((data) => {
							this.cartData = data;
							for (let i = 0; i < this.cartData.result.length; i++) {
								this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
								if (this.showGetQuote) {
									break;
								}
							}
							this.routeHistory();
							this.isLoading = false;
						}).catch(error => {
							this.routeHistory();
							this.isLoading = false;
						});
					}
				}
			}
		});


	}

	routeHistory() {
		if (!this.cartService.cartList.length && this.getPlatformService.isDesktop && this.router.url.includes("cart")) {
			let url = this.commonService.localStorageGet('url');
			if (url) {
				this.commonService.localStorageRemove('url');
				this.navCtrl.navigateRoot(`/${this.currentLang}${url}`, { animated: false, replaceUrl: true })
			} else {
				this.navCtrl.navigateRoot('/' + this.currentLang + "/home/product-listing", { animated: false, replaceUrl: true })
			}
		}
	}

	ngOnInit() {
		this.commonService.routerEventVariable = this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			takeUntil(this.closed$)
		).subscribe(event => {
			const token = this.commonService.localStorageGet('accessToken');
			this.isQuoteExist = this.commonService.localStorageGet('tempQuoteId') != undefined ? true : false;

			if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

			if (this.isLoggedIn && this.router.url.includes("cart")) {
				this.isLoading = true;
				let params = this.commonService.localStorageGet('fromCartHeader');
				if (params != 'true' && this.router.url.includes("cart")) {
					this.removeAppliedCode();
				}
				else {

					this.cartService.getCart().then((data) => {
						this.cartData = data;
						for (let i = 0; i < this.cartData.result.length; i++) {
							this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
							if (this.showGetQuote) {
								break;
							}
						}
						this.routeHistory();
						this.isLoading = false;

					}).catch(error => {
						this.routeHistory();
						this.isLoading = false;
					});
				}

			}
		})
	}

	ionViewDidEnter() {
	}

	ionViewWillEnter() {
		this.translateConfigService.getDefaultLanguage();
		const token = this.commonService.localStorageGet('accessToken');

		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn && this.router.url.includes("cart")) {
			this.isLoading = true;
			let params = this.commonService.localStorageGet('fromCartHeader');
			if (params != 'true' && this.router.url.includes("cart")) {
				this.removeAppliedCode();
			}
			else {
				this.cartService.getCart().then((data) => {
					this.cartData = data;
					for (let i = 0; i < this.cartData.result.length; i++) {
						this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
						if (this.showGetQuote) {
							break;
						}
					}
					this.routeHistory();
					this.isLoading = false;
				}).catch(error => {
					this.routeHistory();
					this.isLoading = false;
				});

			}

		}
	}

	showDiscount() {
		// this.isShowDiscount = !this.isShowDiscount;
	}

	proceedCheckout() {
		let outOfstockProductExist: boolean;
		this.cartData.result.forEach(element => {
			element.products.forEach(element2 => {
				if (element2.is_sold_out) {
					outOfstockProductExist = true;
				}
			});
		});

		if (outOfstockProductExist == true) {
			this.toastService.displayToast(this.commonService.domDirectionLTR ? "One of product is out of stock in cart" : "أحد المنتجات غير متوفر في سلة التسوق", null, "danger")
			return;
		}
		if (this.cartData.grand_total.total_price < 0) {
			this.toastService.displayToast(this.commonService.domDirectionLTR ? "Not valid amount of cart for proceed to checkout" : "كمية عربة التسوق غير صالحة للشروع في الخروج", null, "danger")
			return;
		}
		this.router.navigate(['' + this.translate.currentLang + '/checkout-step-1'])
	}

	showOfferDialog(isMobile?) {
		let componentData;
		if (isMobile) {
			componentData = { mobileView: true }
		}

		let options: any = {
			componentProps: componentData,
			id: "offerDialog",
		};
		options['component'] = OfferDialogComponent;

		let modal: any = this.modalService.openModal(options);
		modal.then((data) => {
			if (data.data && data.data.coupon_id) {
				this.couponApplyCart(data.data.coupon_id);
				this.appliedCouponCode = data.data.coupon_code;
				this.appliedCouponCodeValue = data.data.coupon_code;
			}
		})


	}

	couponApplyCart(couponId) {
		const params = {
			"coupon_id": +couponId
		}

		if (this.commonService.localStorageGet('shipping_address_id')) {
			params['address_id'] = +this.commonService.localStorageGet('shipping_address_id')
		}
		else {
			params['city_id'] = +this.commonService.localStorageGet("shipping_city_id");
			params['latitude'] = +this.commonService.localStorageGet("latitude");
			params['longitude'] = +this.commonService.localStorageGet("longitude");

		}

		this.subscription.add(
			this.dataService.post(URLS.couponApply, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.codeApplied = true;
					this.cartData = data['data'];
					if (this.cartData.grand_total && this.cartData.grand_total.length) {
						this.cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
					}
					this.commonService.localStorageSet('discount_id', couponId);
					this.commonService.localStorageSet('discount_code', this.appliedCouponCodeValue);

				} else {
					this.codeApplied = false;
					this.appliedCouponCode = null;
					this.appliedCouponCodeValue = null;
					this.codeApplied = false;
					this.removeAppliedCode();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.codeApplied = false;
				this.appliedCouponCode = null;
				this.appliedCouponCodeValue = null;
				this.codeApplied = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}
	applyCodeToCart() {
		if (!this.appliedCouponCodeValue || this.appliedCouponCodeValue.trim() === '') {
			this.toastService.displayToast(this.commonService.domDirectionLTR ? "Please enter discount code" : "الرجاء إدخال رمز الخصم", null, 'danger');
			return;
		}
		this.codeApplied = false;
		this.appliedCouponCode = this.appliedCouponCodeValue;
		const params = {
			"coupon_code": this.appliedCouponCodeValue,
		}

		if (this.commonService.localStorageGet('shipping_address_id')) {
			params['address_id'] = +this.commonService.localStorageGet('shipping_address_id')
		}
		else {
			params['city_id'] = +this.commonService.localStorageGet("shipping_city_id");
			params['latitude'] = +this.commonService.localStorageGet("latitude");
			params['longitude'] = +this.commonService.localStorageGet("longitude");

		}

		this.subscription.add(
			this.dataService.post(URLS.couponApply, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.codeApplied = true;
					this.commonService.localStorageSet('discount_code', this.appliedCouponCodeValue);
					this.cartData = data['data'];
					if (this.cartData.grand_total && this.cartData.grand_total.length) {
						this.cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
					}
				} else {
					this.codeApplied = false;
					this.appliedCouponCode = null;
					this.appliedCouponCodeValue = null;
					this.codeApplied = false;
					this.removeAppliedCode();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.codeApplied = false;
				this.appliedCouponCode = null;
				this.appliedCouponCodeValue = null;
				this.codeApplied = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	removeAppliedCode() {
		this.commonService.localStorageSet('discount_id', null);
		this.commonService.localStorageSet('discount_code', null);
		this.appliedCouponCode = null;
		this.appliedCouponCodeValue = null;
		this.codeApplied = false;
		this.isLoading = true;

		this.cartService.getCart().then((data) => {
			this.cartData = data;
			for (let i = 0; i < this.cartData.result.length; i++) {
				this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
				if (this.showGetQuote) {
					break;
				}
			}
			this.isLoading = false;
		}).catch(error => {
			this.isLoading = false;
		});
	}

	quantityInput(data) {
		if (data.total_quantity === 'e' || data.total_quantity === '+' || data.total_quantity === '-') {
			data.total_quantity = data.min_quantity;
		}
		if (data.total_quantity == 0 || !data.total_quantity || data.total_quantity == null) {
			data.total_quantity = data.min_quantity;
		}

	}

	async qtychange(flag, data, supplier) {

		let dumpData = data;

		if (flag == 'enter') {
			if (dumpData.total_quantity <= dumpData.min_quantity) {
				dumpData.total_quantity = dumpData.min_quantity;
			}
			if (dumpData.total_quantity >= dumpData.available_quantity) {
				return;
			}
		}
		if (flag == 'minus') {
			if (dumpData.total_quantity <= dumpData.min_quantity) {
				return;
			}
			dumpData.total_quantity--;
		}
		if (flag == 'add') {
			if (dumpData.total_quantity >= dumpData.available_quantity) {
				return;
			}
			dumpData.total_quantity++;
		}
		await this.cartService.updateCart(supplier.cart_id, dumpData).then(data2 => {
			if (data2 && data2['code'] == 200) {
				this.cartService.getCart().then((data) => {
					this.cartData = data;
					for (let i = 0; i < this.cartData.result.length; i++) {
						this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
						if (this.showGetQuote) {
							break;
						}
					}
				}).catch(error => {
				});
			}
		});;

	}

	async deleteProduct(data, cartData, event) {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		const alertCtrl = await this.alertController.create({
			header: this.translate.instant("DeleteCartProduct"),
			message: this.translate.instant("DeleteCartProductMsg"),
			buttons: [
				{
					text: this.translate.instant("no"),
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: this.translate.instant("yes"),
					role: 'confirm',
					handler: () => {
						this.isLoading = true;
						this.cartService.deleteCart(data).then(data2 => {
							if (data2 && data2['code'] == 200) {
								this.isLoading = false;
								this.removeAppliedCode();
								this.commonService.setEvent("removeFromCart", null);
								this.cartService.getCart().then((data) => {
									this.cartData = data;
									for (let i = 0; i < this.cartData.result.length; i++) {
										this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
										if (this.showGetQuote) {
											break;
										}
									}
								}).catch(error => {
									this.isLoading = false;
								});
							}
						});
					},
				},
			],
		});

		await alertCtrl.present();
		const { role } = await alertCtrl.onDidDismiss();



	}


	openLoginModal() {
		this.modalService.dismissModal();
		this.navCtrl.navigateForward('/' + this.currentLang + '/login');
	}

	handleRefresh(event) {
		setTimeout(() => {
			// code maybe required
			// this.isLoading = false;
			if (this.isLoggedIn) {
				this.isLoading = true;
				this.cartService.getCart().then((data) => {
					this.cartData = data;
					for (let i = 0; i < this.cartData.result.length; i++) {
						this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
						if (this.showGetQuote) {
							break;
						}
					}
					this.isLoading = false;
				}).catch(error => {
					this.isLoading = false;
				});
			}
			event.target.complete();
		}, 1000);
	};

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let catnameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.sub_class_name_en : data.sub_class_name);
		this.navCtrl.navigateForward('/' + this.currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
			{
				animated: false,
				"state": {
					"breadcrumb": data.product_name_en,
					"url": "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				}
			});
	}


	ngOnDestroy() {
		this.commonService.routerEventVariable.unsubscribe();

	}

	async goBack() {
		await this.navCtrl.navigateRoot('/' + this.currentLang + '/home', { animated: false });
		this.commonService.localStorageRemove('tempQuoteId');
	}

	clearCart() {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.clearCartAPI).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.commonService.localStorageRemove('discount_id');
					this.commonService.localStorageRemove('discount_code');

					this.cartService.getCart().then((data) => {
						this.cartData = data;
						for (let i = 0; i < this.cartData.result.length; i++) {
							this.showGetQuote = this.cartData.result[i].products.some(obj => !obj.is_sample_product);
							if (this.showGetQuote) {
								break;
							}
						}
						this.isLoading = false;
					}).catch(error => {
						this.isLoading = false;
					});
				}
				else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}

			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	isnumeric(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
		let pattern = /^([0-9])$/;
		let result = pattern.test(e.key);
		return result;
	}

	calculateSUOM(ratio, quantity) {
		if (ratio && quantity) {
			return ratio * (+quantity);
		} else {
			return ratio;
		}
	}
	openRfqConfirmation(cartData) {
		const token = this.commonService.localStorageGet('accessToken');
		if (!token) {

			this.navCtrl.navigateForward('/' + this.currentLang + '/login');
		}
		else {
			let tempCartArray = [];
			cartData.forEach(seller => {
				seller.products.forEach(element => {
					if (!element.is_sample_product) {
						tempCartArray.push({ product_id: element.product_id, quantity: element.total_quantity });
					}
				});
			});


			let options: any = {
				id: "addReviewModal",
				cssClass: 'rfq-popover',
				componentProps: {
					from: 'cart',
					cartArray: tempCartArray
				},
			};
			options['component'] = RFQConfirmationComponent;
			let modal: any = this.modalService.openModal(options);
			modal.then((data) => {
				if (data && data.data) {
					// value can be mail or pdf
					if (data.data.value) {
						this.openRFQCartModal(data.data.value, data.data.quote_id);
					}
				}
			})
		}
	}

	openRFQCartModal(value, quote_id) {
		if (!this.getPlatformService.isDesktop) {
			const navigationExtras: NavigationExtras = {
				queryParams: {
					value: value,
					quoteId: quote_id
				},
			};
			this.router.navigate(['' + this.translate.currentLang + '/home/rfq-mobile'], navigationExtras)
		}
		else {
			let options: any = {
				id: "addReviewModal",
				cssClass: 'rfq-popover',
				componentProps: {
					value: value,
					quoteId: quote_id
				},
			};
			options['component'] = RFQCartComponent;
			let modal: any = this.modalService.openModal(options);
			modal.then((data) => {
				if (data && data.data) {
				}
			})
		}
	}
}
