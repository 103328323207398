import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { MaskitoElementPredicate } from './../../../../node_modules/@maskito/core/src/lib/types/element-predicate.d';
import { MaskitoOptions } from './../../../../node_modules/@maskito/core/src/lib/types/mask-options.d';

@Component({
	selector: 'app-payment-card',
	templateUrl: './payment-card.component.html',
	styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit {

	mobileView = false;
	isLoading = false;
	@Input() paymentInfo: any;
	paymentForm: FormGroup;
	private subscription: Subscription = new Subscription();

	readonly cardMask: MaskitoOptions = {
		mask: [
			...Array(4).fill(/\d/),
			' ',
			...Array(4).fill(/\d/),
			' ',
			...Array(4).fill(/\d/),
			' ',
			...Array(4).fill(/\d/),
		],
	};

	readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();
	// Current month and year
	currentYear: number = new Date().getFullYear();
	currentMonth: number = new Date().getMonth() + 1; // months are zero-indexed in JS, so add 1

	// Variable to bind with the input
	// myExpiryDate = maskitoTransform('4567', this.expiryDateMask); // if ngmodel use

	payment_method: any;

	constructor(private formBuilder: FormBuilder,
		private alertController: AlertController,
		public commonService: CommonService,
		public modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public getPlatformService: GetPlatformService,
		private translateConfigService: TranslateConfigService,
		public navCtrl: NavController,
		public router: Router,
		public cartService: CartService,
		private translate: TranslateService,
		public quoteService: QuoteService,
	) {
		// Initialize the form group with controls and validation
		this.paymentForm = this.formBuilder.group({
			cardNumber: ['', [Validators.required, this.cardNumberValidator]],  // Card number must be 16 digits
			expiryMonth: ['', [
				Validators.required,
				Validators.pattern(/^(0[1-9]|1[0-2])$/), // Must be a valid month (01-12)
				this.expiryMonthValidator.bind(this) // Custom month validation
			]],
			expiryYear: ['', [
				Validators.required,
				Validators.pattern(/^\d{4}$/),  // Must be a valid year (YYYY)
				this.expiryYearValidator.bind(this) // Custom year validation
			]],
			cvv: ['', [Validators.required, Validators.pattern(/^\d{3,4}$/)]],         // CVV should be 3 or 4 digits
		});
	}

	// Custom Validator to strip spaces and validate card number
	cardNumberValidator(control: AbstractControl): ValidationErrors | null {
		const value = control.value?.replace(/\s/g, ''); // Remove spaces
		const pattern = /^\d{16}$/; // Validate 16 digits
		return pattern.test(value) ? null : { invalidCardNumber: true };
	}

	ngOnInit() {
		this.payment_method = this.commonService.localStorageGet('selectedPaymentMethod') ? JSON.parse(this.commonService.localStorageGet('selectedPaymentMethod')) : null;
		console.log(this.payment_method);
	}

	// Get form controls to easily access them in the template
	get cardNumber() {
		return this.paymentForm.get('cardNumber');
	}

	get expiryMonth() {
		return this.paymentForm.get('expiryMonth');
	}

	get expiryYear() {
		return this.paymentForm.get('expiryYear');
	}

	get cvv() {
		return this.paymentForm.get('cvv');
	}

	// Custom validator for expiry month: Ensures month is not in the past
	expiryMonthValidator(control: any) {
		if (!control.value) return null; // Don't validate if the value is empty

		const month = parseInt(control.value, 10);
		const year = parseInt(this.paymentForm.get('expiryYear')?.value, 10);

		// If month is not valid (should be between 1 and 12)
		if (month < 1 || month > 12) {
			return { invalidMonth: true };
		}

		// If the year is the current year and the month is in the past
		if (year === this.currentYear && month < this.currentMonth) {
			return { monthInThePast: true };
		}

		return null;
	}

	// Custom validator for expiry year: Ensures year is at least the current year, and at least one year ahead
	expiryYearValidator(control: any) {
		if (!control.value) return null; // Don't validate if the value is empty

		const year = parseInt(control.value, 10);

		// Year must be at least the current year, and ideally one year in the future
		if (year < this.currentYear) {
			return { invalidYear: true };
		}

		return null;
	}

	// Submit the payment form
	async submitPayment() {
		if (this.paymentForm.invalid) {
			return;
		}

		this.isLoading = true;
		let shipping_id = this.commonService.localStorageGet('cart_shipping_address');
		let billing_id = this.commonService.localStorageGet('cart_billing_address');

		const params = {
			"shipping_address_id": +shipping_id,
			"billing_address_id": +billing_id,
		}
		if (this.payment_method && this.payment_method.payment_type_id) {
			params['payment_type'] = this.payment_method.payment_type_id
		}

		let discount_id = this.commonService.localStorageGet('discount_id');
		let discount_code = this.commonService.localStorageGet('discount_code');

		if ((discount_id || discount_code) && (discount_code != "null" ||
			discount_id != "null") && (discount_code != null || discount_id != null)) {
			if (discount_id && discount_id != "null" && discount_id != null) {
				params['discount_code_id'] = discount_id;
			}
			if (discount_code && discount_code != "null" && discount_code != null) {
				params['discount_code'] = discount_code;
			}
		}

		const cardNumber = this.paymentForm.get('cardNumber').value.trim().replace(/\s+/g, '');  // Remove spaces
		params["card_number"] = cardNumber;
		params["expiration_month"] = +this.paymentForm.get('expiryMonth').value;
		params["expiration_year"] = +this.paymentForm.get('expiryYear').value;
		params["cvv"] = +this.paymentForm.get('cvv').value;

		this.subscription.add(
			this.dataService.post(URLS.checkoutInvoice, params).subscribe(async data => {
				if (data && data['code'] == 200) {
					this.isLoading = false;
					this.commonService.localStorageRemove('fromCartHeader');
					if (data && data['data']) {
						if (this.commonService.localStorageGet('tempQuoteId')) {
							await this.quoteService.updateQuote(this.commonService.localStorageGet('tempQuoteId'), null, null, null, data['data'].invoice_id).then(data2 => {
								if (data2 && data2['code'] == 200) {
									this.commonService.localStorageRemove('tempQuoteId');
								}
							}).catch(error => {
								console.log(error);
							});
						}

						// window.location.href = data['data'].paymentUrl;


						this.router.navigate(['' + this.translate.currentLang + '/home/order-payment'], {
							queryParams: {
								"orderId": data['data'].order_no ? data['data'].order_no : 0,
								"id": data['data'].invoice_id ? data['data'].invoice_id : 0,
								"payment_url": data['data'].payment_url ? data['data'].payment_url : null,
								"transaction_id": data['data'].transaction_id ? data['data'].transaction_id : null,
							}
						});
					}


				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

}
