import { Location } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CustomValidator } from '../../../validators/customvalidators';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.page.html',
    styleUrls: ['./contact-us.page.scss'],
})
export class ContactUsPage implements OnInit {
    public fileToUpload: any;
    public files: any;
    public countryCodeLabel = false;
    isRegionSelected = false;
    regionData: any = [];
    public isLoading = false;
    public optionSelect = {
        cssClass: 'location-popover',
        size: 'cover'
    }

    public message: any;

    contactFormGroup: FormGroup

    private subscription: Subscription = new Subscription();

    public validation_messages = {
        firstName: [
            { type: 'required', message: 'careerPage.firstNameRequired' },
            { type: 'invalidName', message: 'careerPage.firstNameInvalid' },
            { type: 'maxlength', message: 'careerPage.firstNameMaxlength' }

        ],
        // code maybe required
        lastName: [
            { type: 'required', message: 'careerPage.lastNameRequired' },
            { type: 'invalidName', message: 'careerPage.lastNameInvalid' },
            { type: 'maxlength', message: 'careerPage.lastNameMaxlength' }
        ],
        email: [
            { type: 'required', message: 'loginComponent.emailRequired' },
            { type: 'invalidEmail', message: 'loginComponent.emailInvalid' }
        ],
        mobilePhone: [
            { type: 'required', message: 'careerPage.mobilePhoneRequired' },
            { type: 'invalidString', message: 'careerPage.numberInvalid' },
            { type: 'invalidNumber', message: 'careerPage.numberInvalid' }

        ],
        region: [
            { type: 'required', message: 'careerPage.regionRequired' },
        ],
        message: [
            { type: 'required', message: 'careerPage.messageRequired' },
            { type: 'maxlength', message: 'careerPage.messageMaxlength' }

        ]
    }
    currentLang;
    constructor(
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        public router: Router,
        private toastService: ToastService,
        private formBuilder: FormBuilder,
        private dataService: ApiService,
        private location: Location,
        public navCtrl: NavController,
        public translate: TranslateService
    ) {
        this.currentLang = this.translate.currentLang;
     }

    ngOnInit() {
        this.contactFormGroup = this.initializecontactFormGroup();
    }

    ionViewWillEnter() {
        this.getRegionList();
    }

    get f1() { return this.contactFormGroup.controls; }


    onMobilePhoneFocus() {
        this.countryCodeLabel = true;
    }

    getCityList() {
        this.isRegionSelected = true;
    }

    onMobilePhoneBlur(event) {
        if (!event.target.value) {
            this.countryCodeLabel = false;
        } else {
            this.countryCodeLabel = true;
        }
    }

    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    getRegionList() {
        this.isLoading = true;
        let params = {
            dropdown: true,
            page: 1,
            limit: 1000
        }

        this.subscription.add(
            this.dataService.get(URLS.regionList, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.regionData = data['data']['result'];
                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )

    }

    remove(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    contactFormSubmit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (this.contactFormGroup.valid) {
            this.isLoading = true;
            const params = {
                enquirer_firstname: this.f1.firstName.value,
                enquirer_lastname: this.f1.lastName.value,
                enquirer_number: this.f1.mobilePhone.value,
                enquirer_email: this.f1.email.value,
                enquiry_message: this.f1.message.value,
                region_id: this.f1.region.value.toString()
            }

            this.subscription.add(
                this.dataService.post(URLS.contactUs, params).subscribe(data => {
                    if (data['data'] && data['code'] == 200) {
                        this.isLoading = false;
                        this.contactFormGroup.reset();
                        setTimeout(() => {
                            this.isRegionSelected = false;
                            this.countryCodeLabel = false;
                        }, 400);
                        this.router.navigate(['/home']);
                    } else {
                        this.isLoading = false;
                        this.toastService.displayToast(data['message'], null, 'danger');
                    }
                }, error => {
                    this.isLoading = false;
                    this.toastService.displayToast(error['message'], null, 'danger');
                })
            )
        }
    }

    clearAndGoBack() {
        this.contactFormGroup.reset();
        this.navCtrl.navigateForward('/' + this.currentLang + "/home", { animated: false });
    }

    initializecontactFormGroup() {
        return this.formBuilder.group({

            firstName: new FormControl(null, {
                validators: Validators.compose([Validators.required, this.translate.currentLang == 'en' ? CustomValidator.nameValidator : CustomValidator.arabicNameValidator, Validators.maxLength(20)]),
            }),
            lastName: new FormControl(null, {
                validators: Validators.compose([Validators.required, this.translate.currentLang == 'en' ? CustomValidator.nameValidator : CustomValidator.arabicNameValidator, Validators.maxLength(20)]),
            }),
            email: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
            }),
            mobilePhone: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.phoneValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            region: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            message: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(200)]),
            }),
        })
    }

    goBack() {
        this.navCtrl.navigateForward('/' + this.currentLang + "/home", { animated: false });
    }

    goBackMobile() {
        this.navCtrl.back({ animated: false });
    }
}
