import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from './../../services/api-data.service';
import { URLS } from 'src/app/_config/api.config';

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {

	private subscription: Subscription = new Subscription();
	public isLoading = false;
	@Input() categoryData: any = [];
	@Output() loadMoreData = new EventEmitter();
	@Input() enableNext: boolean = false;
	totalCount: any;

	constructor(
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		private route: ActivatedRoute,
		private navCtrl: NavController,
		private translate: TranslateService,
	) {
	}

	ionViewWillEnter() {
		this.getProductCount();
	}


	ngOnInit() {
		this.getProductCount();
	}

	loadData(event) {
		this.loadMoreData.emit(event);
	}

	getProductCount() {
		this.isLoading = true;
		let params = {
			page: 1,
			size: 10
		}
		this.subscription.add(
			this.dataService.post(URLS.productlist, params).subscribe(
				(data) => {
					if (data["code"] == 200) {
						this.totalCount = data["data"]["total_count"];
						this.isLoading = false;

					} else {
						this.isLoading = false;
					}
				},
				(error) => {
					if (error.code == 500) {
						this.isLoading = false;
					}
				}
			)
		);
	}
	async goToProductListing(data) {
		let sub_class_id = data.sub_class_id;
		let subClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);
		let currentLang = this.commonService.currentLang;
		await this.navCtrl.navigateForward('/' + currentLang + '/product-listing/' + subClassNameURL + "/" + sub_class_id, {
			animated: false, "state": {
				"breadcrumb": this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en,
				"url": '/' + currentLang + '/product-listing/' + subClassNameURL + "/" + sub_class_id,
				id: data.sub_class_id,
				"ar_label": data.display_sub_class_name_ar,
				"banner_img": data.sub_class_image_url
			}
		});
	}

	async goProductListing() {
		let currentLang = this.commonService.currentLang;

		await this.navCtrl.navigateForward('/' + currentLang + '/product-listing/', {
			animated: false, "state": {
				"breadcrumb": this.commonService.domDirectionLTR ? "View All" : "مشاهدة الكل",
				"url": '/' + currentLang + '/product-listing/',
				"ar_label": "مشاهدة الكل",
			}
		});
	}
}
