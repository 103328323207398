<div class="orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{"statementComponent.title" | translate}}
		</h1>
		<div class="search-and-filter">
			<div class="filter-div">
				<div class="printer-icon">
					<app-common-button (click)="downloadExcel()" [btnStatus]="btnStatus" color="white"
						background="var(--ion-color-green-80)" type="solid" title="{{ 'export' | translate }}"
						[isIcon]="true" iconSrc="assets/icon/fi_printer.svg" [specificPaddingStart]="'20px'"
						[specificPaddingEnd]="'20px'" [isHideInMobile]="true" [specificWidth]="'auto'"
						[specificHeight]="'50px'" [specificFontWeight]="'bold'" [margin]="0"></app-common-button>

				</div>
				<div class="search-div">
					<!-- <ion-item lines="none"> -->
					<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
						[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (keyup.enter)="search()">
						<img src="./assets/icon/search.svg" slot="start" (click)="search()" alt="Jazeera">
						<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
							alt="Jazeera" (click)="clearSearch()">
					</ion-input>
					<!-- </ion-item> -->
				</div>
			</div>
		</div>
	</div>

	<!-- -------------- default Date ---------------- -->
	<form [formGroup]="defaultDateRange">
		<div class="filter-section">
			<div class="filter-header">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'customField.selectDate' |
						translate}}*</ion-label>
				</div>

				<div>
					<ion-row>
						<div class="input-div-container">
							<div class="from-date-div">
								<ion-item class="from-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="defaultFromDateId">
									<ion-input value="{{ defaultFromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
	                | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="defaultFromDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date" [min]="'2000-01-01'"
												[max]="defaultToDate ? defaultToDate : maxFromDate"
												[(ngModel)]="defaultFromDate" formControlName="defaultfrom"
												#defaultfromDate (ionChange)="onDateChange(defaultfromDate)"><span
													slot="title">{{"from"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>

						<div class="input-div-container">
							<div class="to-date-div">
								<ion-item class="to-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="defaultToDateId">
									<ion-input value="{{defaultToDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
					                | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="defaultToDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date"
												[min]="defaultFromDate ? defaultFromDate : '2000-01-01'" [max]="maxDate"
												[(ngModel)]="defaultToDate" formControlName="defaultto" #defaulttoDate
												(ionChange)="onDateChange(defaulttoDate)"><span slot="title">{{"to"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>

						<div class="filter-buttons">

							<button class="filter-apply-button" (click)="applyDefaultBtn()">{{"apply" |
								translate}}</button>
							<div class="apply-button ion-text-end">
								<button class="filter-apply-button" (click)="clearDefaultFilter()">{{"clear" |
									translate}}</button>
							</div>
						</div>

					</ion-row>

					<ion-row>

					</ion-row>
				</div>
			</div>
		</div>
	</form>

	<ng-container>
		<div class="height-1 bg-new-grey width-100"></div>
		<div class="listing-header" *ngIf="showHeader">
			<div class="search-and-filter justify-start mt-10">
				<div class="filter-div">
					<div class="section-block ">
						<div class="filter-icon" (click)="openFilters()" *ngIf="!showFilter">
							<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
						</div>
						<div class="filter-icon" (click)="openFilters()" *ngIf="showFilter">
							<img src="./assets/icon/modal-close-mobile.svg" alt="Jazeera">
						</div>
						<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''" (click)="openFilters()">
							{{"ordersComponent.filters" | translate}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>


	<form [formGroup]="dateRange" *ngIf="showFilter">
		<div class="filter-section">

			<!-- ---------------------- post_date filter -------------- -->

			<div class="filter-header">
				<div class="status-label">
					<ion-label
						[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.post_date' |
						translate}}</ion-label>
				</div>
				<ion-row>
					<div class="input-div-container">
						<div class="from-date-div">
							<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
								id="fromDateId">
								<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
                | translate}}" class="ion-text-start"></ion-input>
								<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
									alt="Jazeera"></ion-icon>
								<ion-popover trigger="fromDateId" size="cover" id="datePopover">
									<ng-template>
										<ion-datetime presentation="date" [min]="'2000-01-01'"
											[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
											formControlName="from" #fromdate (ionChange)="onDateChange(fromdate)"><span
												slot="title">{{"from"
												| translate}}</span></ion-datetime>
									</ng-template>
								</ion-popover>
							</ion-item>
						</div>
					</div>

					<div class="input-div-container">
						<div class="to-date-div">
							<ion-item class="to-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
								id="toDateId">
								<ion-input value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
				                | translate}}" class="ion-text-start"></ion-input>
								<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
									alt="Jazeera"></ion-icon>
								<ion-popover trigger="toDateId" size="cover" id="datePopover">
									<ng-template>
										<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'"
											[max]="maxDate" [(ngModel)]="toDate" formControlName="to" #todate
											(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
												| translate}}</span></ion-datetime>
									</ng-template>
								</ion-popover>
							</ion-item>
						</div>
					</div>
				</ion-row>
			</div>

			<!-- ---------------------- doc_date filter -------------- -->

			<div class="filter-header">
				<div class="status-label">
					<ion-label
						[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.doc_date'|translate}}*</ion-label>
				</div>

				<div>
					<ion-row>
						<div class="input-div-container">
							<div class="from-date-div">
								<ion-item class="from-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="docFromDateId">
									<ion-input value="{{ docFromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
				                | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="docFromDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date" [min]="'2000-01-01'"
												[max]="docToDate ? docToDate : maxFromDate" [(ngModel)]="docFromDate"
												formControlName="docfrom" #docfromDate
												(ionChange)="onDateChange(docfromDate)"><span slot="title">{{"from"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>

						<div class="input-div-container">
							<div class="to-date-div">
								<ion-item class="to-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="docToDateId">
									<ion-input value="{{docToDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
								                | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="docToDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date"
												[min]="docFromDate ? docFromDate : '2000-01-01'" [max]="maxDate"
												[(ngModel)]="docToDate" formControlName="docto" #doctoDate
												(ionChange)="onDateChange(doctoDate)"><span slot="title">{{"to"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>
					</ion-row>
				</div>
			</div>

			<!-- ---------------------- doc_no_filter -------------- -->
			<div class="filter-header mr-16">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.doc_no' |
						translate}}</ion-label>
				</div>
				<ion-row>
					<div class="input-div-container">
						<div class="from-date-div">
							<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
								<ion-input [(ngModel)]="doc_no_filter" [ngModelOptions]="{standalone: true}"
									placeholder="{{'statementComponent.doc_no'| translate}}" appDigitDecimalNumber
									class="ion-text-start"></ion-input>
							</ion-item>
						</div>
					</div>
				</ion-row>
			</div>

			<!-- ---------------------- credit_filter -------------- -->
			<div class="filter-header mr-16">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.credit' |
						translate}}</ion-label>
				</div>
				<ion-row>
					<div class="input-div-container">
						<div class="from-date-div">
							<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
								<ion-input [(ngModel)]="credit_filter" [ngModelOptions]="{standalone: true}"
									placeholder="{{'statementComponent.credit'| translate}}" appTwoDigitDecimalNumber
									class="ion-text-start"></ion-input>
							</ion-item>
						</div>
					</div>
				</ion-row>
			</div>

			<!-- ---------------------- debit_filter -------------- -->
			<div class="filter-header mr-16">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'statementComponent.debit' |
						translate}}</ion-label>
				</div>
				<ion-row>
					<div class="input-div-container">
						<div class="from-date-div">
							<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
								<ion-input [(ngModel)]="debit_filter" [ngModelOptions]="{standalone: true}"
									appTwoDigitDecimalNumber placeholder="{{'statementComponent.debit'| translate}}"
									class="ion-text-start"></ion-input>
							</ion-item>
						</div>
					</div>
				</ion-row>
			</div>


			<div class="filter-header mr-16">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">&nbsp;</ion-label>
				</div>
				<ion-row>
					<div class="filter-buttons">
						<button class="filter-apply-button" (click)="applyBtn()">{{"apply" |
							translate}}</button>
						<div class="apply-button ion-text-end">
							<button class="filter-apply-button" (click)="clearFilter()">{{"clear" |
								translate}}</button>
						</div>
					</div>
				</ion-row>
			</div>
		</div>
	</form>

	<div class="myStatementHeader" *ngIf="gridViewData && gridViewData.balance && !isLoading">
		<ion-row class="statement-row mv-5 box1 common-box">
			<p class="m-0 pv-5"> {{"statementComponent.remainingBalance"|translate}}:</p>
			<p class="text-bold m-0 pv-5">{{commonService.currencySymbol}} {{gridViewData.remaining_balance}}</p>
		</ion-row>&nbsp;&nbsp;&nbsp;&nbsp;
		<ion-row class="statement-row mv-5 box1 common-box">
			<p class="m-0 pv-5"> {{"statementComponent.currentBalance"|translate}} </p>
			<p class="text-bold m-0 pv-5">{{commonService.currencySymbol}} {{gridViewData.balance}}</p>
		</ion-row>&nbsp;&nbsp;&nbsp;&nbsp;
		<ion-row class="statement-row mv-5 box1 common-box">
			<p class="m-0 pv-5"> {{"statementComponent.creditLimit"|translate}} :</p>
			<p class="text-bold m-0 pv-5">{{commonService.currencySymbol}} {{gridViewData.credit_limit}}</p>
		</ion-row>
	</div>

	<app-loader *ngIf="isLoading"></app-loader>

	<div class="listing-table" *ngIf="!isLoading">
		<table class="table">
			<thead>
				<th class="column-heading status-col mw-120 ion-text-center">{{"statementComponent.customerName"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">{{"statementComponent.customerCode"
					| translate}}</th>
				<th class="column-heading ion-text-center order-total-col mw-120">
					{{"statementComponent.post_date"
					| translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"statementComponent.doc_date"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"statementComponent.doc_no"|
					translate}}
				</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"statementComponent.doc_text"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"statementComponent.doc_type"|
					translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">{{"statementComponent.credit"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">{{"statementComponent.debit"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">
					{{"statementComponent.invoicePaymentTerms"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">
					{{"statementComponent.invoiceAssignmentNo"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">
					{{"statementComponent.invoiceClearanceDate"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">{{"statementComponent.invoiceTotalAmount"
					| translate}}</th>
				<th class="column-heading status-col mw-120 ion-text-center">{{"statementComponent.balance"
					| translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"statementComponent.invoice_no"|
					translate}}</th>

			</thead>
			<tbody class="table-body">
				<tr *ngFor="let order of statementList;index as i" class="table-row">
					<td class="ion-text-center">{{order.customer_name || '--'}}</td>
					<td class="ion-text-center">{{order.customer_code || '--'}}</td>
					<td class="ion-text-center">{{order.post_date | date :'MMM dd, YYYY'}}</td>
					<td class="ion-text-center">{{order.doc_date | date :'MMM dd, YYYY'}}</td>
					<td class="ion-text-center">{{order.doc_no || '--'}}</td>
					<td class="ion-text-center">{{order.doc_text || '--'}}</td>
					<td class="ion-text-center">{{order.doc_type || '--'}}</td>
					<td class="ion-text-center">{{commonService.currencySymbol}} {{(order.credit | number)|| 0}}</td>
					<td class="ion-text-center">{{commonService.currencySymbol}} {{(order.debit | number)|| 0}}</td>

					<td class="ion-text-center">{{order.invoice_payment_terms || '--'}}</td>
					<td class="ion-text-center">{{order.invoice_assignment_no || '--'}}</td>
					<td class="ion-text-center">{{order.invoice_clearance_date | date :'MMM dd, YYYY'}}</td>
					<td class="ion-text-center">{{commonService.currencySymbol}} {{order.invoice_total_amount || 0}}
					</td>
					<td class="order-id ion-text-center">
						{{commonService.currencySymbol}} {{(order.balance| number)|| 0}}</td>
					<td class="ion-text-center text-bold">{{order.invoice_no}}
						<img *ngIf="order.invoice_url" src="./assets/icon/fi_download.svg" class="pl-10"
							(click)="downloadInvoice($event,order.invoice_url)" alt="Jazeera"
							[title]="('downloadInvoice'|translate)">
					</td>


				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!statementList.length && !isLoading">
			{{'noStatements' | translate}}
		</div>
	</div>

	<div class="mobile-view-listing">
		<ion-content class="orders-ion-content">
			<div class="order-container">
				<ion-grid>
					<ion-row class="order-card" *ngFor="let order of statementList;index as i">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_id" | translate}}</div>
										<div class="grid-values">{{order.order_id}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_total" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}{{order.order_total}}
										</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.date" | translate}}</div>
										<div class="grid-values">{{order.date}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<!-- <div class="grid-label">{{ordersComponent.status}}</div> -->
										<div class="grid-values">
											<ion-button fill="clear" class="status-btn"
												[ngClass]="{'pending' : order.order_status == 0,'shipped':order.order_status == 1,'canceled':order.order_status == 2}">{{order.order_status
												== 1 ? ('ordersComponent.shipped' | translate) :order.order_status == 2
												?
												('ordersComponent.cancelled' |
												translate) :('ordersComponent.pending' | translate)}}
											</ion-button>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
						<ion-col class="mobile-right-arrow" size="1">
							<div [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
								<img src="./assets/icon/rightArrow.svg" alt="righta-arrow">
							</div>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'lodingMoreData' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</ion-content>
	</div>

	<div class="pagination-wrapper" *ngIf="statementList.length && from != 'dashboard'">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col">
				<div class="perpage-col">
					<div class="perpage-dropdown">
						<ion-item class="ion-card" lines="none">
							<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								interface="popover" [interfaceOptions]="perPageInterface" fill="" mode="md"
								label-placement="stacked" (ionChange)="selectPageLength($event)"
								[(ngModel)]="itemsPerPage">
								<ion-select-option value="10">10 / {{"ordersComponent.per_page" |
									translate}}</ion-select-option>
								<ion-select-option value="20">20 / {{"ordersComponent.per_page" |
									translate}}</ion-select-option>
							</ion-select>
						</ion-item>
					</div>
				</div>
			</ion-col>
			<ion-col size="8" class="pagination-col">
				<app-tile class="grid-tile" *ngFor="let post of statementList | paginate :
				    					{ itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
				   						 let i = index" [current]="post"></app-tile>
				<pagination-controls class="paginator"
					[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'" previousLabel=""
					nextLabel="" (pageChange)="onChange($event)"></pagination-controls>


			</ion-col>
		</ion-row>
	</div>
</div>