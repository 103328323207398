<ion-content>

	<div class="container">
		<div class="div-bg common-input">
			<img router-direction="none" src="./assets/icon/companyLogo.svg" height="100%" width="100%"
				alt="company-logo" class="logo-img  mb-25">

			<form [formGroup]="paymentForm" (ngSubmit)="submitPayment()" class="width-100">
				<!-- Card Number Input -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Card Number</ion-label>
						<ion-input formControlName="cardNumber" type="text" placeholder="1234 5678 9101 1121"
							[maskito]="cardMask" [maskitoElement]="maskPredicate"></ion-input>
					</ion-item>
					<ion-text color="danger" *ngIf="cardNumber.invalid && cardNumber.touched">
						<div *ngIf="cardNumber.errors?.['required']">Card number is required.</div>
						<div *ngIf="cardNumber.errors?.['invalidCardNumber']">Invalid card number (must be 16 digits).
						</div>
					</ion-text>
				</div>


				<!-- Expiry month Input -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Expiry Month</ion-label>
						<ion-input formControlName="expiryMonth" type="text" placeholder="MM"
							[maxlength]="2"></ion-input>
					</ion-item>
					<ion-text color="danger"
						*ngIf="expiryMonth.invalid && expiryMonth.touched && expiryMonth.markAsDirty">
						<div *ngIf="expiryMonth.errors?.['required']">Expiry Month is required.</div>
						<div *ngIf="expiryMonth.errors?.['minlength']">Expiry Month is must be between 01 and 12.</div>
						<div *ngIf="expiryMonth.errors?.['invalidMonth']">Expiry Month is must be between 01 and 12.
						</div>
						<div *ngIf="expiryMonth.errors?.['monthInThePast']">Expiry Month is must be between 01 and 12.
						</div>
					</ion-text>
				</div>


				<!-- Expiry Year Input -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Expiry Year (YYYY)</ion-label>
						<ion-input formControlName="expiryYear" type="text" placeholder="YYYY"
							[maxlength]="4"></ion-input>
					</ion-item>
					<ion-text color="danger" *ngIf="expiryYear.invalid && expiryYear.touched && expiryYear.markAsDirty">
						<div *ngIf="expiryYear.errors?.['required']">Expiry Year is required.</div>
						<div *ngIf="expiryYear.errors?.['invalidYear']">Invalid Expiry Year (YYYY format).The year must
							be
							the current or future year.
						</div>
					</ion-text>
				</div>

				<!-- CVV Input -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">CVV</ion-label>
						<ion-input formControlName="cvv" type="password" placeholder="123"></ion-input>
					</ion-item>
					<ion-text color="danger" *ngIf="cvv.invalid && cvv.touched && cvv.markAsDirty">
						<div *ngIf="cvv.errors?.['required']">CVV is required.</div>
						<div *ngIf="cvv.errors?.['pattern']">Invalid CVV (3 or 4 digits).</div>
					</ion-text>
				</div>



				<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
					title="{{'Submit Payment' | translate}}" [isIcon]="false" [isHideInMobile]="false"
					(click)="submitPayment()" [isFullWidth]="true" [disabled]="paymentForm.invalid"
					[specificFontSize]="'1rem'">
				</app-common-button>

			</form>
		</div>
	</div>

</ion-content>