import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../common/shared.module';
import { PressComponent } from './press.component';
import { PressComponentRoutingModule } from './press-routing.module';


@NgModule({
  declarations: [PressComponent],
  imports: [
    CommonModule,
    SharedModule,
    PressComponentRoutingModule
  ]
})
export class PressModule { }
