import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';

@Component({
	selector: 'app-sort-by',
	templateUrl: './sort-by.component.html',
	styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent implements OnInit {

	@Input() selectedSort: any;


	sortByOptions = [
		// {
		//     value: 'RELEVANCE',
		//     label: 'Relevance',
		//     translateLabel: 'relevance',
		//     checked: false
		// },
		// {
		//     value: 'POPULARITY',
		//     label: 'Popularity',
		//     translateLabel: 'popularity',
		//     checked: false,
		// },
		// {
		//     value: 'PRODUCT_NAME',
		//     label: 'Name',
		//     checked: false,
		// },
		{
			value: 'A-Z',
			label: 'A-Z',
			translateLabel: 'A-Z',
			checked: false,
		},
		{
			value: 'Z-A',
			label: 'Z-A',
			translateLabel: 'Z-A',
			checked: false,
		},
		{
			value: 'PRICE1',
			label: 'Price ascending',
			translateLabel: 'priceASC',
			checked: false,
		},
		{
			value: 'PRICE2',
			label: 'Price descending',
			translateLabel: 'priceDESC',
			checked: false,
		},
		// {
		//     value: 'DISCOUNTED_PRODUCTS',
		//     label: 'Discounted Products',
		//     translateLabel: 'discountedPrice',
		//     checked: false,
		// },

	]

	constructor(private modalService: ModalService,
		public commonService: CommonService) { }

	ngOnInit() {
		if (this.selectedSort) {
			this.sortByOptions.forEach((element) => {
				if (element.translateLabel == this.selectedSort) {
					element['checked'] = true;
				}
			});
		}
	}

	closeModal() {
		this.modalService.dismissModal(this.sortByOptions);
	}

	selectCheckbox(event, id) {
		this.sortByOptions.forEach((element, index) => {
			if (index === id && event.detail.checked) {
				element['checked'] = true;
				setTimeout(() => {
					this.closeModal();
				}, 200);
			} else if (index == id && !event.detail.checked) {
				element['checked'] = false;
			} else if (index != id && event.detail.checked) {
				element['checked'] = false;
			}
		});
		this.commonService.sortByData(this.sortByOptions);
	}
}
