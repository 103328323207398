<div class="two-fa-container ion-text-center" *ngIf="getPlatformService.isDesktop">
	<div class="modal-close text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="upper-part ">
		<div class="heading text-left">{{ 'orderSample.heading' | translate }} </div>
		<div class="text text-left">{{ 'orderSample.description' | translate }} </div>
	</div>
	<div class="detail-section">
		<div class="image-block-part">
			<div class="left-part">
				<img class="product-image mr-20" [src]="productImage" alt="Jazeera">
				<div class="details ml-20 text-left">
					<span class="product">{{commonService.domDirectionLTR ? orderSampleData['product_name_en']
						:orderSampleData['product_name_ar'] }}</span>
					<div class="options-block" *ngFor="let item of orderSampleData['product_configurations']">
						<span class="name"
							*ngIf="item['optionName'] != 'ESTIMATED DELIVERY'">{{commonService.domDirectionLTR
							?item['configuration_name_en']:item['configuration_name_ar']}}</span>:
						<span class="value" *ngIf="item['optionName'] != 'ESTIMATED DELIVERY'">{{
							commonService.domDirectionLTR ?
							item['configuration_value_en']:item['configuration_value_ar']}}</span>
					</div>
				</div>
			</div>
			<div>
				<div class="price">{{ commonService.currencySymbol}}{{sample_price_per_unit ? sample_price_per_unit :
					'0' }}

				</div>
				<div class="options-block options-block-unit">
					<span class="name">{{sample_unit_value ? sample_unit_value : null}} <span
							*ngIf="sample_unit_name_en">{{commonService.domDirectionLTR ?
							sample_unit_name_en :sample_unit_name_ar }}</span></span>
				</div>
			</div>


		</div>
		<span class="note width-100 text-left">
			{{ 'orderSample.note' | translate }} {{ max_sample_unit_provided ?
			max_sample_unit_provided : '0' }} <span *ngIf="max_sample_unit_provided > 1">{{ 'orderSample.pieces' |
				translate }}</span> <span *ngIf="max_sample_unit_provided <= 1">{{ 'orderSample.piece' | translate
				}}</span>
		</span>
	</div>
	<div class="bottom-container">
		<div class="cancel" (click)="closeModal()">
			{{ 'Cancel' | translate }}
		</div>
		<div class="login-button ml-20">
			<ion-button class="d-flex" (click)="addtocart()">
				<div *ngIf="isAddToCartLoading">
					<ion-spinner name="circular"></ion-spinner>
				</div>
				<div class="pl-10">
					{{ 'orderSample.action' | translate }}
				</div>
			</ion-button>
		</div>
	</div>
</div>

<!-- For Mobile -->
<div class="two-fa-container ion-text-center" *ngIf="!getPlatformService.isDesktop">
	<div class="modal-close text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="header-line" *ngIf="!getPlatformService.isDesktop">
	</div>
	<div class="upper-part">
		<div class="heading">{{ 'orderSample.heading' | translate }} </div>
	</div>
	<div class="horizontal-line"></div>
	<div class="detail-section">
		<div class="image-block-part">
			<div class="left-part">
				<div class="sample-tag">
					SAMPLE
				</div>
				<img class="product-image mr-20" [src]="productImage" alt="Jazeera">
				<div class="details">
					<span class="product">{{commonService.domDirectionLTR ? orderSampleData['product_name_en']
						:orderSampleData['product_name_ar'] }}</span>
					<div class="options-block" *ngFor="let item of orderSampleData['product_configurations']"
						[ngClass]="[ item['configuration_name_en'] == 'ESTIMATED DELIVERY' ? 'estimated-opt' : '']">
						<span class="name">{{item['configuration_name_en']}}</span>:
						<span class="value">{{item['configuration_value_en']}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="note-div">
			<span class="note">
				{{ 'orderSample.note' | translate }} {{ max_sample_unit_provided ?
				max_sample_unit_provided : '0' }} <span *ngIf="max_sample_unit_provided > 1">{{ 'orderSample.pieces' |
					translate }}</span> <span *ngIf="max_sample_unit_provided <= 1">{{ 'orderSample.piece' | translate
					}}</span>
			</span>
			<div class="price">{{ commonService.currencySymbol}}{{sample_price_per_unit ? sample_price_per_unit :
				'0' }}

			</div>
			<div class="options-block">
				<span class="name">{{sample_unit_value ? sample_unit_value : null}} <span
						*ngIf="sample_unit_name_en">{{commonService.domDirectionLTR ?
						sample_unit_name_en :sample_unit_name_ar }}</span></span>
			</div>
		</div>
	</div>
	<div class="bottom-container">
		<div class="login-button">
			<ion-button (click)="addtocart()">
				<ion-spinner *ngIf="isAddToCartLoading" name="circular"></ion-spinner>
				<span class="cart-icon"><img src="assets/icon/fi_shopping-cart.svg" alt="Jazeera"></span>
				{{ 'orderSample.action' | translate }}
			</ion-button>
		</div>
	</div>
</div>