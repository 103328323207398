<ion-header *ngIf="getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="registration-desktop-header ion-text-center">
			{{ 'Editprofile' | translate }}
		</div>
		<div class="text-right" [ngClass]="commonService.domDirectionLTR ? 'modal-close' : 'modal-close-rtl'"
			(click)="closeModal()">
			<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
		</div>
	</ion-toolbar>
</ion-header>

<ion-header class="address-header" mode="md" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="closeModal()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="edit-profile-header">
				{{'Editprofile'| translate}}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div class="registration-container"
		[ngClass]="[ getPlatformService.isDesktop ? 'registration-container-desktop p-top-0': '']">
		<form [formGroup]="registerFromGroup">

			<div class="account-info" [ngClass]="getPlatformService.isDesktop ? 'p-top-0':'pt-25'">
				<div class="name">
					<!-- <ion-item lines="none" mode="md">
                        <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg" alt="Jazeera"> -->
					<!-- <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'NAME' | translate }}<sup
                                class="pl-5 required-star">*</sup>
                        </ion-label>
                        <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Name' | translate
                            }}<sup class="pl-5 required-star">*</sup>
                        </ion-label> -->
					<ion-input label="{{'NAME' |translate}}" label-placement="floating" fill="outline" maxlength="50"
						formControlName="name"></ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'name', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
			</div>
			<div class="company-details">
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating"
							[ngClass]="{'item-has-value' : companyCRNoName}">{{'companyDetailsComponent.companyCRNoLabelFile'
							| translate}}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':companyCRNoName}"
							(change)="upload($event,'company-cr-number')" (click)="$event.stopPropagation()" #crNumber>
						<ion-input *ngIf="!companyCRNoName" formControlName="companyCRNoName" readonly
							class="hidden-input"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!companyCRNoName"
							(click)="crNumber.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="companyCRNoName"
							(click)="remove($event,'company-cr-number')">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="companyCRNoName" #companyCrNoElem>{{companyCRNoName}}</p>
					</ion-item>
					<div class="more-info-tooltip" *ngIf="getPlatformService.isDesktop"><img
							src="./assets/icon/more_info.svg" alt="Jazeera">
						<span
							class="more-info-tooltiptext more-info-file-tooltip">{{'companyDetailsComponent.fileTooltipError'|
							translate}}</span>
					</div>
					<div class="tooltip company-cr-no-tooltip" *ngIf="companyCRNoName"><span
							class="company-span">.</span>
						<span class="tooltiptext">{{companyCRNoName}}</span>
					</div>
					<ng-container *ngIf="isCompanyCRNoFile">
						<ion-text color="danger" class="error-msg">
							{{'companyDetailsComponent.companyCRNoNameError'| translate}}
						</ion-text>
					</ng-container>
				</div>
				<div class="form-inputs">
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating">{{'companyDetailsComponent.companyCRNoLabel' | translate }}<sup
                                class="pl-5 required-star">*</sup></ion-label> -->
					<ion-input label="{{'companyDetailsComponent.companyCRNoLabel' | translate}}"
						label-placement="floating" fill="outline" maxlength="10" type="tel"
						(keypress)="phoneClick($event)" inputmode="numeric" formControlName="crNumber"></ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'crNumber', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating"
							[ngClass]="{'item-has-value' : vatCertificateNoName}">{{'companyDetailsComponent.vatCertificateLabelFile'
							| translate}}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':vatCertificateNoName}"
							(change)="upload($event,'vat-certificate-no')" (click)="$event.stopPropagation()"
							#vatCertificateNo>
						<ion-input *ngIf="!vatCertificateNoName" readonly formControlName="vatCertificateNoName"
							class="hidden-input"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!vatCertificateNoName"
							(click)="vatCertificateNo.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="vatCertificateNoName"
							(click)="remove($event,'vat-certificate-no')">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="vatCertificateNoName" #vatCertificateNoElem>{{vatCertificateNoName}}
						</p>
					</ion-item>
					<div class="more-info-tooltip" *ngIf="getPlatformService.isDesktop"><img
							src="./assets/icon/more_info.svg" alt="Jazeera">
						<span
							class="more-info-tooltiptext more-info-file-tooltip">{{'companyDetailsComponent.fileTooltipError'|
							translate}}</span>
					</div>
					<div class="tooltip vat-certificate-no-tooltip" *ngIf="vatCertificateNoName"><span
							class="company-span">.</span>
						<span class="tooltiptext">{{vatCertificateNoName}}</span>
					</div>
					<ng-container *ngIf="isVatCertificateNoFile">
						<ion-text color="danger" class="error-msg">
							{{'companyDetailsComponent.vatCertificateNoError'| translate}}
						</ion-text>
					</ng-container>
				</div>
				<div class="form-inputs">
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating">{{'companyDetailsComponent.vatCertificateLabel' | translate
                            }}<sup class="pl-5 required-star">*</sup></ion-label> -->
					<ion-input label="{{'companyDetailsComponent.vatCertificateLabel' |translate}}"
						label-placement="floating" fill="outline" maxlength="15" type="tel"
						(keypress)="phoneClick($event)" inputmode="numeric" formControlName="vatNumber"></ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'vatNumber', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating"
							[ngClass]="{'item-has-value' : nationalAddressFile}">{{'companyDetailsComponent.nationalAddressLabelFile'
							| translate}}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':nationalAddressFile}"
							(change)="upload($event,'national-address-file')" (click)="$event.stopPropagation()"
							#nationalAddress>
						<ion-input *ngIf="!nationalAddressFile" readonly formControlName="nationalAddressFile"
							class="hidden-input"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!nationalAddressFile"
							(click)="nationalAddress.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="nationalAddressFile"
							(click)="remove($event,'national-address-file')">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="nationalAddressFile" #nationalAddressFileElem>
							{{nationalAddressFile}}</p>
					</ion-item>
					<div class="more-info-tooltip" *ngIf="getPlatformService.isDesktop"><img
							src="./assets/icon/more_info.svg" alt="Jazeera">
						<span
							class="more-info-tooltiptext more-info-file-tooltip">{{'companyDetailsComponent.fileTooltipError'|
							translate}}</span>
					</div>
					<div class="tooltip national-address-file-tooltip" *ngIf="nationalAddressFile"><span
							class="company-span">.</span>
						<span class="tooltiptext">{{nationalAddressFile}}</span>
					</div>
					<ng-container *ngIf="isNationalAddressFile">
						<ion-text color="danger" class="error-msg">
							{{'companyDetailsComponent.nationalAddressFileError'| translate}}
						</ion-text>
					</ng-container>
				</div>
				<div class="select-intrest-container">
					<div class="select-intrest-head">
						{{ 'Select interests' | translate }}<sup class="asterisk-class">*</sup>
					</div>
					<div class="select-intrest" *ngIf="selectedIntrestList && registrationCheckbox.length > 0">
						<ion-list>
							<ion-item *ngFor="let item of registrationCheckbox;let i = index" lines="none">
								<ion-label class="checkbox-labels"
									[ngClass]="getPlatformService.isDesktop ? 'p-5':'p-0'">
									{{item.label}}</ion-label>
								<ion-checkbox mode="md" slot="start" [value]="item.value"
									(ionChange)="selectCheckbox(item,i,$event)"
									[checked]="item.isChecked"></ion-checkbox>
							</ion-item>
						</ion-list>
						<!-- <ng-container
                            *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'selectIntrestList', 'formName': registerFromGroup} }">
                        </ng-container> -->
					</div>
				</div>
			</div>
		</form>

	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
				{{validation.message | translate}}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>

<ion-footer *ngIf="!getPlatformService.isDesktop">
	<div class="register-button ion-text-center mh-10">
		<app-common-button color="#FFFFFF" background="#0081CA" title="{{'Save'|translate}}" [isIcon]="false"
			[isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
			[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="onSubmit()">
		</app-common-button>
	</div>
</ion-footer>

<ion-footer *ngIf="getPlatformService.isDesktop">
	<div class="register-bottom-container" *ngIf="getPlatformService.isDesktop">
		<div class="cancel pr-40" (click)="closeModal()">
			{{ 'Cancel' | translate }}
		</div>
		<div class="register-button">
			<ion-button (click)="onSubmit()" [disabled]="isLoading">
				{{ 'Save' | translate }}
			</ion-button>
		</div>
	</div>
</ion-footer>