import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NewsDetailsPageRoutingModule } from './news-details-routing.module';

import { SharedModule } from '../../common/shared.module';
import { NewsDetailsPage } from './news-details.page';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		IonicModule,
		NewsDetailsPageRoutingModule
	],
	declarations: [NewsDetailsPage]
})
export class NewsDetailsPageModule { }
