import { Component, Input, OnInit } from "@angular/core";
import { TranslateConfigService } from "../../services/translate-config.service";
// import { Geolocation } from '@capacitor/geolocation';
// import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { ActivatedRoute, Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";
declare var google;
@Component({
	selector: "app-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
	@Input() array: any;
	@Input() hideTitle?: boolean = false;
	@Input() hideCategoryField: boolean = false;
	@Input() from?: boolean;
	enableNext = false;
	pageNo = 1;
	pageSize = "10";
	count = 0;
	notificationList: any = [];
	perPageInterface = { cssClass: "custom-select-dd per-page-dd" };
	private subscription: Subscription = new Subscription();

	notificationArray: any = [];
	categoryId: any;
	constructor(
		public getPlatformService: GetPlatformService,
		private dataService: ApiService,
		private toastService: ToastService,
		private modalService: ModalService,
		public navCtrl: NavController,
		public route: ActivatedRoute,
		public router: Router,
		private translateConfigService: TranslateConfigService,
		public translate: TranslateService,
		public commonService: CommonService
	) {
		this.categoryId = this.route.snapshot.paramMap.get("categoryId");

		this.route.queryParams.subscribe((params) => {
			if (params["categoryId"]) {
				this.categoryId = params["categoryId"] ? params["categoryId"] : null;
			}
		});
		if (!this.array) {
			this.array = this.notificationArray;
		}
	}

	ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params["categoryId"]) {
				this.categoryId = params["categoryId"] ? params["categoryId"] : null;
			}
		});
		if (!this.array) {
			this.array = this.notificationArray;
		}
		this.getNotifications();
	}

	ionViewWillEnter() {
		this.route.queryParams.subscribe((params) => {
			if (params["categoryId"]) {
				this.categoryId = params["categoryId"] ? params["categoryId"] : null;
			}
		});
		this.pageSize = "10";
		this.getNotifications();
	}

	onChange(event): void {
		this.pageNo = event;
		this.getNotifications();
	}

	selectPageLength(event) {
		this.pageSize = event.detail.value;
		this.pageNo = 1;
		this.getNotifications();
	}

	getNotifications(infiniteScroll?) {
		const params = {
			page: this.pageNo,
			limit: +this.pageSize,
			notification_type_id: +this.categoryId,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.notificationList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							this.enableNext = data["data"]["enable_next"] ? true : false;
							this.count =
								data["data"] && data["data"]["total_count"]
									? +data["data"]["total_count"]
									: 0;
							let list =
								data["data"] &&
									data["data"]["result"] &&
									data["data"]["result"].length
									? data["data"]["result"]
									: [];
							if (!infiniteScroll) {
								this.notificationArray = [];
							}
							list.forEach((element) => {
								let obj = {
									title: element.notification_en ? element.notification_en : "",
									titleAr: element.notification_ar
										? element.notification_ar
										: "",
									category: element.notification_type_en
										? element.notification_type_en
										: "",
									categoryAR: element.notification_type_ar
										? element.notification_type_ar
										: "",
									notification_desc_en: element.notification_desc_en
										? element.notification_desc_en
										: "",
									notification_desc_ar: element.notification_desc_ar
										? element.notification_desc_ar
										: "",
									notification_image_url_ar: element.notification_image_url_ar
										? element.notification_image_url_ar
										: "",
									notification_image_url_en: element.notification_image_url_en
										? element.notification_image_url_en
										: "",
									time: element.created_at ? element.created_at : "",
									notification_id: element.notification_id,
									notification_type_id: element.notification_type_id,
									reference_type: element.reference_type
										? element.reference_type
										: "",
									reference_id: element.reference_id
										? element.reference_id
										: "",
									is_read: element.is_read ? element.is_read : "",
								};
								this.notificationArray.push(obj);
							});
							this.array = this.notificationArray;
							resolve(this.array);
						} else {
							reject();
						}
					},
					(error) => {
						reject();
					}
				)
			);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.pageNo += 1;
				this.getNotifications(true).then(
					(data) => {
						event.target.complete();
					},
					(err) => {
						event.target.complete();
					}
				);
			}
		}, 500);
	}

	goBack() {
		this.navCtrl.back({ animated: false });
	}

	ionViewWillLeave() { }

	navigationOfNotificationWeb(data) {
		this.notificationRead(data.notification_id).then((notificationdata) => {
			switch (data.reference_type) {
				case "Coupons": {
					//statements;
					this.router.navigate(['/' + this.translate.currentLang + "/cart"]);
					break;
				}
				case "New Orders": {
					//statements;
					if (data.reference_id) {
						this.router.navigate(
							['/' + this.translate.currentLang + "/home/account-details/my-orders/", data.reference_id],
							{
								state: {
									breadcrumb: this.translate.instant(
										"orderDetailsComponent.orders"
									),
									url: "/home/account-details/my-orders/" + data.reference_id,
								},
							}
						);
					}
					break;
				}
				case "News/Events/Announcements": {
					//statements;
					this.router.navigate(['/' + this.translate.currentLang + "/home"]);
					break;
				}
				case "Return/Cancel Orders": {
					//statements;
					this.router.navigate(['/' + this.translate.currentLang + "/home"]);
					break;
				}
				case "Product Stock Status": {
					//statements;
					this.navCtrl.navigateForward(
						'/' + this.translate.currentLang + "/home/product-detail/" + data.data.reference_id,
						{ animated: false }
					);
					break;
				}
				case "Products and Brands status": {
					//statements;
					break;
				}
				case "Account Information": {
					//statements;
					this.router.navigate(['/' + this.translate.currentLang + "/home/account-details/my-account"]);
					break;
				}
				default: {
					//statements;
					this.router.navigate(['/' + this.translate.currentLang + "/home"]);
					break;
				}
			}
		});
	}
	async navigationOfNotification(data) {
		this.notificationRead(data.notification_id).then((notificationdata) => {
			switch (data.reference_type) {
				case "Coupons": {
					//statements;
					this.navCtrl.navigateForward(['/' + this.translate.currentLang + "/cart"], { animated: false });
					break;
				}
				case "New Orders": {
					//statements;
					this.navCtrl.navigateForward(['/' + this.translate.currentLang + "/home/order-details-mobile"], {
						animated: false,
						queryParams: {
							invoiceId: data.reference_id,
						},
					});

					break;
				}
				case "News/Events/Announcements": {
					this.navCtrl.navigateForward(['/' + this.translate.currentLang + "/home"], { animated: false });
					//statements;
					break;
				}
				case "Return/Cancel Orders": {
					//statements;
					this.navCtrl.navigateForward(['/' + this.translate.currentLang + "/home"], { animated: false });
					break;
				}
				case "Product Stock Status": {
					//statements;
					this.navCtrl.navigateForward(
						"/home/product-detail/" + data.reference_id,
						{ animated: false }
					);
					break;
				}
				case "Account Information": {
					//statements;
					this.navCtrl.navigateForward(['/' + this.translate.currentLang + "/my-account/my-account-details"], {
						animated: false,
					});
					break;
				}
				default: {
					//statements;
					this.navCtrl.navigateForward(['/' + this.translate.currentLang + "/home"], { animated: false });
					break;
				}
			}
		});
	}

	notificationRead(notification_id) {
		const params = {
			notification_id: notification_id,
			is_read: true,
		};

		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.put(URLS.notificationReadStatus, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							this.getNotificationsCategory();
							resolve(data);
						} else {
							reject(data);
						}
					},
					(error) => {
						reject(error);
					}
				)
			);
		});
	}

	getNotificationsCategory() {
		const params = {
			dropdown: true,
		};
		this.subscription.add(
			this.dataService.get(URLS.notificationListType, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.notificationList = data["data"].length ? data["data"] : [];
						this.commonService.notificationCount =
							this.notificationList && this.notificationList.length
								? this.notificationList[0]["total_unread"]
								: null;
					} else {
						this.commonService.notificationCount = null;
					}
				},
				(error) => {
					this.commonService.notificationCount = null;
				}
			)
		);
	}
}
