<div class="add-review-container pt-20" [ngClass]="getPlatformService.isDesktop? 'ph-40':null">
	<div class="modal-close ion-text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%" />
	</div>
	<div class="add-review-header">
		{{'rfqBtntext' | translate}}
	</div>

	<app-loader *ngIf="isLoading"></app-loader>

	<div *ngIf="!isLoading" class="content-div">

		<ion-row class="row-header">
			<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="6" size-xl="6">
				<p class="row-header-1 color-app-grey">{{'cartPage.Item'|translate}}</p>
			</ion-col>
			<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
				<p class="row-header-1 color-app-grey">{{'cartPage.Price'|translate}}</p>
			</ion-col>
			<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
				<p class="row-header-1 color-app-grey">{{'cartPage.Quantity'|translate}}</p>
			</ion-col>
			<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
				<p class="row-header-1 color-app-grey">{{'cartPage.Subtotal'|translate}}</p>
			</ion-col>
		</ion-row>

		<!-- --------------------- web product ---------------------- -->
		<div class="product-div" [ngClass]="value == 'mail' ? '' : 'product-div-pdf'">
			<div *ngIf="quoteData && quoteData.result && quoteData.result.length">
				<div *ngFor="let data of quoteData.result;let i =index">

					<ion-row *ngFor="let item of data.products;let j =index" class="mt-10">
						<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="pl-0">
							<div class="flex-row">

								<div class="image-container" [ngClass]=" item.is_sold_out ? 'product-image-blur':null">

									<div *ngIf="item.discount_type &&  item.discount_type != 'PRICE' && !item.is_sold_out && !item.is_sample_product"
										class="product-discount"
										[ngClass]="commonService.domDirectionLTR ? '':'product-discount-rtl'">
										<span>-</span>{{ item.discount_value }}<span>%</span>
									</div>
									<div *ngIf="item.discount_type &&  item.discount_type == 'PRICE' && !item.is_sold_out && !item.is_sample_product"
										class="product-discount"
										[ngClass]="commonService.domDirectionLTR ? '':'product-discount-rtl'">
										<span>{{"OnSale" | translate}}</span>
									</div>
									<img *ngIf="item.product_cover_image"
										src="{{item.product_cover_image[0].image_url}}" class="product-img"
										alt="Jazeera" />
									<img *ngIf="!item.product_cover_image" src="./assets/images/empty-img.png"
										class="product-img" alt="Jazeera" />
								</div>
								<div *ngIf="item.is_sold_out" class="product-outofstock ml-10">
									<div class="outofstock-label">
										{{"outOfStock" | translate}}
									</div>
								</div>


								<div class="flex-column ml-20">
									<p class="label-3 color-app-black">
										{{commonService.domDirectionLTR ?
										item.product_name_en: item.product_name_ar}}</p>
									<div *ngIf="item.is_sample_product == true" class="sample-div">
										<p>{{'SAMPLE'|translate}}</p>
									</div>

									<p class="label-5 color-app-grey "
										*ngFor="let config of item.product_configuration">
										{{commonService.domDirectionLTR ? config.configuration_name_en:
										config.configuration_name_ar}}:
										<span class="color-app-black mh-5"> {{commonService.domDirectionLTR ?
											config.configuration_value_en: config.configuration_value_ar}}</span>
									</p>

									<p class="label-5 color-app-grey border-div estimated-delivery"
										*ngIf="item.estimated_delivery_date">
										{{'cartPage.EstimatedDelivery'|translate}}: <span
											class="color-app-grey mh-5 estimated-date">
											{{item.estimated_delivery_date}}</span></p>

									<p class="label-5 color-app-grey border-div estimated-delivery"
										*ngIf="item.delivery_days">
										{{'cartPage.EstimatedDelivery'|translate}}: <span
											class="color-app-grey mh-5 estimated-date">
											{{item.delivery_days}} {{item.delivery_days > 1
											?('cartPage.Days'|translate) : ('cartPage.Day'|translate)}}</span></p>
								</div>
							</div>
						</ion-col>
						<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
							<p class="label-3 color-app-black">
								{{ commonService.currencySymbol}}
								{{ item.is_sample_product ? (item.price_per_unit | number) :
								(item.final_price_per_unit |number)}}
								<span *ngIf="item.unit_name_en && !item.is_sample_product"
									class="label-6 color-app-grey">/{{commonService.domDirectionLTR
									?item.unit_name_en:item.unit_name_ar}}</span>


							</p>
							<p *ngIf="item.is_discount_applied && !item.is_sample_product"
								class="discounted-price checked-price">
								<span>{{
									commonService.currencySymbol}}{{item.price_per_unit |
									number}}</span>
							</p>

						</ion-col>
						<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
							<!-- <div class="qty-div">
								<div class="div-up-down ml-10">
									<div class="desktop-visibility-in-flex">
										<ion-input [min]="data.min_quantity" pattern="[0-9]*"
											(keypress)="isnumeric($event)" (change)="qtychange('enter',item,data)"
											(ionInput)="quantityInput($event)" type="text" inputmode="number"
											pattern="[0-9]*" type="input" autocomplete="new-password"
											class="registration-pass" clearOnEdit="false"
											[(ngModel)]="item.total_quantity"
											[disabled]="item.is_sample_product == true">
										</ion-input>
										<div class="div-up-down mr-5" *ngIf="!item.is_sample_product">
											<ion-icon class="feather color-app-grey icon-chevron-up pointer"
												(click)="qtychange('add',item,data)"></ion-icon>
											<ion-icon class="feather color-app-grey icon-chevron-down pointer"
												[ngClass]="item.total_quantity <= 1 ? 'cursor-disable':''"
												(click)="qtychange('minus',item,data)"></ion-icon>
										</div>
									</div>
								</div>
								<span class="label-2 color-app-grey text-right">
									{{commonService.domDirectionLTR
									?item.unit_name_en:item.unit_name_ar}}</span>
							</div> -->
							<div class="mb-10 quantity-counter">
								<div class="quantity">
									<div class="add-quantity qty-div" (click)="qtychange('minus',item,data)">
										<ion-icon *ngIf="item.total_quantity >= 0" src="assets/icon/minus-white.svg"
											alt="Jazeera"></ion-icon>
										<ion-icon *ngIf="item.total_quantity < 0" src="assets/icon/minus-grey.svg"
											alt="Jazeera"></ion-icon>
									</div>
									<div class="quantity-input">
										<ion-input [min]="data.min_quantity" pattern="[0-9]*"
											(keypress)="isnumeric($event)" (change)="qtychange('enter',item,data)"
											(ionInput)="quantityInput($event)" type="text" inputmode="number"
											pattern="[0-9]*" type="input" autocomplete="new-password"
											class="registration-pass" clearOnEdit="false"
											[(ngModel)]="item.total_quantity"
											[disabled]="item.is_sample_product == true"></ion-input>
									</div>
									<div class="subtract-quantity qty-div" (click)="qtychange('add',item,data)">
										<ion-icon src="assets/icon/plus-white.svg" alt="Jazeera"></ion-icon>
									</div>
								</div>
							</div>
							<div class="qty-div suom"
								*ngIf="item.unit_id && item.secondary_unit_id && item.unit_id !== item.secondary_unit_id">
								<div class="label-2">
									{{calculateSUOM(item.conversation_ratio,item.total_quantity)}}
									<span class="price-units ml-10 text-right color-app-grey">
										{{commonService.domDirectionLTR ? item.secondary_unit_name_en
										:
										item.secondary_unit_name_ar}} </span>
								</div>
							</div>
						</ion-col>
						<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
							<div class="display-div">
								<p class="label-4 color-app-black">
									{{commonService.currencySymbol}} {{item.total_price |number}}</p>
								<div class="text-end">
									<div class="more-info more-info-tooltip" (click)="deleteQuote(item,j)">
										<img src="/assets/icon/trash.svg" class="delete-icon color-app-black"
											alt="Jazeera" />
										<span class="more-info-tooltiptext-left more-info-file-tooltip">
											{{"delete"|translate }}</span>
									</div>

								</div>
							</div>

						</ion-col>


						<div class="divider-line mv-10 ml-120"></div>

					</ion-row>
				</div>

				<div class="width-40 float-right">

					<app-order-summary currency="{{commonService.currencySymbol}}"
						orderTotal="{{quoteData.grand_total.total_price}}"
						orderSubTotal="{{quoteData.grand_total.items_sub_total}}"
						[shippingEliminated]="quoteData.is_shipping_cost_eliminated"
						[eliminatedRates]="quoteData.grand_total"
						orderDiscount="{{quoteData.grand_total.coupon_discounted_value}}"
						discountCode="{{appliedCouponCode ? appliedCouponCode : null}}"
						orderVAT="{{quoteData.grand_total.vat}}" [orderSummeryData]="quoteData.grand_total"
						orderShipping="{{quoteData.grand_total.shipping_charges}}"
						orderShippingWithoutVat="{{quoteData.grand_total.shipping_charges_without_vat}}"
						shippingVat="{{quoteData.grand_total.shipping_vat_charges}}"
						vatPercent="{{quoteData.grand_total.vat_percentage}}" isTotalSummary="true">
					</app-order-summary>
				</div>
			</div>

			<div *ngIf="quoteData.result && !quoteData.result.length">
				<div class="no-data">
					{{"noRecordFound" | translate}}
				</div>
			</div>
		</div>

		<!-- ------------- for shipping -------------- -->

		<div class="mb-10">
			<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep1.Shippingaddress' | translate}}</p>

			<!-- <ion-item lines="none" mode="md"> -->
			<ion-select label="{{'rfqModal.selectAddress'|translate}}" label-placement="floating" fill="outline"
				cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
				placeholder="{{'rfqModal.selectAddress'|translate}}" [interface]="'popover'"
				[interfaceOptions]="locationSelect" [(ngModel)]="defalutShippingAddressId"
				class="pt-0 pb-0 width-100 ml-10" (ngModelChange)="shippingChnage($event)">
				<ion-select-option *ngFor="let data of shipping_address" [value]="data.address_id">
					{{data.address}},
					{{commonService.domDirectionLTR ? data.city_en : data.city_ar}},
					{{commonService.domDirectionLTR ? data.region_en : data.region_ar}},
					{{commonService.domDirectionLTR ? data.country_en : data.country_ar}} -
					{{data.zipcode}}
				</ion-select-option>
			</ion-select>

			<!-- </ion-item> -->
		</div>

		<!-- -------------- for email ------------------ -->

		<div class="common-input mb-10 width-100" *ngIf="value == 'mail' || from == 'list'">
			<p class="title3 color-app-black mv-20-30">{{ 'rfqModal.addEmail' | translate}}</p>

			<div class="pt-0 pb-0 width-100">
				<!-- <ion-item lines="none" mode="md">
                    <ion-label position="floating">{{'Email' | translate}}
                    </ion-label> -->
				<ion-input label="{{'Email' | translate}}" label-placement="floating" fill="outline" [(ngModel)]="email"
					#emailInput (ionInput)="emailInputMethod($event)"></ion-input>
				<!-- </ion-item> -->
				<ng-container>
					<ion-text color="danger" class="error-msg" *ngIf="errorMsg">
						{{ errorMsg| translate }}
					</ion-text>
				</ng-container>
			</div>
		</div>


		<!-- ----------------- Button ---------------------- -->

		<div class="login-bottom-container" [ngClass]="[getPlatformService.isDesktop ? '' : 'login-bottom-mobile']"
			*ngIf="from != 'list'">
			<div class="cancel pr-40" (click)="closeModal()">
				{{ 'Cancel' | translate }}
			</div>
			<div class="login-button">
				<ion-button (click)="onSubmit(value)" [disabled]="isLoading">
					{{ value == 'mail' ? ('rfqModal.confirmSend'|translate) : ('rfqModal.confirmDownload'| translate)}}
				</ion-button>
			</div>
		</div>

		<div class="login-bottom-container" [ngClass]="[getPlatformService.isDesktop ? '' : 'login-bottom-mobile']"
			*ngIf="from == 'list'">
			<div class="cancel pr-40" (click)="closeModal()">
				{{ 'Cancel' | translate }}
			</div>
			<div class="login-button">
				<ion-button (click)="onSubmit('mail')" [disabled]="isLoading" class="mr-10">
					{{'rfqModal.confirmSend'|translate }}
				</ion-button>
				<ion-button (click)="onSubmit('pdf')" [disabled]="isLoading">
					{{'rfqModal.confirmDownload'|translate }}
				</ion-button>
			</div>
		</div>

	</div>
</div>