import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonButton, NavController, NavParams, Platform } from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { URLS } from '../../_config/api.config';
import { CustomFirebaseAnalyticsProvider } from '../../services/CustomFirebaseAnalyticsProvider';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-two-factor-authentication',
    templateUrl: './two-factor-authentication.component.html',
    styleUrls: ['./two-factor-authentication.component.scss'],
})
export class TwoFactorAuthenticationComponent implements OnInit {
    @ViewChild('codeInput') codeInput !: CodeInputComponent;
    @ViewChild(IonButton) button;
    fromPage: any;
    isCodeComplete: boolean = false;
    isGettingOtp: boolean = false;
    @Input() twoFaSettingsConformation: boolean = false;
    public resendTimer: any = "00:30";
    public isCodeHidden = true;
    public selected_Product: any;
    public product_value: any;
    public is_sample: boolean;
    registrationDetails: any;
    uuid: any;
    otpCount = 0;
    loginDetails: any;
    mobileNumber: any;
    disableBtn: boolean = true;
    isLoading: boolean = false;
    isTwoFaEnabled: boolean = false;
    public subscription: Subscription = new Subscription();
    public subscribeOtp = new Subscription();
    public otpInput = null;
    currentLang; 
    constructor(private modalService: ModalService,
        private navParams: NavParams,
        public router: Router,
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        public navCtrl: NavController,
        public platform: Platform,
        public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
        public cartService: CartService, public translate: TranslateService) {
        this.fromPage = this.navParams.data.from;
        this.currentLang = this.translate.currentLang;
    }

    ngOnInit() {
        this.startTimer();
        if (this.codeInput) {
            setTimeout(() => {
                this.codeInput.focusOnField(0);
            }, 300);
        }
        const decryptedData = this.commonService.localStorageGet("registration_data");
        if (decryptedData) {
            this.registrationDetails = this.commonService.decryptData(decryptedData);
            this.registrationDetails = JSON.parse(this.registrationDetails);
        }
        const decryptedLoginData = this.commonService.localStorageGet("userInfo");
        if (decryptedLoginData) {
            this.loginDetails = decryptedLoginData;
            this.loginDetails = JSON.parse(this.loginDetails);
        }
        this.uuid = this.commonService.localStorageGet("uuid");
        let fromRegister = this.commonService.localStorageGet('from_register');
        let fromLogin = this.commonService.localStorageGet('from_login');
        let fromForgotPassword = this.commonService.localStorageGet('from_forgotPassword');
        if (fromRegister == 'true') {
            this.mobileNumber = this.registrationDetails['mobile_no'];
            this.mobileNumber = this.mobileNumber.replace(/.{0,7}/, (x) => "*".repeat(x.length))

        } else if (fromLogin == 'true' || fromForgotPassword == 'true') {
            this.mobileNumber = this.commonService.localStorageGet('mobile_no');
            this.mobileNumber = this.mobileNumber.replace(/.{0,7}/, (x) => "*".repeat(x.length))
        }
        if (this.fromPage == 'MyAccount') {
            if (this.commonService.localStorageGet('is_2fa_enabled') == 'true') {
                this.isTwoFaEnabled = true;
            } else {
                this.isTwoFaEnabled = false;
            }
        }
    }


    closeModal() {
        if (this.fromPage != 'MyAccount' && !this.twoFaSettingsConformation) {
            this.modalService.dismissModal();
            this.commonService.localStorageRemove("accessToken");
            this.commonService.localStorageRemove('from_login');
            this.commonService.localStorageRemove('from_register');
            this.commonService.localStorageRemove('userInfo');
            this.commonService.localStorageRemove('uuid');
            this.commonService.localStorageRemove('mobile_no');
            this.commonService.localStorageRemove('from_forgotPassword');
            this.commonService.localStorageRemove('step');
            this.commonService.localStorageRemove('registration_data');
            this.commonService.localStorageRemove('twoFaData');
            this.commonService.localStorageRemove('username');
        } else {
            this.modalService.dismissModal();
        }
    }

    forgotPasswordOpen() {
        this.modalService.dismissModal();
        let options: any = {
            id: "forgotPassword",
            backdropDismiss: false,
        };
        options['component'] = ForgotPasswordComponent;
        options['componentProps'] = {
            id: "forgotPassword",
        };
        this.modalService.openModal(options)
    }

    getOtp() {
        this.otpInput = null;
        this.codeInput.reset();
        if (this.isGettingOtp) {
            return;
        }
        let userInfo = this.commonService.decryptData(this.commonService.localStorageGet('userInfo'));
        userInfo = JSON.parse(userInfo);
        let params = {
            uuid: this.uuid
        }
        let fromRegister = this.commonService.localStorageGet('from_register');
        let fromLogin = this.commonService.localStorageGet('from_login');
        let fromForgotPassword = this.commonService.localStorageGet('from_forgotPassword');
        let mobile_no = this.commonService.localStorageGet('mobile_no');
        if (fromLogin == 'true') {
            params["mobile_no"] = mobile_no;
            params["purpose_type"] = "2FA";

        } else if (fromRegister == 'true') {
            params["mobile_no"] = this.registrationDetails['mobile_no'];
            params["purpose_type"] = "REGISTRATION";
            params['customer_cr_no'] = this.registrationDetails['customer_cr_no'];
            params['customer_vat_no'] = this.registrationDetails['customer_vat_no'];

        } else if (fromForgotPassword == 'true') {
            params["mobile_no"] = mobile_no;
            params["purpose_type"] = "FORGOT_PASSWORD";
        }

        this.isGettingOtp = true;
        this.subscription.add(
            this.dataService.post(URLS.resendOtp, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.startTimer();
                    this.otpCount += 1;
                    if (data['data']) {
                        if (data['data']['uuid']) {
                            this.commonService.localStorageSet('uuid', data['data']['uuid']);
                            this.uuid = data['data']['uuid'];
                        }
                    }
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
                this.isGettingOtp = false;
            }, error => {
                this.isGettingOtp = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    verifyOtp() {

        let params = {};
        let fromRegister = this.commonService.localStorageGet('from_register');
        let fromLogin = this.commonService.localStorageGet('from_login');
        let fromForgotPassword = this.commonService.localStorageGet('from_forgotPassword');

        if (this.otpInput && this.otpInput.length == 5) {
            if (fromRegister == 'true') {
                params['uuid'] = this.uuid;
                params['otp'] = +this.otpInput;
                params['mobile_no'] = this.registrationDetails.mobile_no;
                params['purpose_type'] = "REGISTRATION";
            }
            else if (fromLogin == 'true') {
                let mobile_no = this.commonService.localStorageGet('mobile_no');
                let uuid = this.commonService.localStorageGet('uuid');
                params['mobile_no'] = mobile_no;
                params['uuid'] = uuid;
                params['otp'] = +this.otpInput;
                params['purpose_type'] = "2FA";
            }
            else if (fromForgotPassword == 'true') {
                let mobile_no = this.commonService.localStorageGet('mobile_no');
                let uuid = this.commonService.localStorageGet('uuid');
                params['mobile_no'] = mobile_no;
                params['uuid'] = uuid;
                params['otp'] = +this.otpInput;
                params['purpose_type'] = "FORGOT_PASSWORD";
            }
            else {
                let mobile_no = this.commonService.localStorageGet('mobile_no');
                let uuid = this.commonService.localStorageGet('uuid');
                params['mobile_no'] = mobile_no;
                params['uuid'] = uuid;
                params['otp'] = +this.otpInput;
                params['purpose_type'] = "2FA";
            }

            const encrypted2faData = this.commonService.encryptData(JSON.stringify(params));
            this.commonService.localStorageSet('twoFaData', encrypted2faData);

            this.disableBtn = true;
            this.isLoading = true;
            this.subscription.add(
                this.dataService.post(URLS.verifyOtp, params).subscribe(data => {
                    if (data['code'] == 200 && data['data']) {
                        this.otpInput = null;
                        if (data['data'] && fromRegister) {
                            this.onRegistration();
                        } else if (data['data'] && fromLogin) {
                            // code required
                            if (this.commonService.accessToken) {
                                this.commonService.localStorageSet('accessToken', this.commonService.accessToken);
                            }
                            this.isLoading = false;
                            this.disableBtn = false;
                            this.commonService.localStorageSet('from_login', false);
                            this.cartService.isloggedIn = true;
                            this.commonService.setEvent('profileData', null);
                            this.modalService.dismissAllModal();
                            this.commonService.localStorageRemove('username')
                            this.navCtrl.navigateForward('/'+this.currentLang +'/home', { animated: false });
                            if (this.selected_Product && this.product_value) {
                                if (this.is_sample) {
                                    this.cartService.addIntoCart(this.selected_Product, this.product_value, this.is_sample).then(() => {
                                        this.navCtrl.navigateForward('/'+this.currentLang +'/cart', { animated: false });
                                    });
                                } else {
                                    this.cartService.addIntoCart(this.selected_Product, this.product_value).then(() => {
                                        this.navCtrl.navigateForward('/'+this.currentLang +'/cart', { animated: false });
                                    });
                                }
                            }

                        } else if (data['data'] && fromForgotPassword) {
                            this.isLoading = false;
                            this.disableBtn = false;
                            this.commonService.localStorageSet('from_forgotPassword', false);
                            this.forgotPasswordOpen();
                        }

                    } else {
                        this.otpInput = null;
                        if(this.codeInput){
                            this.codeInput.reset();
                        }
                        this.disableBtn = true;
                        this.isLoading = false;
                        this.cartService.isloggedIn = false;
                        this.toastService.displayToast(data['message'], null, 'danger');
                        if (this.codeInput) {
                            this.codeInput.focusOnField(0);
                        }
                    }
                }, error => {
                    this.otpInput = null;
                    if(this.codeInput){
                        this.codeInput.reset();
                    }
                    this.disableBtn = true;
                    this.isLoading = false;
                    this.cartService.isloggedIn = false;
                    this.toastService.displayToast(error['message'], null, 'danger');
                    if (this.codeInput) {
                        this.codeInput.focusOnField(0);
                    }
                })
            )
        }
    }

    onCodeCompleted(event, codeInput) {
        if (event) {
            this.otpInput = event;
            this.isCodeComplete = true;
        }
        if (this.otpInput.length == 5 && this.getPlatformService.isDesktop) {
            setTimeout(() => {
                this.button.el.focus();
            }, 300);
        }
        //code maybe required
        // codeInput.inputs[4].focus();
    }


    onOtpChanged(event, codeInput) {
        if (this.getPlatformService.isDesktop) {
            codeInput.inputType = "password"
        }
        this.isCodeHidden = true;
        this.otpInput = event;
        if (event.length < 5) {
            this.disableBtn = true;
        } else {
            this.disableBtn = false;
        }
    }

    enterOtpSubmit(event) {
        setTimeout(() => {
            if (event.keyCode === 13 && this.otpInput.length == 5) {
                this.verifyOtp();
            }
        }, 500);
    }

    public resendOtpClick() {
        if (this.resendTimer == null) {
            this.subscribeOtp.unsubscribe();
            this.resendTimer = "00:30";
            this.isCodeHidden = true;
            this.otpInput = null;
            if(this.codeInput){
                this.codeInput.reset();
            }
        }
    }

    public startTimer() {
        this.subscribeOtp = new Subscription();
        this.subscribeOtp = interval(1000).pipe(take(31)).subscribe(res => {
            this.resendTimer = this.transform(30 - res);
            if (res >= 30) {
                this.resendTimer = null;
                this.subscribeOtp.unsubscribe();
            }
        });
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (minutes < 10 ? '0' + minutes : minutes) + ':' + ((value - minutes * 60) < 10 ? '0' + (value - minutes * 60) : (value - minutes * 60))
    }

    twoFaToggle(event) {
        const params = {
            "is_2fa_enabled": !event.target.checked
        }
        this.isLoading = true;
        this.subscription.add(
            this.dataService.put(URLS.updateTwoFaSettings, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isTwoFaEnabled = event.target.checked;
                    this.commonService.localStorageSet("is_2fa_enabled", this.isTwoFaEnabled);
                    this.commonService.twoFaEnabled = this.isTwoFaEnabled;
                    setTimeout(() => {
                        this.twoFaSettingsConformation = this.commonService.localStorageGet("is_2fa_enabled") == "true" ? true : false;
                    }, 600)
                    this.isLoading = false;
                } else {
                    setTimeout(() => {
                        this.isTwoFaEnabled = !this.isTwoFaEnabled;
                    });
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                setTimeout(() => {
                    this.isTwoFaEnabled = !this.isTwoFaEnabled;
                });
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    onRegistration() {
        const params = new FormData();
        if (this.registrationDetails["mobile_no"]) {
            params.append("mobile_no", this.registrationDetails["mobile_no"])
        }
        if (this.registrationDetails["customer_name"]) {
            params.append("customer_name", this.registrationDetails["customer_name"])
        }
        if (this.registrationDetails["customer_email"]) {
            params.append("customer_email", this.registrationDetails["customer_email"])
        }
        if (this.registrationDetails["password"]) {
            params.append("password", this.registrationDetails["password"])
        }
        if (this.registrationDetails["group_id"]) {
            params.append("group_id", JSON.stringify(this.registrationDetails["group_id"]))
        }
        if (this.registrationDetails["city_id"]) {
            params.append("city_id", this.registrationDetails["city_id"])
        }
        if (this.commonService.vatCertificateFile) {
            params.append("customer_vat_url", this.commonService.vatCertificateFile)
        }
        if (this.commonService.companyCrFile) {
            params.append("customer_cr_url", this.commonService.companyCrFile)
        }
        if (this.commonService.nationalAddressFile) {
            params.append("national_address_url", this.commonService.nationalAddressFile)
        }
        if (this.registrationDetails["customer_cr_no"]) {
            params.append("customer_cr_no", this.registrationDetails["customer_cr_no"])
        }
        if (this.registrationDetails["customer_vat_no"]) {
            params.append("customer_vat_no", this.registrationDetails["customer_vat_no"])
        }
        if (this.registrationDetails["national_id"]) {
            params.append("national_id", this.registrationDetails["national_id"])
        }
        params.append("default_language_id", '1')
        params.append("platform", this.platform.is("android") ? "ANDROID" : this.platform.is("ios") ? "IOS" : "WEB")

        if (this.platform.is("ios") || this.platform.is("android")) {
            params.append('notification_token', this.commonService.cookieGet('notification_token'));
        }
        this.subscription.add(this.dataService.post(URLS.registration, params).subscribe(
            (response) => {
                if (response['code'] === 200) {
                    this.commonService.localStorageSet("accessToken", response["data"]["accessToken"]);
                    this.commonService.localStorageRemove('registration_data');
                    this.commonService.localStorageSet('from_register', false);
                    this.isLoading = false;
                    this.disableBtn = false;
                    this.commonService.setEvent('profileData', null);
                    this.cartService.isloggedIn = true;
                    this.modalService.dismissAllModal();
                    this.navCtrl.navigateForward('/'+this.currentLang +'/home', { animated: false });
                    if (this.selected_Product && this.product_value) {
                        if (this.is_sample) {
                            this.cartService.addIntoCart(this.selected_Product, this.product_value, this.is_sample).then(() => {
                                this.navCtrl.navigateForward('/'+this.currentLang +'/cart', { animated: false });
                            });
                        } else {
                            this.cartService.addIntoCart(this.selected_Product, this.product_value).then(() => {
                                this.navCtrl.navigateForward('/'+this.currentLang +'/cart', { animated: false });
                            });
                        }
                    }

                } else {
                    this.isLoading = false;
                    this.cartService.isloggedIn = false;
                    this.toastService.displayToast(response['message'], null, 'danger');
                }
            }, (error) => {
                this.cartService.isloggedIn = false;
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            }));
    }

}
