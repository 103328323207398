<div class="add-review-container pt-20" [ngClass]="getPlatformService.isDesktop? 'pl-30 pr-30':null">
	<div class="header-line" *ngIf="!getPlatformService.isDesktop">
	</div>
	<div class="modal-close ion-text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%" />
	</div>
	<div class="add-review-header">
		{{'addReview' | translate}}
	</div>
	<div class="horizontal-line" *ngIf="!getPlatformService.isDesktop"></div>
	<div class="rating-container">
		<nz-rate class="add-review-rating" #rating [(ngModel)]="product_rating" [rating]="product_rating"
			[nzAllowHalf]="false" (ngModelChange)="ratingChange($event)"></nz-rate>
	</div>
	<form [formGroup]="reviewFormGroup" class="reviewFormContainer">
		<div class="review-title pb-15">
			<!-- <ion-item lines="none" mode="md" *ngIf="!isLoggedIn"> -->
			<!-- <ion-label position="floating" class="text-uppercase">{{'field.CustomerName' |translate}}<sup
                        class="required-star">*</sup></ion-label> -->
			<ion-input label="{{ 'field.CustomerName' | translate }}" label-placement="floating" fill="outline"
				formControlName="customer_name" maxlength="500"></ion-input>
			<!-- </ion-item> -->
			<ng-container
				*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'customer_name', 'formName': reviewFormGroup} }">
			</ng-container>
		</div>

		<div class="review-title pb-15">
			<!-- <ion-item lines="none" mode="md"> -->
			<!-- <ion-label position="floating" class="text-uppercase">{{'field.ReviewTitle' |translate}}<sup
                        class="required-star">*</sup></ion-label> -->
			<ion-input label="{{'field.ReviewTitle' |translate}}" label-placement="floating" fill="outline" rows="1"
				formControlName="review_title" maxlength="100"></ion-input>
			<!-- </ion-item> -->
			<ng-container
				*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'review_title', 'formName': reviewFormGroup} }">
			</ng-container>
		</div>
		<div class="review-title pb-15 textarea">
			<!-- <ion-item lines="none" mode="md"> -->
			<!-- <ion-label position="floating" class="text-uppercase">{{'field.ReviewDescription' |translate}}<sup
                        class="required-star">*</sup></ion-label> -->
			<ion-textarea label="{{'field.ReviewDescription' |translate}}" labelPlacement="floating" fill="outline"
				rows="4" formControlName="review_description" maxlength="3000"></ion-textarea>
			<!-- </ion-item> -->
		</div>

		<ng-container
			*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'review_description', 'formName': reviewFormGroup} }">
		</ng-container>
	</form>
</div>
<ion-footer>
	<div class="footer-button-container">
		<div class="cancel" (click)="closeModal()">
			{{ 'Cancel' | translate }}
		</div>
		<div class="footer-button">
			<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
				title=" {{ 'Submit' | translate }}" [isIcon]="false" [isHideInMobile]="false" [specificWidth]="'122px'"
				[specificHeight]="'46px'" [specificFontSize]="'0.9375rem'" (click)="onSubmit()">
			</app-common-button>
		</div>
	</div>
</ion-footer>

<ng-template let-formObject #validationMessages>
	<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
		<ion-text color="danger" class="error-msg"
			*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
			{{validation.message | translate }}
		</ion-text>
	</ng-container>
</ng-template>