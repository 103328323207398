export const newsData = [
	{
		id: 1,
		img: '/assets/images/news1.png',
		title: "Jazeera Paints is participating in the digital transformation at the...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 2,
		img: '/assets/images/news2.png',
		title: "Jazeera Paints Holds a Ramadan Iftar ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 3,
		img: '/assets/images/news3.png',
		title: "Jazeera Paints Showcases the Latest Artificial Intelligence and...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX....",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 4,
		img: '/assets/images/news4.png',
		title: "Jazeera Paints Selects the Best Colors for Children’s Bedrooms ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 5,
		img: '/assets/images/news3.png',
		title: "Jazeera Paints Showcases the Latest Artificial Intelligence and...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX....",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 6,
		img: '/assets/images/news2.png',
		title: "Jazeera Paints Selects the Best Colors for Children’s Bedrooms ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 7,
		img: '/assets/images/news1.png',
		title: "Jazeera Paints is participating in the digital transformation at the...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 8,
		img: '/assets/images/news3.png',
		title: "Jazeera Paints Holds a Ramadan Iftar ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 9,
		img: '/assets/images/news4.png',
		title: "Jazeera Paints Holds a Ramadan Iftar ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 10,
		img: '/assets/images/news2.png',
		title: "Jazeera Paints is participating in the digital transformation at the...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 11,
		img: '/assets/images/news1.png',
		title: "Jazeera Paints Selects the Best Colors for Children’s Bedrooms ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 12,
		img: '/assets/images/news3.png',
		title: "Jazeera Paints Showcases the Latest Artificial Intelligence and...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX....",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 13,
		img: '/assets/images/news2.png',
		title: "Jazeera Paints Holds a Ramadan Iftar ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 14,
		img: '/assets/images/news1.png',
		title: "Jazeera Paints Selects the Best Colors for Children’s Bedrooms ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 15,
		img: '/assets/images/news2.png',
		title: "Jazeera Paints is participating in the digital transformation at the...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 16,
		img: '/assets/images/news4.png',
		title: "Jazeera Paints Showcases the Latest Artificial Intelligence and...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX....",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
]

