import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductDetailPage } from './product-detail.page';

const routes: Routes = [
    {
        path: '',
        component: ProductDetailPage
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProductDetailPageRoutingModule { }
