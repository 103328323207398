import { DOCUMENT, PlatformLocation } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController, NavParams, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ApiService } from "../../services/api-data.service";
import { CartService } from "../../services/cart.service";
import { CommonService } from "../../services/common.service";
import { GetPlatformService } from "../../services/get-platform.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "app-order-payment",
  templateUrl: "./order-payment.component.html",
  styleUrls: ["./order-payment.component.scss"],
  providers: [NavParams],
})
export class OrderPaymentComponent implements OnInit {
  public isLoading = false;
  orderId: any;
  invoiceId: any;
  transactionId: any;
  paymentUrl: any;
  urlPayment: any;
  public subscription: Subscription = new Subscription();
  jsonLdStringifiedObj1: any;
  cardsType: any;

  constructor(
    private modalService: ModalService,
    private navParams: NavParams,
    public router: Router,
    public getPlatformService: GetPlatformService,
    public commonService: CommonService,
    private dataService: ApiService,
    private toastService: ToastService,
    public navCtrl: NavController,
    public platform: Platform,
    private route: ActivatedRoute,
    public location: PlatformLocation,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    public cartService: CartService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params["orderId"]) {
        this.orderId = params["orderId"];
        this.invoiceId = params["id"];
        this.transactionId = params["transaction_id"];
        this.paymentUrl = params["payment_url"];

        this.urlPayment =
          "/home/payment-loading?transaction_id=" +
          this.transactionId +
          "&invoice_id=" +
          this.invoiceId +
          "&order_id=" +
          this.orderId;
      }
    });
    this.cardsType = this.commonService.localStorageGet("selectedPaymentMethod")
      ? JSON.parse(this.commonService.localStorageGet("selectedPaymentMethod"))
      : null;
  }

  ngOnInit() {
    this.cardsType = this.commonService.localStorageGet("selectedPaymentMethod")
      ? JSON.parse(this.commonService.localStorageGet("selectedPaymentMethod"))
      : null;

    this.loadScript(this.paymentUrl);
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>this.document.body;
    const script = this.document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  goBack() {
    this.navCtrl.navigateBack('/'+this.translateService.currentLang +"/home", { animated: false });
  }

  navigateToPayment() {
    this.navCtrl.navigateRoot(['/'+this.translateService.currentLang +this.urlPayment], { animated: false });
  }
}
