import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../common/shared.module';
import { PaymentLoadingRoutingModule } from './payment-loading-routing.module';
import { PaymentLoadingComponent } from './payment-loading.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        PaymentLoadingRoutingModule
    ],
    declarations: [PaymentLoadingComponent]
})
export class PaymentLoadingModule { }
