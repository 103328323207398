<ion-header class="mobile-visibility app-header">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="order-header">
				{{'voucherRedemption' | translate}}
			</div>
		</ion-title>

		<img src="./assets/icon/addIcon.svg" alt="add" slot="end" (click)="addVoucherDialog()">

	</ion-toolbar>

	<div class="searchbar-container mh-16 mv-12" *ngIf="isLoggedIn">
		<div class="search-div width-auto mr-0">
			<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
				[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (input)="vouchersearch()"
				(ngModelChange)="vouchersearch()" (keyup.enter)="vouchersearch()">
				<img src="./assets/icon/search.svg" slot="start" alt="Jazeera">
				<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText" alt="Jazeera"
					(click)="clearSearch()">
			</ion-input>
		</div>
	</div>


	<!-- <div class="align-center mb-10 mh-16">
		<div class="width-100">
			<ion-item class="from-date-class item-padding" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
				id="voucherNo">
				<ion-input [(ngModel)]="voucherNumebr" placeholder="{{'voucherComponent.field' | translate}}"
					class="ion-text-start"></ion-input>

				<div class="filter-buttons ml-10" slot="end">
					<div class="apply-button ion-text-end">
						<button class="filter-apply-button" [disabled]="!voucherNumebr" (click)="reedemVoucher()">
							{{"redeem"| translate}}</button>
					</div>
				</div>
			</ion-item>
		</div>
	</div> -->

	<ion-row class="align-center mv-5 common-box box2 mh-16 mb-10">
		<p class="m-0 color-black text-bold"> {{"voucherComponent.label"|translate}}
			<span class="text-bold color-blue-60">{{commonService.currencySymbol}}
				{{voucherTotal|number}}</span>
		</p>
	</ion-row>
</ion-header>



<app-loader *ngIf="isLoading"></app-loader>


<ion-content [fullscreen]="true">


	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<div class="orders-listing mh-20" *ngIf="isLoggedIn && !isLoading">
		<div class="mobile-view-listing" *ngIf="voucherList.length">
			<div class="order-container">
				<ion-grid class="p-0 m-0">
					<ion-row class="order-card" *ngFor="let order of voucherList;index as i">
						<ion-col size="12">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.voucherId" | translate}}</div>
										<div class="grid-values">{{order.voucher_code}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.amount" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}
											{{order.voucher_amount ?( order.voucher_amount | number): '0'}}</div>
									</ion-col>

									<ion-col size="12" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.registerDate" | translate}}</div>
										<div class="grid-values">{{order.validity_start_date | date
											:'MMM dd, YYYY hh:mm a'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.expiryDate" | translate}}</div>
										<div class="grid-values">{{order.validity_end_date | date
											:'MMM dd, YYYY'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.status" | translate}}</div>
										<div class="grid-values">
											<ion-button fill="clear" class="order-status-btn"
												[ngClass]=" order.voucher_status == 'Active'? 'btn-app-green ':'btn-app-red '">
												{{order.voucher_status == 'Active' ? ('active' | translate)
												:('Expired' | translate)}}
											</ion-button>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'loadMoreOrders' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</div>

		<div *ngIf="!voucherList.length" class="no-orders">
			<img src="./assets/icon/no-order.svg" alt="Jazeera" class="mb-30" />
			<div class="mb-20">
				<p class="label-3 color-app-black ">{{'noRecordFound' | translate}}</p>
			</div>
		</div>


	</div>
	<app-login-prompt *ngIf="!isLoggedIn && !isLoading"></app-login-prompt>
</ion-content>