import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountDashboardComponent } from '../account-dashboard/account-dashboard.component';
import { AddressComponent } from '../address/address.component';
import { MyAccountComponent } from '../my-account/my-account.component';
import { NotificationComponent } from '../notification/notification.component';
import { ReturnOrderListingComponent } from '../return-order-listing/return-order-listing.component';
import { SettingComponent } from '../setting/setting.component';
import { NotificationCategoryComponent } from './../notification-category/notification-category.component';
import { MobileMenuComponent } from './mobile-menu.component';
import { NewsPage } from 'src/app/pages/news/news.page';
import { NewsDetailsPage } from 'src/app/pages/news-details/news-details.page';


const routes: Routes = [
	{
		path: '',
		component: MobileMenuComponent,
	},
	{
		path: 'dashboard',
		component: AccountDashboardComponent
	},
	{
		path: 'my-account-details',
		component: MyAccountComponent
	},
	{
		path: 'address',
		component: AddressComponent
	},
	{
		path: 'notification',
		component: NotificationCategoryComponent
	},
	{
		path: 'notification-list',
		component: NotificationComponent,
	},
	{
		path: 'return-order',
		component: ReturnOrderListingComponent
	},
	{
		path: 'orders',
		loadChildren: () => import('../../tab3/tab3.module').then(m => m.Tab3PageModule)
	},
	{
		path: 'invoices',
		loadChildren: () => import('../invoices/invoices.module').then(m => m.InvoicesModule)
	},
	{
		path: 'quote-request-list',
		loadChildren: () => import('../quote-list-mobile/quote-list-mobile.module').then(m => m.QuoteListMobilePageModule)
	},
	{
		path: 'my-invoice',
		loadChildren: () => import('../../pages/invoice-mobile/invoice-mobile.module').then(m => m.InvoiceMobilePageModule)
	},
	{
		path: 'my-statement',
		loadChildren: () => import('../../pages/statement-mobile/statement-mobile.module').then(m => m.StatementMobilePageModule)
	},
	{
		path: 'voucher',
		loadChildren: () => import('../../pages/voucher-mobile/voucher-mobile.module').then(m => m.VoucherMobilePageModule)
	},
	{
		path: 'cancel-order',
		component: ReturnOrderListingComponent
	},
	{
		path: 'setting',
		component: SettingComponent
	},
	{
		path: 'news',
		component: NewsPage
	},
	{
		path: "news-details/:name/:id",
		component: NewsDetailsPage
	},
	{
		path: 'other-menu',
		loadChildren: () => import('../../pages/other-menu/other-menu.module').then(m => m.OtherMenuModule)
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MobileMenuPageRoutingModule { }
