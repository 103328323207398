import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CheckOutStep1PageRoutingModule } from './checkout-step1-routing.module';

import { SharedModule } from '../../common/shared.module';
import { CheckOutStep1Page } from './checkout-step1';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CheckOutStep1PageRoutingModule,
        SharedModule,
    ],
    declarations: [CheckOutStep1Page]
})
export class CheckOutStep1PageModule { }
