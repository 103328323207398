import { Injectable } from '@angular/core';
import { isSupported } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { URLS } from './../_config/api.config';
import { CustomFirebaseAnalyticsProvider } from './CustomFirebaseAnalyticsProvider';
import { ApiService } from './api-data.service';
import { CommonService } from './common.service';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root'
})
export class CartService {
	cartList = [];
	cartCount = 0;
	cartProductIds = [];
	cartMainProductIds = [];
	cartIDandData = [];
	shipping_address: any;
	billing_address: any;
	public isloggedIn: boolean = false;
	private subscription: Subscription = new Subscription();

	constructor(
		private dataService: ApiService,
		public commonService: CommonService,
		private toastService: ToastService,
		public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
	) { }

	addIntoCart(data, quantity, is_sample_product?, hideNotification?) {

		const params = {
			"product_id": +data.product_id,
			"total_quantity": parseInt(quantity),
			"is_sample_product": false
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.cartAdd, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.toastService.displayToast(data['message'], null, 'success');
						isSupported().then(() => {
							this.customFirebaseAnalytics.trackEvent('Add to Cart', +data.product_id);  //Google Analytics Log In Event
						})
						this.commonService.localStorageSet('cartChange', 'true');
						this.getCart();
						resolve(data);
					} else {
						if (!hideNotification) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
						reject()
					}
				}, error => {
					if (!hideNotification) {
						this.toastService.displayToast(error['message'], null, 'danger');
					}
					reject(error);
				})
			)
		});
	}

	updateCart(cart_id, data) {
		const params = {
			"cart_id": data.cart_id,
			"total_quantity": parseInt(data.total_quantity),
			"is_sample_product": data.is_sample_product,
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.put(URLS.cartUpdate, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.commonService.localStorageSet('cartChange', 'true');
						resolve(data)
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
						reject()
					}
				}, error => {
					this.toastService.displayToast(error['message'], null, 'danger');
					reject(error);
				})
			)
		});
	}

	deleteCart(data) {
		const params = {
			"cart_id": data.cart_id,
			"is_sample_product": data.is_sample_product,
			"is_deleted": true
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.put(URLS.cartUpdate, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.toastService.displayToast(data['message'], null, 'success');
						this.commonService.localStorageSet('cartChange', 'true');

						resolve(data);
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
						reject();
					}
				}, error => {
					this.toastService.displayToast(error['message'], null, 'danger');
					reject(error);
				})
			)
		});
	}


	getCart(from?) {

		const token = this.commonService.localStorageGet('accessToken');
		if (!token) {
			return;
		}
		let discount_id = this.commonService.localStorageGet('discount_id');
		let discount_code = this.commonService.localStorageGet('discount_code');

		if ((discount_id || discount_code) && (discount_code != "null" ||
			discount_id != "null") && (discount_code != null || discount_id != null)) {
			const params = {};

			if (this.commonService.localStorageGet('shipping_address_id')) {
				params['address_id'] = +this.commonService.localStorageGet('shipping_address_id')
			}
			else {
				params['city_id'] = +this.commonService.localStorageGet("shipping_city_id");
				params['latitude'] = +this.commonService.localStorageGet("latitude");
				params['longitude'] = +this.commonService.localStorageGet("longitude");
			}

			if (discount_id && discount_id != "null" && discount_id != null) {
				params['coupon_id'] = +discount_id;
			}
			if (discount_code && discount_code != "null" && discount_code != null) {
				params['coupon_code'] = discount_code;
			}

			return new Promise((resolve, reject) => {
				// if (this.commonService.localStorageGet('cartData') && this.commonService.localStorageGet('cartChange') == 'false') {
				// 	let storedCartData = JSON.parse(this.commonService.localStorageGet('cartData'));
				// 	let cartData;
				// 	this.cartList = [];
				// 	this.cartProductIds = [];
				// 	this.cartMainProductIds = [];
				// 	cartData = this.tranformDataIntoActualFormat(storedCartData['data']);

				// 	if (cartData.grand_total && cartData.grand_total.length) {
				// 		cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
				// 	}
				// 	cartData.result.forEach(element => {
				// 		element.products.forEach(element2 => {
				// 			this.cartList.push(element2);
				// 			this.cartProductIds.push(element2.product_id);
				// 			this.cartMainProductIds.push(element2.main_product_ref_id);
				// 			this.commonService.localStorageSet('cartMainProductIds', JSON.stringify(this.cartMainProductIds));

				// 		});
				// 	});
				// 	this.cartCount = this.cartList.length;
				// 	resolve(cartData);
				// }
				this.subscription.add(
					this.dataService.post(URLS.couponApply, params).subscribe(data => {
						if (data && data['code'] == 200) {
							this.commonService.localStorageSet('cartData', JSON.stringify(data));
							this.commonService.localStorageSet('cartChange', 'false');

							let cartData;
							this.cartList = [];
							this.cartProductIds = [];
							this.cartMainProductIds = [];
							this.cartIDandData = [];

							// cartData = data['data'];
							cartData = this.tranformDataIntoActualFormat(data['data']);

							if (cartData.grand_total && cartData.grand_total.length) {
								cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
							}
							cartData.result.forEach(element => {
								element.products.forEach(element2 => {
									this.cartList.push(element2);
									this.cartProductIds.push(element2.product_id);
									this.cartMainProductIds.push(element2.main_product_ref_id);
									this.cartIDandData.push({ id: element2.main_product_ref_id, cart_id: element2.cart_id });
									this.commonService.localStorageSet('cartMainProductIds', JSON.stringify(this.cartMainProductIds));
									this.commonService.localStorageSet('cartIDandData', JSON.stringify(this.cartIDandData));

								});
							});
							this.cartCount = this.cartList.length;
							if (this.cartCount <= 0) {
								this.commonService.localStorageRemove('cartMainProductIds');
								this.commonService.localStorageRemove('cartIDandData');
								this.commonService.setEvent("clearCart", null);
							}
							resolve(cartData);
						} else {
							this.commonService.localStorageSet('cartChange', 'false');
							this.commonService.localStorageRemove('cartMainProductIds');
							this.commonService.localStorageRemove('cartIDandData');
							this.toastService.displayToast(data['message'], null, 'danger');
							reject();
						}
					}, error => {
						this.commonService.localStorageSet('cartChange', 'false');
						this.commonService.localStorageRemove('cartMainProductIds');
						this.commonService.localStorageRemove('cartIDandData');
						this.toastService.displayToast(error['message'], null, 'danger');
						reject(error);
					})
				)
			})

		}
		else {
			return new Promise((resolve, reject) => {
				// if (this.commonService.localStorageGet('cartData') && this.commonService.localStorageGet('cartChange') == 'false') {
				// 	let storedCartData = JSON.parse(this.commonService.localStorageGet('cartData'));
				// 	let cartData;
				// 	this.cartList = [];
				// 	this.cartProductIds = [];
				// 	this.cartMainProductIds = [];
				// 	cartData = this.tranformDataIntoActualFormat(storedCartData['data']);

				// 	if (cartData.grand_total && cartData.grand_total.length) {
				// 		cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
				// 	}
				// 	cartData.result.forEach(element => {
				// 		element.products.forEach(element2 => {
				// 			this.cartList.push(element2);
				// 			this.cartProductIds.push(element2.product_id);
				// 			this.cartMainProductIds.push(element2.main_product_ref_id);
				// 			this.commonService.localStorageSet('cartMainProductIds', JSON.stringify(this.cartMainProductIds));

				// 		});
				// 	});
				// 	this.cartCount = this.cartList.length;
				// 	resolve(cartData);
				// }
				// else {
				let params = {}

				if (this.commonService.localStorageGet('shipping_address_id')) {
					params['address_id'] = this.commonService.localStorageGet('shipping_address_id')
				}
				else {
					params['city_id'] = +this.commonService.localStorageGet("shipping_city_id");
					params['latitude'] = +this.commonService.localStorageGet("latitude");
					params['longitude'] = +this.commonService.localStorageGet("longitude");

				}

				this.subscription.add(
					this.dataService.get(URLS.cartList, params).subscribe(data => {
						if (data && data['code'] == 200) {
							this.commonService.localStorageSet('cartData', JSON.stringify(data));
							this.commonService.localStorageSet('cartChange', 'false');
							let cartData;
							this.cartList = [];
							this.cartProductIds = [];
							this.cartMainProductIds = [];
							this.cartIDandData = [];
							cartData = this.tranformDataIntoActualFormat(data['data']);

							if (cartData.grand_total && cartData.grand_total.length) {
								cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
							}
							cartData.result.forEach(element => {
								element.products.forEach(element2 => {
									element2.is_sold_out = false;
									this.cartList.push(element2);
									this.cartProductIds.push(element2.product_id);
									this.cartMainProductIds.push(element2.main_product_ref_id);
									this.cartIDandData.push({ id: element2.main_product_ref_id, cart_id: element2.cart_id });
									this.commonService.localStorageSet('cartMainProductIds', JSON.stringify(this.cartMainProductIds));
									this.commonService.localStorageSet('cartIDandData', JSON.stringify(this.cartIDandData));
								});
							});
							this.cartCount = this.cartList.length;
							if (this.cartCount <= 0) {
								this.commonService.localStorageRemove('cartMainProductIds');
								this.commonService.localStorageRemove('cartIDandData');
								this.commonService.setEvent("clearCart", null);
							}
							resolve(cartData);
						} else {
							this.commonService.localStorageSet('cartChange', 'false');
							this.commonService.localStorageRemove('cartMainProductIds');
							this.commonService.localStorageRemove('cartIDandData');
							reject();
						}
					}, error => {
						this.commonService.localStorageSet('cartChange', 'false');
						this.commonService.localStorageRemove('cartMainProductIds');
						this.commonService.localStorageRemove('cartIDandData');
						reject(error);
					})
				)
				// }
			})
		}



		// 	return new Promise((resolve, reject) => {
		// 		let data = cartDATA;
		// 		if (data && data['code'] == 200) {
		// 			let cartData;
		// 			this.cartList = [];
		// 			this.cartProductIds = [];
		// 			this.cartMainProductIds = [];
		// 			// cartData = data['data'];
		// 			cartData = this.tranformDataIntoActualFormat(data['data']);
		// 			console.log(cartData)

		// 			if (cartData.grand_total && cartData.grand_total.length) {
		// 				cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
		// 			}
		// 			cartData.result.forEach(element => {
		// 				element.products.forEach(element2 => {
		// 					this.cartList.push(element2);
		// 					this.cartProductIds.push(element2.product_id);
		// 					this.cartMainProductIds.push(element2.main_product_ref_id);
		// 				});
		// 			});
		// 			this.cartCount = this.cartList.length;
		// 			resolve(cartData);
		// 		} else {
		// 			this.toastService.displayToast(data['message'], null, 'danger');
		// 			reject();
		// 		}
		// 	})
	}


	isExistInCart(id) {
		// if (this.commonService.localStorageGet('cartMainProductIds')) {
		// 	let cartMainProductIds = JSON.parse(this.commonService.localStorageGet('cartMainProductIds'));
		// 	return cartMainProductIds.includes(+id);
		// }
		// return false;

		if (this.commonService.localStorageGet('cartIDandData')) {
			let cartIDandData = JSON.parse(this.commonService.localStorageGet('cartIDandData'));

			let index = cartIDandData.findIndex((item: any) => item.id == id);
			if (index !== -1) {
				return { exists: true, cart_id: cartIDandData[index].cart_id };
			}
		}
		return { exists: false, cart_id: null };

	}

	tranformDataIntoActualFormat(response: any): any {
		if (response.result.length < 1) {
			let result = {
				"is_shipping_cost_eliminated": null,
				"total_supplier_count": null,
				"result": [],
				"grand_total": null,
				"total_product": null,
				"enable_next": null
			};

			return result;
		}

		let productsArray = [];
		response.result.forEach(element => {
			let productObj = {
				...element,
				//jazeeera key
				cart_id: element.cart_id,
				product_id: element.product_id,
				sku_code: element.sku_code,
				product_sku: element.sku_code,
				product_cover_image: element.product_cover_image ? element.product_cover_image : null,
				product_name_en: element.product_name_en,
				product_name_ar: element.product_name_ar,
				main_product_ref_id: element.main_product_ref_id,
				distribution_center: element.distribution_center,
				quantity: element.quantity,
				price_per_unit: element.price_per_unit,
				available_stock: element.available_stock,
				discount_amount: element.discount_amount,
				tax_amount: element.tax_amount,
				sub_total: element.sub_total,
				//jazeera key
				unit_id: element.unit_id || "--",
				brand_id: element.brand_id || "--",
				category_id: element.category_id || "--",
				supplier_id: element.supplier_id || "--",
				total_price: element.sub_total || "--",
				min_quantity: element.min_quantity || 1,
				unit_name_ar: element.unit_name_ar || element.quantity > 1 ? "قطع" : "قطعة",
				unit_name_en: element.unit_name_en || element.quantity > 1 ? "Pieces" : "Piece",
				brand_name_ar: element.brand_name_ar || "--",
				brand_name_en: element.brand_name_en || "--",
				sold_quantity: element.sold_quantity || 0,
				product_weight: element.product_weight || 0,
				sample_unit_id: element.sample_unit_id || null,
				total_quantity: element.quantity || 0,
				vat_percentage: element.vat_percentage || 0,
				commission_rate: element.commission_rate || 0,
				price_per_piece: element.price_per_unit || 0,
				category_name_ar: element.category_name_ar || 0,
				category_name_en: element.category_name_en || 0,
				is_cart_processed: element.is_cart_processed || false,
				is_sample_product: element.is_sample_product || false,
				sample_unit_value: element.sample_unit_value || null,
				secondary_unit_id: element.secondary_unit_id || null,
				conversation_ratio: element.conversation_ratio || null,
				is_tax_free_product: element.is_tax_free_product || false,
				is_commission_active: element.is_tax_free_product || false,
				product_configuration: element.product_configuration || null,
				sample_price_per_unit: element.sample_price_per_unit || 0,
				secondary_unit_name_ar: element.secondary_unit_name_ar || null,
				secondary_unit_name_en: element.secondary_unit_name_en || null,
				total_quantity_product: element.quantity || null,
				estimated_delivery_date: element.estimated_delivery_date || null,
				max_sample_unit_provided: element.max_sample_unit_provided || null,
				commission_request_percentage: element.commission_request_percentage || null,
				commission_approval_percentage: element.commission_approval_percentage || null,
				feature_product_approval_status: element.feature_product_approval_status || null,
				is_sample_item_and_shipment_free: element.is_sample_item_and_shipment_free || false,
				is_sample_item_and_shipment_charged: element.is_sample_item_and_shipment_charged || false,
				is_sample_item_free_and_shipment_charged: element.is_sample_item_free_and_shipment_charged || false,
				discount_id: element.discount_id || null,
				is_discount_applied: element.is_discount_applied || false,
				discount_value: element.discount_amount || null,
				discount_type: element.discount_type || null,
				final_price: element.sub_total || null,
				final_price_per_unit: element.price_per_unit || null,
				available_quantity: element.available_stock || null,
				is_sold_out: element.is_sold_out || false,
				delivery_days: element.delivery_days || null,
				delivery_warehouse_id: element.delivery_warehouse_id || null,
				tax_per_product: element.tax_per_product || null,
				shipping_charges_without_vat: element.shipping_charges_without_vat || null,
				shipping_charges: element.shipping_charges || null,
				shipping_vat_charges: element.shipping_vat_charges || null,
				eliminated_shipping_charges: element.eliminated_shipping_charges || null,
				eliminated_shipping_charges_without_vat: element.eliminated_shipping_charges_without_vat || null,
				eliminated_shipping_vat_charges: element.eliminated_shipping_vat_charges || null
			}

			productsArray.push(productObj);
		});

		let result = {
			...response,
			"is_shipping_cost_eliminated": false,
			"total_supplier_count": "1",
			"result": [
				{
					"supplier_id": response.supplier_id,
					"supplier_legal_name_en": response.supplier_name_en,
					"supplier_legal_name_ar": response.supplier_name_ar,
					"products": productsArray,
					"sub_total_price": response.grand_total,
				}
			],
			"grand_total": response.grand_total,
			"total_product": productsArray.length,
			"enable_next": false
		}

		return result;
	}
}
