import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { otherMenuComponentRoutingModule } from './other-menu-routing.module';
import { otherMenuComponent } from './other-menu.component';


@NgModule({
    declarations: [otherMenuComponent],
    imports: [
        CommonModule,
        SharedModule,
        otherMenuComponentRoutingModule
    ]
})
export class OtherMenuModule { }
