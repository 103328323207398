import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { TranslateConfigService } from '../../services/translate-config.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CartService } from './../../services/cart.service';
import { CommonService } from './../../services/common.service';
import { ToastService } from './../../services/toast.service';
import { AddEditPaymentCardPage } from './../add-edit-payment-card/add-edit-payment-card.page';

@Component({
	selector: 'app-checkout-step2-page',
	templateUrl: './checkout-step2.html',
	styleUrls: ['./checkout-step2.scss'],
})
export class CheckOutStep2Page implements OnInit, OnDestroy {

	public payment_methods: any = []

	public address: any = [];
	public showCards: boolean = false;
	public isLoading = false;
	private subscription: Subscription = new Subscription();
	defalutBillingAddress: any;
	cartData: any = [];
	appliedCouponCode: any;
	selectedPaymentMethod: any;
	billing_address: any = [];
	tempSelectedAddress: any;
	cartProductData: any = [];

	closed$ = new Subject<any>();


	cardFromGroup: FormGroup;

	public validation_messages = {

		first_name: [
			{ type: 'required', message: 'validationComponent.firstNameRequired' },
			{ type: 'invalidName', message: 'validationComponent.validFirstName' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
		father_name: [
			{ type: 'required', message: 'validationComponent.fatherNameRequired' },
			{ type: 'invalidName', message: 'validationComponent.validFatherName' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
		grand_father_name: [
			{ type: 'required', message: 'validationComponent.grandFatherNameRequired' },
			{ type: 'invalidName', message: 'validationComponent.validGrandFatherName' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
		last_name: [
			{ type: 'required', message: 'validationComponent.lastnameRequired' },
			{ type: 'invalidName', message: 'validationComponent.validLastName' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
		national_id: [
			{ type: 'required', message: 'validationComponent.nationalIdRequired' },
			{ type: 'invalidName', message: 'validationComponent.validNationalId' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
			{ type: 'invalidNumber', message: 'validationComponent.validNationalId' },
		],
		due_date: [
			{ type: 'required', message: 'validationComponent.dueDateRequired' },
			{ type: 'invalidName', message: 'validationComponent.validDueDate' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
		date_of_birth: [
			{ type: 'required', message: 'validationComponent.dateOfBirthRequired' },
			{ type: 'invalidName', message: 'validationComponent.validDateOfBirth' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
		date_of_birth_hijri: [
			{ type: 'required', message: 'validationComponent.dateOfBirthHirjiRequired' },
			{ type: 'invalidName', message: 'validationComponent.validDateOfBirthHirji' },
			{ type: 'maxlength', message: 'validationComponent.maxLengthRequired' },
		],
	}

	due_date_field: any;
	date_of_birth_field: any;
	public shipping_info: any;
	public billing_info: any;
	todayDate: any = new Date().toISOString();
	currentLang;
	is_not_sample_product = true;
	is_sample_item_and_shipment_free = false;
	constructor(private translateConfigService: TranslateConfigService,
		public getPlatformService: GetPlatformService,
		public router: Router,
		public navCtrl: NavController,
		public commonService: CommonService,
		private dataService: ApiService,
		private translate: TranslateService,
		private alertController: AlertController,
		private toastService: ToastService,
		public cartService: CartService,
		private formBuilder: FormBuilder,
		public modalService: ModalService) {
		this.currentLang = this.translate.currentLang;
		this.translateConfigService.getDefaultLanguage();
	}


	ngOnInit() {
		this.isLoading = true;
		this.commonService.routerEventVariable = this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			takeUntil(this.closed$)
		).subscribe(event => {
			const token = this.commonService.localStorageGet('accessToken');
			if (token && this.router.url.includes("checkout-step-2")) {
				this.getAddresses();
				this.getCardType();
				this.cartService.getCart().then((data) => {
					this.cartData = data;
					this.cartProductData = [];
					this.cartData.result.forEach(element => {
						element.products.forEach(element2 => {
							this.cartProductData.push(element2);
						});
					});
					for (let i = 0; i < this.cartData.result.length; i++) {
						const element = this.cartData.result[i];
						if (element.products && element.products.length) {
							this.is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
							this.is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
							if (this.is_not_sample_product || (!this.is_not_sample_product && !this.is_sample_item_and_shipment_free)) {
								break;
							}

						}
					}
					if (!this.is_not_sample_product && this.is_sample_item_and_shipment_free) {
						this.selectedPaymentMethod = true;
					}
					this.routeHistory();
					this.isLoading = false;
				}).catch(error => {
					this.routeHistory();
					this.isLoading = false;
				});
			}
		})


	}


	ionViewWillEnter() {
		this.translateConfigService.getDefaultLanguage();
		this.routeHistory();
		const token = this.commonService.localStorageGet('accessToken');
		if (token && this.router.url.includes("checkout-step-2")) {
			this.isLoading = true;
			this.getAddresses();
			this.getCardType();
			this.cartService.getCart().then((data) => {
				this.cartData = data;
				this.cartProductData = [];
				this.cartProductData = [];
				this.cartData.result.forEach(element => {
					element.products.forEach(element2 => {
						this.cartProductData.push(element2);
					});
				});
				for (let i = 0; i < this.cartData.result.length; i++) {
					const element = this.cartData.result[i];
					if (element.products && element.products.length) {
						this.is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
						this.is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
						if (this.is_not_sample_product || (!this.is_not_sample_product && !this.is_sample_item_and_shipment_free)) {
							break;
						}

					}
				}
				if (!this.is_not_sample_product && this.is_sample_item_and_shipment_free) {
					this.selectedPaymentMethod = true;
				}
				this.routeHistory();
				this.isLoading = false;
			}).catch(error => {
				this.routeHistory();
				this.isLoading = false;
			});
		}
	}

	routeHistory() {
		if (!this.cartService.cartList.length && this.getPlatformService.isDesktop && this.router.url.includes("checkout-step-2")) {
			let url = this.commonService.localStorageGet('url');
			if (url) {
				this.commonService.localStorageRemove('url');
				this.navCtrl.navigateRoot(`/${this.currentLang}${url}`, { animated: false, replaceUrl: true })
			} else {
				this.navCtrl.navigateRoot('/' + this.currentLang + "/home/product-listing", { animated: false, replaceUrl: true })
			}
		}
	}

	choosePamentMethods(i) {
		this.payment_methods.forEach((element, index) => {
			if (index == i) {
				element.select = true;
				this.selectedPaymentMethod = element;
				this.commonService.localStorageSet("selectedPaymentMethod", JSON.stringify(this.selectedPaymentMethod))

			}
			else element.select = false;
		});
	}

	chooseCard(i) {

	}


	addNewCardDialog() {
		let options: any = {
			id: "addEditPaymentCardModal"
		};
		options['component'] = AddEditPaymentCardPage;
		this.modalService.openModal(options)


	}

	buttonDiabledMethod() {
		let isButtonDisable = false;
		if (!this.cartData.grand_total) {
			isButtonDisable = true;
		}

		if (!this.selectedPaymentMethod) {
			isButtonDisable = true;
		}

		if (!this.defalutBillingAddress) {
			isButtonDisable = true;
		}

		return isButtonDisable;
	}

	gotoChekoutReviewPage() {


		if (!this.selectedPaymentMethod) {
			this.toastService.displayToast("Please select payment method", null, 'danger');
			return;
		}
		if (!this.defalutBillingAddress) {
			this.toastService.displayToast("Please select billing address", null, 'danger');
			return;
		}

		if (this.tempSelectedAddress) {
			this.router.navigate(['' + this.translate.currentLang + '/checkout-step-review']);
		}

		else {
			this.billing_address.forEach(element => {
				if (element.is_default_billing_address == true) {
					element.select = true;
					this.defalutBillingAddress = element;
					this.cartService.billing_address = element;
				}
			});
			this.router.navigate(['' + this.translate.currentLang + '/checkout-step-review']);
		}
	}


	async getAddresses() {
		this.billing_address = [];
		await this.subscription.add(
			this.dataService.get(URLS.getAddressList).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.billing_address = data['data'];
					let addresses = data['data'];

					this.billing_address.forEach(element => {
						if (element.is_default_billing_address == true) {
							element.select = true;
							this.defalutBillingAddress = element;
							this.cartService.billing_address = element;
							this.commonService.localStorageSet('cart_billing_address', element.address_id);
						}
					});

					if (this.billing_address.length == 1 && this.billing_address[0].is_default_billing_address != true) {
						const params = {
							"address_id": +this.billing_address[0].address_id,
							"is_default_billing_address": true
						}

						this.subscription.add(
							this.dataService.put(URLS.updateAddress, params).subscribe(data => {
								if (data['data'] && data['code'] == 200) {
									this.tempSelectedAddress = null;
									this.getAddresses();
								} else {
									this.toastService.displayToast(data['message'], null, 'danger');
								}
							}, error => {
								this.toastService.displayToast(error['message'], null, 'danger');
							}))
					}

					else if (!this.defalutBillingAddress) {
						const params = {
							"address_id": +this.billing_address[0].address_id,
							"is_default_billing_address": true
						}

						this.subscription.add(
							this.dataService.put(URLS.updateAddress, params).subscribe(data => {
								if (data['data'] && data['code'] == 200) {
									this.tempSelectedAddress = null;
									this.getAddresses();
								} else {
									this.toastService.displayToast(data['message'], null, 'danger');
								}
							}, error => {
								this.toastService.displayToast(error['message'], null, 'danger');
							}))
					}

					let shipping_id = this.commonService.localStorageGet('cart_shipping_address');
					let billing_id = this.commonService.localStorageGet('cart_billing_address');

					addresses.forEach(element => {
						if (element.address_id == billing_id) {
							this.billing_info = element
						}
						if (element.address_id == shipping_id) {
							this.shipping_info = element
						}
					});

				} else {
				}
			}, error => {
			})
		)
	}
	async getCardType() {
		await this.subscription.add(
			this.dataService.get(URLS.masterPaymentType).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.payment_methods = data['data'] ? data['data'] : [];

					let chosedselectedPaymentMethod = this.commonService.localStorageGet("selectedPaymentMethod");

					if (chosedselectedPaymentMethod && chosedselectedPaymentMethod != "null" && chosedselectedPaymentMethod != null) {
						let selectedPaymentMethod = JSON.parse(chosedselectedPaymentMethod);
						this.payment_methods.forEach((element, index) => {
							if (element.payment_type_id == selectedPaymentMethod.payment_type_id) {
								this.selectedPaymentMethod = element;
								element.select = true;
							}
							else {
								element.select = false;
							}
						});
					}

				} else {
				}
			}, error => {
			})
		)
	}


	chooseAddress(element, i) {
		this.billing_address.forEach(data => {
			if (data.address_id == element.address_id) {
				data.select = true;
				this.tempSelectedAddress = data.address_id;
			}
			else {
				data.select = false;
			}
		});

		this.commonService.localStorageSet('cart_billing_address', this.tempSelectedAddress);
	}

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let catnameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.sub_class_name_en : data.sub_class_name);
		this.navCtrl.navigateForward('/' + this.currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
			{
				animated: false,
				"state": {
					"breadcrumb": data.product_name_en,
					"url": "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				}
			});
	}


	goBack() {
		this.navCtrl.navigateBack('/' + this.currentLang + '/checkout-step-1');
	}

	ngOnDestroy() {
		this.commonService.localStorageRemove('fromCartHeader');
		this.commonService.routerEventVariable.unsubscribe();
	}

	ionViewWillLeave() {
		this.commonService.localStorageRemove('fromCartHeader');
		this.commonService.routerEventVariable.unsubscribe();
	}


	onDateChange(datetime) {
		this.due_date_field = datetime.value;
		this.cardFromGroup.controls['due_date'].setValue(datetime.value);
		datetime.cancel(true);
	}
	onDateChange2(datetime) {
		this.date_of_birth_field = datetime.value;
		this.cardFromGroup.controls['date_of_birth'].setValue(datetime.value);
		datetime.cancel(true);
	}

	calculateSUOM(ratio, quantity) {
		if (ratio && quantity) {
			return ratio * (+quantity);
		} else {
			return ratio;
		}
	}


}
