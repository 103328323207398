import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-payment-loading',
    templateUrl: './payment-loading.component.html',
    styleUrls: ['./payment-loading.component.scss'],
    providers: [NavParams]
})
export class PaymentLoadingComponent implements OnInit {


    public isLoading = false;
    orderId: any;
    invoiceId: any;
    transactionId: any;
    paymentUrl: any;
    urlPayment: any;
    public subscription: Subscription = new Subscription();
    jsonLdStringifiedObj1: any;


    constructor(private modalService: ModalService,
        private navParams: NavParams,
        public router: Router,
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        public navCtrl: NavController,
        public platform: Platform,
        private route: ActivatedRoute,
        public location: PlatformLocation,
        private translateService: TranslateService,
        public sanitizer: DomSanitizer,
        public cartService: CartService) {


        this.route.queryParams
            .subscribe(params => {
                this.transactionId = params['transaction_id'] ? params['transaction_id'] : null;
                this.invoiceId = params['invoice_id'] ? params['invoice_id'] : null;
                this.orderId = params['order_id'] ? params['order_id'] : null;

            });
    }

    ngOnInit() {
        this.paymentStatusMethodCall();
    }

    paymentStatusMethodCall() {

        const params = {
            "transaction_id": this.transactionId,
            "invoice_id": this.invoiceId,
        }

        this.subscription.add(
            this.dataService.get(URLS.paymentStatus, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.isLoading = false;
                    let resData = data['data'];
                    this.commonService.localStorageRemove('selectedPaymentMethod');
                    this.commonService.localStorageRemove('discount_id');
                    this.commonService.localStorageRemove('discount_code');
                    this.commonService.localStorageRemove('fromCartHeader');

                    if (resData['payment_status']) {
                        this.router.navigate([''+this.translateService.currentLang+'/home/order-thank-you'], {
                            queryParams: {
                                "payment_amount": resData.payment_amount ? resData.payment_amount : 0,
                                "payment_id": resData.payment_id ? resData.payment_id : 0,
                                "payment_status": resData.payment_status ? resData.payment_status : 0,
                                "transaction_id": resData.transaction_id ? resData.transaction_id : 0,
                                "orderId": this.orderId ? this.orderId : 0,
                                "id": this.invoiceId ? this.invoiceId : 0,
                            }
                        });
                    }


                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                    this.gotoCart();
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
                this.gotoCart();
            })
        )
    }


    gotoCart() {
        this.navCtrl.navigateBack('/'+this.translateService.currentLang+"/cart", { animated: false })
    }

    goBack() {
        this.navCtrl.navigateBack('/'+this.translateService.currentLang+"/home", { animated: false })
    }


}
