import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReturnOrderListingRoutingModule } from './return-order-listing-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReturnOrderListingRoutingModule
  ]
})
export class ReturnOrderListingModule { }
