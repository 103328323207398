import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CategoryPage } from './category-page.page';

const routes: Routes = [
    {
        path: '',
        component: CategoryPage,

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CategoryPageRoutingModule { }
