import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddEditAddressPageRoutingModule } from './add-edit-address-routing.module';

import { SharedModule } from '../../common/shared.module';
import { AddEditAddressPage } from './add-edit-address.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AddEditAddressPageRoutingModule,
        SharedModule
    ],
    declarations: [AddEditAddressPage]
})
export class AddEditAddressPageModule { }
