<!-- <form action="{{paymentUrl}}" class="paymentWidgets" data-brands="VISA MASTER AMEX" ></form> -->
<!-- <form
    action="https://jazeeraapi.dev2.brainvire.net/customer/payment/status?transaction_id=557C055698C4831767447488B8717A29.uat01-vm-tx03&invoice_id=432"
    class="paymentWidgets" data-brands="VISA MASTER AMEX"></form> -->


<div *ngIf="!getPlatformService.isDesktop" class="text-center mv-25">
    <img routerLink="/" src="./assets/icon/companyLogo.svg" alt="company-logo" height="100%" width="100%"
        class="company-logo pointer">
</div>
<div *ngIf="getPlatformService.isDesktop" class="text-center mv-30">
    <img routerLink="/" src="./assets/icon/companyLogo.svg" alt="company-logo" height="100%" width="100%"
        class="company-logo-web pointer">
</div>

<form *ngIf="cardsType.payment_type_id != 'MADA'" action="{{urlPayment}}" method="POST" class="paymentWidgets"
    id="paymentForm" data-brands="VISA MASTER AMEX"></form>
<form *ngIf="cardsType.payment_type_id == 'MADA'" action="{{urlPayment}}" method="POST" class="paymentWidgets"
    id="paymentForm" data-brands="MADA">

</form>