<div class="account-information" *ngIf="paymentInfo && !isMobile">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.PaymentTermsCode" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{paymentInfo.payment_term_code || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.PaymentTermsName" |translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{paymentInfo.payment_term_name || "--"}}
								</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"statementComponent.currency" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{paymentInfo.currency || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.PaymentDisc"|translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{paymentInfo.payment_term_desc || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="isMobile && paymentInfo">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.PaymentTermsCode"|translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{paymentInfo.payment_term_code || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.PaymentTermsName"|translate}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{paymentInfo.payment_term_name || "--"}}
								</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"statementComponent.currency" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{paymentInfo.currency || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"customField.PaymentDisc" | translate}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{paymentInfo.payment_term_desc || "--"}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>