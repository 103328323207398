import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartModalPageRoutingModule } from './cart-modal-routing.module';

import { SharedModule } from '../../common/shared.module';
// import { CartModalPage } from './cart-modal.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CartModalPageRoutingModule,
        SharedModule
    ],
    // declarations: [CartModalPage]
})
export class CartModalPageModule { }
