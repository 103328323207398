import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-menus',
	templateUrl: './menus.component.html',
	styleUrls: ['./menus.component.scss'],
})
export class MenusComponent implements OnInit {
	subClassList: any = [];
	menuOptions2: any = [];
	isCatLoading = false;
	@Input() menuVisibility;
	@ViewChild('menu') menu: ElementRef;
	menuHeight = 0;
	@Output() public childEvent = new EventEmitter();
	private subscription: Subscription = new Subscription();
	public items = [];
	public isLoading = false;
	selectedClassId: number = 1;
	selectedSubClassId: number = 1;
	classImage: string = null;
	isView: boolean = false;
	currentLang;
	public selectedClassNameEn: any;
	public selectedClassNameAr: any;
	public timeoutId: any;
	public subClassTimeoutId: any;
	constructor(
		public router: Router,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translate: TranslateService,
		private navCtrl: NavController) {
		this.currentLang = this.translate.currentLang;
	}

	ngOnInit() {
		this.getClassList();
	}

	// hide menu on subClass clicks
	hideMenu() {
		this.menuVisibility = false;
		this.childEvent.emit(this.menuVisibility);
	}

	async goToProductListing(data) {
		this.hideMenu();
		let sub_class_id = data.sub_class_id;
		let subClassNameURL: any = this.commonService.trimUrl(data.display_sub_class_name_en);
		await this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + subClassNameURL + "/" + sub_class_id, {
			animated: false, "state": {
				"breadcrumb": this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_ar,
				"url": '/product-listing/' + subClassNameURL + "/" + sub_class_id,
				"id": data.sub_class_id,
				"ar_label": data.display_sub_class_name_ar,
				"banner_img": data.sub_class_image_url
			}
		});
	}

	notifyMe() {
		this.menuHeight = this.menu.nativeElement.offsetHeight;
	}

	getClassList() {
		this.subscription.add(
			this.dataService.get(URLS.allClassList).subscribe(data => {
				if (data['code'] == 200) {
					this.items = [];
					let classData = data['data'];
					this.items = classData;
					this.selectedClassId = this.items && this.items.length > 0 ? this.items[0]['class_id'] : null;
					this.selectedClassNameEn = this.items && this.items.length > 0 ? this.items[0]['display_class_name_en'] : null;
					this.selectedClassNameAr = this.items && this.items.length > 0 ? this.items[0]['display_class_name_ar'] : null;
					this.getSubClassList();
				}
			}, error => {
			})
		)

	}
	getProductCategories() {
		this.isCatLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getProductCategories).subscribe(data => {
				if (data['code'] == 200) {
					this.isCatLoading = false;
					this.items = [];
					let subClassData = data['data']['result'];
					subClassData.forEach((element, i) => {
						let childData = [];
						if (element.children && element.children.length) {
							element.children.forEach(child => {
								let childObj = {
									subClass: child.sub_class_name_en ? child.sub_class_name_en : "",
									subClass_ar: child.sub_class_name_ar ? child.sub_class_name_ar : "",
									subClass_id: child.sub_class_id ? child.sub_class_id : "",
									subClass_image_url: child.sub_class_image_url ? child.sub_class_image_url : ""
								}
								childData.push(childObj);
							});
						}
						let obj = {
							class_id: element.class_id ? element.class_id : "",
							heading: element.display_class_name_en ? element.display_class_name_en : "",
							heading_ar: element.display_class_name_ar ? element.display_class_name_ar : "",
							options: childData
						}
						this.items.push(obj);
					});
				} else {
					this.isCatLoading = false;
				}
			}, error => {
				this.isCatLoading = false;
			})
		)
	}

	goToSubClass(data) {
		let grp_id = data.class_id;
		let subClassNameURL: any = this.commonService.trimUrl(data.heading ? data.heading : data.product_name_en);
		this.router.navigateByUrl('/' + this.currentLang + '/category-listing/' + subClassNameURL + '/' + grp_id, {
			"state": {
				"breadcrumb": this.commonService.domDirectionLTR ? data.heading : data.heading_ar,
				"url": '/' + this.currentLang + '/category-listing/' + subClassNameURL + '/' + grp_id
			}
		});
	}

	getSubClassList() {
		let param = {};
		let class_Id = [];
		class_Id.push(this.selectedClassId);
		param["class_id"] = class_Id;
		param["page"] = 1;
		param["size"] = 9;
		this.isView = false;
		this.subClassList = [];
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.subClassListFilter, param).subscribe(data => {
				if (data['code'] == 200) {
					this.isView = data['data']['enable_next']
					let subClassdata = data['data'];
					if (subClassdata.length && subClassdata[0]['sub_class_id']) {
						this.selectedSubClassId = subClassdata[0]['sub_class_id']
					} else {
						this.selectedSubClassId = null
					}
					if (subClassdata.length && subClassdata[0]['sub_class_image_url']) {
						this.classImage = subClassdata[0]['sub_class_image_url']
					} else {
						this.classImage = null;
					}
					this.subClassList = subClassdata;
					this.subClassList.forEach(element => {
						if (element.display_sub_class_name_en) {
							element['display_sub_class_name_en'] = element.display_sub_class_name_en.charAt(0).toUpperCase() + element.display_sub_class_name_en.slice(1);
						}
					});
					setTimeout(() => {
						this.isLoading = false;
					}, 200);
				} else {
					setTimeout(() => {
						this.isLoading = false;
					}, 200);
				}
			}, error => {
				setTimeout(() => {
					this.isLoading = false;
				}, 200);
			})
		)
	}

	mouseEnter(menu) {
		this.timeoutId = setTimeout(() => {
			this.catItemChange(menu);
		}, 300)
	}

	mouseLeave() {
		clearTimeout(this.timeoutId);
	}

	catItemChange(val) {
		this.selectedClassId = val.class_id;
		this.selectedClassNameEn = val['display_class_name_en'];
		this.selectedClassNameAr = val['display_class_name_ar'];
		this.getSubClassList();
	}

	async goToSubClassListing(data?) {
		let grp_id = data.class_id;
		let subClassNameURL: any = this.commonService.trimUrl(this.selectedClassNameEn);
		let breadcrumbsubClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? this.selectedClassNameEn : this.selectedClassNameAr);
		let currentLang = this.translate.currentLang;
		this.navCtrl.navigateForward('/' + currentLang + '/category-listing/' + subClassNameURL + '/' + grp_id,
			{
				animated: false,
				"state": {
					"breadcrumb": this.commonService.domDirectionLTR ? this.selectedClassNameEn : this.selectedClassNameAr,
					"url": '/' + currentLang + '/category-listing/' + breadcrumbsubClassNameURL + '/' + grp_id,
				},
			});

	}

	async goToClass(data) {
		let grp_id = data.class_id;
		let subClassNameURL: any = this.commonService.trimUrl(data.display_class_name_en);
		let currentLang = this.translate.currentLang;
		this.navCtrl.navigateForward('/' + currentLang + '/category-listing/' + subClassNameURL + '/' + grp_id,
			{
				animated: false,
				"state": {
					"breadcrumb": this.commonService.domDirectionLTR ? data.display_class_name_en : data.display_class_name_en,
					"url": '/' + currentLang + '/category-listing/' + subClassNameURL + '/' + grp_id,
				},
			});
	}

	onSubClassHover(data) {
		this.subClassTimeoutId = setTimeout(() => {
			this.setSubClassImage(data);
		}, 300);
	}

	setSubClassImage(data?) {
		this.classImage = data.sub_class_image_url;
		this.selectedSubClassId = data.sub_class_id;
	}

	onSubClassLeave() {
		clearTimeout(this.subClassTimeoutId);
	}
}
