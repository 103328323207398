<ion-header *ngIf="!showAccordian" mode="md">
	<ion-toolbar mode="md">
		<img class="img-position" src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="filter-header">{{title | translate}}</div>
		</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content>
	<div class="filterBy-content" *ngIf="showAccordian">
		<div class="filterBy-list">
			<div class="filter-options pl-10 mb-5" *ngFor="let data of filterBy; index as i;"
				[ngStyle]="getFilterCategoryBackground(i)">
				<div class="selected-filter-options">
					{{ commonService.domDirectionLTR ? data.label : data.label_ar }}
				</div>
				<div class="pr-10">
					<img src="assets/icon/close_filter.svg" (click)="removeFilterOption(data)" alt="Jazeera"
						height="100%" width="100%">
				</div>
			</div>
		</div>
	</div>
	<div class="web-view">
		<ion-accordion-group multiple="true" class="filter-accordion" *ngIf="all && showAccordian"
			(ionChange)="accordionGroupChange($event)" [value]="filterValue" #accordionGroup>
			<ion-accordion [value]="filter.value" [ngClass]="i != filterOptions.length -1 ? 'filter-item':''"
				*ngFor="let filter of filterOptions;index as i" [disabled]="isAccordianDisabled">
				<ion-item slot="header" lines="none">
					<ion-label class="accordian-label">{{filter.filterName | translate }}</ion-label>
					<ng-container [ngTemplateOutlet]="icons" [ngTemplateOutletContext]="{isOpen:filter.isOpen}">
					</ng-container>
				</ion-item>
				<div slot="content">
					<ng-container *ngIf="filter.filterName == 'priceRange'">
						<ng-container [ngTemplateOutlet]="rangeSlider"
							[ngTemplateOutletContext]="{isOpen:filter.isOpen}">
						</ng-container>
					</ng-container>
					<ng-container *ngIf="filter.filterName != 'price'">
						<div class="category web-filters">
							<ng-container
								*ngTemplateOutlet="subClassContainer; context:{ $implicit:  filter.checkboxes }">
							</ng-container>
							<ng-container *ngIf="filter.filterName != 'priceRange' && filter.checkboxes.length < 1">
								<div class="pl-5">
									No data found
								</div>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</ion-accordion>
		</ion-accordion-group>
	</div>
	<div *ngIf="!all && subClass">
		<ng-container *ngTemplateOutlet="subClassContainer; context:{ $implicit:  subClassCheckbox }">
		</ng-container>
	</div>
	<div *ngIf="!all && region">
		<ng-container *ngTemplateOutlet="subClassContainer; context:{ $implicit:  regionCheckboxes }">
		</ng-container>
	</div>
	<div *ngIf="!all && supplier">
		<ng-container *ngTemplateOutlet="subClassContainer; context:{ $implicit:  supplierCheckbox }">
		</ng-container>
	</div>
	<div *ngIf="!all && brand">
		<ng-container *ngTemplateOutlet="subClassContainer; context:{ $implicit:  brandCheckbox }">
		</ng-container>
	</div>
	<div *ngIf="!all && class">
		<ng-container *ngTemplateOutlet="subClassContainer; context:{ $implicit:  classCheckbox }">
		</ng-container>
	</div>
	<div *ngIf="all && !showAccordian">
		<div class="mobile-filters">
			<ion-list class="filter-list">
				<ng-container *ngFor="let filter of filterOptions;index as i">
					<ng-container *ngIf="filter.filterName == 'priceRange'">
						<ion-item lines="none">
							<ion-label class="filter-list-label">{{filter.filterName | translate }}
							</ion-label>
						</ion-item>
						<ng-container [ngTemplateOutlet]="rangeSlider">
						</ng-container>
					</ng-container>
					<ion-item (click)="onClick(filter)" class="label-item"
						*ngIf="filter.inList && filter.filterName != 'price'">
						<ion-label class="filter-list-label">{{filter.filterName | translate }}
						</ion-label>
						<img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
					</ion-item>
					<div class="selected-filter-container pb-15">
						<ng-container *ngIf="filter.filterName == 'subClass'">
							<div class="selected-filter"
								*ngFor="let data of filterCategoryData;index as i;let last = last">
								<span *ngIf="i <=2">
									{{ commonService.domDirectionLTR ? data : getArabicText(data)}}</span>
								<span *ngIf="i < 2">,</span>
								<span class="and-more" *ngIf="i == 2 && i < 3">{{'andmore' | translate}}</span>
							</div>
						</ng-container>
						<ng-container *ngIf="filter.filterName == 'region'">
							<div class="selected-filter"
								*ngFor="let data of filterRegionData;index as i;let last = last">
								<span *ngIf="i <= 5">
									{{ commonService.domDirectionLTR ? data :getArabicText(data)}}
								</span>
								<span *ngIf="i < 5">,</span>
								<span class="and-more" *ngIf="i == 5 && i < 6">{{'andmore' | translate}}</span>
							</div>
						</ng-container>
						<ng-container *ngIf="filter.filterName == 'brand'">
							<div class="selected-filter"
								*ngFor="let data of filterBrandData;;index as i;let last = last">
								<span *ngIf="i <=2">
									{{ commonService.domDirectionLTR ? data :getArabicText(data)}}
								</span>
								<span *ngIf="i < 2">,</span>
								<span class="and-more" *ngIf="i == 2 && i < 3">{{'andmore' | translate}}</span>
							</div>
						</ng-container>
						<ng-container *ngIf="filter.filterName == 'supplier'">
							<div class="selected-filter"
								*ngFor="let data of filterSupplierData;;index as i;let last = last">
								<span *ngIf="i <=2">
									{{ commonService.domDirectionLTR ? data :getArabicText(data)}}
								</span>
								<span *ngIf="i < 2">,</span>
								<span class="and-more" *ngIf="i == 2 && i < 3">{{'andmore' | translate}}</span>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</ion-list>
		</div>
	</div>
	<ng-template #subClassContainer let-option>
		<ion-list class="filter-checkbox-list pb-20">
			<ion-item *ngFor="let item of option" lines="none">
				<ion-label class="checkbox-labels">
					{{commonService.domDirectionLTR ? item.label : item.label_ar}}
				</ion-label>
				<ion-checkbox [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" slot="start"
					[value]="item.value" (ionChange)="selectCheckbox($event, item, option, item.filter_id)"
					[checked]="item.isChecked"></ion-checkbox>
			</ion-item>
		</ion-list>
	</ng-template>
	<ng-template #icons let-isOpen="isOpen">
		<ion-icon name="remove-outline" class="iconColor" slot='end' *ngIf="isOpen"></ion-icon>
		<ion-icon name="add-outline" class="iconColor" slot='end' *ngIf="!isOpen"></ion-icon>
	</ng-template>
	<ng-template #rangeSlider let-silder>
		<div class="range-container mr-10">
			<div class="range-label-static" *ngIf="dataRangePin">
				<ion-label slot="start">{{lowerRangeValue | number }}</ion-label>
				<ion-label slot="end">{{ upperRangeValue | number }}</ion-label>
			</div>
			<ion-range mode="md" [dualKnobs]="true" [min]="minimunPrice" [max]="maximumPrice" [pin]="false"
				[value]="{ lower: minPrice, upper: maxPrice}" (ionKnobMoveEnd)="onIonKnobMoveEnd($event)"
				[disabled]="dateRangeDisable" (ionChange)="rangeChange($event)">
			</ion-range>
			<div class="range-label" *ngIf="dataRangePin">
				<ion-label slot="start">{{ commonService.currencySymbol }}{{ minimunPrice | number}}</ion-label>
				<ion-label slot="end">{{ commonService.currencySymbol }}{{ maximumPrice | number }}</ion-label>
			</div>
		</div>
	</ng-template>
</ion-content>
<ion-footer *ngIf="all && !showAccordian" class="filter-footer">
	<div class="footer-button-container">
		<div class="apply-filter-btn clear-btn" (click)="clearFiltersMobile()">
			<ion-button fill="clear" class="red-btn">{{'clearFilterBtn' | translate }}</ion-button>
		</div>
		<div class="apply-filter-btn" (click)="applyFiltersMobile()">
			<ion-button fill="clear" class="red-btn">{{'applyFilterBtn' | translate }}</ion-button>
		</div>
	</div>
</ion-footer>