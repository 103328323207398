<ion-header class="mobile-visibility" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBackMobile()">
			<ion-title>{{'otherMenuComponent.contactUs' | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<ion-content>
	<ion-grid class="detail-page">
		<ion-row *ngIf="getPlatformService.isDesktop">
			<ion-col size="12">
				<div class="career-header">
					{{ 'footerComponent.contactUs' | translate }}
				</div>
			</ion-col>
		</ion-row>
		<ion-row [formGroup]="contactFormGroup" *ngIf="this.contactFormGroup ">

			<ion-col [sizeLg]="12" [sizeMd]="12" [sizeSm]="12" [sizeXs]="12">
				<div class="first-name">
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
                            'careerPage.firstName' | translate }}<sup class="pl-5 required-star">*</sup>
                        </ion-label>
                        <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
                            'careerPage.first_name' | translate }}<sup class="pl-5 required-star">*</sup>
                        </ion-label> -->
					<ion-input label="{{ 'fullname' | translate }}" label-placement="floating" fill="outline"
						formControlName="firstName" maxlength="100" mode="md"></ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'firstName', 'formName': contactFormGroup} }">
					</ng-container>
				</div>
			</ion-col>
			<!-- commenting because there is no last name and first name so we add full name bug-B2BP-102 -->
			<!-- <ion-col [sizeLg]="6" [sizeMd]="6" [sizeSm]="12" [sizeXs]="12">
				<div class="last-name">
					<ion-item lines="none" mode="md">
                        <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
                            'careerPage.lastName' | translate }}<sup class="pl-5 required-star">*</sup>
                        </ion-label>
                        <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
                            'careerPage.last_name' | translate }}<sup class="pl-5 required-star">*</sup>
                        </ion-label>
					<ion-input label="{{ 'careerPage.last_name' | translate }}" label-placement="floating"
						fill="outline" formControlName="lastName" maxlength="50" mode="md"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'lastName', 'formName': contactFormGroup} }">
					</ng-container>
				</div>
			</ion-col> -->


			<ion-col [sizeLg]="6" [sizeMd]="6" [sizeSm]="12" [sizeXs]="12">
				<div class="email">
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'EMAIL' |
                            translate
                            }}<sup class="pl-5 required-star">*</sup></ion-label>
                        <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Email' |
                            translate
                            }}<sup class="pl-5 required-star">*</sup></ion-label> -->
					<ion-input label="{{ 'EMAIL' | translate }}" label-placement="floating" fill="outline"
						formControlName="email" mode="md"></ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': contactFormGroup} }">
					</ng-container>
				</div>
			</ion-col>

			<ion-col [sizeLg]="6" [sizeMd]="6" [sizeSm]="12" [sizeXs]="12">
				<div class="phone">
					<!-- <ion-item class="mobile-phone" lines="none" mode="md">
                        <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'MOBILE_PHONE' |
                            translate }}<sup class="pl-5 required-star">*</sup></ion-label>
                        <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'mobile_phone'
                            |
                            translate }}<sup class="pl-5 required-star">*</sup></ion-label> -->
					<ion-input label="{{ 'MOBILE_PHONE' | translate }}" label-placement="floating" fill="outline"
						maxlength="9" inputmode="numeric" (ionFocus)="onMobilePhoneFocus()"
						formControlName="mobilePhone" (keypress)="phoneClick($event)"
						(ionBlur)="onMobilePhoneBlur($event)" mode="md">
						<ion-label slot="start" for="" *ngIf="countryCodeLabel" class="country-code mr-8">
							+966
						</ion-label>
					</ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'mobilePhone', 'formName': contactFormGroup} }">
					</ng-container>
				</div>
			</ion-col>

			<ion-col [sizeLg]="12" [sizeMd]="12" [sizeSm]="12" [sizeXs]="12">
				<div class="name"
					[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic' ]">
					<!-- <ion-item lines="none" mode="md" class="new-address-modal-dropdowns">
                        <ion-label position="floating" *ngIf="isRegionSelected">
                            {{'field.Region'| translate }}
                            <span class="required-text">*</span>
                        </ion-label>
                        <ion-label class="static-label mobile-float" *ngIf="!isRegionSelected">
                            {{'field.Region' |translate }}
                            <sup class="pl-5 required-star">*</sup></ion-label> -->

					<ion-select label="{{'field.Region'| translate }}" label-placement="floating" fill="outline"
						cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" interface="popover"
						formControlName="region" [interfaceOptions]="optionSelect" (ionChange)="getCityList()"
						mode="md">
						<ion-select-option *ngFor="let data of regionData"
							[value]="data.region_id">{{commonService.domDirectionLTR
							?
							data.region_en : data.region_ar}}</ion-select-option>
					</ion-select>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'region', 'formName': contactFormGroup} }">
					</ng-container>
				</div>
			</ion-col>

			<ion-col [sizeLg]="12" [sizeMd]="12" [sizeSm]="12" [sizeXs]="12">
				<div class="email">
					<!-- <ion-item lines="none" mode="md"> -->
					<!-- <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'MESSAGE' | translate
                            }}<sup class="pl-5 required-star">*</sup></ion-label>
                        <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'MESSAGE' | translate
                            }}<sup class="pl-5 required-star">*</sup></ion-label> -->
					<ion-textarea label="{{ 'MESSAGE' | translate }}" label-placement="floating" fill="outline"
						class="custom-textarea" formControlName="message" cols="6" rows="4" mode="md"></ion-textarea>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'message', 'formName': contactFormGroup} }">
					</ng-container>
				</div>

				<div class="footer-button-container">
					<div class="cancel mr-30" (click)="clearAndGoBack()">
						{{ 'Cancel' | translate }}
					</div>
					<div class="footer-button">
						<app-common-button color="#FFF" background="#0081CA" class="width-100"
							title=" {{ 'Submit' | translate }}" [isIcon]="false" [isHideInMobile]="false" type="submit"
							[specificWidth]="'135px'" [specificHeight]="'46px'" [specificFontSize]="'0.9375rem'"
							(buttonClicked)="contactFormSubmit()">
						</app-common-button>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
				{{validation.message | translate}}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>