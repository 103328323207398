import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CheckOutStep2PageRoutingModule } from './checkout-step2-routing.module';

import { SharedModule } from '../../common/shared.module';
import { CheckOutStep2Page } from './checkout-step2';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CheckOutStep2PageRoutingModule,
        SharedModule,
    ],
    declarations: [CheckOutStep2Page]
})
export class CheckOutStep2PageModule { }
