<ng-container *ngIf="!orderSummeryData">
	<div class="main-div">
		<div [ngClass]="fromList == true && getPlatformService.isDesktop? 'sub-div':null">
			<div class="display-div" *ngIf="orderSubTotal">
				<p class="label1 width-inherit color-app-grey">{{'orderSummaryPage.Itemssubtotal'|translate}}</p>
				<p class="textEnd label2 color-app-black">{{commonService.currencySymbol}}{{orderSubTotal ?
					(orderSubTotal
					| number) :'0'}}</p>
			</div>
			<div class="display-div" *ngIf="orderDiscount">
				<p class="label1 width-inherit color-app-grey">{{'orderSummaryPage.Discount'|translate}}</p>
				<p class="textEnd label2 color-app-orange">-{{commonService.currencySymbol}}{{orderDiscount ?
					(orderDiscount | number) :'0'}}</p>
			</div>
			<div class="display-div" *ngIf="fromList && discountCode">
				<p class="label1 width-inherit color-app-grey">{{'orderSummaryPage.Discount'|translate}}
					<span *ngIf="discountCode && orderDiscount && orderDiscount != '0'"
						class="font-bold">({{discountCode}})</span>
				</p>
				<p class="textEnd label2 color-app-orange">-{{commonService.currencySymbol}}{{orderDiscount ?
					(orderDiscount | number) :'0'}}</p>
			</div>
			<div class="display-div" *ngIf="!fromList && discountCode">
				<p class="label1 width-inherit color-app-grey">{{'orderSummaryPage.Discount'|translate}}
					<span *ngIf="discountCode && orderDiscount && orderDiscount != '0'"
						class="font-bold">({{discountCode}})</span>
				</p>
				<p class="textEnd label2 color-app-orange">-{{commonService.currencySymbol}}{{orderDiscount ?
					(orderDiscount | number) : 0}}</p>
			</div>
			<div class="display-div">
				<p class="label1  width-inherit color-app-grey">
					<span *ngIf="!isTotalSummary">
						{{'orderSummaryPage.VAT'|translate}}
					</span>
					<span *ngIf="isTotalSummary">
						{{'totalVat'|translate}}
					</span>
					<span *ngIf="vatPercent != 0 && !isTotalSummary">({{vatPercent ? vatPercent : 0 }}%)</span>
					<span class="tooltip" *ngIf="getPlatformService.isDesktop">
						<img src="/assets/icon/help.svg" class="help-icon" alt="Jazeera" />
						<span class="tooltiptext" *ngIf="!isTotalSummary">
							{{commonService.domDirectionLTR ? vatEnText1 : vatArText1}}
							<span>
								{{vatPercent ? vatPercent : 0}}
							</span>
							{{commonService.domDirectionLTR ? vatEnText2 : vatArText2}}
						</span>
						<span class="tooltiptext" *ngIf="isTotalSummary">
							{{'vatTotalEnText' | translate}}
						</span>
					</span>
				</p>
				<p class="textEnd label2 color-app-black">{{commonService.currencySymbol}}{{orderVAT|number}}</p>
			</div>
			<div class="display-div"
				*ngIf="(eliminatedRates && eliminated_shipping_charges_without_vat )|| orderShippingWithoutVat">
				<p class="label1 display-div width-inherit color-app-grey">
					{{'orderSummaryPage.ShippingwithoutVat'|translate}}
					<span class="tooltip" *ngIf="getPlatformService.isDesktop">
						<img src="/assets/icon/help.svg" class="help-icon" alt="Jazeera" />
						<span class="tooltiptext">
							{{commonService.domDirectionLTR ? shippingEnTextwithoutVAT : shippingArTextwithoutVAT}}
						</span>
					</span>
				</p>
				<div class="width-100">
					<p class="textEnd label2 color-app-black" [ngClass]="shippingEliminated ? 'line-through': ''">
						{{commonService.currencySymbol}}{{eliminatedRates &&
						eliminatedRates.eliminated_shipping_charges_without_vat ?
						(eliminatedRates.eliminated_shipping_charges_without_vat
						| number) :'0'}}</p>
					<p class="textEnd label2 color-app-black" *ngIf="shippingEliminated">
						{{commonService.currencySymbol}}{{orderShippingWithoutVat ? (orderShippingWithoutVat
						| number) :'0'}}</p>
				</div>
			</div>
			<div class="display-div" *ngIf="(eliminatedRates && eliminated_shipping_vat_charges )|| shippingVat">
				<p class="label1 display-div width-inherit color-app-grey" *ngIf="shippingEnVAT || shippingArVAT">
					{{'orderSummaryPage.ShippingVatCharges'|translate}}
					<span class="tooltip" *ngIf="getPlatformService.isDesktop">
						<img src="/assets/icon/help.svg" class="help-icon" alt="Jazeera" />
						<span class="tooltiptext">
							{{commonService.domDirectionLTR ? shippingEnVAT : shippingArVAT}}
						</span>
					</span>
				</p>
				<div class="width-100">
					<p class="textEnd label2 color-app-black" *ngIf="shippingEliminated"
						[ngClass]="shippingEliminated ? 'line-through': ''">
						{{commonService.currencySymbol}}{{eliminatedRates &&
						eliminatedRates.eliminated_shipping_vat_charges
						?
						(eliminatedRates.eliminated_shipping_vat_charges
						| number) :'0'}}</p>
					<p class="textEnd label2 color-app-black" *ngIf="shippingVat">
						{{commonService.currencySymbol}}{{shippingVat ? (shippingVat
						| number) :'0'}}</p>
				</div>
			</div>
			<div class="display-div" *ngIf="eliminatedRates && eliminatedRates.eliminated_shipping_charges">
				<p class="label1 display-div width-inherit color-app-grey">{{'orderSummaryPage.Shipping'|translate}}
					<span class="tooltip" *ngIf="getPlatformService.isDesktop">
						<img src="/assets/icon/help.svg" class="help-icon" alt="Jazeera" />
						<span class="tooltiptext">
							{{commonService.domDirectionLTR ? shippingEnText : shippingArText}}
						</span>
					</span>
				</p>
				<div class="width-100">
					<p class="textEnd label2 color-app-black" *ngIf="shippingEliminated"
						[ngClass]="shippingEliminated ? 'line-through': ''">
						{{commonService.currencySymbol}}{{eliminatedRates && eliminatedRates.eliminated_shipping_charges
						?
						(eliminatedRates.eliminated_shipping_charges
						| number) :'0'}}</p>
					<p class="textEnd label2 color-app-black">
						{{commonService.currencySymbol}}{{orderShipping ? (orderShipping
						| number) :'0'}}</p>
				</div>
			</div>
			<div class="divider-line mv-10" *ngIf="!fromList"></div>
			<div class="display-div text-left"
				*ngIf="!fromList && (orderTotal || eliminatedRates && eliminatedRates.eliminated_total_price)">
				<p class="label3 width-inherit color-app-grey">{{'orderSummaryPage.Total'|translate}}</p>
				<div class="width-100">
					<p class="textEnd label4 color-app-black" *ngIf="shippingEliminated"
						[ngClass]="shippingEliminated ? 'line-through': ''">
						{{commonService.currencySymbol}}{{eliminatedRates && eliminatedRates.eliminated_total_price ?
						(eliminatedRates.eliminated_total_price
						| number) :'0'}}</p>
					<p class="textEnd label4 color-app-black">
						{{commonService.currencySymbol}}{{orderTotal ? (orderTotal |
						number) :'0'}}</p>
				</div>
			</div>
			<div class="display-div mb-40" *ngIf="fromList == true">
				<p class="label1 width-inherit color-app-grey">{{'orderSummaryPage.Total'|translate}}
				</p>
				<div class="width-100">
					<p class="textEnd label4 color-app-black" *ngIf="shippingEliminated"
						[ngClass]="shippingEliminated ? 'line-through': ''">
						{{commonService.currencySymbol}}{{eliminatedRates && eliminatedRates.eliminated_total_price ?
						(eliminatedRates.eliminated_total_price
						| number) :'0'}}</p>
					<p class="textEnd label4 color-app-black">
						{{commonService.currencySymbol}}{{orderTotal ? (orderTotal |
						number) :'0'}}</p>
				</div>
			</div>
		</div>

	</div>
</ng-container>
<ng-container *ngIf="orderSummeryData && orderSummeryData.length">
	<div class="main-container pb-20" [style.--background]="background" [style.background]="background">
		<div *ngFor="let item of orderSummeryData" class="width-100"
			[ngClass]="fromList == true && getPlatformService.isDesktop? 'sub-div':null">

			<div class="horizontal-line" *ngIf="item.is_visible && item.key == 'total_price'"></div>
			<div class="display-div" *ngIf="item.is_visible && item.key != 'shipping_charges'">
				<p class="label1 width-inherit color-app-grey">
					{{ item.display_value }}
					<span class="tooltip" *ngIf="item.information && getPlatformService.isDesktop">
						<img src="/assets/icon/help.svg" class="help-icon" alt="Jazeera" />
						<span class="tooltiptext">
							{{ item.information }}
						</span>
					</span>
				</p>
				<div class="width-100">
					<p class="textEnd color-app-black"
						[ngClass]="[item.is_striked_out ? 'line-through': '', item.is_bold ? 'label4' : 'label2']"
						[ngStyle]="item.value_text_colour ? { 'color': item.value_text_colour } : {}">
						{{commonService.currencySymbol}}{{
						item.value ? (item.value
						| number) :'0'}}
					</p>
					<p *ngIf="item.is_striked_out" class="textEnd color-app-black"
						[ngClass]="item.is_bold ? 'label4' : 'label2'">{{commonService.currencySymbol}}{{
						item.value ? (item.value
						| number) :'0'}}</p>
				</div>
			</div>
		</div>
	</div>
</ng-container>