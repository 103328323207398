import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HomePageRoutingModule } from './home-routing.module';

import { RFQCartMobileComponent } from 'src/app/components/rfq-cart-mobile/rfq-cart-mobile.component';
import { SharedModule } from '../../common/shared.module';
import { HomePage } from './home.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HomePageRoutingModule,
        SharedModule
    ],
    declarations: [HomePage, RFQCartMobileComponent]
})
export class HomePageModule { }
