<div
	[ngClass]="[isHideInMobile ?'hide-btn-mobile ' : ' btn-container', isPrimaryBtn ? 'primary-btn' : isSecondryBtn ? 'secondary-btn' : 'tertiary-btn']">
	<ion-button fill="{{type}}" [style.--padding-start]="specificPaddingStart"
		[style.--padding-end]="specificPaddingEnd" [style.--background]="background"
		[style.--border-radius]="borderRadius" [style.height]="specificHeight" [class.full-width]="isFullWidth == true"
		[style.fontSize]="specificFontSize" [style.width]="specificWidth" [style.--border-width]="borderWidth"
		[style.min-width]="specificWidth" [class.box-shadow-none]="boxshadow == false" [style.margin]="margin"
		[style.--border-style]="borderStyle" [ngClass]="[notifyMeButton ? 'notify-button' : '']" [disabled]="disabled"
		[attr.disabled]="buttonDisabled" class="btn-css" [style.--border-color]="borderColor" (click)="firechange()"
		aria-label="action button">
		<div *ngIf="isButtonLoading">
			<ion-spinner name="circular"></ion-spinner>
		</div>
		<div *ngIf="isShippmentIcon">
			<ion-icon *ngIf="iconSrc" src="{{iconSrc}}" alt="Jazeera"></ion-icon>
			<i *ngIf="iconClass" class="{{iconClass}}"></i>
		</div>
		<div class="text-css base-medium" [ngClass]="isShippmentIcon ? 'shippment-icon' : ''" [style.color]="color"
			[style.fontSize]="specificFontSize" [style.fontWeight]="specificFontWeight">
			{{title}}
		</div>
		<div *ngIf="isIcon" class="button-icon pl-8">
			<ion-icon *ngIf="iconSrc" src="{{iconSrc}}" alt="Jazeera"></ion-icon>
			<i *ngIf="iconClass" class="{{iconClass}}"></i>
		</div>
	</ion-button>

</div>