<div *ngIf="getPlatformService.isDesktop">
	<div class="modal-close ion-text-right" (click)="closeModal()" *ngIf="getPlatformService.isDesktop">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
	</div>
	<div class="add-edit-address-container" [ngClass]="getPlatformService.isDesktop ? 'paddingHeader':'p-25'">
		<div class="add-edit-address-desktop-header" *ngIf="getPlatformService.isDesktop">
			{{ 'NewCard' | translate }}
		</div>
	</div>
</div>

<ion-header *ngIf="!getPlatformService.isDesktop" class="address-header" mode="md">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="closeModal()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title>
			{{'NewCard' | translate}}
		</ion-title>
	</ion-toolbar>
</ion-header>
<div class="heightInherit">
	<div class="add-edit-address-container" [ngClass]="getPlatformService.isDesktop ? 'p-24-30':'p-10-24'">
		<form [formGroup]="registerFromGroup">
			<div class="account-info">

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="name">
							<!-- <ion-item lines="none" mode="md">
                                <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
                                    alt="Jazeera">
                                <ion-label position="floating" *ngIf="getPlatformService.isDesktop"
                                    class="texUpperCase">{{
                                    'field.CardNumber'
                                    | translate }}
                                </ion-label> -->
							<ion-input label="{{ 'field.CardNumber' | translate }}" label-placement="floating"
								fill="outline" placeholder="{{ 'field.CardNumber' | translate }}"
								formControlName="cardNumber">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'cardNumber', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>

				</ion-row>

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="name">
							<!-- <ion-item lines="none" mode="md">
                                <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
                                    alt="Jazeera">
                                <ion-label position="floating" *ngIf="getPlatformService.isDesktop"
                                    class="texUpperCase">{{
                                    'field.Name' |
                                    translate }}
                                </ion-label> -->
							<ion-input label="{{ 'field.Name' | translate }}" label-placement="floating" fill="outline"
								placeholder="{{ 'field.Name' | translate }}" formControlName="name">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'name', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name">
							<!-- <ion-item lines="none" mode="md">
                                <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
                                    alt="Jazeera">
                                <ion-label position="floating" *ngIf="getPlatformService.isDesktop"
                                    class="texUpperCase">{{
                                    'field.ExpirationDate' |
                                    translate }}
                                </ion-label> -->
							<ion-input label="{{ 'field.ExpirationDate' | translate }}" label-placement="floating"
								fill="outline" placeholder="{{ 'field.ExpirationDate' | translate }}"
								formControlName="expiry_date">
							</ion-input>
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'expiry_date', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
						<div class="name">
							<!-- <ion-item lines="none" mode="md">
                                <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
                                    alt="Jazeera">
                                <ion-label position="floating" *ngIf="getPlatformService.isDesktop"
                                    class="texUpperCase">{{
                                    'field.CVV' |
                                    translate }}
                                </ion-label> -->
							<ion-input label="{{ 'field.CVV' | translate }}" label-placement="floating" fill="outline"
								placeholder="{{ 'field.CVV' | translate }}" formControlName="cvv">
							</ion-input>
							<img slot="end" src="/assets/icon/help.svg" class="help-icon" alt="Jazeera" />
							<!-- </ion-item> -->
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'cvv', 'formName': registerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>



			</div>

		</form>
	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
				{{validation.message}}
			</ion-text>
		</ng-container>
	</ng-template>
</div>

<div class="login-bottom-container" [ngClass]="getPlatformService.isDesktop ? 'ph-30':'ph-25'">
	<div class="cancel" (click)="closeModal()">
		{{ 'Cancel' | translate }}
	</div>
	<div class="login-button">
		<ion-button>
			{{'Save' | translate}}
		</ion-button>
	</div>
</div>