import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SharedModule } from 'src/app/common/shared.module';
import { ExploreContainerComponentModule } from 'src/app/explore-container/explore-container.module';
import { QuoteListMobilePageRoutingModule } from './quote-list-mobile-routing.module';
import { QuoteListMobilePage } from './quote-list-mobile.page';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ExploreContainerComponentModule,
        QuoteListMobilePageRoutingModule,
        SharedModule
    ],
    declarations: [QuoteListMobilePage]
})
export class QuoteListMobilePageModule { }
