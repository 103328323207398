export const newsData =
{
	id: 1,
	img: '/assets/images/news1.png',
	title: "Jazeera Paints Showcases the Latest Artificial Intelligence and Augmented Reality Technologies in the Paints Field at “LEAP24” Conference ",
	desc: `<div style="font-family: Arial, sans-serif; color: #333;">
    <p style="font-size: 18px;">
        <strong>Jazeera Paints</strong>, the leading company in the industry of paints, colors, and construction solutions,
        participates as a <strong>Platinum Sponsor</strong> in the third edition of LEAP technical conference for the year 2024,
        which was held for four days at <strong>Riyadh International Conventions and Exhibitions Center</strong> in Malham District;
        to showcase its latest innovative technologies in the world of paints and construction solutions and to enhance its presence
        and position in the market.
    </p>

    <div style="text-align: center;">
        <img src="https://jazeerapaints.com/media/wysiwyg/News_2_7_.jpg" alt="Jazeera Paints at LEAP 2024" style="width: 100%; height: auto;" />
    </div>

    <p style="font-size: 16px;">
        During its participation in LEAP 2024, Jazeera Paints highlighted the features it offers as the first company globally to use the latest
        artificial intelligence and augmented reality technologies in the paint field. This is to facilitate customers' lives, considering it as a
        fundamental value upon which it always relies and motivates towards providing innovative services and technologies that contribute to creating
        homes that reflect their owners' identity and enhance their quality of life.
    </p>

    <p style="font-size: 16px;">
        Therefore, the technologies presented included Virtual Reality technology, which took its customers on a journey to their virtual home
        and coloring it according to their preferences and tastes, Augmented Reality technology, which facilitates customers' experience in
        choosing paint colors that suit their spaces, and Artificial Intelligence technology, which provides solutions to address all wall
        problems with ease and without any hassle or effort, simply by using the <strong>Jazeera Paints Application</strong>.
    </p>

    <div style="text-align: center;">
        <img src="https://jazeerapaints.com/media/wysiwyg/News_3_5_.jpg" alt="Visitors interacting at the Jazeera Paints booth" style="width: 100%; height: auto;" />
    </div>

    <p style="font-size: 16px;">
        The <strong>"Jazeera Paints"</strong> stand was distinguished by its eye-catching colors and futuristic appearance, which earned it
        additional attractiveness and interest from visitors, and they interacted significantly with the innovative technologies presented,
        as they were able to experience these technologies and perceive their benefits themselves.
    </p>

    <p style="font-size: 16px;">
        This provided valuable opportunities for visitors to familiarize themselves with the latest technologies in the paint industry and
        the positive impact they have in improving services and better meeting customer needs.
    </p>

   <div style="background-color: #f0f0f0; padding: 10px; border-left: 5px solid #28a745;">
    <p style="font-size: 16px;">
        <em>“Jazeera Paints, the leading company in the industry of paints, colors, and construction solutions,
        participates as a Platinum Sponsor in the third edition of LEAP technical conference for the year 2024”</em>
    </p>
</div>

</div>
`,
	created_by: "Jazeera New",
	created_at: "17 Oct 2024"
}


export const latestNewsData = [
	{
		id: 2,
		img: '/assets/images/news2.png',
		title: "Jazeera Paints Holds a Ramadan Iftar ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 3,
		img: '/assets/images/news3.png',
		title: "Jazeera Paints Showcases the Latest Artificial Intelligence and...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX....",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 4,
		img: '/assets/images/news4.png',
		title: "Jazeera Paints Selects the Best Colors for Children’s Bedrooms ",
		desc: "Jazeera Paints the leading company in the industry of paints, colors...",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
	{
		id: 5,
		img: '/assets/images/news3.png',
		title: "Jazeera Paints Showcases the Latest Artificial Intelligence and...",
		desc: "'Jazeera Paints' is participating in the digital transformation at the GITEX....",
		created_by: "Jazeera New",
		created_at: "17 Oct 2024"
	},
]



