<ion-header mode="md" class="order-details-mobile-header">
	<ion-toolbar mode="md">
		<img class="img-position back-icon" src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start"
			(click)="goBack()">
		<ion-title mode="ios" class="ion-text-center page-title">
			<div class="order-details-header">{{'orderDetailsMobile.title' | translate}}</div>
		</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content>
	<app-loader *ngIf="isLoading"></app-loader>
	<ng-container *ngIf="!isLoading">
		<div class="main-order-detail-container">
			<ion-grid class="order-detail-grid p-0">
				<ion-row>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{'orderDetailsMobile.orderNo' | translate}}
						</div>
						<div class="main-order-value">
							#{{ orderData['order_no']}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"orderDetailsComponent.totalOrder" | translate}}
						</div>
						<div class="main-order-value">
							{{commonService.currencySymbol}} {{orderData['total_price'] ? (orderData['total_price'] |
							number) :'0'}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"orderDetailsComponent.date" | translate}}
						</div>
						<div class="main-order-value">
							{{orderData['order_date'] | date : 'MMM dd, YYYY'}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0" *ngIf="false">
						<div class="main-order-status">
							<ion-button fill="clear" class="order-status-btn"
								[ngClass]="orderStatus(orderData)">{{orderData.order_status
								== 'CANCELLED'
								? ('cancelled' | translate) :orderData.order_status == 'DISPATCHED' ?
								('dispatched' |
								translate) :orderData.order_status == 'DELIVERED' ? ('delivered' | translate)
								:('confirmed' |
								translate)}}
							</ion-button>
						</div>
					</ion-col>
					<ion-col size="12" class="p-0">
						<div class="header-label">
							{{"orderDetailsComponent.shipTo" | translate}}
						</div>
						<div class="main-order-value ship-address-value">
							{{orderData['shipping_address']}} {{orderData['city_en'] ? ',':''}} {{orderData['city_en']
							?
							orderData['city_en'] :''}}{{orderData['region_en'] ? ',':''}}
							{{orderData['region_en'] ? orderData['region_en'] :''}} {{orderData['country_en'] ?
							',':''}}
							{{orderData['country_en'] ?
							orderData['country_en'] :''}} {{orderData['zipcode'] ? ',':''}} {{orderData['zipcode'] ?
							orderData['zipcode'] :''}}
							<p class="m-0 p-0">
								{{orderData['mobile_no']}}
							</p>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</div>
		<ng-container *ngFor="let supplierDetail of supplierData">
			<div class="supplier-data-container">
				<div class="supplier-header">
					<div class="supplier-name">
						{{ commonService.domDirectionLTR ? supplierDetail.supplier_legal_name_en :
						supplierDetail.supplier_legal_name_ar}}
					</div>
					<div class="total-items">
						{{ supplierDetail.products && supplierDetail.products.length ? supplierDetail.products.length :
						0 }}
						{{supplierDetail.products && supplierDetail.products.length > 1 ? ('orderDetailsMobile.items' |
						translate)
						:('orderDetailsMobile.item' |
						translate) }}
					</div>
				</div>
				<div class="supplier-products">
					<div class="product-data" *ngFor="let product of supplierDetail.products">
						<div class="product-image" (click)="goToProductDetail(product)">
							<div class="sample-div" *ngIf="product.is_sample_product"
								[ngClass]="commonService.domDirectionLTR ? '' :'placement-right'">
								<p>{{'SAMPLE'|translate}}</p>
							</div>
							<img [src]="product.product_cover_image[0].image_url"
								*ngIf="product.product_cover_image && product.product_cover_image.length" alt="Jazeera">
							<img src="./assets/images/empty-img.png"
								*ngIf="!product.product_cover_image||(product.product_cover_image && !product.product_cover_image.length)"
								alt="Jazeera">
						</div>
						<div class="product-details">
							<div class="product-name" (click)="goToProductDetail(product)">
								{{ commonService.domDirectionLTR ? product.product_name_en :
								product.product_name_ar}}
							</div>
							<div class="product-status">
								<ion-button fill="clear" class="order-status-btn btn-green product-status-btn"
									*ngIf="product.shipping_status == 'DISPATCHED'">
									{{('dispatched' |translate)}}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-red product-status-btn"
									*ngIf="product.shipping_status == 'NOT_YET_DELIVERED'">
									{{('notYetDelivered'
									| translate) }}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-red product-status-btn"
									*ngIf="product.shipping_status == 'NOT_DELIVERED' || product.shipping_status == 'NOT DELIVERED'">
									{{('notYetDelivered'
									| translate) }}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-red product-status-btn"
									*ngIf="product.shipping_status == 'RETURNED'">{{('returned'
									| translate) }}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-red product-status-btn"
									*ngIf="product.shipping_status == 'CANCELLED'">{{('cancelled'
									| translate) }}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-green product-status-btn"
									*ngIf="product.shipping_status == 'DELIVERED'">{{('delivered'
									| translate) }}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-green product-status-btn"
									*ngIf="product.shipping_status == 'CONFIRMED'">{{('confirmed'
									| translate) }}
								</ion-button>

								<ion-button fill="clear"
									class="order-status-btn  btn-orange product-status-btn return-request-btn"
									*ngIf="product.shipping_status == 'CANCELLATION_REQUESTED'">{{"orderDetailsComponent.cancelRequested"
									| translate}}
								</ion-button>

								<ion-button fill="clear"
									class="order-status-btn  btn-orange product-status-btn return-request-btn"
									*ngIf="product.shipping_status == 'RETURN REQUESTED'">{{"orderDetailsComponent.returnRequested"
									| translate}}
								</ion-button>

								<ion-button fill="clear" class="order-status-btn btn-red click-btn"
									(click)="cancelProduct(product)" *ngIf="product.is_cancellation_allowed == true ">
									{{"orderDetailsComponent.cancelProduct" | translate}}
								</ion-button>



								<ion-button fill="clear" class="order-status-btn btn-orange return-request-btn"
									(click)="cancelProduct(product,true)" *ngIf="product.is_return_allowed == true">
									{{"orderDetailsComponent.returnProduct" | translate}}
								</ion-button>
							</div>
							<ng-container *ngIf="product.product_configuration && product.product_configuration.length">
								<div class="product-configuration">
									<div class="product-color" *ngFor="let confg of product.product_configuration">
										<span class="config-label">{{ commonService.domDirectionLTR ?
											confg.configuration_name_en : confg.configuration_name_ar}}:</span><span
											class="config-values">

											{{ commonService.domDirectionLTR ? confg.configuration_value_en :
											confg.configuration_value_ar}}
										</span>
									</div>
								</div>
							</ng-container>
							<div class="product-calculation">
								<div class="product-quantity">
									{{ product.total_quantity }} &times; {{commonService.currencySymbol}} {{
									product.is_discount_applied ? product.discounted_price_per_product ?
									(product.discounted_price_per_product | number) :'0':
									product.price_per_unit ? (product.price_per_unit | number) :'0' }}<span
										class="product-unit">/{{
										commonService.domDirectionLTR ? product.unit_name_en :
										product.unit_name_ar}}</span>
								</div>
								<div class="product-total-price">
									{{commonService.currencySymbol}} {{ product.total_price ? ( product.total_price |
									number) :'0' }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="shipment-btn-container" *ngIf="supplierDetail.tracking_url">
					<ion-button fill="clear" class="btn-dark-blue tracking-order-btn">
						<ion-icon src="./assets/icon/mobileTrackShipment.svg" class="mr-10" alt="Jazeera"></ion-icon>
						{{'orderDetailsComponent.trackShippment' |
						translate}}
					</ion-button>
				</div>
				<div class="product-divider">
					<div class="divider-line" [ngClass]="supplierDetail.tracking_url ? 'mv-10': 'mt-5 mb-10'"></div>
				</div>
				<div class="supplier-summary">
					<div class="summary-row">
						<div class="summary-label">
							{{"orderSummaryPage.Itemssubtotal" | translate}}
						</div>
						<div class="summary-value">
							{{commonService.currencySymbol}} {{ supplierDetail['sub_total_price'] &&
							supplierDetail['sub_total_price']['items_sub_total'] ?
							(supplierDetail['sub_total_price']['items_sub_total'] | number) :'-'}}
						</div>
					</div>
					<div class="summary-row">
						<div class="summary-label">
							{{"orderDetailsComponent.vat" | translate}} <span>
								({{ supplierDetail['sub_total_price'] &&
								supplierDetail['sub_total_price']['vat_percentage'] ?
								supplierDetail['sub_total_price']['vat_percentage']: '0' }}%)
							</span>
							<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
						</div>
						<div class="summary-value">
							{{commonService.currencySymbol}} {{ supplierDetail['sub_total_price'] &&
							supplierDetail['sub_total_price']['vat'] ? (supplierDetail['sub_total_price']['vat'] |
							number) : '0'}}
						</div>
					</div>
					<div class="summary-row">
						<div class="summary-label">
							{{"orderSummaryPage.ShippingwithoutVat" | translate}}
							<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
						</div>
						<div class="summary-value">
							{{commonService.currencySymbol}} {{ supplierDetail['sub_total_price'] &&
							supplierDetail['sub_total_price']['shipping_charges_without_vat'] ?
							(supplierDetail['sub_total_price']['shipping_charges_without_vat'] | number) :'0'}}
						</div>
					</div>
					<div class="summary-row">
						<div class="summary-label">
							{{"orderSummaryPage.ShippingVatCharges" | translate}}
							<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
						</div>
						<div class="summary-value">
							{{commonService.currencySymbol}} {{ supplierDetail['sub_total_price'] &&
							supplierDetail['sub_total_price']['shipping_vat_charges'] ?
							(supplierDetail['sub_total_price']['shipping_vat_charges'] | number) :'0'}}
						</div>
					</div>
					<div class="summary-row">
						<div class="summary-label">
							{{"orderSummaryPage.Shipping" | translate}}
							<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
						</div>
						<div class="summary-value">
							{{commonService.currencySymbol}} {{ supplierDetail['sub_total_price'] &&
							supplierDetail['sub_total_price']['shipping_charges'] ?
							(supplierDetail['sub_total_price']['shipping_charges'] | number) :'0'}}
						</div>
					</div>
					<div class="summary-row">
						<div class="summary-label">
							{{"orderSummaryPage.Total" | translate}}
						</div>
						<div class="summary-value supplier-total">
							{{commonService.currencySymbol}} {{ supplierDetail['sub_total_price'] &&
							supplierDetail['sub_total_price']['total_price'] ?
							(supplierDetail['sub_total_price']['total_price'] |
							number) :'0' }}
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="divider-line mv-10"></div>
		<div class="mobile-order-summary">
			<div class="mobile-order-summary-header">
				{{"orderDetailsComponent.orderSummery" | translate}}
			</div>
			<div class="mobile-order-summary-row">
				<div class="order-summary-label">
					{{"orderSummaryPage.Itemssubtotal" | translate}}
				</div>
				<div class="order-summary-value order-subtotal">
					{{commonService.currencySymbol}} {{ orderData['sub_total'] | number}}
				</div>
			</div>
			<div class="mobile-order-summary-row">
				<div class="order-summary-label">
					{{"orderDetailsMobile.discountAmount" | translate}} <span class="coupon-code"
						*ngIf="orderData['discount_code']">
						({{ orderData['discount_code'] }})
					</span>
					<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
				</div>
				<div class="order-summary-value">
					{{commonService.currencySymbol}} {{ orderData['discount_price'] ?(orderData['discount_price'] |
					number):'0'}}
				</div>
			</div>
			<div class="mobile-order-summary-row">
				<div class="order-summary-label">
					{{'totalVat'|translate}} <span *ngIf="false">
						({{ orderData['vat_percent'] }}%)
					</span>
					<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
				</div>
				<div class="order-summary-value">
					{{commonService.currencySymbol}} {{ orderData['vat'] }}
				</div>
			</div>
			<div class="mobile-order-summary-row">
				<div class="order-summary-label">
					{{"orderSummaryPage.ShippingwithoutVat" | translate}}
					<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
				</div>
				<div class="order-summary-value">
					{{commonService.currencySymbol}} {{ orderData['shipping_charges_without_vat'] | number }}
				</div>
			</div>
			<div class="mobile-order-summary-row">
				<div class="order-summary-label">
					{{"orderSummaryPage.ShippingVatCharges" | translate}}
					<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
				</div>
				<div class="order-summary-value">
					{{commonService.currencySymbol}} {{ orderData['shipping_vat_charges'] | number }}
				</div>
			</div>
			<div class="mobile-order-summary-row">
				<div class="order-summary-label">
					{{"orderSummaryPage.Shipping" | translate}}
					<img src="assets/icon/more_info.svg" class="question-icon ml-5" alt="Jazeera">
				</div>
				<div class="order-summary-value">
					{{commonService.currencySymbol}} {{ orderData['shipping_charges'] | number }}
				</div>
			</div>
			<div class="divider-line mv-10"></div>
			<div class="mobile-order-summary-row order-total-row">
				<div class="order-summary-label order-total-label">
					{{"orderSummaryPage.Total" | translate}}
				</div>
				<div class="order-summary-value order-total-value">
					{{commonService.currencySymbol}} {{ orderData['total_price'] | number }}
				</div>
			</div>
		</div>
		<div class="action-btns">
			<ion-button fill="clear" class=" reorder-btn" (click)="reorder()" [disabled]="isReordering">
				<ion-icon src="./assets/icon/reorder-icon.svg" class="mr-10" alt="Jazeera"></ion-icon>
				{{"orderDetailsComponent.reorder" | translate}}
			</ion-button>
			<!-- <ion-button fill="clear" class="cancel-btn">
        {{'orderDetailsMobile.cancelOrder' |
        translate}}
      </ion-button> -->
		</div>
	</ng-container>
</ion-content>