import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { InvoicesComponent } from './invoices.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: InvoicesComponent,
	},
	{
		path: ":id",
		component: OrderDetailsComponent,
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class InvoicesRoutingModule { }
