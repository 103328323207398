<ng-container *ngIf="!getPlatformService.isDesktop && !isLoading">
	<ion-header class="address-header" mode="md">
		<ion-toolbar mode="md">
			<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
				[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
			<ion-title mode="ios">
				<div class="setting-header">
					{{'settingComponent.settings'| translate}}
				</div>
			</ion-title>
		</ion-toolbar>
	</ion-header>

	<ion-content>
		<ng-container *ngIf="isLoggedIn">
			<ng-container *ngFor="let data of componentData;let i = index">
				<div class="setting-component">
					<div class="section-heading">
						<div class="section-name">{{data.section_name | translate}}</div>
						<div class="section-button-containter" *ngIf="data.section_btn">
							<span class="section-btn">
								{{data.section_btn | translate}}
							</span>
						</div>
					</div>
					<div class="section-content">
						<ng-container *ngIf="data.id == '0' && !isLoading">
							<div class="common-input mb-10" *ngIf="notificationSetting.length > 0">
								<div class="dis-flex-notification " *ngFor="let item of notificationSetting">
									<div class="width-100 label-small mr-5">
										{{commonService.domDirectionLTR ? item.title :
										item.title_ar}}
									</div>
									<div class="select-input ion-text-center">
										<span (click)="presentAlert(item)" class="toggle-container">
											<ion-toggle [(ngModel)]="item.is_active" mode="ios"></ion-toggle>
										</span>
									</div>
								</div>
							</div>
							<div *ngIf="notificationSetting.length < 1 "
								class="color-app-black ion-text-center no-data ">
								{{'noRecordFound'| translate}}
							</div>
						</ng-container>


						<ng-container *ngIf="data.id == '1'">
							<div class="common-input mb-10">
								<ion-item lines="none" mode="md">
									<img slot="start" src="{{this.selectedLanguage.img}}" alt="Jazeera" height="16px"
										class="pl-10">
									<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
										placeholder="{{'selectOne'|translate}}"
										[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'"
										[interfaceOptions]="{ 'header': ('Select Language' | translate)}"
										[(ngModel)]="selectedLanguage" class="pt-0 pb-0 width-100 ml-10"
										(ionChange)="languageChanged($event)">
										<ion-select-option *ngFor="let lang of languageArray" [value]="lang"><span
												class='flag-icon flag-icon-{{lang.title}}'></span>{{lang.title
											| translate}}
										</ion-select-option>
									</ion-select>
								</ion-item>

								<p class="label-small mt-5">{{"Select Language"|translate}}</p>
							</div>
						</ng-container>

						<ng-container *ngIf="data.id == '2'">
							<div class="common-input mb-10">
								<ion-item lines="none" mode="md">
									<img slot="start" src="assets/icon/map.svg" class="pl-10" alt="Jazeera">
									<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
										[ngClass]="[ isLocationSelected ? '' : 'location-select']"
										class="pt-0 pb-0 width-100 ml-10"
										[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'"
										placeholder="{{'LOCATION' | translate }}"
										[interfaceOptions]="{ 'header': ('selectLocation' | translate)}"
										[(ngModel)]="selectedLocation" (ionChange)="locationChanged($event)">
										<ion-select-option *ngFor="let data of locationData" [value]="data.city_id">
											{{ commonService.domDirectionLTR ? data.city_en
											:data.city_ar}}</ion-select-option>
									</ion-select>

								</ion-item>

								<p class="label-small mt-5">{{"selectLocation" | translate}}</p>
							</div>
						</ng-container>

						<ng-container *ngIf="data.id == '3'">
							<div class="dis-flex mb-10">
								<img slot="start" src="assets/icon/security.svg" alt="Jazeera">
								<div class="ml-12 width-100">
									<div class="m-0 section-name2 ">{{'settingComponent.2FAauthentication'| translate}}
									</div>
									<p class="label-small mt-5">{{"2FAAccountDescription" | translate}}</p>
								</div>
								<div class="select-input ion-text-center">
									<span (click)="presentAlert(commonService.twoFaEnabled,'2fa')"
										class="toggle-container">
										<ion-toggle [(ngModel)]="commonService.twoFaEnabled" mode="ios"></ion-toggle>
									</span>
								</div>

							</div>
						</ng-container>
					</div>
				</div>
				<div class="horizontal-line" *ngIf="componentData.length != i+1 "></div>

			</ng-container>
		</ng-container>
		<ng-container *ngIf="!isLoggedIn">
			<div class="setting-component">
				<div class="common-input mb-10">
					<ion-item lines="none" mode="md">
						<img slot="start" src="{{this.selectedLanguage.img}}" alt="Jazeera" height="16px" class="pl-10">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							placeholder="{{'selectOne'|translate}}"
							[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'"
							[interfaceOptions]="{ 'header': ('Select Language' | translate)}"
							[(ngModel)]="selectedLanguage" class="pt-0 pb-0 width-100 ml-10"
							(ionChange)="languageChanged($event)">
							<ion-select-option *ngFor="let lang of languageArray" [value]="lang"><span
									class='flag-icon flag-icon-{{lang.title}}'></span>{{lang.title
								| translate}}
							</ion-select-option>
						</ion-select>
					</ion-item>

					<p class="label-small mt-5">{{"Select Language"|translate}}</p>
				</div>
			</div>
		</ng-container>
	</ion-content>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>

<ng-container *ngIf="getPlatformService.isDesktop && !isLoading">
	<div class="main-title">
		<h2>{{'settingComponent.settings'| translate}}</h2>
	</div>
	<ng-container *ngFor="let data of componentData;let i = index">
		<div class="setting-component">
			<div class="section-heading">
				<div class="section-name">{{data.section_name | translate}}</div>
				<div class="section-button-containter" *ngIf="data.section_btn">
					<span class="section-btn">
						{{data.section_btn | translate}}
					</span>
				</div>
			</div>
			<div class="section-content">
				<ng-container *ngIf="data.id == '0' && !isLoading">
					<div class="common-input mb-10" *ngIf="notificationSetting.length > 0">
						<div class="dis-flex-notification " *ngFor="let item of notificationSetting">
							<div class="width-100 label-small">
								{{commonService.domDirectionLTR ? item.title :
								item.title_ar}}
							</div>
							<div class="select-input ion-text-center">
								<span (click)="presentAlert(item)" class="toggle-container">
									<ion-toggle [(ngModel)]="item.is_active" mode="ios"></ion-toggle>
								</span>
							</div>

						</div>
					</div>

					<div *ngIf="noData" class="color-app-black ion-text-center no-data ">
						{{'noRecordFound'| translate}}
					</div>
				</ng-container>


				<ng-container *ngIf="data.id == '1'">
					<div class="common-input mb-10 language-location">
						<ion-item lines="none" mode="md">
							<img slot="start" src="{{this.selectedLanguage.img}}" alt="Jazeera" height="16px"
								class="web-icon pl-10">
							<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								placeholder="{{'selectOne'|translate}}"
								[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'"
								[interfaceOptions]="locationSelect" [(ngModel)]="selectedLanguage"
								class="pt-0 pb-0 width-100 height-100 pl-45 ml-10"
								(ionChange)="languageChanged($event)">
								<ion-select-option *ngFor="let lang of languageArray" [value]="lang"><span
										class='flag-icon flag-icon-{{lang.title}}'></span>{{lang.title
									| translate}}
								</ion-select-option>
							</ion-select>
						</ion-item>

						<p class="label-small mt-5">{{"Select Language"|translate}}</p>
					</div>
				</ng-container>

				<ng-container *ngIf="data.id == '2'">
					<div class="common-input mb-10 language-location">
						<ion-item lines="none" mode="md" *ngIf="locationData.length">
							<img slot="start" src="assets/icon/map.svg" class="web-icon pl-10" alt="Jazeera">
							<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								[ngClass]="[ isLocationSelected ? '' : 'location-select']"
								class="pt-0 pb-0 width-100 height-100 pl-45 ml-10"
								[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'"
								placeholder="{{'LOCATION' | translate }}" [interfaceOptions]="locationSelect"
								[(ngModel)]="selectedLocation" (ionChange)="locationChanged($event)">
								<ion-select-option *ngFor="let data of locationData"
									[value]="data.city_id">{{commonService.domDirectionLTR ? data.city_en :
									data.city_ar }}</ion-select-option>
							</ion-select>

						</ion-item>

						<p class="label-small mt-5">{{"selectLocation" | translate}}</p>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="horizontal-line" *ngIf="componentData.length != i+1 "></div>

	</ng-container>

</ng-container>