// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiUrl: 'https://jazeeraapi.dev2.brainvire.net', //dev
	webUrl: "https://jazeeracustomer.dev2.brainvire.net",
	rsa_public_key: "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe",
	supplierUrl: "https://jazeerasupplier.dev2.brainvire.net/login",
	appversion: "5.0",
	// apiUrl: 'https://api.stage.juman-bm.com', //staging
	// rsa_public_key: "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe", //staging
	// supplierUrl: 'https://supplier.stage.juman-bm.com/login', //staging
	firebaseConfig: {
		apiKey: "AIzaSyC10ShEcxI1Q-BVpwYbSt6LS6rwp-8J0nA",
		authDomain: "juman-marketplace-android.firebaseapp.com",
		projectId: "juman-marketplace-android",
		storageBucket: "juman-marketplace-android.appspot.com",
		messagingSenderId: "892620674733",
		appId: "1:892620674733:web:52d46ece5436734a3c1824",
		measurementId: "G-WT7WVZQ0Y3",
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
