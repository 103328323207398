// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	SENTRY_AUTH_TOKEN: "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",
	sentryAuthToken: "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",
	apiUrl: 'https://jazeeraapi.dev2.brainvire.net', //dev
	webUrl: "https://jazeeracustomer.dev2.brainvire.net",
	rsa_public_key: "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe",
	supplierUrl: "https://jazeerasupplier.dev2.brainvire.net/login",
	appversion: "5.0",
	firebaseConfig: {
		apiKey: "AIzaSyBZ0CIB_vq6210kgn9g8VHXIDr-tYYOY54",
		authDomain: "jazeera-a3bd6.firebaseapp.com",
		projectId: "jazeera-a3bd6",
		storageBucket: "jazeera-a3bd6.firebasestorage.app",
		messagingSenderId: "1069352888615",
		appId: "1:1069352888615:web:efcd94fa08a07f6d462102",
		measurementId: "G-64GTDV87S7"
	},
};

export const SENTRY_AUTH_TOKEN = "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";


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
