import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isSupported } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { URLS } from '../_config/api.config';
import { CustomFirebaseAnalyticsProvider } from './CustomFirebaseAnalyticsProvider';
import { ApiService } from './api-data.service';
import { CommonService } from './common.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class QuoteService {
    quoteList = [];
    quoteCount = 0;
    quoteProductIds = [];
    shipping_address: any;
    billing_address: any;
    public isloggedIn: boolean = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private dataService: ApiService,
        public commonService: CommonService,
        private toastService: ToastService,
        private translate: TranslateService,
        private alertController: AlertController,
        public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
    ) { }

    addIntoQuote(product_id, quantity, hideNotification?) {
        const params = new FormData();
        const paramsData: any = [{
            "product_id": +product_id,
            "product_quantity": parseInt(quantity),
        }]

        params.append("quotation_items", JSON.stringify(paramsData))
        if (this.commonService.localStorageGet('shipping_address_id')) {
            params.append("address_id", this.commonService.localStorageGet('shipping_address_id'));
        }
        else {
            this.toastService.displayToast(this.commonService.domDirectionLTR ?
                "Please add or select a shipping address" :
                "الرجاء إضافة أو تحديد عنوان الشحن",
                null, 'danger');
            return;
        }

        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.post(URLS.quoteAdd, params).subscribe(data => {
                    if (data && data['code'] == 200) {
                        isSupported().then(() => {
                            this.customFirebaseAnalytics.trackEvent('Add to Quote', +data.product_id);  //Google Analytics Log In Event
                        })
                        resolve(data);
                    } else {
                        if (!hideNotification) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                        reject()
                    }
                }, error => {
                    if (!hideNotification) {
                        this.toastService.displayToast(error['message'], null, 'danger');
                    }
                    reject(error);
                })
            )
        });
    }
    addIntoQuoteBulk(productArray, hideNotification?) {
        const params = new FormData();
        const paramsData: any = [];

        productArray.forEach(element => {
            paramsData.push({
                "product_id": +element.product_id,
                "product_quantity": parseInt(element.quantity),
            })
        });
        params.append("quotation_items", JSON.stringify(paramsData))
        if (this.commonService.localStorageGet('shipping_address_id')) {
            params.append("address_id", this.commonService.localStorageGet('shipping_address_id'));
        }
        else {
            this.toastService.displayToast(this.commonService.domDirectionLTR ?
                "Please add or select a shipping address" :
                "الرجاء إضافة أو تحديد عنوان الشحن",
                null, 'danger');
            return;
        }

        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.post(URLS.quoteAdd, params).subscribe(data => {
                    if (data && data['code'] == 200) {
                        isSupported().then(() => {
                            this.customFirebaseAnalytics.trackEvent('Add to Quote', +data.product_id);  //Google Analytics Log In Event
                        })
                        resolve(data);
                    } else {
                        if (!hideNotification) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                        reject()
                    }
                }, error => {
                    if (!hideNotification) {
                        this.toastService.displayToast(error['message'], null, 'danger');
                    }
                    reject(error);
                })
            )
        });
    }

    updateQuote(quote_id, dataItem?, addressId?, quoteDelete?, invoiceId?,isFullfilled?) {
        return new Promise((resolve, reject) => {
            this.getQuote(quote_id).then(data => {
                const params = {};
                const paramsData: any = []
                if (dataItem && dataItem.length) {
                    dataItem.forEach(element => {
                        paramsData.push({ "product_id": +element.product_id, "product_quantity": parseInt(element.total_quantity) })
                    });
                }
                else {
                    this.quoteList.forEach(element => {
                        paramsData.push({ "product_id": +element.product_id, "product_quantity": parseInt(element.total_quantity) })
                    });
                }


                params['quotation_request_id'] = +quote_id;
                if (quoteDelete) {
                    params["quotation_items"] = [];
                    params["is_deleted"] = "true";
                }
                else if (!invoiceId) {
                    params["quotation_items"] = paramsData;
                }


                if (addressId) {
                    params["address_id"] = addressId;
                }
                if (invoiceId) {
                    params["invoice_id"] = invoiceId;
                }
                if(isFullfilled){
                    params["is_fulfilled"] = isFullfilled;
                }

                this.subscription.add(
                    this.dataService.put(URLS.quoteUpdate, params).subscribe(data => {
                        if (data && data['code'] == 200) {
                            resolve(data)
                        } else {
                            this.toastService.displayToast(data['message'], null, 'danger');
                            reject()
                        }
                    }, error => {
                        this.toastService.displayToast(error['message'], null, 'danger');
                        reject(error);
                    })
                )
            })
                .catch((error) => { });
        });

    }

    async deleteQuote(index) {
        if (this.quoteList.length == 1) {
            const alert = await this.alertController.create({
                header: this.translate.instant("myQuoteComponent.deleteQuote"),
                message: this.translate.instant("myQuoteComponent.deleteQuoteDesc"),
                buttons: [
                    {
                        text: this.translate.instant("no"),
                        role: "cancel",
                        handler: () => { },
                    },
                    {
                        text: this.translate.instant("yes"),
                        role: "confirm",
                        handler: () => {
                            return new Promise((resolve, reject) => {
                                this.quoteList.splice(index, 1);
                                resolve(this.quoteList);
                            });
                        },
                    },
                ],
            });

            await alert.present();

            const { role } = await alert.onDidDismiss();
        }
        else {
            return new Promise((resolve, reject) => {
                this.quoteList.splice(index, 1);
                resolve(this.quoteList);
            });
        }

    }


    getQuote(quote_id) {
        const token = this.commonService.localStorageGet('accessToken');
        if (!token) {
            return;
        }

        return new Promise((resolve, reject) => {
            let params = {
                quotation_request_id: quote_id,
            }

            if (this.commonService.localStorageGet('shipping_address_id')) {
                params['address_id'] = this.commonService.localStorageGet('shipping_address_id')
            }
            else {
                this.toastService.displayToast(this.commonService.domDirectionLTR ?
                    "Please add or select a shipping address" :
                    "الرجاء إضافة أو تحديد عنوان الشحن",
                    null, 'danger');
                return;
            }

            this.subscription.add(
                this.dataService.get(URLS.quoteDetails, params).subscribe(data => {
                    if (data && data['code'] == 200) {
                        let quoteData;
                        this.quoteList = [];
                        this.quoteProductIds = [];
                        quoteData = data['data'];

                        quoteData.result.forEach(element => {
                            element.products.forEach(element2 => {
                                this.quoteList.push(element2);
                                this.quoteProductIds.push(element2.product_id);
                            });
                        });
                        this.quoteCount = this.quoteList.length;
                        resolve(quoteData);
                    } else {
                        reject();
                    }
                }, error => {
                    reject(error);
                })
            )
        })
    }

}
