import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { CustomValidator } from '../../../validators/customvalidators';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-career',
    templateUrl: './career.page.html',
    styleUrls: ['./career.page.scss'],
})
export class CareerPage implements OnInit {


    public isJobTypeSelected = false;
    public isJobRoleSelected = false;
    public isQualificationSelected = false;
    public countryCodeLabel = false;
    public isindentityProof = false;
    public indentityProof: any;
    public message: any;
    public fileToUpload: any;
    public files: any;
    public jobSelect = {
        size: 'cover',
        side: 'bottom',
        alignment: 'center',
        cssClass: 'location-popover'
    }
    careerFromGroup: FormGroup

    public validation_messages = {
        jobType: [
            { type: 'required', message: 'careerPage.jobTypeRequired' },
        ],
        jobRole: [
            { type: 'required', message: 'careerPage.jobRoleRequired' },
        ],
        firstName: [
            { type: 'required', message: 'careerPage.firstNameRequired' },
        ],
        lastName: [
            { type: 'required', message: 'careerPage.lastNameRequired' },
        ],
        email: [
            { type: 'required', message: 'loginComponent.emailRequired' },
            { type: 'invalidEmail', message: 'loginComponent.emailInvalid' }
        ],
        mobilePhone: [
            { type: 'required', message: 'careerPage.mobilePhoneRequired' },
        ],
        city: [
            { type: 'required', message: 'careerPage.cityRequired' },
        ],
        region: [
            { type: 'required', message: 'careerPage.regionRequired' },
        ],
        country: [
            { type: 'required', message: 'careerPage.countryRequired' },
        ],
        qualification: [
            { type: 'required', message: 'careerPage.qualificationRequired' },
        ],
        indentityProof: [
            { type: 'required', message: 'careerPage.identityProofRequired' },
        ]
    }

    constructor(
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        private toastService: ToastService,
        private formBuilder: FormBuilder,
        public navCtrl: NavController,
        public translate: TranslateService,
    ) { }

    ngOnInit() {
        this.careerFromGroup = this.initializecareerFromGroup();
    }

    get f1() { return this.careerFromGroup.controls; }

    jobTypeSelected(event) {
        if (event.target.value) {
            this.isJobTypeSelected = true;
        }
    }

    jobRoleSelected(event) {
        if (event.target.value) {
            this.isJobRoleSelected = true;
        }
    }

    qualificationSelected(event) {
        if (event.target.value) {
            this.isQualificationSelected = true;
        }
    }

    onMobilePhoneFocus() {
        this.countryCodeLabel = true;
    }

    onMobilePhoneBlur(event) {
        if (!event.target.value) {
            this.countryCodeLabel = false;
        } else {
            this.countryCodeLabel = true;
        }
    }

    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    upload(files) {
        files.preventDefault();
        files.stopPropagation();

        this.files = files['target']['files'][0].name;
        if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {
            const max_size = 2097152;
            if (files['target']['files'][0].size > max_size) {
                this.message =
                    'Maximum size allowed is ' + max_size / 1048576 + 'Mb';
                this.files = null;
                this.fileToUpload = null;
                files.target.value = null;
                this.toastService.displayToast(this.message, null, 'warning');
                return false;
            }

            if ((files['target']['files'][0].name.toLowerCase()).includes(".pdf")) {
                this.message = null;
                this.indentityProof = files['target']['files'][0].name;
                this.isindentityProof = false;
            }
            else {
                this.message = 'Only PDF files are allowed';
                this.fileToUpload = null
                this.files = null;
                files.target.value = null;
                this.toastService.displayToast(this.message, null, 'warning');
                return false;
            }
        }
        files.target.value = '';

    }

    remove(event) {
        event.preventDefault();
        event.stopPropagation();
        this.indentityProof = null;
    }

    careerSubmit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        this.isindentityProof = true;
        if (this.indentityProof) this.isindentityProof = false;
    }

    initializecareerFromGroup() {
        return this.formBuilder.group({
            jobType: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            jobRole: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            firstName: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            lastName: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            email: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
            }),
            mobilePhone: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            city: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            region: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            country: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            qualification: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            indentityProof: new FormControl(null, {
                validators: Validators.compose([]),
            }),
        })
    }

    goToHome() {
        this.navCtrl.navigateRoot('/'+ this.translate.currentLang+"/home", { animated: false, replaceUrl: true })
    }


}
