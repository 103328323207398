<ion-content>

	<div class="container">
		<div class="div-bg common-input">
			<img router-direction="none" src="./assets/icon/companyLogo.svg" height="100%" width="100%"
				alt="company-logo" class="logo-img  mb-25">

			<form [formGroup]="bankTransferForm" (ngSubmit)="submitPayment()" class="width-100">

				<!-- Bank Name -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Name (As shown in Bank Account)</ion-label>
						<ion-input formControlName="bankName" type="text" required placeholder="Enter Name"></ion-input>
					</ion-item>
					<ion-text color="danger"
						*ngIf="bankTransferForm.get('bankName').touched && bankTransferForm.get('bankName').invalid">
						<small *ngIf="bankTransferForm.get('bankName').hasError('required')">Bank Name is
							required.</small>
					</ion-text>
				</div>

				<!-- Card Number Input -->
				<div class="mb-15">
					<ion-item lines="none" mode="md" class="width-100">
						<ion-select label-placement="stacked" label="Select Bank" formControlName="bank"
							placeholder="Select a bank" interface="popover" class="width-100" mode="md" fill=""
							id="myselect" #myselect>
							<ion-select-option *ngFor="let bank of banks" [value]="bank.id">{{
								bank.name}}</ion-select-option>
						</ion-select>
					</ion-item>
					<ion-text color="danger"
						*ngIf="bankTransferForm.get('bank').hasError('required') && bankTransferForm.get('bank').touched">
						Bank selection is required.
					</ion-text>
				</div>

				<!-- Account Number -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Account Number</ion-label>
						<ion-input formControlName="accountNumber" type="text" required
							placeholder="Enter account number"></ion-input>
					</ion-item>
					<ion-text color="danger"
						*ngIf="bankTransferForm.get('accountNumber').touched && bankTransferForm.get('accountNumber').invalid">
						<small *ngIf="bankTransferForm.get('accountNumber').hasError('required')">Account Number is
							required.</small>
						<small *ngIf="bankTransferForm.get('accountNumber').hasError('pattern')">Account Number must be
							a 10-digit number.</small>
					</ion-text>
				</div>

				<!-- IBAN -->
				<div class="mb-15">

					<ion-item lines="none" mode="md">
						<ion-label position="stacked">IBAN</ion-label>
						<ion-input formControlName="iban" type="text" required
							placeholder="Enter IBAN number"></ion-input>
					</ion-item>
					<ion-text color="danger"
						*ngIf="bankTransferForm.get('iban').touched && bankTransferForm.get('iban').invalid">
						<small *ngIf="bankTransferForm.get('iban').hasError('required')">IBAN is required.</small>
						<small *ngIf="bankTransferForm.get('iban').hasError('pattern')">IBAN format is
							incorrect.</small>
					</ion-text>
				</div>

				<!-- Receipt Number -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Receipt Number</ion-label>
						<ion-input formControlName="receiptNo" type="text"
							placeholder="Enter receipt number"></ion-input>
					</ion-item>
					<ion-text color="danger"
						*ngIf="bankTransferForm.get('receiptNo').hasError('required') && bankTransferForm.get('receiptNo').touched">
						Receipt number is required.
					</ion-text>
					<ion-text color="danger"
						*ngIf="bankTransferForm.get('receiptNo').hasError('pattern') && bankTransferForm.get('receiptNo').touched">
						Receipt number must be alphanumeric.
					</ion-text>
				</div>

				<!-- File Upload -->
				<div class="mb-15">
					<ion-item lines="none" mode="md" class="input-height">
						<div class="file-upload">
							<ion-label position="stacked" class="upload-label">Upload Bank Acknowledgment (JPG, PNG,
								PDF)</ion-label>
							<input type="file" (change)="onFileChange($event)" accept=".jpg, .png, .pdf"
								class="upload-btn" />
						</div>
					</ion-item>
					<ion-text
						*ngIf="bankTransferForm.get('acknowledgmentFile').touched && bankTransferForm.get('acknowledgmentFile').invalid"
						color="danger">
						<small *ngIf="bankTransferForm.get('acknowledgmentFile').hasError('required')">File upload is
							required.</small>
					</ion-text>
				</div>

				<!-- Notes -->
				<div class="mb-15">
					<ion-item lines="none" mode="md">
						<ion-label position="stacked">Notes</ion-label>
						<ion-input formControlName="notes" type="text"
							placeholder="Enter any notes (optional)"></ion-input>
					</ion-item>
				</div>

				<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
					title="{{'Submit Payment' | translate}}" [isIcon]="false" [isHideInMobile]="false"
					(click)="submitPayment()" [isFullWidth]="true" [disabled]="bankTransferForm.invalid"
					[specificFontSize]="'1rem'">
				</app-common-button>

			</form>
		</div>
	</div>

</ion-content>