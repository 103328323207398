import { DOCUMENT, isPlatformServer } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.page.html',
	styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {
	faqsData: any = [];
	fromPage: any;
	isLoading: boolean = false;
	noData: boolean = false;
	public subscription: Subscription = new Subscription();
	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		private route: ActivatedRoute,
		private router: Router,
		public commonService: CommonService,
		private activeRoute: ActivatedRoute,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title
	) {

	}

	ngOnInit() {
		const queryParams = this.route.snapshot.queryParams;
		this.fromPage = queryParams['fromPage'];
		setTimeout(() => {
			const element = this.document.querySelector('#scrollId');
			if (element) {
				element.scrollIntoView();
			}
		}, 600);
		this.getData();
	}

	getData() {
		this.isLoading = true;
		this.noData = false;
		const params = {
			is_active: true,
		};
		if (this.fromPage == "fromSupplierFaq") {
			params["faq_type"] = "SELLER_FAQ";
		} else {
			params["faq_type"] = "CUSTOMER_FAQ";
		}

		this.subscription.add(
			this.dataService.get(URLS.getFAQs, params).subscribe(data => {
				if (data['code'] == 200) {
					this.faqsData = data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
					if (this.fromPage == "fromSupplierFaq") {
						this.metaService.updateTag({
							property: "og:title",
							content: "Jazeera Paints | " + 'Seller FAQ',
						});
						let title = 'Jazeera Paints | ' + 'Seller FAQ';
						this.setTitleService.setTitle(title);
					}
					if (this.fromPage != "fromSupplierFaq") {
						this.metaService.updateTag({
							property: "og:title",
							content: "Jazeera Paints | " + 'FAQ',
						});
						let title = 'Jazeera Paints | ' + 'FAQ';
						this.setTitleService.setTitle(title);
					}
					if (this.faqsData.length < 1) {
						this.noData = true;
					}
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
			})
		)
	}


	elementClicked($event: any) {
		const target = $event.target || $event.srcElement || $event.currentTarget;
		if (!target) return;
		const href = target.attributes.href;
		if (!href) return;
		if (!isPlatformServer(this.platformId)) {
			if (href.value.includes('#')) {
				$event.stopPropagation();
				$event.preventDefault();
				const element = this.document.getElementById(href.value.replace('#', ''));
				element.scrollIntoView();
			}
		}
	}

	goBack() {
		this.navCtrl.back({ animated: false });
	}
}
