<app-loader *ngIf="isLoading && !getPlatformService.isDesktop "></app-loader>

<ion-content>
	<ion-row class="height-100">
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="5" size-xl="5" class="center-div height-100"
			[ngClass]="!getPlatformService.isDesktop ? 'background-blur':''">
			<ion-toolbar mode="md">

				<img router-direction="none" src="./assets/icon/companyLogo.svg" alt="Jazeera" height="100%"
					width="100%" class="company-logo">

				<div class="login-desktop-header ion-text-center">
					{{'Login' | translate}}
				</div>
			</ion-toolbar>

			<div class="login-container" [ngClass]="[ getPlatformService.isDesktop ? 'login-container-desktop' : '']">
				<form id="loginForm" class="pt-10" [formGroup]="loginFromGroup">
					<div class="email">
						<ion-input label="{{'Email' | translate }} / {{'mobile' |translate }}" mode="md"
							label-placement="floating" fill="outline" autocomplete="new-email" formControlName="email"
							#emailInput>
						</ion-input>
						<!-- </ion-item> -->
						<ng-container
							*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': loginFromGroup} }">
						</ng-container>
					</div>
					<div class="password">
						<div class="height-100 p-relative">
							<ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
								(click)="passwordType = 'password'"
								[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
							<ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
								(click)="passwordType = 'text'"
								[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
							<ion-input label="{{ 'PASSWORD' | translate }}" label-placement="floating" fill="outline"
								autocomplete="new-password" type="{{passwordType}}" clearOnEdit="false" mode="md"
								(keydown.space)="$event.preventDefault();" formControlName="password">
							</ion-input>
						</div>
						<ng-container
							*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': loginFromGroup} }">
						</ng-container>
					</div>
				</form>
				<div class="remember-me pt-10">
					<div lines="none" mode="md" class="checkbox-item">
						<ion-checkbox slot="start" class="mr-10 checkbox" [(ngModel)]="rememberMe"></ion-checkbox>
						<ion-label class="checkbox-label pointer" (click)="rememberMeClick()">{{'rememberMe' | translate
							}}</ion-label>
					</div>
				</div>
				<div class="forgot-password text-right">
					<span (click)="forgotPasswordOpen()">{{ 'Forgot password?' | translate }}</span>
				</div>
				<div class="login-bottom-container">
					<div class="login-button">
						<button type="submit" (click)="login()" form="loginForm" [disabled]="isLoading">
							<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
							{{'Login' | translate}}
						</button>
					</div>
				</div>
			</div>

		</ion-col>
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="7" size-xl="7" class="p-0 m-0"
			*ngIf="getPlatformService.isDesktop">
			<div class="bg-img"></div>
		</ion-col>
	</ion-row>

	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
				{{validation.message | translate}}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>