import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationComponent } from '../notification/notification.component';
import { NotificationCategoryComponent } from './notification-category.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: NotificationCategoryComponent,
    },
    {
        path: ':categoryId',
        component: NotificationComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NotificationCategoryRoutingModule { }
