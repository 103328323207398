<ion-header class="mobile-visibility custom-header" mode="md">
	<ion-toolbar mode="md">
		<ion-title>{{"categoryPage.title" | translate}}</ion-title>
	</ion-toolbar>
</ion-header>

<div class="wrapper desktop-visibility" [ngStyle]="{'height':  !menuVisibility ? '' + (menuHeight) + 'px': ''}">
	<div class="menu-section" #menu>
		<ng-container>
			<div class="d-flex width-100">
				<div class="width-30p">
					<div class="side-menu pointer">
						<div *ngIf="items && items.length" (click)="goProductListing()" ion-item lines='none'
							class="menu pr-20 pl-20 item-active-left text-uppercase ">
							<div class="fs-16 pointer text">
								{{'allProducts' |
								translate}} </div>
						</div>

						<ng-container *ngFor="let menu of items;index as i">
							<div [title]="commonService.domDirectionLTR ? menu['display_class_name_en']: menu['display_class_name_ar']"
								(mouseenter)="mouseEnter(menu)" (mouseleave)="mouseLeave()" (click)="goToClass(menu)"
								[class.item-active-left]="menu && menu.class_id == selectedClassId" ion-item
								lines='none' class="menu pr-20 pl-20">
								<span class="fs-16 pointer text">
									{{ commonService.domDirectionLTR ? menu['display_class_name_en'] :
									menu['display_class_name_ar']}}
								</span>
								<img alt="Right Arrow" *ngIf="menu.class_id != selectedClassId"
									src="../../../assets/icon/slider-right-arrow.svg">
								<img alt="Image" *ngIf="menu.class_id == selectedClassId"
									src="../../../assets/icon/slider-right-whiteArrow.svg">
							</div>
						</ng-container>


					</div>


				</div>


				<div class="menu-details">
					<div class="products-wrapper" *ngIf="subClassList && subClassList.length > 0 && !isLoading">
						<div class="products-name d-flex"
							*ngFor="let subClass of subClassList;let l = last;let i = index">
							<div class="product-detail pointer" *ngIf="i < 7"
								[class.item-active-right]="subClass && subClass.sub_class_id == selectedSubClassId"
								(click)="goToProductListing(subClass)" (mouseenter)="onSubClassHover(subClass)"
								(mouseleave)="onSubClassLeave()"
								title="{{ commonService.domDirectionLTR  ? subClass.display_sub_class_name_en : subClass.display_sub_class_name_ar }}">
								{{ commonService.domDirectionLTR ? subClass.display_sub_class_name_en :
								subClass.display_sub_class_name_ar
								}}
							</div>
							<div class="view-all ion-text-right" *ngIf="subClassList.length >=5 && l"
								(click)="goToSubClassListing(subClass)">
								{{'ViewAll' |
								translate}} </div>
						</div>
					</div>
					<div class="products-wrapper" *ngIf="isLoading">
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>
						<div class="products-name d-flex">
							<div class="product-detail skeleton-container">
								<ion-skeleton-text [animated]="true"></ion-skeleton-text>
							</div>
						</div>

					</div>
					<div class="no-record-container" *ngIf="subClassList && subClassList.length < 1">
						<div class="no-record-found mt-70"
							*ngIf="subClassList && subClassList.length < 1 && !isLoading">
							{{'noRecordFound' | translate}}
						</div>
					</div>
					<div class="details m-30" *ngIf="!isLoading">
						<div class="product-img" *ngIf="classImage">
							<img alt="Image" [src]="classImage">
						</div>
					</div>
					<div class="details m-30" *ngIf="isLoading">
						<div class="product-img">
							<ion-skeleton-text [animated]="true"></ion-skeleton-text>
						</div>
					</div>

				</div>

			</div>

		</ng-container>
		<!-- <div class="linking-section" *ngFor="let item of items;index as i">
            <ng-container>
                <div class="heading pointer" (click)=" goToSubClass(item)">{{ commonService.domDirectionLTR ?
                    item['heading']
                    :
                    item['heading_ar']}}</div>
                <ul>

                    <ng-container *ngFor="let data of item['options'];index as i">
                        <li *ngIf="i <= 10" (click)="goToProductListing(data)">
                            {{ commonService.domDirectionLTR ? data['subClass'] : data['subClass_ar']}}
                        </li>
                    </ng-container>
                    <li *ngIf="item['options'].length > 11" (click)="goToSubClass(item)" class="viewallcss mt-10">
                        {{'viewAll'|translate}}
                    </li>
                </ul>
            </ng-container>
        </div> -->
	</div>
</div>

<ion-content class="mobile-visibility">
	<div class="mobile-wrapper">
		<div class="category-section" *ngFor="let item of items">
			<div class="left-section">
				<img src="assets/icon/slider-image1.svg" alt="Jazeera">
			</div>
			<div class="right-section">
				<div class="category-part">
					<span class="category">Linoleum</span>
					<span class="options">167 items</span>
				</div>
				<div class="ioc-section">
					<img src="assets/icon/rightArrow.svg" alt="Jazeera">
				</div>
			</div>
		</div>
	</div>
</ion-content>