<div class="orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{"ordersComponent.title" | translate}}
		</h1>
		<div class="search-and-filter">
			<div class="filter-div">

				<div class="section-block ph-15">
					<div class="filter-label" (click)="navigateToInvoices()">
						{{"ordersComponent.olderOrders" | translate}}
					</div>
				</div>
				<div class="printer-icon">
					<img src="./assets/icon/fi_printer.svg" (click)="downloadExcel()" title="{{'export'|translate}}"
						alt="Jazeera">
				</div>
				<div class="search-div">
					<!-- <ion-item lines="none"> -->
					<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
						[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (keyup.enter)="search()">
						<img src="./assets/icon/search.svg" slot="start" (click)="search()" alt="Jazeera">
						<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
							alt="Jazeera" (click)="clearSearch()">
					</ion-input>
					<!-- </ion-item> -->
				</div>
				<div class="section-block">
					<div class="filter-icon" (click)="openFilters()" *ngIf="!showFilter">
						<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
					</div>
					<div class="close-filter-icon" (click)="openFilters()" *ngIf="showFilter">
						<img src="./assets/icon/modal-close-mobile.svg" alt="Jazeera">
					</div>
					<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''" (click)="openFilters()">
						{{"ordersComponent.filters" | translate}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<form [formGroup]="dateRange" *ngIf="showFilter">
		<div class="filter-section">
			<div class="filter-header mw-250" *ngIf="false">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{"status"
						| translate}}</ion-label>
				</div>
				<div class="input-div-container">
					<div class="status-dropdown-div">
						<ion-list class="status-dropdown" mode="md">
							<!-- <ion-item class="ion-card " lines="none"> -->
							<ion-select label="{{ 'select' | translate }}" label-placement="floating" fill="outline"
								cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" interface="popover"
								value="all" [interfaceOptions]="selectInterface" [(ngModel)]="selectStatus"
								placeholder="{{ 'select' | translate }}" [ngModelOptions]="{standalone: true}">
								<ion-select-option [value]="data.value" *ngFor="let data of statusList">{{data.label
									| translate}}</ion-select-option>
							</ion-select>
							<!-- </ion-item> -->
						</ion-list>
					</div>
				</div>
			</div>
			<div class="filter-header">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'orderDate' |
						translate}}</ion-label>
				</div>

				<div>
					<ion-row>
						<div class="input-div-container">
							<div class="from-date-div">
								<ion-item class="from-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="fromDateId">
									<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
                                | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="fromDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date" [min]="'2000-01-01'"
												[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
												formControlName="from" #fromdate
												(ionChange)="onDateChange(fromdate)"><span slot="title">{{"from"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>

						<div class="input-div-container">
							<div class="to-date-div">
								<ion-item class="to-date-class"
									[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="toDateId">
									<ion-input value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
				                                | translate}}" class="ion-text-start"></ion-input>
									<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
										alt="Jazeera"></ion-icon>
									<ion-popover trigger="toDateId" size="cover" id="datePopover">
										<ng-template>
											<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'"
												[max]="maxDate" [(ngModel)]="toDate" formControlName="to" #todate
												(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
													| translate}}</span></ion-datetime>
										</ng-template>
									</ion-popover>
								</ion-item>
							</div>
						</div>
					</ion-row>

					<ion-row>
						<div class="filter-buttons">
							<div class="apply-button ion-text-end">
								<button class="filter-apply-button" (click)="clearFilter()">{{"clear" |
									translate}}</button>
							</div>
							<button class="filter-apply-button" (click)="applyBtn()">{{"apply" | translate}}</button>
						</div>
					</ion-row>
				</div>


			</div>




		</div>
	</form>
	<div class="listing-table">
		<app-loader *ngIf="isLoading"></app-loader>
		<table class="table" *ngIf="!isLoading">
			<thead>
				<th class="column-heading order-id-col mw-100 ion-text-center">{{"ordersComponent.order_id"
					| translate}}</th>
				<th class="column-heading date-col mw-120 ion-text-center">{{"ordersComponent.date"
					| translate}}</th>
				<th class="column-heading ship-to-col mw-250 ion-text-center">{{"ordersComponent.ship_to"
					| translate}}</th>

				<!-- <th class="column-heading ion-text-center status-col mw-120">{{"ordersComponent.countOfItems"|
					translate}}
				</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"ordersComponent.deliverdCountOfItems"|
					translate}}
				</th> -->
				<th class="column-heading ion-text-center order-total-col mw-120">
					{{"ordersComponent.order_total"
					| translate}}</th>

				<th class="column-heading ion-text-center status-col mw-120">{{"ordersComponent.status"|
					translate}}
				</th>
				<th class="column-heading ion-text-center download-col">{{"action" | translate}}</th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let order of orderList;index as i" class="table-row">
					<td class="order-id ion-text-center" (click)="gotoOrderDetails(order.invoice_id , order.order_no)">
						{{order.order_no}}</td>
					<td class="ion-text-center">{{order.date | date
						:'MMM dd, YYYY'}}</td>
					<td class="text-left">{{order.ship_to}}</td>
					<!-- <td class="ion-text-center"> {{order.items_count}} </td>
					<td class="ion-text-center"> {{order.items_count}} </td> -->
					<td class="ion-text-center">
						{{commonService.currencySymbol}} {{order.order_total ? (order.order_total | number) :'-'}}
					</td>
					<td class="ion-text-center">
						<ion-button fill="clear" class="order-status-btn" [ngClass]="orderStatus(order)">
							{{order.order_status == 'CANCELLED' ? ('cancelled' | translate)
							:order.order_status == 'DISPATCHED' ? ('dispatched' | translate)
							:order.order_status == 'DELIVERED' ? ('delivered' | translate)
							:order.order_status == 'NOT_YET_DELIVERED' ? ('notYetDelivered' | translate)
							:order.order_status == 'NOT_DELIVERED' ? ('notYetDelivered' | translate)
							:order.order_status == 'RETURNED' ? ('returned' | translate)
							:order.order_status == 'CANCELLED' ? ('cancelled' | translate)
							:order.order_status == 'CONFIRMED' ? ('confirmed' | translate)
							:('confirmed' |
							translate)}}
						</ion-button>
					</td>
					<td>
						<ion-row class="p-0 justify-center">
							<div class="download-arrow"
								[ngClass]="commonService.domDirectionLTR ? 'justify-end ml-10' : 'justify-start mr-10'"
								*ngIf="order.payment_status == 'SUCCESSFUL' && order.invoice_url">
								<img src="./assets/icon/fi_download.svg"
									(click)="downloadInvoice($event,order.invoice_url)" alt="Jazeera"
									[title]="('downloadInvoice'|translate)">
							</div>

							<div class="download-arrow"
								[ngClass]="commonService.domDirectionLTR ? 'justify-end ml-10' : 'justify-start mr-10'">
								<img src="./assets/icon/reorder-icon.svg" alt="Jazeera"
									[title]="('orderDetailsComponent.reorder'|translate)">
							</div>

							<div class="right-arrow" (click)="gotoOrderDetails(order.invoice_id , order.order_no)"
								[ngClass]="commonService.domDirectionLTR ? 'justify-end ml-10' : ' rotate-arrow justify-start mr-10'">
								<img src="./assets/icon/rightArrow.svg" alt="righta-arrow" class="arrow-icon"
									[title]="('view'|translate)">
							</div>
						</ion-row>

					</td>
				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!orderList.length">
			{{'noOrdersYet' | translate}}
		</div>
	</div>

	<div class="mobile-view-listing">
		<ion-content class="orders-ion-content">
			<div class="order-container">
				<ion-grid>
					<ion-row class="order-card" *ngFor="let order of orderList;index as i">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_id" | translate}}</div>
										<div class="grid-values">{{order.order_id}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_total" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}{{order.order_total}}
										</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.date" | translate}}</div>
										<div class="grid-values">{{order.date}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<!-- <div class="grid-label">{{ordersComponent.status}}</div> -->
										<div class="grid-values">
											<ion-button fill="clear" class="status-btn"
												[ngClass]="{'pending' : order.order_status == 0,'shipped':order.order_status == 1,'canceled':order.order_status == 2}">{{order.order_status
												== 1 ? ('ordersComponent.shipped' | translate) :order.order_status == 2
												?
												('ordersComponent.cancelled' |
												translate) :('ordersComponent.pending' | translate)}}
											</ion-button>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
						<ion-col class="mobile-right-arrow" size="1">
							<div [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
								<img src="./assets/icon/rightArrow.svg" alt="righta-arrow">
							</div>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'lodingMoreData' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</ion-content>
	</div>

	<div class="pagination-wrapper" *ngIf="orderList.length && from != 'dashboard'">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col">
				<ion-list class="perpage-dropdown">
					<ion-item class="ion-card" lines="none">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							interface="popover" [interfaceOptions]="perPageInterface"
							(ionChange)="selectPageLength($event)" [(ngModel)]="itemsPerPage">
							<ion-select-option value="10">10</ion-select-option>
							<ion-select-option value="20">20</ion-select-option>
						</ion-select>
					</ion-item>
					<ion-label class="perpage-title">{{"ordersComponent.per_page" | translate}}</ion-label>
				</ion-list>
			</ion-col>
			<ion-col size="8" class="pagination-col">
				<app-tile class="grid-tile" *ngFor="let post of orderList | paginate :
    { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
    let i = index" [current]="post"></app-tile>
				<pagination-controls class="paginator"
					[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
					previousLabel="{{'ordersComponent.prev' | translate}}"
					nextLabel="{{'ordersComponent.next' | translate}}"
					(pageChange)="onChange($event)"></pagination-controls>
			</ion-col>
		</ion-row>
	</div>
</div>