import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CartService } from 'src/app/services/cart.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-other-menu',
    templateUrl: './other-menu.component.html',
    styleUrls: ['./other-menu.component.scss'],
})
export class otherMenuComponent implements OnInit {

    menuOptions = [
        {
            menu_name: 'otherMenuComponent.aboutUs',
            route: '/about-us',
            is_enable: true,
            icon_url: '',
            id: 1,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.FAQ',
            route: '/faq',
            is_enable: true,
            icon_url: '',
            id: 2,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.sellerFAQ',
            route: '/faq?fromPage=fromSupplierFaq',
            is_enable: true,
            icon_url: '',
            id: 3,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.privacyPolicy',
            route: '/privacy-policy',
            is_enable: true,
            icon_url: '',
            id: 4,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.returnsRefundPolicy',
            route: "/return-policy",
            is_enable: true,
            icon_url: '',
            id: 5,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.contactUs',
            route: "/contact-us",
            is_enable: true,
            icon_url: '',
            id: 6,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.cookiesPolicy',
            route: "/cookie-policy",
            is_enable: true,
            icon_url: '',
            id: 7,
            is_public: true,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'otherMenuComponent.termsOfUse',
            route: "/terms-of-use",
            is_enable: true,
            icon_url: '',
            id: 8,
            is_public: true,
            is_private: true,
            is_web: true
        },
    ];

    isLoggedIn: boolean;
    private subscription: Subscription = new Subscription();
    public isLoading = false;
    currentLang;
    constructor(private modalService: ModalService,
        public commonService: CommonService,
        private alertController: AlertController,
        private authService: AuthenticationService,
        private toastService: ToastService,
        private dataService: ApiService,
        public getPlatformService: GetPlatformService,
        private router: Router,
        private navCtrl: NavController,
        public cartService: CartService, public translate: TranslateService) {
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
        this.currentLang = this.translate.currentLang;
    }

    ngOnInit() {

    }

    goBack() {
        this.navCtrl.back({ animated: false });
    }


    async openModal(id) {
        if (id == 1) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/about-us', { animated: false });
        }
        if (id == 2) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/faq', { animated: false });
        }
        if (id == 3) {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    fromPage: 'fromSupplierFaq'
                }
            };

            this.router.navigate(['/'+this.currentLang +'/my-account/other-menu/faq'], navigationExtras)
        }
        if (id == 4) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/privacy-policy', { animated: false });
        }
        if (id == 5) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/return-policy', { animated: false });
        }
        if (id == 6) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/contact-us', { animated: false });
        }
        if (id == 7) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/cookie-policy', { animated: false });
        }
        if (id == 8) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/my-account/other-menu/terms-of-use-mobile', { animated: false });
        }


    }


    menuDisplay(data) {
        if (this.isLoggedIn && data.is_enable) {
            return true;
        }
        else if (!this.isLoggedIn && data.is_public) {
            return true;
        }
        else return false;
    }


}