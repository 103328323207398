import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { CancelProductComponent } from '../../components/cancel-product/cancel-product.component';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
@Component({
	selector: 'app-order-details-mobile',
	templateUrl: './order-details-mobile.component.html',
	styleUrls: ['./order-details-mobile.component.scss'],
})
export class OrderDetailsMobileComponent implements OnInit {
	invoiceId: any;
	sapID: any;
	isLoading: boolean = false;
	private subscription: Subscription = new Subscription();
	orderData = {
		order_no: '-',
		order_date: '',
		order_status: 'CONFIRMED',
		shipping_address: '',
		sub_total: '',
		items_sub_total: '',
		shipping_charges: '',
		shipping_charges_without_vat: '',
		shipping_vat_charges: '',
		total_price: '',
		vat: '',
		vat_percent: '15',
		discount_price: '',
		discount_code: '',
		mobile_no: '',
		city_en: '',
		city_ar: '',
		region_ar: '',
		region_en: '',
		country_ar: '',
		country_en: '',
		zipcode: '',
		is_cancellation_allowed: '',
		is_return_allowed: '',
		grand_total: ''
	};
	supplierData = [];
	isReordering = false;
	statusMap = {
		DISPATCHED: { label: 'dispatched', class: 'btn-default' },
		NOT_YET_DELIVERED: { label: 'notYetDelivered', class: 'btn-default' },
		NOT_DELIVERED: { label: 'notYetDelivered', class: 'btn-default' },
		'NOT DELIVERED': { label: 'notYetDelivered', class: 'btn-default' },
		RETURNED: { label: 'returned', class: 'btn-red product-status-btn' },
		CANCELLED: { label: 'cancelled', class: 'btn-red product-status-btn' },
		DELIVERED: { label: 'delivered', class: 'btn-green product-status-btn' },
		CONFIRMED: { label: 'confirmed', class: 'btn-green product-status-btn' },
		CANCELLATION_REQUESTED: {
			label: 'orderDetailsComponent.cancelRequested',
			class: 'btn-orange product-status-btn return-request-btn'
		},
		'RETURN REQUESTED': {
			label: 'orderDetailsComponent.returnRequested',
			class: 'btn-orange product-status-btn return-request-btn'
		}
	};
	constructor(
		private modalCtrl: ModalController,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private modalService: ModalService,
		private cartService: CartService,
		private router: Router,
		private navCtrl: NavController,
		private route: ActivatedRoute,
		public translate: TranslateService

	) {
		this.route.queryParams
			.subscribe(params => {
				this.invoiceId = params['invoiceId'] ? params['invoiceId'] : null;
				this.sapID = params['sapID'] ? params['sapID'] : null;
			});
	}

	ngOnInit() {

		if (this.sapID && this.sapID !== 'null' && this.sapID !== null) {
			this.getExternalOrderDetails();
		} else {
			this.getOrderDetails();
		}
	}

	getOrderDetails() {
		if (!this.invoiceId && !this.sapID) return;
		const params = {
			invoice_id: this.invoiceId
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.internalOrderDetails, params).subscribe(data => {
				if (data && data['code'] == 200) {
					if (data['data']) {
						this.orderData = {
							...data['data'],
							order_no: data['data']['order_no'] ? data['data']['order_no'] : '-',
							order_date: data['data']['order_date'] ? data['data']['order_date'] : '',
							order_status: data['data']['order_status'] ? data['data']['order_status'] : 'CONFIRMED',
							address: data['data']['address'] ? data['data']['address'] : '',
							sub_total: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'items_sub_total')?.value : 0,
							items_sub_total: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'items_sub_total')?.value : 0,
							shipping_charges: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_charges')?.value : 0,
							shipping_charges_without_vat: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_charges_without_vat')?.value : 0,
							shipping_vat_charges: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_vat_charges')?.value : 0,
							total_price: data['data']['total_order_amount'] || 0,
							vat: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'vat')?.value : 0,
							vat_percent: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'vat_percentage')?.value : '15',
							discount_price: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'total_discount')?.value : 0,
							discount_code: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'coupon_code')?.value : 0,
							mobile_no: data['data']['mobile_no'] ? data['data']['mobile_no'] : '',
							city_en: data['data']['city_en'] ? data['data']['city_en'] : '',
							city_ar: data['data']['city_ar'] ? data['data']['city_ar'] : '',
							region_ar: data['data']['region_ar'] ? data['data']['region_ar'] : '',
							region_en: data['data']['region_en'] ? data['data']['region_en'] : '',
							country_ar: data['data']['country_ar'] ? data['data']['country_ar'] : '',
							country_en: data['data']['country_en'] ? data['data']['country_en'] : '',
							zipcode: data['data']['zipcode'] ? data['data']['zipcode'] : '',
							is_cancellation_allowed: data['data']['is_cancellation_allowed'] ? data['data']['is_cancellation_allowed'] : null,
							is_return_allowed: data['data']['is_return_allowed'] ? data['data']['is_return_allowed'] : null,
							grand_total: data['data']['grand_total'] ? data['data']['grand_total'] : null
						}
						this.supplierData = data['data']['order_items'] && data['data']['order_items'].length ? data['data']['order_items'] : [];
						if (this.supplierData) {
							this.supplierData.forEach(element => {
								element.unit_name_ar = element.unit_name_ar || element.quantity > 1 ? "قطع" : "قطعة";
								element.unit_name_en = element.unit_name_en || element.quantity > 1 ? "Pieces" : "Piece";
							});
						}
					}
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.goBack();
				}
			}, error => {
				this.isLoading = false;
				this.goBack();
			})
		)
	}

	getExternalOrderDetails() {
		if (!this.sapID) return;
		const params = {
			sap_order_no: this.sapID
		}

		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.externalOrderDetails, params).subscribe(data => {
				if (data && data['code'] == 200) {
					if (data['data']) {
						this.orderData = {
							...data['data'],
							order_no: data['data']['order_no'] ? data['data']['order_no'] : '-',
							order_date: data['data']['order_date'] ? data['data']['order_date'] : '',
							order_status: data['data']['order_status'] ? data['data']['order_status'] : 'CONFIRMED',
							address: data['data']['address'] ? data['data']['address'] : '',
							sub_total: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'items_sub_total')?.value : 0,
							items_sub_total: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'items_sub_total')?.value : 0,
							shipping_charges: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_charges')?.value : 0,
							shipping_charges_without_vat: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_charges_without_vat')?.value : 0,
							shipping_vat_charges: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_vat_charges')?.value : 0,
							total_price: data['data']['total_order_amount'] || 0,
							vat: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'vat')?.value : 0,
							vat_percent: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'vat_percentage')?.value : '15',
							discount_price: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'total_discount')?.value : 0,
							discount_code: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'coupon_code')?.value : 0,
							mobile_no: data['data']['mobile_no'] ? data['data']['mobile_no'] : '',
							city_en: data['data']['city_en'] ? data['data']['city_en'] : '',
							city_ar: data['data']['city_ar'] ? data['data']['city_ar'] : '',
							region_ar: data['data']['region_ar'] ? data['data']['region_ar'] : '',
							region_en: data['data']['region_en'] ? data['data']['region_en'] : '',
							country_ar: data['data']['country_ar'] ? data['data']['country_ar'] : '',
							country_en: data['data']['country_en'] ? data['data']['country_en'] : '',
							zipcode: data['data']['zipcode'] ? data['data']['zipcode'] : '',
							is_cancellation_allowed: data['data']['is_cancellation_allowed'] ? data['data']['is_cancellation_allowed'] : null,
							is_return_allowed: data['data']['is_return_allowed'] ? data['data']['is_return_allowed'] : null,
							grand_total: data['data']['grand_total'] ? data['data']['grand_total'] : null
						}
						this.supplierData = data['data']['order_items'] && data['data']['order_items'].length ? data['data']['order_items'] : [];
						if (this.supplierData) {
							this.supplierData.forEach(element => {
								element.unit_name_ar = element.unit_name_ar || element.quantity > 1 ? "قطع" : "قطعة";
								element.unit_name_en = element.unit_name_en || element.quantity > 1 ? "Pieces" : "Piece";
							});
						}
					}
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.goBack();
				}
			}, error => {
				this.isLoading = false;
				this.goBack();
			})
		)
	}

	goBack() {
		this.navCtrl.back();
	}

	cancelProduct(data, returnProduct?) {
		let options: any = {
			cssClass: 'cancel-product-mobile-popup',
			backdropDismiss: false,
			componentProps: { orderData: data, rows: 10, mobileClasses: true, invoiceId: this.invoiceId, isCancel: returnProduct ? false : true },
		};
		options['component'] = CancelProductComponent;
		let modal = this.modalService.openModal(options);
		modal.then(data => {
			if (data && data['data'] && data['data']['data']) {
				this.getOrderDetails();
			}
		})
	}


	reorder() {
		if (this.isReordering) {
			return
		}
		let promiseArray = [];
		this.supplierData.forEach(element => {
			this.isReordering = true;
			promiseArray.push(
				this.cartService.addIntoCart(element, element.ordered_quantity, false, true)
			)
		});
		Promise.all(promiseArray).then(data => {
			this.isReordering = false;
			this.router.navigate(['' + this.commonService.currentLang + '/cart'])
		}, err => {
			this.isReordering = false;
			this.router.navigate(['' + this.commonService.currentLang + '/cart'])

		})
	}

	orderStatus(orderData, productlevel?) {
		let status = orderData.order_status ? orderData.order_status : orderData.shipping_status;
		switch (status) {
			case 'NOT_YET_DELIVERED':
			case 'DISPATCHED':
			case 'CONFIRMED':
				if (productlevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
			case 'DELIVERED':
				if (productlevel) {
					return 'btn-black product-status-btn';
				} else {
					return 'btn-black';
				}
			case 'CANCELLED':
				if (productlevel) {
					return 'btn-red product-status-btn';
				} else {
					return 'btn-red';
				}
			default:
				if (productlevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
		}
	}

	goToProductDetail(data) {
		let currentLang = this.commonService.currentLang;
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let subClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);
		this.navCtrl.navigateRoot('/' + currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id, { animated: false, });
	}
}
