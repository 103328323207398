<app-loader *ngIf="isLoading"></app-loader>

<ion-header *ngIf="!getPlatformService.isDesktop && !isLoading" class="address-header" mode="md">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="address-header">
				{{"addressComponent.title" | translate}}
			</div>
		</ion-title>
		<img src="./assets/icon/addIcon.svg" alt="add" slot="end" (click)="openModal()">
	</ion-toolbar>
</ion-header>

<ion-content *ngIf="!getPlatformService.isDesktop && !isLoading">
	<div class="addresses">
		<div class="address-grid">
			<ion-grid>
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0"
						*ngFor="let address of additionalAddressArray">
						<div class="address-holder mobile-view-holder">
							<div class="address-label">
								{{address.address_label | translate}}
								<img class="deleteIcon"
									[ngClass]="commonService.domDirectionLTR ? 'iconOnRight2' : 'iconOnLeft2'"
									(click)="openModal(address.address_id,address)" src="./assets/icon/edit-icon.svg"
									alt="edit-icon" slot="end" *ngIf="!getPlatformService.isDesktop">

								<img class="deleteIcon"
									[ngClass]="commonService.domDirectionLTR ? 'iconOnRight' : 'iconOnLeft'"
									(click)="deleteModal(address.address_id)" src="./assets/icon/deleteIcon.svg"
									alt="delete-icon" slot="end" *ngIf="!getPlatformService.isDesktop">
							</div>
							<div class="full-address-container">
								<div class="address-name">
									{{address.first_name}} {{address.last_name}}
								</div>
								<div class="full-address">
									{{address.address}},<br> {{commonService.domDirectionLTR ? address.city_en :
									address.city_ar}},
									{{commonService.domDirectionLTR ? address.region_en : address.region_ar}},<br>
									{{commonService.domDirectionLTR ? address.country_en : address.country_ar}} -
									{{address.zipcode}}
								</div>
								<div class="contact-no">
									+966 {{address.mobile_no}}
								</div>
							</div>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</div>
	</div>

	<div *ngIf="!additionalAddressArray.length">
		<div class="no-data m-20">
			{{"noRecordFound" | translate}}
		</div>
	</div>
</ion-content>




<div class="addresses" *ngIf="getPlatformService.isDesktop && !isLoading">

	<div class="address-listing-header" *ngIf="showTable != false && getPlatformService.isDesktop && !isLoading">
		<h1 class="title-div m-0">
			{{"addressComponent.title" | translate}}
		</h1>
		<div class="section-name">{{"myAccountComponent.addresses" | translate}}</div>
	</div>
	<div *ngIf="!showEditBtn && !additionalAddressArray.length && !isLoading">
		<div class="no-data">
			{{"noRecordFound" | translate}}
			{{"customField.selectNote" | translate}}
		</div>
	</div>

	<div class="address-grid" *ngIf="addressList.length">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0"
					*ngFor="let address of addressList">
					<div class="address-holder">
						<div class="address-label">
							{{address.address_label | translate}}
						</div>
						<div class="full-address-container">
							<div class="address-name">
								{{address.first_name}} {{address.last_name}}
							</div>
							<div class="full-address">
								{{address.address}},<br> {{commonService.domDirectionLTR ? address.city_en :
								address.city_ar}},
								{{commonService.domDirectionLTR ? address.region_en : address.region_ar}},<br>
								{{commonService.domDirectionLTR ? address.country_en : address.country_ar}} -
								{{address.zipcode}}
							</div>
							<div class="contact-no">
								+966 {{address.mobile_no}}
							</div>
						</div>
						<!-- <div class="edit-btn-container" *ngIf="getPlatformService.isDesktop">
                            <span class="edit-btn" (click)="openModal(address.address_id,address)">
                                {{editBtnLabel | translate}}
                            </span>
                        </div> -->
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>


<div *ngIf="showEditBtn &&!addressList.length && !isLoading">
	<div class="no-data">
		{{"noRecordFound" | translate}}
	</div>
</div>


<div *ngIf="showTable != false && getPlatformService.isDesktop && !isLoading">
	<div *ngFor="let data of componentData">

		<div class="section-heading pt-30">
			<div class="section-name">{{data.section_name | translate}}</div>
			<div class="section-button-containter" *ngIf="data.section_btn">
				<span class="section-btn" (click)="openModal()">
					{{data.section_btn | translate}}
				</span>
			</div>
		</div>
		<div *ngIf="!additionalAddressArray.length">
			<div class="no-data mt-40">
				{{"noRecordFound" | translate}}
			</div>
		</div>

		<div class="section-content pt-30" *ngIf="additionalAddressArray.length">
			<div class="listing-table">
				<table class="table">
					<thead>
						<th class="column-heading text-left">
							{{"field.Firstname"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.Lastname"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.StreetAddress"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.Country"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.Region"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.City"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.ZipcodeSpace"
							| translate}}</th>
						<th class="column-heading text-left">
							{{"field.PhoneNo"
							| translate}}</th>
						<th class="column-heading ion-text-center download-col">{{"field.Action" | translate}}</th>
					</thead>
					<tbody class="table-body">
						<tr *ngFor="let data of additionalAddressArray;index as i" class="table-row">
							<td class="text-left">
								{{data.first_name ? data.first_name : '-'}}
							</td>
							<td class="text-left">
								{{data.last_name ? data.last_name : '-'}}
							</td>
							<td class="text-left">
								{{data.address ? data.address : '-'}}</td>

							<td class="text-left">
								{{commonService.domDirectionLTR ? data.country_en:data.country_ar}}</td>

							<td class="text-left">
								{{commonService.domDirectionLTR ? data.region_en:data.region_ar}}</td>

							<td class="text-left">
								{{commonService.domDirectionLTR ? data.city_en : data.city_ar}}</td>

							<td class="text-left">
								{{data.zipcode}}</td>

							<td class="text-left">
								+966 {{data.mobile_no}}</td>

							<td class="actionCol">
								<div class="download-arrow " (click)="openModal(data.address_id,data)"
									[ngClass]="commonService.domDirectionLTR ? 'justify-end' : 'justify-start'">
									<img src="./assets/icon/edit-review.svg" alt="{{'edit' |translate}}"
										title="{{'edit' |translate}}">


								</div>

								<div class="right-arrow" (click)="deleteModal(data.address_id)"
									[ngClass]="commonService.domDirectionLTR ? 'justify-end' : 'justify-start'">
									<img src="./assets/icon/trash.svg" alt="right-arrow" alt="{{'delete' |translate}}"
										title="{{'delete' |translate}}">
								</div>
							</td>
							<!-- <td>

                            </td> -->
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>