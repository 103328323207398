import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appDigitDecimalNumber]'
})
export class DecimalNumberDirective {
	private regex: RegExp = new RegExp(/^\d+(\d+)?$/);

	constructor(private el: ElementRef) { }

	@HostListener('input', ['$event'])
	onInput(event: Event) {
		const inputElement: HTMLInputElement = this.el.nativeElement;
		let value: string = inputElement.value;

		if (value !== '-' && !value.match(this.regex)) {
			// Revert to the previous valid value
			inputElement.value = this.getPreviousValidValue(value);
		}
	}

	private getPreviousValidValue(value: string): string {
		// Find the last valid substring that matches the regex
		const matches = value.match(/^\d+(\d+)?$/);
		if (matches && matches.length > 0) {
			return matches[0];
		}
		return '';
	}
}
