<ion-row class="row-css">
	<ion-col *ngFor="let data of array" size-xs="6" size-sm="6" size-md="auto" size-lg="auto" size-xl="auto">
		<div class="slide-container" [ngClass]="['productDetailLabel' ? 'detail-slide-container' : '']">
			<div class="product-image-container"
				[ngClass]="categories ? 'categories-image-container' : 'product-image-container'"
				(click)="gotToProductDetail(data)">
				<div class="product-fav" (click)="addToWishlist($event, data.product_id, data.is_wishlist_product)">
					<ion-icon *ngIf="!this.data.is_wishlist_product" src="assets/icon/fav.svg" alt="Jazeera"></ion-icon>
					<ion-icon *ngIf="this.data.is_wishlist_product" src="assets/icon/fav-liked.svg"
						alt="Jazeera"></ion-icon>
				</div>
				<div class="product-discount">
					<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> 20% OFF
					</div>
				</div>
				<div class="product-image-div">
					<img class="wishListImg" [ngClass]="[ data.is_sold_out ? 'product-image' : '']"
						src="{{ data.product_img }}" alt="Jazeera">
					<img class="paint-container-img"
						[ngClass]="[ data.is_sold_out ? 'product-image' : '']"
						src="{{ data.product_container_img }}" alt="Jazeera">
				</div>
				<div *ngIf="data.is_sold_out" class="product-outofstock">
					<div class="outofstock-label">
						{{"outOfStock" | translate}}
					</div>
				</div>
			</div>
			<div (click)="gotToProductDetail(data)" class="product-description text-left"
				[ngClass]="[categories ? 'categories-product-description' : '']">
				{{ commonService.domDirectionLTR ? data.product_name : data.product_name_ar}}
			</div>
			<div (click)="gotToProductDetail(data)" class="items-available pt-5 text-left" *ngIf="data.items">
				{{ data.items }}
			</div>
			<div class="product-price text-left d-flex" *ngIf="!data.is_sold_out && data.product_price">
				<div class="product-price-data">
					<span class="currency">{{ commonService.currencySymbol}}</span>
					<span class="price">{{data.final_price }}</span>
				</div>
				<div class="product-price-data">
					<span *ngIf="data.is_discount_applied" class="checked-price">
						<span class="currency2">{{commonService.currencySymbol}}</span>
						<span class="price2">{{data.price_per_unit | number}}</span>
					</span>
				</div>
				<div class="product-price-data pl-5" itemprop="price">
					<span class="checked-price currency2">
						<span class="currency2">{{commonService.currencySymbol}}</span>
						<span class="price2">200</span>
					</span>
				</div>
			</div>
			<!-- <div class="notify-me" *ngIf="data.is_sold_out && !data.take_action"
                (click)="notifyMeClick(data.product_id)">
                <app-common-button color="white" background="var(--ion-app-primary)" type="solid"
                    title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true"
                    [specificWidth]="'136px'" [specificHeight]="'39px'"
                    [specificFontWeight]="'bold'"></app-common-button>
            </div> -->
			<div class="action-block" *ngIf="data.take_action">
				<div class="left-block">
					<!-- code-required -->
					<!-- <img class="edit" src="assets/icon/edit-review.svg" alt="Jazeera"> -->
					<img class="delete" (click)="removeFromWishList(data)" src="assets/icon/deleteIcon.svg"
						alt="Jazeera">
				</div>
				<div class="right-block mr-10">
					<span class="move" (click)="addToCart(data)" *ngIf="data.main_product_ref_id && !data.is_sold_out">
						{{"moveToCart"|translate}}</span>
					<span class="move" (click)="gotToProductDetail(data)"
						*ngIf="!data.main_product_ref_id  && !data.is_sold_out">
						{{"toDetail"|translate}}</span>
					<span class="move" (click)="notifyMeClick(data.product_id)" *ngIf="data.is_sold_out">
						{{"NotifyMe"|translate}}</span>
				</div>
			</div>
		</div>

	</ion-col>
</ion-row>