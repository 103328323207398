<ion-header mode="md" class="order-details-mobile-header">
	<ion-toolbar mode="md">
		<img class="img-position back-icon" src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start"
			(click)="goBack()">
		<ion-title mode="ios" class="ion-text-center page-title">
			<div class="order-details-header">{{'orderDetailsMobile.title' | translate}}</div>
		</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content>
	<app-loader *ngIf="isLoading"></app-loader>
	<ng-container *ngIf="!isLoading">
		<div class="main-order-detail-container">
			<ion-grid class="order-detail-grid p-0">
				<ion-row>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{'orderDetailsMobile.orderNo' | translate}}
						</div>
						<div class="main-order-value">
							#{{ orderData['order_no']}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"orderDetailsComponent.totalOrder" | translate}}
						</div>
						<div class="main-order-value">
							{{commonService.currencySymbol}} {{orderData['total_price'] ? (orderData['total_price'] |
							number) :'0'}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"orderDetailsComponent.date" | translate}}
						</div>
						<div class="main-order-value">
							{{orderData['order_date'] | date : 'MMM dd, YYYY'}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"customField.paymentMode" | translate}}
						</div>
						<div class="main-order-value">
							<div fill="clear">
								{{orderData['payment_type'] ? orderData['payment_type'] :'--'}}
							</div>
						</div>
					</ion-col>

					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"customField.ReferenceNo" | translate}}
						</div>
						<div class="main-order-value">
							{{orderData['cx_reference_no'] || "--"}}
						</div>
					</ion-col>
					<ion-col size="6" class="p-0">
						<div class="header-label">
							{{"customField.ReferenceNo1" | translate}}
						</div>
						<div class="main-order-value">
							<div fill="clear">
								{{orderData['sap_order_no'] ? orderData['sap_order_no'] :'--'}}
							</div>
						</div>
					</ion-col>

					<ion-col size="6" class="p-0" *ngIf="false">
						<div class="main-order-status">
							<ion-button fill="clear" class="order-status-btn"
								[ngClass]="orderStatus(orderData)">{{orderData.order_status|translate}}
							</ion-button>
						</div>
					</ion-col>
					<ion-col size="12" class="p-0">
						<div class="header-label">
							{{"orderDetailsComponent.shipTo" | translate}}
						</div>
						<div class="main-order-value ship-address-value">
							{{orderData['address']}} {{orderData['city_en'] ? ',':''}} {{orderData['city_en']
							?
							orderData['city_en'] :''}}{{orderData['region_en'] ? ',':''}}
							{{orderData['region_en'] ? orderData['region_en'] :''}} {{orderData['country_en'] ?
							',':''}}
							{{orderData['country_en'] ?
							orderData['country_en'] :''}} {{orderData['zipcode'] ? ',':''}} {{orderData['zipcode'] ?
							orderData['zipcode'] :''}}
							<p class="m-0 p-0">
								{{orderData['mobile_no']}}
							</p>
						</div>
					</ion-col>


				</ion-row>
			</ion-grid>
		</div>
		<ng-container *ngIf="supplierData">
			<div class="supplier-data-container">
				<div class="supplier-header">
					<div class="total-items">
						{{ supplierData && supplierData.length ? supplierData.length :
						0 }}
						{{supplierData && supplierData.length > 1 ? ('orderDetailsMobile.items' |
						translate)
						:('orderDetailsMobile.item' |
						translate) }}
					</div>
				</div>
				<div class="supplier-products">
					<ng-container *ngFor="let product of supplierData">
						<div class="product-data">
							<div class="product-image" (click)="goToProductDetail(product)">
								<div class="sample-div" *ngIf="product.is_sample_product"
									[ngClass]="commonService.domDirectionLTR ? '' :'placement-right'">
									<p>{{'SAMPLE'|translate}}</p>
								</div>
								<img [src]="product.cover_image" *ngIf="product.cover_image" alt="Jazeera">
								<img src="./assets/images/empty-img.png" *ngIf="!product.cover_image" alt="Jazeera">
							</div>
							<div class="product-details">
								<div class="product-name" (click)="goToProductDetail(product)">
									{{ commonService.domDirectionLTR ? product.product_name_en :
									product.product_name_ar}}
								</div>

								<div class="product-configuration">
									<div class="product-color">
										<span class="config-values">
											{{ product.product_sku || '--' }}
										</span>
									</div>
								</div>
								<div class="ion-text-center product-details-col pl-0">
									<div class="product-status">
										<ion-button *ngIf="product.shipping_status" fill="clear"
											class="order-status-btn "
											[ngClass]="statusMap[product.shipping_status]?.class || ''">
											{{ statusMap[product.shipping_status]?.label | translate }}
										</ion-button>

										<div *ngIf="product.is_cancellation_allowed || product.is_return_allowed"
											class="cancel-product">
											<ion-button fill="clear"
												class="order-status-btn product-status-btn return-request-btn"
												*ngIf="supplierData.is_cancellation_allowed">
												{{"orderDetailsComponent.cancelProduct" | translate}}
											</ion-button>
											<span *ngIf="supplierData.is_return_allowed"
												(click)="openCancelProductModal(supplierData, true)">
												{{"orderDetailsComponent.returnProduct" | translate}}
											</span>
										</div>
									</div>
								</div>

								<ng-container
									*ngIf="product.product_configuration && product.product_configuration.length">
									<div class="product-configuration">
										<div class="product-color" *ngFor="let confg of product.product_configuration">
											<span class="config-label">{{ commonService.domDirectionLTR ?
												confg.configuration_name_en : confg.configuration_name_ar}}:</span><span
												class="config-values">

												{{ commonService.domDirectionLTR ? confg.configuration_value_en :
												confg.configuration_value_ar}}
											</span>
										</div>
									</div>
								</ng-container>

							</div>


						</div>
						<ion-row class="mh-16">
							<ion-col size="6">
								<div class="product-configuration">
									<div class="product-color">
										<span class="config-label">{{ "orderDetailsComponent.orderQty" |
											translate}}:</span>
										<span class="config-values">
											{{ product.ordered_quantity || "--" }}
										</span>
									</div>
								</div>
							</ion-col>
							<ion-col size="6">
								<div class="product-configuration">
									<div class="product-color">
										<span class="config-label">{{ "orderDetailsComponent.deliverQty" |
											translate}}:</span>
										<span class="config-values">
											{{ product.delivered_quantity || "--" }}
										</span>
									</div>
								</div>

							</ion-col>
							<ion-col size="6">
								<div class="product-configuration">
									<div class="product-color">
										<span class="config-label">{{ "orderDetailsComponent.invoiceQty" |
											translate}}:</span>
										<span class="config-values">
											{{ product.invoiced_quantity || "--" }}
										</span>
									</div>
								</div>
							</ion-col>
							<ion-col size="6">
								<div class="product-configuration">
									<div class="product-color">
										<span class="config-label">{{ "orderDetailsComponent.balanceQty" |
											translate}}:</span>
										<span class="config-values">
											{{ product.balance_quantity || "--" }}
										</span>
									</div>
								</div>
							</ion-col>
						</ion-row>

						<div class="product-calculation mh-16">
							<div class="product-quantity">
								{{ product.ordered_quantity }} &times; {{commonService.currencySymbol}} {{
								product.is_discount_applied ? product.discounted_price_per_product ?
								(product.discounted_price_per_product | number) :'0':
								product.price_per_unit ? (product.price_per_unit | number) :'0' }}<span
									class="product-unit">/{{
									commonService.domDirectionLTR ? product.unit_name_en :
									product.unit_name_ar}}</span>
							</div>
							<div class="product-total-price">
								{{commonService.currencySymbol}} {{ product.sub_total ? ( product.sub_total |
								number) :'0' }}
							</div>
						</div>

					</ng-container>

				</div>
				<div class="shipment-btn-container" *ngIf="supplierData.tracking_url">
					<ion-button fill="clear" class="btn-dark-blue tracking-order-btn">
						<ion-icon src="./assets/icon/mobileTrackShipment.svg" class="mr-10" alt="Jazeera"></ion-icon>
						{{'orderDetailsComponent.trackShippment' |
						translate}}
					</ion-button>
				</div>

			</div>
		</ng-container>
		<div class="divider-line mv-10"></div>
		<div class="mobile-order-summary">
			<app-order-summary [currency]="commonService.currencySymbol" [orderTotal]="orderData['total_order_amount']"
				[orderSubTotal]="orderData['total_items_sub_total']" [orderVAT]="orderData['total_vat_amount']"
				[orderDiscount]="orderData['discount_applied']" [orderShipping]="orderData.shipping_charges"
				isTotalSummary="false" [background]="'white'">
			</app-order-summary>

		</div>
		<div class="action-btns">
			<ion-button fill="clear" class="reorder-btn" (click)="reorder()" [disabled]="isReordering">
				<ion-icon src="./assets/icon/reorder-icon.svg" class="mr-10" alt="Jazeera"></ion-icon>
				{{"orderDetailsComponent.reorder" | translate}}
			</ion-button>
			<!-- <ion-button fill="clear" class="cancel-btn">
        {{'orderDetailsMobile.cancelOrder' |
        translate}}
      </ion-button> -->
		</div>
	</ng-container>
</ion-content>