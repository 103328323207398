import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { NotificationCategoryRoutingModule } from './notification-category.routing.module';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SharedModule,
        NotificationCategoryRoutingModule
    ]
})
export class NotificationCategoryModule { }
