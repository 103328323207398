import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-terms-of-use',
	templateUrl: './terms-of-use.page.html',
	styleUrls: ['./terms-of-use.page.scss'],
})
export class TermsOfUsePage implements OnInit {
	pageData: any = {};
	@Input() fromRegisters: any = false;
	public subscription: Subscription = new Subscription();
	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private router: Router,
		public navCtrl: NavController,
		public modalService: ModalService,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title,
	) { }

	ngOnInit() {
		this.getData()
	}

	ionViewWillEnter() {
		this.getData()
	}

	getData() {
		const params = {
			content_type: 'TERM_OF_USE'
		}
		this.subscription.add(
			this.dataService.get(URLS.getCMSdata, params).subscribe(data => {
				if (data['code'] == 200) {
					this.pageData = data['data'] ? data['data'] : {};
					if (this.pageData.content_title_en) {
						this.metaService.updateTag({
							property: "og:title",
							content: "Jazeera Paints | " + this.pageData.content_title_en,
						});
						let title = 'Jazeera Paints | ' + this.pageData.content_title_en;
						this.setTitleService.setTitle(title);
					}
					if (this.pageData.content_en) {
						let bodyContent = this.pageData.content_en.replace(/<[^>]+>/g, '');

						this.metaService.updateTag({
							name: "description",
							content: "Jazeera Paints | " + bodyContent,
						});
						this.metaService.updateTag({
							property: "og:description",
							content: "Jazeera Paints | " + bodyContent,
						});
					}
				}
			}, error => {
			})
		)
	}

	elementClicked($event: any) {
		const target = $event.target || $event.srcElement || $event.currentTarget;
		if (!target) return;
		const href = target.attributes.href;
		if (!href) return;
		if (!isPlatformServer(this.platformId)) {
			if (href.value.includes('#')) {
				$event.stopPropagation();
				$event.preventDefault();
				const element = this.document.getElementById(href.value.replace('#', ''));
				element.scrollIntoView();
			}
		}
	}

	goBack() {
		if (!this.fromRegisters) {
			this.navCtrl.back({ animated: false });
		}
		else {
			this.modalService.dismissModal();
		}

	}
}
