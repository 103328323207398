import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../../validators/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { TermsOfUsePage } from '../terms-of-use/terms-of-use.page';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.page.html',
	styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

	@ViewChild('companyLogoNameElem') companyLogoNameElem: ElementRef;
	@ViewChild('companyCrNoElem') companyCrNoElem: ElementRef;
	@ViewChild('vatCertificateNoElem') vatCertificateNoElem: ElementRef;
	@ViewChild('brandLogoElem') brandLogoElem: ElementRef;
	@ViewChild('nationalAddressFileElem') nationalAddressFileElem: ElementRef;

	public desktopView: boolean = false;
	public countryCodeLabel: boolean = false;
	public isLoading = false;
	private subscription: Subscription = new Subscription();
	public selectedCategory = [];
	public message: any;
	public fileToUpload: any;
	public passwordType = 'password';
	public repeatPasswordType = 'password';
	public isLocationSelected: boolean = false;
	public selected_Product: any;
	public product_value: any;
	public locationData: any = [];
	public is_sample: any;
	public city_id: any;
	public locationSelect = {
		size: 'cover',
		side: 'bottom',
		alignment: 'center',
		cssClass: 'location-popover'
	}
	isCompanyCRNoFile = false;
	isVatCertificateNoFile = false;
	companyCRNoName: any;
	vatCertificateNoName: any;
	companyLogoFile: any;
	brandLogoFile: any;
	companyCRNoFile: any;
	vatCertificateNoFile: any;
	nationalAddressNoFile: any;
	supplierId: any;
	accessToken: any;
	files: any;
	nationalAddressFile: any;
	isNationalAddressFile = false;
	isCheckedList = [];
	termsCheck: boolean = false;
	public validation_messages = {
		email: [
			{ type: 'required', message: 'loginComponent.emailRequired' },
			{ type: 'invalidEmail', message: 'loginComponent.emailInvalid' }
		],
		password: [
			{ type: 'required', message: 'loginComponent.passwordRequired' },
			{ type: 'invalidString', message: 'loginComponent.passwordStringInvalid' },
			{ type: 'invalidPassword', message: 'loginComponent.passwordInvalid' }
		],
		name: [
			{ type: 'required', message: 'registerComponent.nameRequired' },
			{ type: 'invalidName', message: 'registerComponent.nameInvalidString' },
			{ type: 'maxlength', message: 'registerComponent.maxLengthRequired' },
		],
		mobilephone: [
			{ type: 'required', message: 'registerComponent.mobilePhoneRequired' },
			{ type: 'invalidNumber', message: 'registerComponent.invalidPhoneNo' },
			{ type: 'minlength', message: 'registerComponent.invalidPhoneNo' },
			{ type: 'invalidString', message: 'registerComponent.invalidPhoneNo' },
		],
		repeatPassword: [
			{ type: 'required', message: 'registerComponent.repeatPasswordRequired' },
			{ type: 'invalidString', message: 'registerComponent.repeatPasswordInvalidString' },
			{ type: 'invalidPassword', message: 'registerComponent.repeatPasswordInvalid' }
		],
		companyRegistrationNumber: [
			{ type: 'required', message: 'registerComponent.compRegistrationNoRequired' },
			{ type: 'invalidString', message: 'registerComponent.compRegistrationNoInvalidString' },
		],
		location: [
			{ type: 'required', message: 'registerComponent.locationRequired' },
		],
		selectIntrestList: [
			{ type: 'required', message: 'registerComponent.intrestsRequired' }
		],
		vatNumber: [
			{ type: 'required', message: 'companyDetailsComponent.vatNumberRequired' },
			{ type: 'invalidString', message: 'companyDetailsComponent.vatNumbervalid' },
			{ type: 'invalidNumber', message: 'companyDetailsComponent.vatNumbervalid' },
			{ type: 'minlength', message: 'companyDetailsComponent.vatNumbervalid' }

		],
		crNumber: [
			{ type: 'required', message: 'companyDetailsComponent.crNumberRequired' },
			{ type: 'invalidString', message: 'companyDetailsComponent.crNumberValid' },
			{ type: 'invalidNumber', message: 'companyDetailsComponent.crNumberValid' },
			{ type: 'minlength', message: 'companyDetailsComponent.vatNumbervalid' }

		],
		companyCRNoName: [
			{ type: 'required', message: 'companyDetailsComponent.companyCRNoNameError' },
		],
		vatCertificateNoName: [
			{ type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },

		],
		nationalAddressFile: [
			{ type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },
		],
		nationalId: [
			{ type: 'required', message: 'companyDetailsComponent.nationalIdError' },
			{ type: 'invalidNationalId', message: 'companyDetailsComponent.invalidNationalIdError' },
		]
	}

	registerFromGroup: FormGroup;
	currentLang;
	userData: any;
	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		public navCtrl: NavController,
		public router: Router,
		public platform: Platform,
		private translate: TranslateService,
		private route: ActivatedRoute,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.currentLang = this.translate.currentLang;

	}

	ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params && params["data"]) {

				console.log(this.commonService.decryptData(params["data"]));
				this.userData = JSON.parse(this.commonService.decryptData(params["data"]));

				console.log(this.userData);
				if (this.userData) {
					if (new Date(this.userData.expiryDate) < new Date()) {
						this.toastService.displayToast(this.translate.instant('msg.expriyLinkMsg'), null, 'danger');
						this.router.navigate(['/' + this.translate.currentLang + '/login']);
					}
					this.registerFromGroup = this.initializeRegisterFromGroup(this.userData);
				}

			}
			else {
				this.router.navigate(['/' + this.translate.currentLang + '/login']);
			}
		});
	}

	registrationCheckbox = [];


	selectCheckbox(item) {
		if (item["isChecked"] === false) {
			item["isChecked"] = true;
			this.isCheckedList.push(item);
			this.selectedCategory.push(item.value);
		} else {
			item["isChecked"] = false;
			this.isCheckedList.splice(this.isCheckedList.indexOf(item), 1);
			this.selectedCategory.splice(this.selectedCategory.indexOf(item.value), 1);
		}
		if (this.isCheckedList.length === 0) {
			this.f1.selectIntrestList.setErrors({ 'required': true });
		}

	}


	openLoginModal() {
		this.navCtrl.navigateForward('/' + this.currentLang + '/login');
	}

	get f1() { return this.registerFromGroup.controls; }

	register() {

		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (this.termsCheck != true) {
			this.toastService.displayToast(this.translate.instant('termConditionMsg'), null, 'danger');
			return;
		}
		if (this.registerFromGroup.valid) {
			const params = {
				"username": this.registerFromGroup.controls.email.value ? this.registerFromGroup.controls.email.value.trim() : null,
				"password": this.registerFromGroup.controls.password.value,
				"confirm_password": this.registerFromGroup.controls.repeatPassword.value,
			}

			const encryptedData = this.commonService.encryptData(JSON.stringify(params));

			this.subscription.add(
				this.dataService.post(URLS.setPassword, { data: encryptedData }).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.openLoginModal();
						}
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				})
			)

		} else {
			return;
		}
	}


	initializeRegisterFromGroup(data) {
		return this.formBuilder.group({
			email: new FormControl(data && data['email'] ? data['email'] : null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
			}),
			repeatPassword: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.matchPassword]),
			})
		})
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	gotoTermsConditions() {
		if (this.getPlatformService.isDesktop) {
			let options: any = {
				id: "termsWebModal",
				backdropDismiss: false,
				componentProps: { fromRegisters: true },

			};
			options['component'] = TermsOfUsePage;
			this.modalService.openModal(options)
			// this.router.navigate(['/terms-of-use']);
		}
		else {
			// this.navCtrl.navigateForward('/home/other-menu/terms-of-use', { animated: false });
			let options: any = {
				id: "addressModal",
				backdropDismiss: false,
				componentProps: { fromRegisters: true },
			};
			options['component'] = TermsOfUsePage;
			this.modalService.openModal(options)

		}
	}

}
