<ion-header class="mobile-visibility" mode="md" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBack()">
			<ion-title>{{"cmsComponent.aboutUs" | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content>
	<div class="detail-page">
		<div class="cms-wrapper">
			<div (click)="elementClicked($event)"
				*ngIf="pageData && pageData.content_en && commonService.domDirectionLTR"
				[innerHTML]="pageData.content_en | safe :'html'"></div>
			<div (click)="elementClicked($event)"
				*ngIf="pageData && pageData.content_ar && !commonService.domDirectionLTR"
				[innerHTML]="pageData.content_ar | safe :'html'"></div>
		</div>
	</div>

	<div class="wrapper-block home-page" *ngIf="getPlatformService.isDesktop">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>