<ion-header class="account-menu-header" mode="md">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="account-menu-header">
				{{"accountMenuComponent.title" | translate}}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>
<div class="mobile-menu">
	<div class="user-info-container">
		<div class="user-details" *ngIf="isLoggedIn && !isLoading">
			<div class="user-name">
				{{username}}
			</div>
			<div class="user-email">
				{{useremail}}
			</div>
		</div>
		<div class="user-details" *ngIf="isLoggedIn && isLoading">
			<div class="user-name">
				<ion-skeleton-text [animated]="true"></ion-skeleton-text>
			</div>
			<div class="user-email">
				<ion-skeleton-text [animated]="true"></ion-skeleton-text>
			</div>
		</div>
		<div class="connect-to-account" *ngIf="!isLoggedIn">
			<div class="connect-label">
				{{'connectLabel' | translate}}
			</div>
			<div class="connect-description">
				{{'connectDescription' | translate}}
			</div>
			<div class="buttons-container">
				<ion-button fill="clear" class="connect-buttons login-btn" (click)="openLoginModal()">
					{{buttonLabel.login | translate}}
					<span class="arrowIcon">
						<img src="./assets/icon/redRightArrow.svg"
							[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'" alt="right-arrow"
							slot="end">
					</span>
				</ion-button>
			</div>

		</div>
	</div>
	<div class="menu-list">
		<ion-list>
			<ng-container *ngFor="let menu of menuOptions;index as i">
				<ion-item class="outer-item" lines='none' *ngIf="menu.is_enable && menuDisplay(menu)"
					(click)="openModal(menu.id)">
					<img [src]="menu.icon_url" alt="right-arrow" slot="start" class="menu-icon">
					<ion-item class="inner-item" lines="none"
						[ngClass]="i !=  menuOptions.length -1 ? 'bottom-border' : ''">
						<ion-label class="menu-list-label">
							{{menu.menu_name | translate}}
						</ion-label>
						<img src="./assets/icon/rightArrow.svg" alt="right-arrow" slot="end"
							[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
					</ion-item>
				</ion-item>
			</ng-container>
		</ion-list>
	</div>
</div>