<div class="add-review-container pt-30">
	<div>
		<div class="text-right" [ngClass]="commonService.domDirectionLTR ? 'modal-close' : 'modal-close-rtl'"
			(click)="closeModal()">
			<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
		</div>
		<div class="add-review-header ph-30" [ngClass]="mobileClasses ? 'ion-text-center':''">
			{{isCancel ? ("cancelProductComponent.cancelProduct" | translate) :("cancelProductComponent.returnProduct"
			| translate)}}
		</div>
		<div class="divider-line mt-15" *ngIf="mobileClasses"></div>
		<div class="rating-container pt-15 pb-15 ph-30" [ngClass]="mobileClasses ? 'ion-text-center':''">
			{{isCancel? ("cancelProductComponent.cancelMessage" | translate) :("cancelProductComponent.returnMessage" |
			translate)}} <span class="product-name">{{commonService.domDirectionLTR
				? orderData && orderData['product_name_en'] ? orderData['product_name_en'] : '': orderData &&
				orderData['product_name_ar'] ? orderData['product_name_ar'] : ''}}</span>{{commonService.domDirectionLTR
			?
			'?':'؟'}}
		</div>
		<form [formGroup]="cancelForm">
			<div class="common-text-area review-title pb-15 ph-30">
				<ion-item lines="none" mode="md">
					<ion-label position="floating">{{"cancelProductComponent.chooseReason" | translate}}<sup
							class="required-star">*</sup></ion-label>
					<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
						[interface]="getPlatformService.isDesktop ? 'popover':'alert'" formControlName="reason">
						<ion-select-option *ngFor="let reason of reasonsList"
							[value]="reason.id">{{commonService.domDirectionLTR
							? reason.name : reason.name_ar}}</ion-select-option>
					</ion-select>
				</ion-item>
				<ng-container
					*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'reason', 'formName': cancelForm} }">
				</ng-container>
			</div>
			<div class="common-text-area ph-30">
				<ion-item lines="none" mode="md">
					<ion-label position="floating">{{"cancelProductComponent.reasonInDetail" | translate}}<sup
							class="required-star">*</sup></ion-label>
					<ion-textarea [rows]="rows" maxlength="250" formControlName="reasonDesc"></ion-textarea>
				</ion-item>
				<ng-container
					*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'reasonDesc', 'formName': cancelForm} }">
				</ng-container>
			</div>
		</form>
		<ng-template let-formObject #validationMessages>
			<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
				<ion-text color="danger" class="error-msg"
					*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
					{{validation.message | translate}}
				</ion-text>
			</ng-container>
		</ng-template>
	</div>
	<div class="footer-button-container pt-40 ph-30 pb-30" *ngIf="mobileClasses">
		<div class="footer-button">
			<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
				title="{{'cancelProductComponent.submit' | translate}}" [isIcon]="false" [isHideInMobile]="false"
				[specificWidth]="'100%'" [specificHeight]="'46px'" [specificFontSize]="'0.9375rem'"
				(buttonClicked)="submit()" [buttonDisalbed]="isLoading">
			</app-common-button>
		</div>
	</div>
</div>
<ion-footer *ngIf="!mobileClasses">
	<div class="footer-button-container">
		<div class="cancel" (click)="closeModal()">
			{{ 'Cancel' | translate }}
		</div>
		<div class="footer-button">
			<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
				title="{{'cancelProductComponent.submit' | translate}}" [isIcon]="false" [isHideInMobile]="false"
				[specificWidth]="'122px'" [specificHeight]="'46px'" [specificFontSize]="'0.9375rem'"
				(buttonClicked)="submit()" [buttonDisalbed]="isLoading">
			</app-common-button>
		</div>
	</div>
</ion-footer>