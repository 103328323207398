import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-return-order-mobile-filter',
	templateUrl: './return-order-mobile-filter.component.html',
	styleUrls: ['./return-order-mobile-filter.component.scss'],
})
export class ReturnOrderMobileFilterComponent implements OnInit {
	maxDate: any;
	orderStatus = 1;
	selectedStatus: any;
	fromDate: any;
	toDate: any;
	maxFromDate: any;
	statusList = [];
	todaysDate = new Date();
	statusInterface = { cssClass: 'filterby-dropdown' };
	dateRange: FormGroup;
	filterObj = {};
	fromOrdersList = false;
	fromQuotesList = false;
	constructor(
		private modalCtrl: ModalController,
		private datePipe: DatePipe,
		private toastService: ToastService,
		public commonService: CommonService,
		private fb: FormBuilder,
	) { }

	ngOnInit() {
		let date = new Date();
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
		})
		if (this.filterObj) {
			delete this.filterObj['search'];
			if (this.filterObj['status']) {
				this.selectedStatus = this.filterObj['status'];
			}
			if (this.filterObj['from_date']) {
				this.fromDate = new Date(this.filterObj['from_date']);
				this.dateRange.controls.from.setValue(this.fromDate)
			}
			if (this.filterObj['to_date']) {
				this.toDate = new Date(this.filterObj['to_date']);
				this.dateRange.controls.to.setValue(this.toDate)
			}
		}
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
	}

	apply() {
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'From Date'." : 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, 'danger');
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'To Date'." : 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, 'danger');
			}
			return
		}
		if (this.fromDate && this.toDate) {
			this.filterObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.filterObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		if (this.filterObj['from_date'] && this.filterObj['to_date']) {
			if (this.filterObj['from_date'] > this.filterObj['to_date']) {
				let msg = this.commonService.domDirectionLTR ? "'From Date' cannot be greater than 'To Date'." : 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, 'danger');
				return
			}
		}
		let isValid = false;
		if (isValid && this.filterObj['from_date'] && this.filterObj['to_date'] && (this.filterObj['from_date'] == this.filterObj['to_date'])) {
			let msg = this.commonService.domDirectionLTR ? "'From Date' and 'To Date' cannot be same." : 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, 'danger');
			return
		}
		if (this.selectedStatus) {
			this.filterObj['status'] = this.selectedStatus;
		}
		this.modalCtrl.dismiss(this.filterObj);
	}

	clear() {
		this.filterObj = {};
		this.modalCtrl.dismiss(this.filterObj);
	}

	closeModal() {
		this.modalCtrl.dismiss();
	}

	onDateChange(datetime) {
		// datetime.cancel(true);
	}
}
