<div class="add-review-container pt-20">
	<div class="header-line">
	</div>
	<div class="modal-close ion-text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%" />
	</div>
	<div class="add-review-header">
		{{'rfqBtntext' | translate}}
	</div>
	<div class="horizontal-line"></div>
</div>
<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="!isLoading">
	<div class="content-div">

		<!------------------------- mobile product ------------------------ -->
		<div>
			<ion-row class="p-0">
				<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9"
					*ngIf="quoteData.result && quoteData.result.length" class="p-0">

					<div>
						<div *ngIf="quoteData && quoteData.result && quoteData.result.length">
							<div *ngFor="let data of quoteData.result;let i =index">

								<ion-item-sliding *ngFor="let item of data.products;let j =index" class="p-0 p-sudo-0">
									<ion-item lines="none" mode="md" class="p-0 p-sudo-0">
										<ion-row class="mt-10">
											<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2"
												class="ph-0">
												<div class="mobile-cart-product-card">
													<img *ngIf="item.product_cover_image"
														(click)="goToProductDetail(item)"
														src="{{item.product_cover_image[0].image_url}}"
														class="product-img pointer" alt="Jazeera" />
													<img *ngIf="!item.product_cover_image"
														(click)="goToProductDetail(item)"
														src="./assets/images/empty-img.png" class="product-img pointer"
														alt="Jazeera" />
													<div *ngIf="item.is_sample_product == true" class="sample-div"
														[ngClass]=" commonService.domDirectionLTR ? '':'sample-rtl'">
														<p>{{'SAMPLE'|translate}}</p>
													</div>
													<div *ngIf="item.discount_type &&  item.discount_type != 'PRICE' && !item.is_sold_out && !item.is_sample_product"
														[ngClass]=" commonService.domDirectionLTR ? '':'sample-rtl'"
														class="discount-mob-div">
														<span>-</span>{{ item.discount_value }}<span>%</span>
													</div>
													<div *ngIf="item.discount_type &&  item.discount_type == 'PRICE' && !item.is_sold_out && !item.is_sample_product"
														[ngClass]=" commonService.domDirectionLTR ? '':'sample-rtl'"
														class="discount-mob-div">
														<span>{{"OnSale" | translate}}</span>
													</div>
													<div class="pl-15 width-100">
														<p class="label-3 color-app-black pointer"
															(click)="goToProductDetail(item)">
															{{commonService.domDirectionLTR ?
															item.product_name_en: item.product_name_ar}}</p>

														<p class="label-5 color-app-grey "
															*ngFor="let config of item.product_configuration">
															{{commonService.domDirectionLTR ?
															config.configuration_name_en:
															config.configuration_name_ar}}:
															<span class="color-app-black mh-5">
																{{commonService.domDirectionLTR ?
																config.configuration_value_en:
																config.configuration_value_ar}}</span>
														</p>


														<p class="label-5 color-app-grey border-div estimated-delivery"
															*ngIf="item.estimated_delivery_date">
															{{'cartPage.EstimatedDelivery' | translate}}: <span
																class="color-app-grey mh-5 estimated-date">
																{{item.estimated_delivery_date}}</span></p>

														<p class="label-5 color-app-grey border-div estimated-delivery"
															*ngIf="item.delivery_days">
															{{'cartPage.EstimatedDelivery'|translate}}: <span
																class="color-app-grey mh-5 estimated-date">
																{{item.delivery_days}} {{item.delivery_days > 1
																?('cartPage.Days'|translate) :
																('cartPage.Day'|translate)}}</span></p>

													</div>

													<div>
														<div><img src="/assets/icon/trash.svg" class="delete-icon-mob"
																(click)="deleteQuote(item,j)" alt="Jazeera"></div>
													</div>
												</div>
											</ion-col>

											<ion-row class="row-div ph-0">
												<div class="qty-div">
													<div class="display-flex">
														<ion-input maxlength="5" (keypress)="isnumeric($event)"
															type="text" inputmode="number" pattern="[0-9]*"
															(ionInput)="quantityInput($event)"
															(change)="qtychange('enter',item,data)"
															autocomplete="new-password" class="registration-pass"
															clearOnEdit="false" [(ngModel)]="item.total_quantity">
														</ion-input>
														<div class="div-up-down mr-10" *ngIf="!item.is_sample_product">
															<ion-icon
																class="feather color-app-grey icon-chevron-up pointer"
																[ngClass]="item.total_quantity >= item.available_quantity ? 'cursor-disable':''"
																(click)="qtychange('add',item,data)"></ion-icon>
															<ion-icon
																class="feather color-app-grey icon-chevron-down pointer"
																[ngClass]="item.total_quantity <= item.min_quantity? 'cursor-disable':''"
																(click)="qtychange('minus',item,data)"></ion-icon>
														</div>
													</div>
													<span class="label-2 color-app-grey text-right">
														{{commonService.domDirectionLTR
														?item.unit_name_en:item.unit_name_ar}}</span>
												</div>
												<p class="label-4 color-app-black">X</p>
												<p class="label-3 color-app-black">
													{{ commonService.currencySymbol }}{{item.final_price_per_unit |
													number}}
													<span *ngIf="item.unit_name_en && !item.is_sample_product"
														class="label-6 color-app-grey">/{{commonService.domDirectionLTR
														?
														item.unit_name_en:item.unit_name_ar}}</span>
												</p>
												<span *ngIf="item.total_quantity > item.available_quantity"
													class="error-txt w-160">{{"quantityError" | translate}}
													{{item.available_quantity}}</span>
											</ion-row>
											<ion-row class="row-div ph-0 width-100">
												<p class="label-4 color-app-black pv-10 float-end width-100">
													{{commonService.currencySymbol}}{{item.total_price|number}}</p>
											</ion-row>

											<div class="divider-line mv-10"></div>
										</ion-row>
									</ion-item>
									<ion-item-options [side]="'end'">
										<button ion-button class="btn-delete">
											<div class="icon-wrap" (click)="deleteQuote(item,j)">
												<div><img src="/assets/icon/trash-white.svg" class="delete-icon"
														alt="Jazeera"></div>
											</div>
										</button>
									</ion-item-options>
								</ion-item-sliding>
							</div>
						</div>

					</div>
				</ion-col>


				<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="ph-0"
					*ngIf="quoteData.result && quoteData.result.length">

					<div *ngIf="quoteData.grand_total">
						<app-order-summary currency="{{commonService.currencySymbol}}"
							orderTotal="{{quoteData.grand_total.total_price}}"
							[shippingEliminated]="quoteData.is_shipping_cost_eliminated"
							[eliminatedRates]="quoteData.grand_total"
							orderSubTotal="{{quoteData.grand_total.items_sub_total}}"
							orderDiscount="{{quoteData.grand_total.coupon_discounted_value}}"
							discountCode="{{appliedCouponCode ? appliedCouponCode : null}}"
							orderVAT="{{quoteData.grand_total.vat}}"
							orderShipping="{{quoteData.grand_total.shipping_charges}}"
							orderShippingWithoutVat="{{quoteData.grand_total.shipping_charges_without_vat}}"
							shippingVat="{{quoteData.grand_total.shipping_vat_charges}}"
							vatPercent="{{quoteData.grand_total.vat_percentage}}" isTotalSummary="true">
						</app-order-summary>
					</div>
				</ion-col>

			</ion-row>

			<div *ngIf="quoteData.result && !quoteData.result.length">
				<div class="vertical_center">
					<img src="./assets/icon/emptyCart.svg" alt="Jazeera" class="mb-30" />
					<div class="mb-20">
						<p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
					</div>
				</div>
			</div>
		</div>

		<!-- ------------- for shipping -------------- -->
		<div class="common-input mb-10">
			<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep1.Shippingaddress' | translate}}</p>

			<ion-item lines="none" mode="md">
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
					placeholder="{{'rfqModal.selectAddress'|translate}}" [interface]="alert"
					[interfaceOptions]="{ 'header': ('rfqModal.selectAddress' | translate) , cssClass: 'rfq-dropdown'}"
					[(ngModel)]="defalutShippingAddressId" class="pt-0 pb-0 width-100 ml-10"
					(ionChange)="shippingChnage($event)">
					<ion-select-option *ngFor="let data of shipping_address" [value]="data.address_id">
						{{data.address}},
						{{commonService.domDirectionLTR ? data.city_en : data.city_ar}},
						{{commonService.domDirectionLTR ? data.region_en : data.region_ar}},
						{{commonService.domDirectionLTR ? data.country_en : data.country_ar}} -
						{{data.zipcode}}
					</ion-select-option>
				</ion-select>
			</ion-item>
		</div>

		<!-- -------------- for email ------------------ -->
		<div class="common-input mb-10 width-100" *ngIf=" value == 'mail' || from == 'list'">
			<p class="title3 color-app-black mv-20-30">{{ 'rfqModal.addEmail' | translate}}</p>

			<div class="pt-0 pb-0 width-100">
				<ion-item lines="none" mode="md">
					<ion-label position="floating">{{'Email' | translate}}
					</ion-label>
					<ion-input [(ngModel)]="email" #emailInput (ionInput)="emailInputMethod($event)"></ion-input>
				</ion-item>
				<ng-container>
					<ion-text color="danger" class="error-msg" *ngIf="errorMsg">
						{{ errorMsg| translate }}
					</ion-text>
				</ng-container>
			</div>
		</div>

		<!-- ----------------- Button ---------------------- -->
		<div class="login-bottom-container login-bottom-mobile">
			<div class="login-button" *ngIf="from != 'list'">
				<app-common-button color="#FFFFFF" background="#0081CA"
					title="{{ value == 'mail' ? ('rfqModal.confirmSend'|translate) : ('rfqModal.confirmDownload'| translate)}}"
					[isIcon]="false" [isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
					[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="onSubmit(value)">
				</app-common-button>
			</div>

			<div class="login-button mb-20" *ngIf="from == 'list'">
				<app-common-button color="#FFFFFF" background="#0081CA" title="{{'rfqModal.confirmSend'|translate}}"
					[isIcon]="false" [isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
					[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="onSubmit('mail')">
				</app-common-button>
			</div>

			<div class="login-button" *ngIf="from == 'list'">
				<app-common-button color="#FFFFFF" background="#0081CA"
					title="{{'rfqModal.confirmDownload'| translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'48px'" [specificFontWeight]="'bold'" [specificFontSize]="'0.9rem'"
					[isFullWidth]="true" (buttonClicked)="onSubmit('pdf')">
				</app-common-button>
			</div>
		</div>
	</div>
</ion-content>