import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { CancelProductComponent } from '../cancel-product/cancel-product.component';
@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	invoiceId: any;
	isReordering: boolean = false;
	private subscription: Subscription = new Subscription();
	currentLang;
	constructor(
		private modalService: ModalService,
		private route: ActivatedRoute,
		private router: Router,
		private dataService: ApiService,
		private toastService: ToastService,
		public getPlatformService: GetPlatformService,
		private navCtrl: NavController,
		private cartService: CartService,
		public commonService: CommonService,
		public translate: TranslateService) {
		this.currentLang = this.translate.currentLang;
	}

	orderData = {
		order_no: '-',
		order_date: '',
		order_status: 'CONFIRMED',
		shipping_address: '',
		sub_total: '',
		shipping_charges: '',
		shipping_charges_without_vat: '',
		shipping_vat_charges: '',
		total_price: '',
		items_sub_total: '',
		vat: '',
		vat_percent: '15',
		discount_price: '',
		discount_code: '',
		mobile_no: '',
		city_en: '',
		city_ar: '',
		region_ar: '',
		region_en: '',
		country_ar: '',
		country_en: '',
		zipcode: '',
		is_cancellation_allowed: '',
		is_return_allowed: '',
		grand_total: ''
	};
	supplierData = [];

	ngOnInit() {
		this.invoiceId = this.route.snapshot.paramMap.get('id');
		this.getOrderDetails();
	}

	ionViewWillEnter() {
		this.invoiceId = this.route.snapshot.paramMap.get('id');
		this.getOrderDetails();
	}

	getOrderDetails() {
		if (!this.invoiceId) return;
		const params = {
			invoice_id: this.invoiceId
		}
		this.subscription.add(
			this.dataService.get(URLS.orderDetails, params).subscribe(data => {
				if (data && data['code'] == 200) {
					if (data['data']) {
						this.orderData = {
							order_no: data['data']['order_no'] ? data['data']['order_no'] : '-',
							order_date: data['data']['order_date'] ? data['data']['order_date'] : '',
							order_status: data['data']['order_status'] ? data['data']['order_status'] : 'CONFIRMED',
							shipping_address: data['data']['shipping_address'] ? data['data']['shipping_address'] : '',
							sub_total: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'items_sub_total')?.value : 0,
							items_sub_total: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'items_sub_total')?.value : 0,
							shipping_charges: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_charges')?.value : 0,
							shipping_charges_without_vat: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_charges_without_vat')?.value : 0,
							shipping_vat_charges: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'shipping_vat_charges')?.value : 0,
							total_price: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'total_price')?.value : 0,
							vat: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'vat')?.value : 0,
							vat_percent: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'vat_percentage')?.value : '15',
							discount_price: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'total_discount')?.value : 0,
							discount_code: data['data']['grand_total'] ? data['data'].grand_total.find(item => item.key === 'coupon_code')?.value : 0,
							mobile_no: data['data']['mobile_no'] ? data['data']['mobile_no'] : '',
							city_en: data['data']['city_en'] ? data['data']['city_en'] : '',
							city_ar: data['data']['city_ar'] ? data['data']['city_ar'] : '',
							region_ar: data['data']['region_ar'] ? data['data']['region_ar'] : '',
							region_en: data['data']['region_en'] ? data['data']['region_en'] : '',
							country_ar: data['data']['country_ar'] ? data['data']['country_ar'] : '',
							country_en: data['data']['country_en'] ? data['data']['country_en'] : '',
							zipcode: data['data']['zipcode'] ? data['data']['zipcode'] : '',
							is_cancellation_allowed: data['data']['is_cancellation_allowed'] ? data['data']['is_cancellation_allowed'] : null,
							is_return_allowed: data['data']['is_return_allowed'] ? data['data']['is_return_allowed'] : null,
							grand_total: data['data']['grand_total'] ? data['data']['grand_total'] : null
						}
						this.supplierData = data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
					}

				} else {
					this.navCtrl.navigateBack(['/' + this.currentLang + '/home/account-details/my-orders'])
				}
			}, error => {
				this.navCtrl.navigateBack(['/' + this.currentLang + '/home/account-details/my-orders'])

			})
		)
	}

	openCancelProductModal(data, returnProduct) {
		let options: any = {
			id: "orderDetailModal",
			backdropDismiss: false,
			componentProps: { orderData: data, invoiceId: this.invoiceId, isCancel: returnProduct ? false : true },
		};
		options['component'] = CancelProductComponent;
		let modal: any = this.modalService.openModal(options);
		modal.then((data) => {
			if (data && data['data'] && data['data']['data']) {
				this.getOrderDetails();
			}

		})

	}

	reorder() {
		if (this.isReordering) {
			return
		}
		let promiseArray = [];
		this.supplierData.forEach(element => {
			if (element.products && element.products.length) {
				element.products.forEach(product => {
					this.isReordering = true;
					promiseArray.push(
						this.cartService.addIntoCart(product, product.total_quantity, product.is_sample_product, true)
					)
				});
			}
		});

		Promise.all(promiseArray).then(data => {
			this.isReordering = false;
			this.router.navigate(['/' + this.translate.currentLang + '/cart'])
		}, err => {
			this.isReordering = false;
			this.router.navigate(['/' + this.translate.currentLang + '/cart'])

		})
	}

	orderStatus(orderData, productLevel?) {
		let status = orderData.shipping_status ? orderData.shipping_status : orderData.shipping_status;
		switch (status) {
			case 'DISPATCHED':
			case 'CONFIRMED':
				if (productLevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
			case 'DELIVERED':
				if (productLevel) {
					return 'btn-black product-status-btn';
				} else {
					return 'btn-black';
				}
			case 'NOT_YET_DELIVERED':
			case 'NOT_DELIVERED':
			case 'CANCELLED':
				if (productLevel) {
					return 'btn-red product-status-btn';
				} else {
					return 'btn-red';
				}
			default:
				if (productLevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
		}
	}

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let subClassNameURL = this.commonService.trimUrl(data.display_sub_class_name_en ? data.display_sub_class_name_en : data.display_sub_class_name_en);
		this.navCtrl.navigateForward('/' + this.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
			{
				animated: false,
				"state": {
					"breadcrumb": data.product_name_en,
					"url": "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id,
				}
			});
	}

	calculateSUOM(ratio, quantity) {
		if (ratio && quantity) {
			return ratio * (+quantity);
		} else {
			return ratio;
		}
	}
}
