import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

	@Input() priceRange;
	@Input() maximumPrice: any = 0;
	@Input() subClassDatas = [];
	@Input() regionDatas = [];
	@Input() brandDatas = [];
	@Input() supplierDatas = [];
	@Input() classDatas = [];
	@Input() isAccordianDisabled: boolean = false;
	@Output() callFilterData = new EventEmitter();
	@Output() filterByData = new EventEmitter();
	private subscription: Subscription = new Subscription();
	lowerRangeValue: any;
	appliedPriceRange: any;
	upperRangeValue: any;
	minimunPrice = 0;
	all = true;
	isPriceOpen = false;
	isSupplierOpen = false;
	isRegionOpen = false;
	isBrandOpen = false;
	isCategoryOpen = false;
	subClass = true;
	price = true;
	classValue = ['first', 'second', 'third', 'fourth', 'fifth']
	region = true;
	showAccordian = true;
	isClassChecked = false;
	brand = true;
	class = true;
	supplier = true;
	maxPrice: any;
	minPrice: any;
	filterBy = [];
	filtersubClass = [];
	filterClass = [];
	filterSubClassData = [];
	filterClassData = []
	filterRegionData = [];
	filterSupplierData = [];
	filterBrandData = [];
	filterRegion = [];
	filterBrand = [];
	filterSupplier = [];
	selectedFilters = [];
	@Input() subClassCheckbox = [];
	@Input() brandCheckbox = [];
	@Input() supplierCheckbox = [];
	@Input() regionCheckboxes = [];
	@Input() classCheckbox = [];
	selectedClass = [];
	dataRangePin: boolean = true;
	dateRangeDisable: boolean = false;
	public filterValue: any;
	filterOptions = [
		{
			filterName: 'class',
			label: 'category',
			isOpen: false,
			value: 'class',
			checkboxes: this.classCheckbox,
			filterTitle: "Select Category",
			filterTitle_ar: "اختر الفئة",
			inList: true,
			selected: '',
		},
		{
			filterName: 'subClass',
			label: 'subcategory',
			isOpen: false,
			value: 'subClass',
			checkboxes: this.subClassCheckbox,
			filterTitle: "Select Sub Category",
			filterTitle_ar: "اختر الفئة الفرعية",
			inList: true,
			selected: '',
		},
		{
			filterName: 'priceRange',
			label: "priceRange",
			isOpen: false,
			value: 'price',
			checkboxes: [],
			filterTitle: "Select Price",
			filterTitle_ar: "حدد السعر",
			inList: false,
			selected: ''
		},
		// code maybe required
		// {
		//     filterName: 'region',
		//     isOpen: false,
		//     value: 'region',
		//     checkboxes: this.regionCheckboxes,
		//     filterTitle: "Select Region",
		//     inList: true,
		//     selected: ''
		// },
		// {
		// 	filterName: 'brand',
		// 	isOpen: false,
		// 	value: 'brand',
		// 	checkboxes: this.brandCheckbox,
		// 	filterTitle: "Select Brand",
		// 	filterTitle_ar: "حدد العلامة التجارية",
		// 	inList: true,
		// 	selected: ''
		// },
		// {
		// 	filterName: 'supplier',
		// 	isOpen: false,
		// 	value: 'supplier',
		// 	checkboxes: this.supplierCheckbox,
		// 	filterTitle: "Select Supplier",
		// 	filterTitle_ar: "حدد المورد",
		// 	inList: true,
		// 	selected: ''
		// }
	];
	title = "selectCategory";
	currentLang = '';
	@ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;
	constructor(private modalCtrl: ModalController,
		public commonService: CommonService,
		private dataService: ApiService,
		public getPlatformService: GetPlatformService,
		private navCtrl: NavController, public translate: TranslateService) {
		this.currentLang = this.commonService.currentLang;
	}

	ngOnInit() {
		let filterOption = JSON.parse(this.commonService.localStorageGet("filter_options"));
		if (!this.getPlatformService.isDesktop) {
			this.filterOptions = [
				{
					filterName: 'class',
					label: "category",
					isOpen: false,
					value: 'class',
					checkboxes: this.classCheckbox,
					filterTitle: "Select Class",
					filterTitle_ar: "اختر مجموعة",
					inList: true,
					selected: '',
				},
				{
					filterName: 'priceRange',
					label: 'priceRange',
					isOpen: false,
					value: 'price',
					checkboxes: [],
					filterTitle: "Select Price",
					filterTitle_ar: "حدد السعر",
					inList: false,
					selected: ''
				},
				{
					filterName: 'subClass',
					label: 'subcategory',
					isOpen: false,
					value: 'subClass',
					checkboxes: this.subClassCheckbox,
					filterTitle: "Select Categories",
					filterTitle_ar: "حدد الفئات",
					inList: true,
					selected: '',
				},
				// code maybe required
				// {
				//     filterName: 'region',
				//     isOpen: false,
				//     value: 'region',
				//     checkboxes: this.regionCheckboxes,
				//     filterTitle: "Select Region",
				//     inList: true,
				//     selected: ''
				// },
				// {
				// 	filterName: 'brand',
				// 	isOpen: false,
				// 	value: 'brand',
				// 	checkboxes: this.brandCheckbox,
				// 	filterTitle: "Select Brand",
				// 	filterTitle_ar: "حدد العلامة التجارية",
				// 	inList: true,
				// 	selected: ''
				// },
				// {
				// 	filterName: 'supplier',
				// 	isOpen: false,
				// 	value: 'supplier',
				// 	checkboxes: this.supplierCheckbox,
				// 	filterTitle: "Select Supplier",
				// 	filterTitle_ar: "حدد المورد",
				// 	inList: true,
				// 	selected: ''
				// }
			];
		}

		if (!this.getPlatformService.isDesktop && filterOption) {
			this.filterSubClassData = [];
			this.filterOptions = filterOption
			this.filterOptions.forEach(element => {
				if (element.filterName == "subClass") {
					this.subClassCheckbox = element.checkboxes
				}
				if (element.filterName == "class") {
					this.classCheckbox = element.checkboxes
				}
				if (element.filterName == "brand") {
					this.brandCheckbox = element.checkboxes
				}
				if (element.filterName == "supplier") {
					this.supplierCheckbox = element.checkboxes
				}
			});

		} else {
			setTimeout(() => {
				this.classDatas.forEach(element => {
					this.classCheckbox.push({
						label: element["display_class_name_en"],
						label_ar: element["display_class_name_ar"],
						value: +element["class_id"],
						isChecked: element["isChecked"],
						class_id: element["class_id"],
						type: "class",
						filter_id: 5
					});
				});
				this.subClassDatas.forEach(element => {
					this.subClassCheckbox.push({
						label: element['display_sub_class_name_en'],
						label_ar: element['display_sub_class_name_ar'],
						subClass_image_url: element['sub_class_image_url'],
						value: +element['sub_class_id'],
						isChecked: element['isChecked'],
						type: 'subClass',
						filter_id: 1
					})
				});
				// code maybe required
				// this.regionDatas.forEach(element => {
				//     this.regionCheckboxes.push({
				//         label: element['region_en'],
				//         label_ar: element['region_ar'],
				//         value: element['region_id'],
				//         isChecked: element['isChecked'],
				//         type: 'region',
				//         filter_id: 2
				//     })
				// });
				this.brandDatas.forEach(element => {
					this.brandCheckbox.push({
						label: element['brand_name_en'],
						label_ar: element['brand_name_ar'],
						value: element['brand_id'],
						isChecked: element['isChecked'],
						type: 'brand',
						filter_id: 3
					})
				});
				this.supplierDatas.forEach(element => {
					this.supplierCheckbox.push({
						label: element['supplier_name_en'],
						label_ar: element['supplier_name_ar'],
						value: element['supplier_id'],
						isChecked: element['isChecked'],
						type: 'supplier',
						filter_id: 4
					})
				});

				let subClass_id = +this.commonService.localStorageGet("sub_class_id");
				if (subClass_id) {
					this.subClassCheckbox.forEach((element, index) => {
						if (element.value === +subClass_id && element.type == 'subClass') {
							this.filterBy.push(element);
							if (!this.filterSubClassData.includes(element.label)) {
								this.filterSubClassData.push(element.label);
								this.filtersubClass.push(+subClass_id);
							}
							this.filter();
						}
						this.filterOptions.forEach(filterElement => {
							if (filterElement.value === element.type) {
								filterElement.isOpen = true;
								this.filterValue = filterElement.value;
							} else {
								filterElement.isOpen = false;
							}
						});
					});
				}
			});
		}
		if (this.priceRange) {
			this.minPrice = this.priceRange['min_product_price'] ? Math.round(+this.priceRange['min_product_price']) : 0;
			this.maxPrice = this.priceRange['max_product_price'] ? Math.round(+this.priceRange['max_product_price']) : 100000;
			this.minimunPrice = 0;
			this.maximumPrice = this.priceRange['max_product_price'] ? Math.round(+this.priceRange['max_product_price']) : 100000;
			this.lowerRangeValue = this.priceRange['min_product_price'] ? Math.round(+this.priceRange['min_product_price']) : 0;
			this.upperRangeValue = this.priceRange['max_product_price'] ? Math.round(+this.priceRange['max_product_price']) : 100000;
		} else {
			this.getPriceRange();
		}
		if (!this.maximumPrice) {
			this.maximumPrice = 0;
		}
		if (this.minPrice == 0 && this.maxPrice == 0) {
			this.dataRangePin = false;
			this.dateRangeDisable = true;
		}
	}

	filter() {
		this.filterByData.emit({
			filter_by: this.filterBy
		})
	}

	async onClick(filter) {
		const modal = await this.modalCtrl.create({
			component: FilterComponent,
			componentProps: {
				subClass: filter.filterName == 'subClass' ? true : false,
				price: false,
				region: filter.filterName == 'region' ? true : false,
				supplier: filter.filterName == 'supplier' ? true : false,
				brand: filter.filterName == 'brand' ? true : false,
				class: filter.filterName == 'class' ? true : false,
				all: false,
				subClassCheckbox: this.subClassCheckbox,
				regionCheckboxes: this.regionCheckboxes,
				supplierCheckbox: this.supplierCheckbox,
				brandCheckbox: this.brandCheckbox,
				classCheckbox: this.classCheckbox,
				title: this.commonService.domDirectionLTR ? filter.filterTitle : filter.filterTitle_ar,
				showAccordian: this.showAccordian,
				filtersubClass: this.filtersubClass,
				filterBrand: this.filterBrand,
				filterRegion: this.filterRegion,
				filterSupplier: this.filterSupplier,
				filterClass: this.filterClass,
				filterSubClassData: this.filterSubClassData,
				filterRegionData: this.filterRegionData,
				filterBrandData: this.filterBrandData,
				filterSupplierData: this.filterSupplierData,
				filterClassData: this.filterClassData
			}
		});
		modal.present();
		modal.onWillDismiss().then((data) => {
			if (data && data['data']) {
				if (data['data'].subClass) {
					this.filtersubClass = data['data'].subClass;
					this.filterSubClassData = data['data'].filterSubClassData
					this.subClassCheckbox = data['data'].subClassCheckbox
				}
				if (data['data'].brand) {
					this.filterBrand = data['data'].brand;
					this.filterBrandData = data['data'].filterBrandData
					this.brandCheckbox = data['data'].brandCheckbox
				}
				// if (data['data'].region) {
				//     this.filterRegion = data['data'].region;
				//     this.filterRegionData = data['data'].filterRegionData
				// }
				if (data['data'].supplier) {
					this.filterSupplier = data['data'].supplier;
					this.filterSupplierData = data['data'].filterSupplierData
					this.supplierCheckbox = data['data'].supplierCheckbox
				}
				if (data['data'].class) {
					this.filterClassData = data['data'].class;
					this.filterClassData = data['data'].filterClassData
					this.classCheckbox = data['data'].classCheckbox
				}
			}
		})
	}

	selectCheckbox(event, option, checkboxOption, filter_id?) {
		event.preventDefault();
		event.stopPropagation();
		checkboxOption.forEach(element => {
			if (option.value == element.value) {
				option.isChecked = event.detail.checked;
			}
		});
		if (option.isChecked) {
			this.filterBy.push(option);
			if (filter_id == 5) {
				this.filterSubClassData = [];
				this.isClassChecked = option.isChecked;
				this.selectedClass.push({
					class_id: option.class_id,
					label: option.label
				});
			}
		}
		else {
			const index = this.filterBy.indexOf(option);
			const subClassIndex = this.filterSubClassData.indexOf(option.label);
			const regionIndex = this.filterRegionData.indexOf(option.label);
			const brandIndex = this.filterBrandData.indexOf(option.label);
			const supplierIndex = this.filterSupplierData.indexOf(option.label);
			const classIndex = this.filterClassData.indexOf(option.label);
			if (index > -1) {
				this.filterBy.splice(index, 1);
			}
			if (subClassIndex > -1) {
				this.filterSubClassData.splice(subClassIndex, 1);
			}
			if (regionIndex > -1) {
				this.filterRegionData.splice(regionIndex, 1);
			}
			if (brandIndex > -1) {
				this.filterBrandData.splice(brandIndex, 1);
			}
			if (supplierIndex > -1) {
				this.filterSupplierData.splice(supplierIndex, 1);
			}
			if (classIndex > -1) {
				this.filterClassData.splice(classIndex, 1);
			}

			console.log(option);
			console.log(checkboxOption);
			console.log(filter_id);

			if (filter_id == 5) {
				this.filterSubClassData = [];
				this.filtersubClass = [];
				this.commonService.localStorageRemove('sub_class_id');
				this.isClassChecked = option.isChecked;
				const classIndex = this.selectedClass.findIndex(obj => obj.label === option.label);
				if (classIndex > -1) {
					this.selectedClass.splice(classIndex, 1);
				}
				if (this.selectedClass.length == 0) {
					this.updateCategoryListing();
				}
			}
		}
		if (filter_id == 5) {
			let selectedClassFilter = [];
			this.filterSubClassData = [];
			this.filtersubClass = [];
			this.commonService.localStorageRemove('sub_class_id');
			this.selectedClass.forEach(element => {
				selectedClassFilter.push(element.class_id);
			});
			let selectedClassStr = selectedClassFilter.join(',');
			if (selectedClassStr) {
				this.updateCategoryListing(selectedClassStr);
			}
		}
		this.checkboxFilterSelection(filter_id, option.type);
	}

	updateCategoryListing(selectedClassStr?) {
		let params = {};
		if (selectedClassStr) {
			params['class_id'] = selectedClassStr;
		}
		this.subscription.add(
			this.dataService.get(URLS.subClassListFilter, params).subscribe(
				(data) => {
					if (data["code"] == 200) {
						let subClassListData = data["data"];
						let subClassCheckboxData = [];
						this.filtersubClass = [];
						if (subClassListData) {
							subClassListData.forEach((element) => {
								subClassCheckboxData.push({
									label: element['display_sub_class_name_en'],
									label_ar: element['display_sub_class_name_ar'],
									value: element['sub_class_id'],
									isChecked: element['isChecked'],
									type: 'subClass',
									filter_id: 1
								});
							});
						}


						this.filterOptions.forEach(filterElement => {
							if (filterElement.filterName == "subClass") {
								filterElement['checkboxes'] = subClassCheckboxData
								this.subClassCheckbox = subClassCheckboxData
							}
						});

						this.commonService.localStorageSet("filter_options", JSON.stringify(this.filterOptions))

						this.filterBy = this.filterBy.filter(obj => obj.type != "subClass");

					} else {
					}
				},
				(error) => {

				}
			)
		);
	}

	removeFilterOption(option) {

		this.filterBy = [...this.filterBy];


		this.filterBy.forEach((element, i) => {
			if (option.value === element.value) {
				this.filterBy.splice(i, 1);
			}
		});


		this.classCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.subClassCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.regionCheckboxes.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.supplierCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.brandCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.classCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		})
		this.checkboxFilterSelection();

	}
	checkboxFilterSelection(filter_id?, type?) {
		if (filter_id == 1) {
			this.filtersubClass = [];
		}
		if (filter_id == 2) {
			this.filterRegion = [];
		}
		if (filter_id == 3) {
			this.filterBrand = [];
		}
		if (filter_id == 4) {
			this.filterSupplier = [];
		}
		if (filter_id == 5) {
			this.filterClass = [];
		}
		this.filterBy = [...this.filterBy];

		setTimeout(() => {
			this.filterBy.forEach(element => {
				if (element.type == 'subClass' && element.type == type) {
					this.filtersubClass.push(element.value);
					if (!this.filterSubClassData.includes(element.label)) {
						this.filterSubClassData.push(element.label);
					}
				}
				// code maybe required
				// if (element.type == 'region' && element.type == type) {
				//     this.filterRegion.push(element.value)
				//     if (!this.filterRegionData.includes(element.label)) {
				//         this.filterRegionData.push(element.label);
				//     }
				// }
				if (element.type == 'brand' && element.type == type) {
					this.filterBrand.push(element.value)
					if (!this.filterBrandData.includes(element.label)) {
						this.filterBrandData.push(element.label);
					}
				}
				if (element.type == 'supplier' && element.type == type) {
					this.filterSupplier.push(element.value)
					if (!this.filterSupplierData.includes(element.label)) {
						this.filterSupplierData.push(element.label);
					}
				}
				if (element.type == 'class' && element.type == type) {
					this.filterClass.push(element.value)
					if (!this.filterClassData.includes(element.label)) {
						this.filterClassData.push(element.label);
					}
				}
			});
		}, 200)


		setTimeout(() => {
			this.passFilterDatas();
			this.filter();
			if (this.filterBy.length === 0) {
				this.navCtrl.navigateForward('/' + this.currentLang + '/home/product-listing');
			}
		}, 500);

	}

	getFilterCategoryBackground(index) {
		return { 'background': index === 0 ? '#38C2D4' : (index === 1 ? '#6AD1DF' : (index === 2 ? '#9BE0E9' : (index === 3 ? '#CDF0F4' : '#e6faff'))) };
	}

	// slider end change detect
	onIonKnobMoveEnd(event) {
		event.preventDefault();
		event.stopPropagation();
		this.lowerRangeValue = event['detail']['value']['lower'];
		this.upperRangeValue = event['detail']['value']['upper'];
		this.minPrice = this.lowerRangeValue;
		this.maxPrice = this.upperRangeValue;
		setTimeout(() => {
			this.passFilterDatas()
		}, 500);
	}

	accordionGroupChange(event) {
		const selectedValue = event.detail.value;
		this.filterOptions.forEach(element => {
			if (selectedValue.includes(element.value)) {
				element.isOpen = true;
			} else {
				element.isOpen = false;
			}

		});
	}

	applyFiltersMobile() {
		this.modalCtrl.dismiss({
			rangeLower: this.lowerRangeValue,
			rangeUpper: this.upperRangeValue,
			subClass: this.filtersubClass.length == 0 ? null : this.filtersubClass,
			class: this.filterClass.length == 0 ? null : this.filterClass,
			// region: this.filterRegion.length == 0 ? null : this.filterRegion,
			brand: this.filterBrand.length == 0 ? null : this.filterBrand,
			supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
			subClassData: this.subClassCheckbox,
			regionData: this.regionCheckboxes,
			brandData: this.brandCheckbox,
			supplierData: this.supplierCheckbox,
			filterSubClassData: this.filterSubClassData,
			filterRegionData: this.filterRegionData,
			filterBrandData: this.filterBrandData,
			filterSupplierData: this.filterSupplierData,
			filterClassData: this.filterClassData,
			priceData: {
				max_product_price: this.upperRangeValue,
				min_product_price: this.lowerRangeValue,
			}
		})


		setTimeout(() => {
			this.passFilterDatas();
		}, 500);
	}

	getPriceRange() {
		this.subscription.add(
			this.dataService.get(URLS.priceRange).subscribe(data => {
				if (data['code'] == 200) {
					this.priceRange = data['data'];
					this.minPrice = data['data']['min_product_price'] ? Math.round(+data['data']['min_product_price']) : 0;
					this.maxPrice = data['data']['max_product_price'] ? Math.round(+data['data']['max_product_price']) : 0;
					this.minimunPrice = 0;
					this.maximumPrice = data['data']['max_product_price'] ? Math.round(+data['data']['max_product_price']) : 0;
					this.lowerRangeValue = data['data']['min_product_price'] ? Math.round(+data['data']['min_product_price']) : 0;
					this.upperRangeValue = data['data']['max_product_price'] ? Math.round(+data['data']['max_product_price']) : 0;
				}
				else {
					this.priceRange = [];
				}
			})
		)
	}

	clearFiltersMobile() {
		this.commonService.localStorageSet("sub_class_id", null);
		this.minPrice = this.minimunPrice;
		this.maxPrice = this.maximumPrice;
		this.lowerRangeValue = this.minPrice;
		this.upperRangeValue = this.maxPrice;
		this.filterSubClassData = [];
		this.filterBrandData = [];
		this.filterRegionData = [];
		this.filterSupplierData = [];
		this.filterClassData = [];
		this.subClassCheckbox.forEach(element => {
			element['isChecked'] = false;
		});
		this.regionCheckboxes.forEach(element => {
			element['isChecked'] = false;
		});
		this.brandCheckbox.forEach(element => {
			element['isChecked'] = false;
		});
		this.supplierCheckbox.forEach(element => {
			element['isChecked'] = false;
		});
		this.applyFiltersMobile();
	}
	goBack() {
		if (this.title === "Filter By") {
			let filtersubClass = [];
			let filterSubClassData = [];
			let subClass_id = this.commonService.localStorageGet("sub_class_id");
			if (subClass_id) {
				this.subClassCheckbox.forEach((element, index) => {
					if (element.value === +subClass_id && element.type == 'subClass') {
						if (!filterSubClassData.includes(element.label)) {
							filterSubClassData.push(element.label);
							filtersubClass.push(+subClass_id);
						}
					}
				})
			}

			this.filterBrand = [];
			this.filterSupplier = [];
			this.filterRegionData = [];
			this.filterBrandData = [];
			this.filterSupplierData = [];
			this.filterClassData = [];
			this.regionCheckboxes.forEach(element => {
				element['isChecked'] = false;
			});
			this.brandCheckbox.forEach(element => {
				element['isChecked'] = false;
			});
			this.supplierCheckbox.forEach(element => {
				element['isChecked'] = false;
			});
			this.brandDatas.forEach(element => {
				element['isChecked'] = false;
			});
			this.supplierDatas.forEach(element => {
				element['isChecked'] = false;
			});
			this.modalCtrl.dismiss({
				subClass: filtersubClass.length == 0 ? null : filtersubClass,
				// region: this.filterRegion.length == 0 ? null : this.filterRegion,
				brand: this.filterBrand.length == 0 ? null : this.filterBrand,
				supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
				class: this.filterClass.length == 0 ? null : this.filterClass,
				filterSubClassData: filterSubClassData,
				filterRegionData: this.filterRegionData,
				filterBrandData: this.filterBrandData,
				filterSupplierData: this.filterSupplierData,
				filterClassData: this.filterClassData,
				brandData: [],
				supplierData: [],
				regionData: [],
				rangeLower: this.lowerRangeValue,
				rangeUpper: this.upperRangeValue,
			})

		}
		else {
			this.modalCtrl.dismiss({
				subClass: this.filtersubClass.length == 0 ? null : this.filtersubClass,
				// region: this.filterRegion.length == 0 ? null : this.filterRegion,
				brand: this.filterBrand.length == 0 ? null : this.filterBrand,
				supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
				class: this.filterClass.length == 0 ? null : this.filterClass,
				filterSubClassData: this.filterSubClassData,
				filterRegionData: this.filterRegionData,
				filterBrandData: this.filterBrandData,
				filterSupplierData: this.filterSupplierData,
				filterClassData: this.filterClassData,
				subClassCheckbox: this.subClassCheckbox,
				regionCheckboxes: this.regionCheckboxes,
				supplierCheckbox: this.supplierCheckbox,
				brandCheckbox: this.brandCheckbox,
				classCheckbox: this.classCheckbox,
				rangeLower: this.lowerRangeValue,
				rangeUpper: this.upperRangeValue,
			})
		}

		if (this.title === "filterBy") {
			this.commonService.localStorageRemove("filter_options")
		}

		if (this.title === "Select Class") {
			this.filterSubClassData = [];
		}

	}

	rangeChange(event) {
		event.preventDefault();
		event.stopPropagation();
		this.lowerRangeValue = event['detail']['value']['lower'];
		this.upperRangeValue = event['detail']['value']['upper'];
	}

	passFilterDatas() {
		this.callFilterData.emit({
			rangeLower: this.lowerRangeValue,
			rangeUpper: this.upperRangeValue,
			subClass: this.filtersubClass.length == 0 ? null : this.filtersubClass,
			class: this.filterClass.length == 0 ? null : this.filterClass,
			// region: this.filterRegion.length == 0 ? null : this.filterRegion,
			brand: this.filterBrand.length == 0 ? null : this.filterBrand,
			supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
			subClass_data: this.filterSubClassData ? this.filterSubClassData : null
		})
	}

	getArabicText(label) {
		let arText: any;
		this.subClassCheckbox.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		this.regionCheckboxes.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		this.brandCheckbox.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		this.supplierCheckbox.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		return arText;
	}
}
