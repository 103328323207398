<ion-header *ngIf="getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="registration-desktop-header">
			{{ 'SelectVariantComponent.selectVariant' | translate }}
		</div>
		<div class="text-right" [ngClass]="commonService.domDirectionLTR ? 'modal-close' : 'modal-close-rtl'"
			(click)="closeModal()">
			<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
		</div>
	</ion-toolbar>
</ion-header>

<ion-header class="address-header" mode="md" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<ion-title mode="ios" class="ion-text-center">
			<div class="select-header">
				{{'SelectVariantComponent.selectVariant'| translate}}
			</div>
		</ion-title>

		<img src="./assets/icon/close.svg" alt="back-arrow" slot="end" (click)="closeModal()">
	</ion-toolbar>
</ion-header>

<ion-content *ngIf="getPlatformService.isDesktop">
	<div class="registration-container" *ngIf="mainProductData"
		[ngClass]="[ getPlatformService.isDesktop ? 'registration-container-desktop p-top-0': '']">

		<div class="row">
			<div class="width-40">
				<div class="product-image-container">
					<div *ngIf="discount_type &&  discount_type != 'PRICE' && !is_sold_out" class="product-discount">
						<span>-</span>{{ discount_value }}<span>%</span>
					</div>
					<div *ngIf="discount_type && discount_type == 'PRICE' && !is_sold_out" class="product-discount">
						<span>{{"OnSale" | translate}}</span>
					</div>
					<div class="product-list-image-div">
						<img *ngIf="mainProductData.product_images_web" [ngClass]="is_sold_out ? 'product-image':null"
							[src]="getPlatformService.isDesktop ? mainProductData.product_images_web : mainProductData.product_images_mobile"
							width="100%" height="100%" alt="Jazeera">
						<img *ngIf="!mainProductData.product_images_web" src="assets/images/no-img1.png" width="100%"
							height="100%" [ngClass]="is_sold_out ? 'product-image':null" alt="Jazeera">
					</div>
					<div *ngIf="is_sold_out || productNotExist" class="product-outofstock">
						<div class="outofstock-label">
							{{"outOfStock" | translate}}
						</div>
					</div>
				</div>
				<div class="product-description text-left"
					[ngClass]="[categories ? 'categories-product-description' : '']">
					<span class="text-ellipse">{{commonService.domDirectionLTR ? selected_subproduct.product_name_en :
						selected_subproduct.product_name_ar
						}}</span>
				</div>
				<div class="product-brand text-left" *ngIf="mainProductData.brand_name_en">
					<span>{{commonService.domDirectionLTR ? mainProductData.brand_name_en :
						mainProductData.brand_name_ar }}
					</span>
				</div>

				<div class="product-price text-left" *ngIf="!is_sold_out">
					<div class="from">
						{{"from" | translate}}
					</div>
					<div class="product-price-data">
						<span class="currency"
							*ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
						<span class="price">{{final_price | number}}</span>
						<span class="currency"
							*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
						<span class="unit pl-5" *ngIf="mainProductData.unit_name_en">/{{commonService.domDirectionLTR
							?mainProductData.unit_name_en : mainProductData.unit_name_ar }}
						</span>
					</div>
					<div class="product-price-data">
						<span *ngIf="is_discount_applied" class="checked-price">
							<span class="currency2"
								*ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							<span class="price2">{{product_price | number}}</span>
							<span class="currency2"
								*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
						</span>
					</div>
				</div>
			</div>
			<div>
				<div class="panel-design-desktop-container">
					<ng-container *ngFor="let data of configData;index as i">
						<div class="panel-size-container">
							<div class="panel-size pr-8">
								{{ commonService.domDirectionLTR ? data.configuration_name_en :
								data.configuration_name_ar }}
							</div>
							<div class="more-info more-info-tooltip">
								<img src="assets/icon/more_info.svg" alt="Jazeera">
								<span class="more-info-tooltiptext-left more-info-file-tooltip">{{
									commonService.domDirectionLTR ? data.configuration_name_en :
									data.configuration_name_ar }}</span>
							</div>
						</div>
						<div class="size-container pt-10">
							<ion-segment mode="md" [(ngModel)]="data['attributeValue']"
								(ionChange)="attributeChange(data)">
								<ion-segment-button class="mr-10" [value]="j"
									*ngFor="let values of data.value_array; index as j"
									(click)="designChecked(values, data.value_array)">
									<div class="size-options">
										{{ commonService.domDirectionLTR ? values['configuration_value_en'] :
										values['configuration_value_ar'] }}
									</div>
								</ion-segment-button>
							</ion-segment>
						</div>
					</ng-container>

				</div>

				<div class="qualtity-container pt-30">
					<div class="quantity-head pb-10">
						{{ "Quantity" | translate }}
					</div>
					<div class="quantity-counter-container">
						<div class="quantity-counter">
							<div class="quantity">
								<div class="add-quantity pl-15 pr-15" (click)="decrementQuantity()">
									<ion-icon *ngIf="productValue > selected_subproduct.min_quantity" alt="Jazeera"
										src="assets/icon/minus.svg"></ion-icon>
									<ion-icon *ngIf="productValue <= selected_subproduct.min_quantity" alt="Jazeera"
										src="assets/icon/minus-grey.svg"></ion-icon>
								</div>
								<div class="quantity-input">
									<ion-input [(ngModel)]="productValue" type="text" inputmode="number"
										pattern="[0-9]*" [min]="selected_subproduct.min_quantity"
										(keypress)="numericOnly($event)" [max]="available_quantity" (change)="qtySet()"
										(ionInput)="quantityInput($event)" mode="md"></ion-input>
								</div>
								<div class="subtract-quantity pl-15 pr-15" (click)="incrementQuantity()">
									<ion-icon src="assets/icon/plus.svg" alt="Jazeera"
										*ngIf="productValue < selected_subproduct.available_quantity - cart_quantity"></ion-icon>
									<ion-icon src="assets/icon/plus-grey.svg" alt="Jazeera"
										*ngIf="productValue >= selected_subproduct.available_quantity - cart_quantity"></ion-icon>
								</div>
							</div>
							<div class="quantity-unit pl-10">
								{{ commonService.domDirectionLTR ? product_unit : product_unit_ar }}
							</div>

						</div>
						<span *ngIf="minQuantErr" class="error-txt">{{"minQuantityErr" |
							translate}}
						</span>
						<span *ngIf="availableQuantErr" class="error-txt">{{"quantityError" | translate}}
						</span>
					</div>

				</div>
			</div>
		</div>


	</div>

</ion-content>

<div *ngIf="!getPlatformService.isDesktop && mainProductData" class="registration-container mobile-div-scroll">
	<div class="row">
		<div class="product-image-container mr-15">
			<div *ngIf="discount_type &&  discount_type != 'PRICE' && !is_sold_out" class="product-discount">
				<span>-</span>{{ discount_value }}<span>%</span>
			</div>
			<div *ngIf="discount_type && discount_type == 'PRICE' && !is_sold_out" class="product-discount">
				<span>{{"OnSale" | translate}}</span>
			</div>
			<div class="product-list-image-div">
				<img *ngIf="mainProductData.product_images_mobile" [ngClass]="is_sold_out ? 'product-image':null"
					[src]="getPlatformService.isDesktop ? mainProductData.product_images_web : mainProductData.product_images_mobile"
					width="100%" height="100%" alt="Jazeera">
				<img *ngIf="!mainProductData.product_images_mobile" src="assets/images/no-img1.png" width="100%"
					height="100%" [ngClass]="is_sold_out ? 'product-image':null" alt="Jazeera">
			</div>
			<div *ngIf="is_sold_out || productNotExist" class="product-outofstock">
				<div class="outofstock-label">
					{{"outOfStock" | translate}}
				</div>
			</div>
		</div>

		<div>
			<div class="product-description text-left" [ngClass]="[categories ? 'categories-product-description' : '']">
				<span class="text-ellipse">{{commonService.domDirectionLTR ? selected_subproduct.product_name_en :
					selected_subproduct.product_name_ar
					}}</span>
			</div>
			<div class="product-brand text-left" *ngIf="mainProductData.brand_name_en">
				<span>{{commonService.domDirectionLTR ? mainProductData.brand_name_en :
					mainProductData.brand_name_ar }}
				</span>
			</div>

			<div class="product-price text-left" *ngIf="!is_sold_out">
				<div class="from" *ngIf="productDetailLabel">
					{{"from" | translate}}
				</div>
				<div class="product-price-data">
					<span class="currency" *ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
					<span class="price">{{final_price | number}}</span>
					<span class="currency"
						*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
					<span class="unit pl-5" *ngIf="mainProductData.unit_name_en">/{{commonService.domDirectionLTR
						?mainProductData.unit_name_en : mainProductData.unit_name_ar }}
						<span *ngIf="is_discount_applied" class="checked-price ph-10">
							<span class="currency2"
								*ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							<span class="price2">{{product_price | number}}</span>
							<span class="currency2"
								*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>

						</span>
					</span>
				</div>
			</div>
		</div>
	</div>

	<div>
		<div class="panel-design-mobile-container panel-design-desktop-container">
			<ng-container *ngFor="let data of configData;index as i">
				<div>
					<div class="panel-size-container color-design">
						<div class="panel-size pr-8">
							{{ commonService.domDirectionLTR ? data.configuration_name_en :
							data.configuration_name_ar}}
						</div>
						<div class="more-info">
							<!-- <img src="assets/icon/more_info.svg" id="{{ 'click-trigger' + i }}"> -->
							<!-- <ion-popover showBackdrop="false" trigger="{{ 'click-trigger' + i }}"
                                                        triggerAction="click" side="right" alignment="center" [interfaceOptions]="attributePopUp">
                                                        <ng-template>
                                                            <ion-content class="ion-padding">{{
                                                                data.configuration_name_en }}</ion-content>
                                                        </ng-template>
                                                    </ion-popover> -->
							<div class="more-info more-info-tooltip">
								<img src="assets/icon/more_info.svg" (click)="showToolTip = true"
									(blur)="showToolTip = false" alt="Jazeera">
								<span class="more-info-tooltiptext-left more-info-file-tooltip" *ngIf="showToolTip">{{
									commonService.domDirectionLTR ? data.configuration_name_en :
									data.configuration_name_ar }}</span>
							</div>
						</div>
					</div>
					<div class="info-container mr-10">
						<ion-list>
							<!-- <ion-item class="sort-by-item" lines="none"
                                (click)="openDesignColorModal(data.value_array, data.configuration_id, data.selected_value, data.configuration_name_en)">
                                <div class="desing-color-popup">
                                    <ng-container>
                                        <div class="color-image mr-15">
                                            {{ data.selected_value }}
                                        </div>
                                    </ng-container>
                                </div>
                                <ion-icon slot="end" src="assets/icon/relevancy_down_arrow.svg"></ion-icon>
                            </ion-item> -->
							<!-- <ion-item class="sort-by-item" lines="none"> -->

							<ion-select label-placement="floating" fill="outline" cancelText="{{'Cancel'|translate}}"
								okText="{{'Ok' | translate}}" [interface]="'alert'" [(ngModel)]="data.selected_id"
								[interfaceOptions]="{ 'header': commonService.domDirectionLTR ? data.configuration_name_en :
                                    data.configuration_name_ar}"
								(ionChange)="mobileAttributeChange(data.selected_id, data.value_array)">
								<ion-select-option *ngFor="let item of data.value_array"
									[value]="item.configuration_value_id">{{commonService.domDirectionLTR
									? item.configuration_value_en :
									item.configuration_value_ar}}</ion-select-option>
							</ion-select>
							<!-- </ion-item> -->
						</ion-list>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="qualtity-container pt-30 pb-30">
			<div class="quantity-head pb-10">
				{{ "Quantity" | translate }}
			</div>
			<div class="quantity-counter-container">
				<div class="quantity-counter">
					<div class="quantity">
						<div class="add-quantity pr-15" (click)="decrementQuantity()">
							<ion-icon *ngIf="productValue > selected_subproduct.min_quantity" alt="Jazeera"
								src="assets/icon/minus.svg"></ion-icon>
							<ion-icon *ngIf="productValue <= selected_subproduct.min_quantity" alt="Jazeera"
								src="assets/icon/minus-grey.svg"></ion-icon>
						</div>
						<div class="quantity-input">
							<ion-input [(ngModel)]="productValue" type="number" [min]="selected_subproduct.min_quantity"
								(keypress)="numericOnly($event)" [max]="available_quantity" (change)="qtySet()"
								(ionInput)="quantityInput($event)" mode="md"></ion-input>
						</div>
						<div class="subtract-quantity pl-15 " (click)="incrementQuantity()">
							<ion-icon alt="Jazeera" src="assets/icon/plus.svg"
								*ngIf="productValue < selected_subproduct.available_quantity -  cart_quantity"></ion-icon>
							<ion-icon alt="Jazeera" src="assets/icon/plus-grey.svg"
								*ngIf="productValue >= selected_subproduct.available_quantity - cart_quantity"></ion-icon>
						</div>
					</div>
					<div class="quantity-unit pl-10">
						{{ commonService.domDirectionLTR ? product_unit : product_unit_ar }}
					</div>
					<span *ngIf="minQuantErr" class="error-txt">{{"minQuantityErr" |
						translate}}
					</span>
					<span *ngIf=" availableQuantErr" class="error-txt">{{"quantityError" | translate}}
					</span>
				</div>
			</div>

		</div>
	</div>
</div>

<ion-footer *ngIf="!getPlatformService.isDesktop">
	<!-- <div class="notify-me" *ngIf="is_sold_out" (click)="notifyMeClick(mainProductData.product_id)">
        <app-common-button color="white" background="var(--ion-app-primary)" type="solid"
            title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true" [specificWidth]="'136px'"
            [specificHeight]="'39px'" [specificFontWeight]="'bold'"></app-common-button>
    </div> -->

	<div class="register-button ion-text-center mh-10" *ngIf="!is_sold_out">
		<app-common-button color="#FFFFFF" background="#0081CA" title="{{'moveToCart'|translate}}" [isIcon]="false"
			[isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
			[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="onSubmit()">
		</app-common-button>
	</div>
</ion-footer>

<ion-footer *ngIf="getPlatformService.isDesktop">
	<div class="register-bottom-container" *ngIf="getPlatformService.isDesktop">
		<div class="register-button">
			<!-- <ion-button (click)="notifyMeClick(mainProductData.product_id)" [disabled]="isLoading" *ngIf="is_sold_out">
                {{ 'NotifyMe' | translate }}
            </ion-button> -->
			<ion-button (click)="onSubmit()" [disabled]="isLoading" *ngIf="!is_sold_out">
				{{ 'moveToCart' | translate }}
			</ion-button>
		</div>
	</div>
</ion-footer>