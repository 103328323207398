<div class="home-page app-header">
	<app-header [catName]="commonService.domDirectionLTR ? display_sub_class_name_en : display_sub_class_name_ar"
		[prodName]="commonService.domDirectionLTR ? product_name : product_name_ar"
		[catUrlName]="display_sub_class_name_en" [prodUrlName]="product_name"></app-header>
</div>
<ion-header class="product-review-header" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div [ngClass]="[ commonService.domDirectionLTR ? 'mobile-back-arrow' : 'mobile-back-arrow-arabic']"
			*ngIf="!getPlatformService.isDesktop">
			<img src="./assets/icon/mobile-header-back.svg" alt="back-arrow" slot="start" (click)="goBack()"
				[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		</div>
		<div [ngClass]="[ commonService.domDirectionLTR ? 'fav-product' : 'fav-product-arabic']"
			(click)="addToWishlist(mainProductData.is_wishlist_product)" *ngIf="isLoggedIn">
			<img *ngIf="mainProductData && !mainProductData.is_wishlist_product" src="assets/icon/fav_product.svg"
				alt="Jazeera">
			<img *ngIf="mainProductData && mainProductData.is_wishlist_product" src="assets/icon/fav-liked.svg"
				alt="Jazeera">
		</div>
	</ion-toolbar>
</ion-header>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="!isLoading" [fullscreen]="true" [scrollEvents]="true" (ionScroll)="onScroll($event)">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<ion-grid class="detail-page detail-mobile" id="scrollId">
		<ion-row class="detail-page-container">
			<ion-col (dblclick)="zoom()" class="product-slider-col" size-xs="12" size-sm="12" size-md="12" size-lg="6"
				size-xl="6">
				<div class="product-slider-container">
					<swiper-container zoom="true" *ngIf="commonService.domDirectionLTR" #slides [pager]="sliderPager"
						(slideChangeTransitionEnd)="slideChanged()" dir="ltr">
						<swiper-slide *ngFor="let images of productImagesArray">
							<div class="product-image swiper-zoom-container"
								[ngClass]="selected_subproduct && selected_subproduct?.product_name && is_sold_out == true ? 'product-image-blur':null">
								<img [src]="images.image_url" loading="lazy" alt="Jazeera">
							</div>
						</swiper-slide>
					</swiper-container>
					<swiper-container zoom="true" *ngIf="!commonService.domDirectionLTR" #slides [pager]="sliderPager"
						(slideChangeTransitionEnd)="slideChanged()" dir="rtl">
						<swiper-slide *ngFor="let images of productImagesArray">
							<div class="product-image swiper-zoom-container"
								[ngClass]="selected_subproduct && selected_subproduct?.product_name && is_sold_out == true ? 'product-image-blur':null">
								<img [src]="images.image_url" loading="lazy" alt="Jazeera">
							</div>
						</swiper-slide>
					</swiper-container>
					<div [ngClass]="[ commonService.domDirectionLTR ? 'fav-product' : 'fav-product-arabic']"
						(click)="addToWishlist(mainProductData.is_wishlist_product)"
						*ngIf="isLoggedIn && getPlatformService.isDesktop">
						<img *ngIf="mainProductData && !mainProductData.is_wishlist_product"
							src="assets/icon/fav_product.svg" alt="Jazeera">
						<img *ngIf="mainProductData && mainProductData.is_wishlist_product"
							src="assets/icon/fav-liked.svg" alt="Jazeera">
					</div>
					<div [ngClass]="[ commonService.domDirectionLTR ? 'zoom-container' : 'zoom-container-arabic' ]"
						*ngIf="!zoomButtonClick && !noImageData && getPlatformService.isDesktop && !is_sold_out">
						<img src="assets/icon/zoom.svg" alt="Jazeera" (click)="zoom()">
					</div>
					<div class="zoom-out" [ngClass]="[ commonService.domDirectionLTR ? 'zoom-out' : 'zoom-out-arabic' ]"
						*ngIf="zoomButtonClick && !noImageData && !is_sold_out" (click)="zoom()">
						<img src="assets/icon/zoom-out.svg" alt="Jazeera">
					</div>

					<div *ngIf="(selected_subproduct && selected_subproduct?.product_name && is_sold_out == true) || productNotExist == true"
						class="product-outofstock">
						<div class="outofstock-label">
							{{"outOfStock" | translate}}
						</div>
					</div>

				</div>


				<div *ngIf="!sliderPager" class="segmentHolder">
					<ion-segment mode="md" [(ngModel)]="iontabs" (ionChange)="segmentChanged($event)">
						<ion-segment-button [value]="i" *ngFor="let images of productImagesArray; index as i">
							<div class="mr-r-10 image-container">
								<img [src]="images.image_url" loading="lazy" alt="more-info">
							</div>
						</ion-segment-button>
					</ion-segment>
				</div>
			</ion-col>
			<ion-col class="detail-page-col pt-4 pl-45" size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
				<div class="detail-page-description">
					<h1 class="product-name m-0">
						{{ commonService.domDirectionLTR ? product_name : product_name_ar }}
					</h1>

					<h3 class="fw-700 fs-20 mv-10 color-app-grey ">
						{{mainProductData.product_sku }}
					</h3>

					<div class="brand-name">
						{{'brand' | translate}}: <span class="brand">{{ commonService.domDirectionLTR ? brand_name :
							brand_name_ar }}</span>
					</div>
					<div class="supplier-name">
						{{'soldBy' | translate}}: <span class="supplier">{{ commonService.domDirectionLTR ?
							supplier_name : supplier_name_ar }}</span>
					</div>
					<div class="rating-container">
						<nz-rate class="add-review-rating" #rating [rating]="average_rating"
							[(ngModel)]="average_rating" nzDisabled (click)="scrollToReview('review')"
							[nzAllowHalf]="false"></nz-rate>

						<div class="rating-count">
							({{ total_ratings ? total_ratings : 0 }})
						</div>
					</div>
					<div class="panel-design-desktop-container">
						<ng-container *ngFor="let data of configData;index as i">
							<div class="panel-size-container">
								<div class="panel-size pr-8">
									{{ commonService.domDirectionLTR ? data.configuration_name_en :
									data.configuration_name_ar }}
								</div>
								<div class="more-info more-info-tooltip">
									<img src="assets/icon/more_info.svg" alt="more-info">
									<span class="more-info-tooltiptext-left more-info-file-tooltip">{{
										commonService.domDirectionLTR ? data.configuration_name_en :
										data.configuration_name_ar }}</span>
								</div>
							</div>
							<div class="size-container pt-10">
								<ion-segment mode="md" [(ngModel)]="data['attributeValue']"
									(ionChange)="attributeChange(data)">
									<ion-segment-button class="mr-10" [value]="j"
										*ngFor="let values of data.value_array; index as j" id="segment-{{j}}"
										(click)="designChecked(values, data.value_array,j)">
										<div class="size-options">
											{{ commonService.domDirectionLTR ? values['configuration_value_en'] :
											values['configuration_value_ar'] }}
										</div>
									</ion-segment-button>
								</ion-segment>
							</div>
						</ng-container>
					</div>
					<div class="product-price-container-mobile">
						<span *ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
						{{ final_price ?(final_price| number) : "0" }}
						<span *ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
					</div>
					<div class="product-price-container-mobile mob-pv-5">
						<span *ngIf="is_discount_applied" class="checked-price mr-20 currency2">
							<span class="currency2"
								*ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							<span class="currency2">{{product_price | number}}</span>
							<span class="currency2"
								*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
						</span>

						<span
							*ngIf="selected_subproduct && selected_subproduct?.discount_type &&  selected_subproduct?.discount_type != 'PRICE' && !is_sold_out"
							class="currency2 discount-mob-div">
							<span>-</span>{{ selected_subproduct?.discount_value }}<span>%</span>
						</span>
						<span
							*ngIf="selected_subproduct && selected_subproduct?.discount_type &&  selected_subproduct?.discount_type == 'PRICE' && !is_sold_out"
							class="currency2 discount-mob-div">
							<span>{{"OnSale" | translate}}</span>
						</span>

						<p class="excluding-vat m-0" *ngIf="is_tax_free_product != true">
							{{'excludingVAT' | translate}}
						</p>

					</div>
					<div class="panel-design-mobile-container">
						<ng-container *ngFor="let data of configData;index as i">
							<div>
								<div class="panel-size-container color-design">
									<div class="panel-size pr-8">
										{{ commonService.domDirectionLTR ? data.configuration_name_en :
										data.configuration_name_ar}}
									</div>
									<div class="more-info">
										<div class="more-info more-info-tooltip">
											<img src="assets/icon/more_info.svg" alt="more-info"
												(click)="showToolTip = true" (blur)="showToolTip = false">
											<span class="more-info-tooltiptext-left more-info-file-tooltip"
												*ngIf="showToolTip">{{
												commonService.domDirectionLTR ? data.configuration_name_en :
												data.configuration_name_ar }}</span>
										</div>
									</div>
								</div>
								<div class="info-container mr-10">
									<ion-list>
										<ion-item class="sort-by-item" lines="none"
											(click)="openDesignColorModal(data.value_array, data.configuration_id, data.selected_value, data.configuration_name_en , data.configuration_name_ar)">
											<div class="desing-color-popup">
												<ng-container>
													<div class="color-image mr-15">
														{{commonService.domDirectionLTR ? data.selected_value :
														data.selected_value_ar}}
													</div>
												</ng-container>
											</div>
											<ion-icon slot="end" src="assets/icon/relevancy_down_arrow.svg"
												alt="Jazeera"></ion-icon>
										</ion-item>
									</ion-list>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="qualtity-container pt-30">
						<div class="quantity-head pb-10">
							{{ "Quantity" | translate }}
						</div>
						<div class="quantity-counter-container">
							<div class="quantity-counter">
								<div class="quantity">
									<div class="add-quantity qty-div" (click)="decrementQuantity()">
										<ion-icon
											*ngIf="selected_subproduct && productValue > selected_subproduct?.min_quantity"
											src="assets/icon/minus-white.svg" alt="Jazeera"></ion-icon>
										<ion-icon
											*ngIf="selected_subproduct && productValue <= selected_subproduct?.min_quantity"
											src="assets/icon/minus-grey.svg" alt="Jazeera"></ion-icon>
									</div>
									<div class="quantity-input">
										<ion-input [(ngModel)]="productValue" type="text" inputmode="number"
											pattern="[0-9]*" [min]="selected_subproduct?.min_quantity"
											(keypress)="numericOnly($event)" (change)="qtySet()"
											(ionInput)="quantityInput($event)" mode="md"></ion-input>
									</div>
									<div class="subtract-quantity qty-div" (click)="incrementQuantity()">
										<ion-icon src="assets/icon/plus-white.svg" alt="Jazeera"
											*ngIf="selected_subproduct && productValue < selected_subproduct?.available_quantity - selected_subproduct?.cart_quantity"></ion-icon>
										<ion-icon src="assets/icon/plus-grey.svg"
											*ngIf="selected_subproduct && productValue >= selected_subproduct?.available_quantity - selected_subproduct?.cart_quantity"
											alt="Jazeera"></ion-icon>
									</div>
								</div>
								<div class="quantity-unit pl-10">
									<span class="price-units"
										*ngIf="mainProductData && mainProductData.unit_id && mainProductData.secondary_unit_id && mainProductData.unit_id !== mainProductData.secondary_unit_id">
										({{calculateSUOM(selected_subproduct?.conversation_ratio , productValue )}})
									</span>
								</div>
							</div>
							<ng-container *ngIf="selected_subproduct && !is_sold_out">
								<span *ngIf="minQuantErr " class="error-txt">{{"minQuantityErr"| translate}}
								</span>
								<span *ngIf="availableQuantErr" class="error-txt">{{"quantityError" | translate}}
								</span>
							</ng-container>
						</div>
						<div class="quantity-head mv-10 color-app-green"
							*ngIf="selected_subproduct && selected_subproduct.available_quantity">
							{{ "cartPage.availableStock" | translate }}:
							{{selected_subproduct?.available_quantity}}
						</div>

						<div class="product-price-container">
							<span *ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							{{ final_price ? (final_price| number) : "0" }}
							<span *ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>

							<span class="price-units"
								*ngIf="mainProductData && mainProductData.unit_id && mainProductData.secondary_unit_id && mainProductData.unit_id !== mainProductData.secondary_unit_id">
								({{selected_subproduct?.conversation_ratio}}) </span>

							<span *ngIf="is_discount_applied" class="checked-price currency2 ml-15 pr-15">
								<span class="currency2"
									*ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
								<span class="currency2">{{product_price | number}}</span>
								<span class="currency2"
									*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							</span>

							<span class="currency2"
								*ngIf="selected_subproduct && selected_subproduct?.discount_type &&  selected_subproduct?.discount_type != 'PRICE' && !is_sold_out">
								<span>-</span>{{ selected_subproduct.discount_value }}<span>%</span>
							</span>
							<span class="currency2"
								*ngIf="selected_subproduct && selected_subproduct?.discount_type &&  selected_subproduct?.discount_type == 'PRICE' && !is_sold_out">
								<span>{{"OnSale" | translate}}</span>
							</span>

						</div>

						<div class="price-per-piece-container"
							*ngIf="price_per_piece && mainProductData && mainProductData.unit_id && mainProductData.secondary_unit_id && mainProductData.unit_id !== mainProductData.secondary_unit_id && price_per_piece != final_price">
							<span *ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							{{ price_per_piece ? (price_per_piece| number) : "0" }}
							<span *ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
							<span class="price-units">/
								{{commonService.domDirectionLTR ? mainProductData.secondary_unit_name_en :
								mainProductData.secondary_unit_name_ar}}</span>
							<p class="excluding-vat m-0" *ngIf="is_tax_free_product != true">
								{{'excludingVAT' | translate}}
							</p>

						</div>



						<div class="add-cart-container" *ngIf="selected_subproduct && !productNotExist">
							<div class="add-cart-button ion-text-start">
								<ion-button *ngIf="isLoading || btnDisabled">
									<ion-spinner color="white" class="cart-icon"></ion-spinner>
									{{'addToCart' | translate}}
								</ion-button>
								<ion-button (click)="addToCart()" *ngIf="!btnDisabled">
									<span class=" cart-icon"><img src="assets/icon/fi_shopping-cart.svg"
											alt="cart"></span>
									{{'addToCart' | translate}}
								</ion-button>
							</div>
						</div>

						<div class="add-cart-container" *ngIf="selected_subproduct && !productNotExist">
							<div class="rfq-btn pt-10 ion-text-start">
								<ion-button *ngIf="btnDisabled">
									{{'rfqBtntext' | translate}}
								</ion-button>

								<ion-button (click)="openRfqConfirmation()" *ngIf="!btnDisabled">
									{{'rfqBtntext' | translate}}
								</ion-button>
							</div>
						</div>




						<div class="add-cart-container-mobile" *ngIf="selected_subproduct && !productNotExist">
							<div class="pt-20">
								<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
									(click)="addToCart()" title="{{'addToCart' | translate}}" [isShippmentIcon]="true"
									[isHideInMobile]="false" iconSrc="assets/icon/add-to-cart-mobile.svg"
									[specificWidth]="'100%'" [specificHeight]="'48px'" [specificFontSize]="'0.9375rem'">
								</app-common-button>

								<div class="mt-10"></div>

								<app-common-button color="#FFF" background="#434345" type="solid" class="width-100 "
									(click)="openRfqConfirmation()" title="{{'rfqBtntext' | translate}}"
									[isHideInMobile]="false" [specificWidth]="'100%'" [specificHeight]="'48px'"
									[specificFontSize]="'0.9375rem'">
								</app-common-button>
							</div>
						</div>

						<!-- <div class="notify-me pt-30"
							*ngIf="selected_subproduct && selected_subproduct?.product_name && selected_subproduct?.is_sold_out && !selected_subproduct?.notify_me"
							(click)="notifyMeClick(selected_subproduct.product_id)">
							<app-common-button color="white" background="var(--ion-app-primary)" type="solid"
								title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true"
								[specificWidth]="'190px'" [specificHeight]="'54px'"
								[specificFontWeight]="'bold'"></app-common-button>
						</div>
						<div class="notify-me pt-30"
							*ngIf="selected_subproduct && selected_subproduct?.product_name && selected_subproduct?.is_sold_out && selected_subproduct?.notify_me">
							<app-common-button color="var(--ion-app-primary)" background="white" type="outline"
								title="{{'Notified' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true"
								[specificWidth]="'190px'" [specificHeight]="'54px'"
								[specificFontWeight]="'bold'"></app-common-button>
						</div> -->

					</div>
				</div>
			</ion-col>
		</ion-row>
		<ion-row class="over-view-container scroll-spy-container">
			<div class="over-view-head">
				<div class="tab-header" [class.active]="activeSection === 'section1'"
					(click)="scrollToReview('overview')">
					{{'overview' | translate}}
				</div>
				<div class="tab-header" [class.active]="activeSection === 'section5'"
					(click)="scrollToReview('specification')">
					{{ "Specifications" | translate }}
				</div>
				<div class="tab-header" [class.active]="activeSection === 'section2'"
					(click)="scrollToReview('review')">
					{{ "Reviews" | translate }}
				</div>
				<div *ngIf="documentData && documentData.length" class="tab-header"
					[class.active]="activeSection === 'section4'" (click)="scrollToReview('documents')">
					{{ "Documents" | translate }}
				</div>
				<div class="tab-header" [class.active]="activeSection === 'section3'"
					(click)="scrollToReview('relatedProducts')">
					{{ "Related_products" | translate }}
				</div>
			</div>
		</ion-row>
		<ion-row class="over-view-container scrollToSection mt-15" id="section1">
			<h1 class="over-view-header m-0">
				{{'overview' | translate}}
			</h1>
			<div class="overview-desktop">
				<div class="section-container">
					<div class="section-head">
						{{'description' | translate}}
					</div>
					<div class="section pt-10 pb-40" *ngIf="product_description">
						{{ commonService.domDirectionLTR ? product_description : product_description_ar }}
					</div>
					<div class="section pt-10 pb-40" *ngIf="!product_description">
						{{'noDescription' | translate}}.
					</div>
				</div>
				<!-- required later -->
				<!-- <div class="section-container">
                  <div class="section-head">
                    Section #2
                  </div>
                  <div class="section pt-10">
                    <div class="section-data" *ngFor="let data of sectionTwo">
                      {{ data.section }}
                    </div>
                  </div>
                </div> -->
			</div>
			<div class="overview-mobile">
				<div class="section">
					{{ commonService.domDirectionLTR ? product_description : product_description_ar }}
				</div>
			</div>
		</ion-row>
		<ion-row class="over-view-container specification-row" id="section5">
			<h1 class="over-view-header specificaition-header m-0">
				{{ "Specifications" | translate }}
			</h1>

			<ng-container *ngIf="showSpecification">
				<div class="specification-container">
					<div class="specification" *ngFor="let data of productSpecification">
						<div class="specification-name" *ngIf="data.configuration_value_id || data.configuration_value">
							{{ commonService.domDirectionLTR ?
							data.configuration_name_en :
							data.configuration_name_ar}} :
						</div>
						<div class="specification-value"
							*ngIf="data.configuration_value_id || data.configuration_value">
							{{data.configuration_value_id && commonService.domDirectionLTR ?
							data.configuration_value_en :
							data.configuration_value_ar }}
							<div [innerHTML]="data.configuration_value | safe :'html'" *ngIf="data.configuration_value">
							</div>
						</div>
					</div>
				</div>


			</ng-container>
			<ng-container *ngIf="!showSpecification">
				<div class="no-specification pt-30 pb-40">
					{{ 'no_spec' | translate }}
				</div>
			</ng-container>
		</ion-row>
		<!-- web Review section --->
		<ion-row class="review-container scrollToSection" id="section2" *ngIf="getPlatformService.isDesktop">
			<div class="review-header">
				<h1 class="m-0">
					{{'reviews' | translate}}<span *ngIf="reviews.length > 0" class="review-numbers">({{
						reviewsArray['total_count'] }})</span>
				</h1>
				<div>
					<div class="add-review pt-30" (click)="addReviewModal()">
						+ {{'addReview' | translate}}
					</div>
				</div>
			</div>
			<ng-container *ngIf="reviews.length > 0">
				<div class="review-top-section">
					<div class="review-rating-container">
						<div class="review-rating">
							{{ average_rating }}
						</div>
						<div class="review-star-rating">
							<nz-rate class="star-rating" #rating [ngModel]="average_rating" [nzAllowHalf]="true"
								nzDisabled></nz-rate>
						</div>
					</div>
				</div>
				<div class="review-middle-section">
					<ng-container *ngFor="let data of reviews;let last = last;index as i;">
						<div *ngIf="i <= this.reviewparams.size">
							<div class="reviewer-name-container">
								<div class="reviewer-name">
									{{ data['name'] }}
								</div>
								<div class="review-date">
									{{'reviewBy' | translate}} {{ data['user_name'] }}, {{ data['date'] | date:
									'dd/MM/yyyy' }}
								</div>
							</div>
							<div class="verified-container" *ngIf="data['verified']">
								<div class="verified-logo">
									<img src="assets/icon/verified.svg" alt="Jazeera">
								</div>
								<div class="verified">
									{{'verifiedPurchase' | translate}}
								</div>
							</div>
							<div class="reviewer-rating comment-rating">
								<nz-rate class="comment-rating" #rating [ngModel]="data.rating" [nzAllowHalf]="true"
									nzDisabled></nz-rate>
							</div>
							<div class="reviewer-comment" [ngClass]="[last ? 'comment-border' : '']">
								{{ data['comment'] }}
							</div>
						</div>
						<div *ngIf="i > this.reviewparams.size && loadMore">
							<div class="reviewer-name-container">
								<div class="reviewer-name">
									{{ data['name'] }}
								</div>
								<div class="review-date">
									{{'reviewBy' | translate}} {{ data['user_name'] }}, {{ data['date'] | date:
									'dd/MM/yyyy' }}
								</div>
							</div>
							<div class="verified-container" *ngIf="data['verified']">
								<div class="verified-logo">
									<img src="assets/icon/verified.svg" alt="Jazeera">
								</div>
								<div class="verified">
									{{'verifiedPurchase' | translate}}
								</div>
							</div>
							<div class="reviewer-rating">
								<nz-rate class="add-review-rating" #rating [ngModel]="data.rating" [nzAllowHalf]="true"
									nzDisabled></nz-rate>
							</div>
							<div class="reviewer-comment" [ngClass]="[last ? 'comment-border' : '']">
								{{ data['comment'] }}
							</div>
						</div>
					</ng-container>
					<div *ngIf="this.reviewsArray.enable_next" class="more-reviews ion-text-center"
						(click)="loadMoreReviews()">
						{{ loadMore ? 'Hide reviews' : 'Load more reviews' }}
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="reviews.length == 0">
				<div class="no-reviews pt-30 pb-40">
					{{'noReviews' | translate}}.
				</div>
			</ng-container>
		</ion-row>
		<!-- web Review section end--->

		<!-- mobile Review section --->
		<ion-row class="review-container review-mobile-section scrollToSection" id="section2"
			*ngIf="!getPlatformService.isDesktop">
			<h1 class="review-header m-0">
				{{'reviews' | translate}}

				<span class="review-count" *ngIf="reviews.length > 0" (click)="openReviewMobileModal()">
					{{reviews.length > 0 ? reviewsArray['total_count'] : 0}} {{'reviews' | translate}}
					<img src="./assets/icon/fill_arrow.svg" alt="Jazeera" slot="end">
				</span>
			</h1>

			<ng-container *ngIf="reviews.length > 0">
				<div class="review-top-section">
					<div class="review-rating-container mr-10">
						<div class="review-rating">
							{{ average_rating }}
						</div>
						<div class="review-star-rating">
							<nz-rate class="add-review-rating" #rating [ngModel]="average_rating" [nzAllowHalf]="true"
								nzDisabled></nz-rate>
						</div>
					</div>
					<div class="add-review">
						<ion-row *ngFor="let data of reviewCount" class="review-row">
							<ion-label class="review-label">{{ commonService.domDirectionLTR ? data.name :data.name_ar
								}}</ion-label>
							<ion-range [value]="data.percentage" [pin]="false" class="mh-10 p-0"></ion-range>
							<ion-label class="review-count-label">{{ data.count }}</ion-label>
						</ion-row>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="reviews.length == 0">
				<div class="no-reviews pt-30 pb-40">
					{{'noReviews' | translate}}.
				</div>
			</ng-container>
			<ng-container>
				<div>
					<app-common-button color="#0081CA" background="transparent" type="clear" class="width-100"
						title="{{'addReview' | translate}}" [isShippmentIcon]="true" iconSrc="assets/icon/addIcon.svg"
						[isHideInMobile]="false" [isFullWidth]="true" [specificFontSize]="'1em'"
						(click)="addReviewModal()">
					</app-common-button>
				</div>
			</ng-container>
		</ion-row>
		<!-- mobile Review section end--->

		<ion-row class="documents-container scrollToSection" id="section4" *ngIf="documentData && documentData.length">
			<h1 class="documents-header m-0">
				{{ "Documents" | translate }}
			</h1>
			<div class="document-data-container pt-30">
				<ng-container *ngIf="!isDocument">
					<div *ngFor="let data of documentData;index as i" class="mb-30">

						<div class="document-header-container" *ngIf="data['documents'] && data['documents'].length">
							<div class="document-data-header">
								<span class="document-type">{{ commonService.domDirectionLTR ? data.document_type_en :
									data.document_type_ar}}</span>
								<div class="more-info-tooltip ml-10" *ngIf="getPlatformService.isDesktop"><img
										src="./assets/icon/more_info.svg" alt="more-info">
									<span class="more-info-tooltiptext more-info-file-tooltip">{{
										commonService.domDirectionLTR ? data.document_description_en :
										data.document_description_ar }}.</span>
								</div>
							</div>
							<div class="document-data-header dowload-header" (click)="showDocumentData(data)">
								<i class="feather icon-minus color-primary cursor-pointer"
									*ngIf="data['documentView']"></i>
								<i class="feather icon-plus color-primary cursor-pointer"
									*ngIf="!data['documentView']"></i>
							</div>
						</div>
						<ng-container *ngIf="data['documentView'] && data['documents'].length">
							<div class="document-data" *ngFor="let docData of data['documents']">
								<div class="document-description">
									{{ commonService.domDirectionLTR ? docData.document_name_en :
									docData.document_name_ar }}
									<div class="more-info-tooltip ml-10" *ngIf="getPlatformService.isDesktop"><img
											src="./assets/icon/more_info.svg" alt="more-info">
										<span class="more-info-tooltiptext more-info-file-tooltip">{{
											commonService.domDirectionLTR ? docData.document_description_en :
											docData.document_description_ar}}.</span>
									</div>
								</div>
								<div>
									<div (click)="downloadDocument(docData.document_url)" class="download-icon">
										<i class="feather icon-download color-primary cursor-pointer "></i>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="isDocument">
					<div class="no-documents">
						{{ 'no_doc' | translate }}
					</div>
				</ng-container>
			</div>
		</ion-row>
		<!--required later -->
		<ion-row class="related-products-container scrollToSection" id="section3">
			<h1 class="related-products-header m-0">
				{{ "Related_products" | translate }}
			</h1>
			<div class="related-products-slider">
				<app-product-slider [relatedProducts]="true" [sliderData]="recommendedSliderData"></app-product-slider>
			</div>
		</ion-row>
		<ion-row class="related-products-container-mobile pt-30 pb-20 pl-5">
			<div class="related-products-slider">
				<app-product-slider [viewAll]="true" [sliderData]="recommendedSliderData"
					[productTitle]="'homePage.recommended_for_you' | translate"></app-product-slider>
			</div>
		</ion-row>
		<!-- </ion-grid>
        </ion-content> -->
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>