<ion-header class="mobile-visibility" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBack()">
			<ion-title>{{'otherMenuComponent.cookiesPolicy' | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>
<ion-content>
	<div class="detail-page">
		<div class="cms-wrapper">
			<div *ngIf="pageData && pageData.content_en && commonService.domDirectionLTR"
				(click)="elementClicked($event)" [innerHTML]="pageData.content_en | safe :'html'"></div>
			<div *ngIf="pageData && pageData.content_ar && !commonService.domDirectionLTR"
				(click)="elementClicked($event)" [innerHTML]="pageData.content_ar | safe :'html'"></div>
		</div>
	</div>

	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>