import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { TranslateConfigService } from '../../services/translate-config.service';

@Component({
    selector: 'app-account-details',
    templateUrl: './account-details.page.html',
    styleUrls: ['./account-details.page.scss'],
})
export class AccountDetailsPage implements OnInit {

    public orderDetailsPage: boolean = false;
    public accounDetailsPage: boolean = false;
    public hideMenu = false;
    public hideShowMenu = false;
    constructor(private translateConfigService: TranslateConfigService,
        private router: Router,
        public commonService: CommonService) {
        this.translateConfigService.getDefaultLanguage();
        this.orderDetailsComponentLoad();
        this.commonService.eventObservable.subscribe(data => {
            if (data.event === "menu-btn-clicked") {
                this.hideShowMenu = !this.hideShowMenu;
            }
        })

    }

    ngOnInit() {
        this.orderDetailsComponentLoad();
    }

    ionViewWillEnter() {
        this.translateConfigService.getDefaultLanguage();
    }

    orderDetailsComponentLoad() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (event.url.includes("my-orders/")) {
                this.hideMenu = true;
            } else {
                this.hideMenu = false;
            }
            if (event.url.includes("order-details")) {
                this.orderDetailsPage = true;
                this.accounDetailsPage = false;
            } else {
                this.accounDetailsPage = true;
                this.orderDetailsPage = false;
            }

        });
    }
}
