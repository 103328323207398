import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../common/shared.module';
import { OrderThankYouRoutingModule } from './order-thank-you-routing.module';
import { OrderThankYouComponent } from './order-thank-you.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        OrderThankYouRoutingModule
    ],
    declarations: [OrderThankYouComponent]
})
export class OrderThankYouModule { }
