import { DatePipe, isPlatformServer } from "@angular/common";
import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ExportExcelService } from "../../services/export-excel.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";

@Component({
	selector: "app-orders",
	templateUrl: "./orders.component.html",
	styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
	isLoading = false;
	isDownloading = false;
	searchText: any;
	private subscription: Subscription = new Subscription();
	perPageInterface = { cssClass: "custom-select-dd per-page-dd" };
	currencySymbol: string = "SAR";
	showSearch = false;
	enableNext = false;
	filterObj = {};
	showFilter = false;

	public count = 0;

	public itemsPerPage = "10";

	public currentPage = 1;
	selectInterface = { cssClass: "custom-select-dd", size: "cover" };
	selectStatus: any = "all";
	fromDate: any;
	toDate: any;
	dateRange: FormGroup;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	columnHeaders = {
		order_id: "Order #",
		date: "Date",
		ship_to: "Ship to",
		order_total: "Order total",
		status: "Status",
	};
	statusList = [];
	orderList = [];
	backUpOrderList = [];
	@Input() showHeader: boolean;
	@Input() from: any;
	@Input() dataArray: any;
	constructor(
		private modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translateService: TranslateService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private exportService: ExportExcelService
	) {
		if (this.router.url.includes("my-orders")) {
			this.showHeader = true;
		}
	}

	ngOnInit() {
		if (this.router.url.includes("my-orders")) {
			this.showHeader = true;

			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
			this.initializeDateForm();

			this.getOrderList(this.filterObj);
		}
		if (this.from == "dashboard") {
			this.orderList = this.dataArray;
		}
	}

	ionViewWillEnter() {
		this.initializeDateForm();
		if (this.router.url.includes("my-orders")) {
			this.showHeader = true;
			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
		}
		if (this.from == "dashboard") {
			this.orderList = this.dataArray;
		}
	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
		});
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, "YYYY-MM-dd");
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");
	}

	applyBtn() {
		this.filterObj = {};
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.filterObj["from_date"] = this.datePipe.transform(
				this.fromDate,
				"yyyy-MM-dd"
			);
			this.filterObj["to_date"] = this.datePipe.transform(
				this.toDate,
				"yyyy-MM-dd"
			);
		}
		if (this.filterObj["from_date"] && this.filterObj["to_date"]) {
			if (this.filterObj["from_date"] > this.filterObj["to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			this.filterObj["from_date"] &&
			this.filterObj["to_date"] &&
			this.filterObj["from_date"] == this.filterObj["to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}
		if (this.selectStatus == "all") {
			delete this.filterObj["status"];
		} else {
			this.filterObj["status"] = this.selectStatus;
		}
		this.currentPage = 1;
		// this.getOrderList(this.filterObj);
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false, this.filterObj)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.selectStatus = "all";
		this.dateRange.reset();
		this.filterObj = {};
		if (this.searchText) {
			this.filterObj["search"] = this.searchText;
		}
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false, null)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	onDateChange(datetime) {
		// datetime.cancel(true);
	}

	search() {
		if (!this.searchText) return;
		this.filterObj["search"] = this.searchText;
		this.currentPage = 1;

		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false, null)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	gotoOrderDetails(id, data) {
		let breadcrumbData = id ? data.order_no : data.sap_order_no;
		this.router.navigate(["/home/account-details/my-orders/" + id + "/" + data.sap_order_no], {
			state: {
				breadcrumb:
					this.translateService.instant("orderDetailsComponent.orders") +
					breadcrumbData,
				url: "/home/account-details/my-orders/" + id + "/" + data.sap_order_no,
			},
		});
	}

	onChange(event): void {
		this.currentPage = event;
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
				console.error("Error processing data:", error);
			});
	}

	getOrderList(params, infiniteScroll?, download?) {
		if (!download) {
			params = {
				page: this.currentPage,
				limit: +this.itemsPerPage,
				...params,
			};
		}
		return new Promise((resolve, reject) => {
			if (!download) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.get(URLS.orderList, params).subscribe(
					(data) => {
						if (data?.code === 200) {

							this.backUpOrderList = data['data'] || [];
							this.backUpOrderList.forEach(element => {
								if (element.document_date == "0000-00-00") element.document_date = null;
								if (element.document_date == "    -  -  ") element.document_date = null;
								if (element.invoice_date == "    -  -  ") element.invoice_date = null;
								if (element.invoice_date == "0000-00-00") element.invoice_date = null;
							});

							this.processAndPaginateData(
								this.backUpOrderList,
								this.itemsPerPage,
								this.currentPage,
								download,
								infiniteScroll
							)
								.then((orderList) => {
									resolve(orderList);
								})
								.catch((error) => {
									console.error("Error processing data:", error);
									reject(error);
								});
						} else {
							reject(data?.message || "Failed to fetch data");
						}
						this.isLoading = false;
					},
					(error) => {
						reject();
						this.isLoading = false;
					}
				)
			);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
					.then((statementList) => {
						event.target.complete();
					})
					.catch((error) => {
						event.target.complete();
					});
			}
		}, 500);
	}

	selectPageLength(event) {
		this.itemsPerPage = event.detail.value;
		this.currentPage = 1;
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
			});
	}

	clearSearch() {
		this.searchText = null;
		delete this.filterObj["search"];
		this.currentPage = 1;
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
			});
	}

	downloadExcel() {
		if (this.isLoading) {
			return;
		}
		const params = {
			dropdown: true,
		};
		this.isLoading = true;
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, true, false)
			.then((orderList) => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.isLoading = false;
			});
	}

	orderStatus(orderData, productlevel?) {
		switch (orderData.order_status) {
			case "DISPATCHED":
			case "CONFIRMED":
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
			case "DELIVERED":
				if (productlevel) {
					return "btn-black product-status-btn";
				} else {
					return "btn-black";
				}
			case "NOT_YET_DELIVERED":
			case "NOT_DELIVERED":
			case "CANCELLED":
				if (productlevel) {
					return "btn-red product-status-btn";
				} else {
					return "btn-red";
				}
			default:
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
		}
	}

	downloadInvoice(event, data) {
		event.preventDefault();
		event.stopPropagation();
		const params = {
			invoice_no: data.sap_order_no,
		};
		this.isDownloading = true;

		this.subscription.add(
			this.dataService.post(URLS.invoiceDownload, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						if (!isPlatformServer(this.platformId)) {
							window.open(data["data"]);
						}
					} else {
					}
					this.isDownloading = false;
				},
				(error) => {
					this.isDownloading = false;
				}
			)
		);
	}

	navigateToInvoices() {
		this.router.navigateByUrl('/home/account-details/my-invoice');
	}

	processAndPaginateData(data, pageSize, pageIndex, download = false, infiniteScroll, filterPayload?) {
		if (this.filterObj && Object.keys(this.filterObj).length > 0) {
			filterPayload = this.filterObj
		}
		return new Promise((resolve, reject) => {
			if (!data) {
				reject("No data available.");
				return;
			}

			let filterData = [];
			if (filterPayload) {
				// Apply filtering

				filterData = filterData = data.filter((item) => {
					const doc_from_date = filterPayload.from_date ? new Date(filterPayload.from_date) : null;
					const doc_to_date = filterPayload.to_date ? new Date(filterPayload.to_date) : null;


					const documentDate = item.order_date && item.order_date != "0000-00-00" ? new Date(item.order_date) : null;


					const withinDateRange =
						doc_from_date && doc_to_date && documentDate
							? documentDate >= doc_from_date && documentDate <= doc_to_date
							: false;


					// Return true if any of the conditions are satisfied
					return withinDateRange;
				});
			}

			// Apply the search filter if there is a search text
			if (this.searchText) {
				const lowerCaseSearchTerm = this.searchText.toLowerCase();

				filterData = data.filter(item => {
					// Check if any field in the item matches the search term
					return Object.keys(item).some(key => {
						const value = item[key];
						if (value) {
							// Check if the value is a string or a number, and match case-insensitively
							return value.toString().toLowerCase().includes(lowerCaseSearchTerm);
						}
						return false;
					});
				});
			}


			let listData = filterPayload || this.searchText ? filterData : data;


			pageSize = +pageSize;
			// Total items and pages
			const totalCount = listData.length;
			const startIndex = (pageIndex - 1) * pageSize; // Adjust for pageIndex starting from 1
			const endIndex = startIndex + pageSize;
			const paginatedData = listData.slice(startIndex, endIndex);

			if (download) {
				this.exportService.exportAsExcelFile(listData, "order_list");
				resolve([]);
				return;
			}
			const mappedData = paginatedData.map((item) => ({
				invoice_id: item.invoice_id || null,
				order_no: item.order_no || null,
				order_date: item.order_date || null,
				total_order_amount: item.total_order_amount || item.total_order_amount != "0.000" ? parseFloat(item.total_order_amount) : "0.0",
				order_status: item.order_status || null,
				cx_reference_no: item.cx_reference_no || null,
				sap_order_no: item.sap_order_no || null,
			}));

			if (!infiniteScroll) {
				this.orderList = mappedData; // Replace the list
			} else {
				this.orderList = [...this.orderList, ...mappedData]; // Append for infinite scroll
			}

			this.count = totalCount; // Update total count
			this.enableNext = endIndex < totalCount; // Enable/disable next page
			resolve(this.orderList);
		});
	}
}
