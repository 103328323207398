<div class="orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{"myQuoteComponent.title" | translate}}
		</h1>
		<div class="search-and-filter">
			<div class="filter-div">

				<div class="section-block">
					<div class="filter-icon" (click)="openFilters()" *ngIf="!showFilter">
						<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
					</div>
					<div class="close-filter-icon" (click)="openFilters()" *ngIf="showFilter">
						<img src="./assets/icon/modal-close-mobile.svg" alt="Jazeera">
					</div>
					<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''" (click)="openFilters()">
						{{"ordersComponent.filters" | translate}}
					</div>

				</div>
			</div>
		</div>
	</div>
	<form [formGroup]="dateRange" *ngIf="showFilter">
		<div class="filter-section">
			<div class="filter-header mw-250" *ngIf="true">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{"status"
						| translate}}</ion-label>
				</div>
				<div class="input-div-container">
					<div class="status-dropdown-div">
						<ion-list class="status-dropdown" mode="md">
							<!-- <ion-item class="ion-card " lines="none"> -->
							<ion-select cancelText="{{'Cancel'|translate}}" label="{{'select' |translate}}"
								label-placement="floating" fill="outline" okText="{{'Ok' | translate}}"
								[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" interface="popover"
								value="all" [interfaceOptions]="selectInterface" [(ngModel)]="selectStatus"
								placeholder="{{ 'select' | translate }}" [ngModelOptions]="{standalone: true}">
								<ion-select-option [value]="data.value" *ngFor="let data of statusList">{{data.label
									| translate}}</ion-select-option>
							</ion-select>
							<!-- </ion-item> -->
						</ion-list>
					</div>
				</div>
			</div>
			<div class="filter-header">
				<div class="status-label">
					<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'myQuoteComponent.date' |
						translate}}</ion-label>
				</div>
				<div class="input-div-container">
					<div class="from-date-div">
						<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
							id="fromDateId">
							<ion-input id="fromDateId" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
								value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
                                | translate}}" class="ion-text-start"></ion-input>
							<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="Jazeera"></ion-icon>
							<ion-popover trigger="fromDateId" size="cover" id="datePopover">
								<ng-template>
									<ion-datetime presentation="date" [min]="'2000-01-01'"
										[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
										formControlName="from" #fromdate (ionChange)="onDateChange(fromdate)"><span
											slot="title">{{"from"
											| translate}}</span></ion-datetime>
								</ng-template>
							</ion-popover>
						</ion-item>
					</div>
				</div>
			</div>
			<div class="filter-header">
				<div class="input-div-container">
					<div class="to-date-div">
						<ion-item class="to-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
							id="toDateId">
							<ion-input [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="toDateId"
								value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
                                | translate}}" class="ion-text-start"></ion-input>
							<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="Jazeera"></ion-icon>
							<ion-popover trigger="toDateId" size="cover" id="datePopover">
								<ng-template>
									<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'"
										[max]="maxDate" [(ngModel)]="toDate" formControlName="to" #todate
										(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
											| translate}}</span></ion-datetime>
								</ng-template>
							</ion-popover>
						</ion-item>
					</div>
				</div>
			</div>

			<div class="filter-buttons">
				<div class="apply-button ion-text-end">
					<button class="filter-apply-button" (click)="clearFilter()">{{"clear" | translate}}</button>
				</div>
				<button class="filter-apply-button" (click)="applyBtn()">{{"apply" | translate}}</button>
			</div>

		</div>
	</form>
	<div class="listing-table">
		<app-loader *ngIf="isLoading"></app-loader>
		<table class="table" *ngIf="!isLoading">
			<thead>
				<th class="column-heading order-id-col mw-100 text-left">{{"myQuoteComponent.quoteId"
					| translate}}</th>
				<th class="column-heading order-id-col mw-100 text-left">{{"myQuoteComponent.quoteNo"
					| translate}}</th>
				<th class="column-heading date-col mw-120 text-left">{{"myQuoteComponent.date"
					| translate}}</th>
				<th class="column-heading date-col mw-120 text-left">{{"myQuoteComponent.countOfItems"
					| translate}}</th>
				<!-- <th class="column-heading date-col mw-120 text-left">{{"myQuoteComponent.totalPrice"
                    | translate}}</th> -->
				<th class="column-heading ion-text-center status-col mw-120">{{"myQuoteComponent.status"|
					translate}}
				</th>
				<th class="column-heading ion-text-center status-col mw-120"></th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let quote of quoteList;index as i" class="table-row">
					<td class="order-id text-left" (click)="openRFQCartModal(quote)">
						{{quote.quotation_request_id}}</td>
					<td class="order-id text-left" (click)="openRFQCartModal(quote)">
						{{quote.quotation_no}}</td>
					<td class="text-left" (click)="openRFQCartModal(quote)">
						{{quote.created_at | date :'MMM dd, YYYY'}}</td>
					<td (click)="openRFQCartModal(quote)">
						{{quote.total_items ? quote.total_items: 0}}
					</td>
					<!-- <td (click)="openRFQCartModal(quote)">
                        {{commonService.currencySymbol}} {{quote.total_price ? quote.total_price : 0}}
                    </td> -->
					<td>
						<ion-button fill="clear" class="order-status-btn" [ngClass]="quoteStatus(quote)">
							{{quote.is_fulfilled != true ?
							('myQuoteComponent.requested' | translate) : ('myQuoteComponent.completed' |translate)}}
						</ion-button>
					</td>

					<td>
						<div *ngIf="!quote.is_fulfilled">
							<ion-button class="order-status-btn btn-label" (click)="proceedToOrderQuote(quote)">
								{{'myQuoteComponent.proceedToOrder' | translate}}
							</ion-button>
						</div>

						<!-- <div class="right-arrow" (click)="openPopup(quote,$event)"
                            [ngClass]=" commonService.domDirectionLTR ? 'justify-end' : ' rotate-arrow justify-start'">
                            <img src=" ./assets/icon/action-button.svg" alt="righta-arrow" class="arrow-icon">
                        </div>

                        <div class="action-div" *ngIf="quote.isOpen">
                            <ion-button class="order-status-btn btn-label" (click)="proceedToOrderQuote(quote)">
                                {{'myQuoteComponent.proceedToOrder' | translate}}
                            </ion-button>
                        </div> -->

					</td>


				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!quoteList.length">
			{{'noQuote' | translate}}
		</div>
	</div>

	<div class="pagination-wrapper" *ngIf="quoteList.length && from != 'dashboard'">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col">
				<ion-list class="perpage-dropdown">
					<ion-item class="ion-card" lines="none">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							interface="popover" [interfaceOptions]="perPageInterface"
							(ionChange)="selectPageLength($event)" [(ngModel)]="itemsPerPage">
							<ion-select-option value="10">10</ion-select-option>
							<ion-select-option value="20">20</ion-select-option>
						</ion-select>
					</ion-item>
					<ion-label class="perpage-title">{{"ordersComponent.per_page" | translate}}</ion-label>
				</ion-list>
			</ion-col>
			<ion-col size="8" class="pagination-col">
				<app-tile class="grid-tile" *ngFor="let post of quoteList | paginate :
    { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
    let i = index" [current]="post"></app-tile>
				<pagination-controls class="paginator"
					[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
					previousLabel="{{'ordersComponent.prev' | translate}}"
					nextLabel="{{'ordersComponent.next' | translate}}"
					(pageChange)="onChange($event)"></pagination-controls>
			</ion-col>
		</ion-row>
	</div>
</div>