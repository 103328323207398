<div class="home-page app-header">
	<app-header></app-header>
</div>
<app-loader *ngIf="isLoading"></app-loader>
<ion-content *ngIf="noRecordFound && !isLoading">
	<div class="no-results-header ion-text-center">
		{{ results_count }} {{'searchBarComponent.searchresultsof'|translate}} '{{ search_type }}'
	</div>
</ion-content>
<ng-container *ngIf="!noRecordFound">
	<ion-content *ngIf="!isLoading">
		<ion-grid class="home-page min-height-content">
			<ion-row class="detail-page-search container" *ngIf="getPlatformService.isDesktop">
				<ion-col>
					<div class="search-results-header" *ngIf="commonService.domDirectionLTR">
						{{ results_count }} {{'searchBarComponent.searchresultsof'|translate}} '{{ search_type }}'
					</div>
					<div dir="ltr" class="search-results-header text-left" *ngIf="!commonService.domDirectionLTR">
						{{ results_count }} {{'searchBarComponent.searchresultsof'|translate}} '{{ search_type }}'
					</div>
				</ion-col>
			</ion-row>

			<ion-row *ngFor="let data of searchTypeData" class="detail-page-search container grid-row">
				<ion-col size="12">
					<div class="search-products pt-30 pb-10 mob-ph-0">
						{{commonService.domDirectionLTR ? data['search_type_en'] : data['search_type_ar'] }}
						({{data['total_count']}} {{'searchBarComponent.results'|translate}})
					</div>
				</ion-col>

				<div class="search-categories-results" *ngIf="data['search_type_en'] != 'PRODUCTS'">
					<div class="results">
						<div *ngFor="let item of data['result'];index as i"
							[ngClass]="item['is_image_vislble'] ? 'results-container2':'results-container mr-20'"
							(click)="goToProductListing(item)">

							<div class="serach-img mr-10" *ngIf="item['is_image_vislble']">
								<img src="{{item['item_image_web']}}" alt="Jazeera" />
							</div>

							<div class="search-text">
								<span *ngIf="commonService.domDirectionLTR"
									[innerHTML]="item['item_name_en'] | highlighter:searchInput"></span>
								<span *ngIf="!commonService.domDirectionLTR"
									[innerHTML]="item['item_name_ar'] | highlighter:searchInput"></span>
							</div>

						</div>
						<ng-container *ngFor="let item of data['result'];index as i;let last = last">
							<div *ngIf="last && enableNextCategory" class="load-more" (click)="loadCategoryData(data)">
								<span>
									...{{'searchBarComponent.more'|translate}}
								</span>
							</div>
						</ng-container>
					</div>
				</div>

				<!-- <ion-content class="search-content"> -->
				<!-- <ion-grid class="grid-data mb-20" *ngIf="data['search_type_en'] == 'PRODUCTS'">
                    <ion-row>
                        <ion-col class="pb-40 mob-pb-20 grid-col" *ngFor="let item of gridArray" size-xs="6" size-sm="6"
                            size-md="4" size-lg="4" size-xl="2.4">
                            <app-product-card (productClicked)="goToProductDetail(item)" [cardData]="item"
                                [productDetailLabel]="true" [searchPage]="true"></app-product-card>
                        </ion-col>
                    </ion-row>
                </ion-grid> -->

				<ion-row class="row-alignment width-100" *ngIf="data['search_type_en'] == 'PRODUCTS'">
					<ng-container *ngFor="let item of gridArray;index as i;">
						<ion-col size-xs="5.5" size-sm="5.8" size-md="4" size-lg="4" size-xl="2.4">
							<app-product-card (productClicked)="goToProductDetail(item)" [cardData]="item"
								[productDetailLabel]="true" [searchPage]="true"></app-product-card>
						</ion-col>
					</ng-container>
				</ion-row>


				<ion-row class="pt-40 mob-pt-20" *ngIf="data['search_type_en'] == 'PRESS'">
					<ion-col class="press-col pr-40 mob-pr-10" size-xs="12" size-sm="12" size-md="4" size-lg="4"
						size-xl="4" *ngFor="let data of data['result']">
						<div class="press-header pb-20">
							{{ data['header'] }}
						</div>
						<div class="press-para pb-10">
							{{ data['para'] }}
						</div>
						<div class="read-more">
							{{'searchBarComponent.Readmore'|translate}} <img class="ml-5"
								src="assets/icon/right-arrow.svg" alt="Jazeera" height="100%" width="100%">
						</div>
					</ion-col>
				</ion-row>
			</ion-row>

			<ion-row class="load-more-row ion-justify-content-center pb-80">
				<app-common-button (click)="loadData($event)" color="white" *ngIf="enableNext && !isLoadMoreLoading"
					background="var(--ion-app-primary)" type="solid"
					title="{{ 'productGridComponent.Load more' | translate }}" [isIcon]="true"
					iconSrc="assets/icon/down-arrow.svg" [specificPaddingStart]="'40px'" [specificPaddingEnd]="'10px'"
					[isHideInMobile]="true" [specificWidth]="'190px'" [specificHeight]="'46px'"
					[specificFontWeight]="'bold'"></app-common-button>
				<ion-spinner *ngIf="isLoadMoreLoading" name="circular"></ion-spinner>
			</ion-row>



		</ion-grid>
		<ion-infinite-scroll (ionInfinite)="loadData($event,data)" *ngIf="!getPlatformService.isDesktop">
			<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
				loadingText="{{'lodingMoreData' | translate}}">
			</ion-infinite-scroll-content>
		</ion-infinite-scroll>
		<!-- </ion-content> -->
		<div class="wrapper-block home-page">
			<div class="app-footer">
				<app-footer></app-footer>
			</div>
		</div>
	</ion-content>
</ng-container>