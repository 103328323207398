<ion-grid class="order-detail-grid">
	<ion-row class="order-detail-header-row">
		<ion-col class="order-detail-header-col">
			<div class="order-detail-header">
				<div class="order-number">
					{{"orderDetailsComponent.orders" | translate}}{{ orderData['order_no']}}
				</div>
				<div class="order-detail-actions">
					<div class="re-order">
						<div class="pt-5">
							<img src="assets/icon/reorder-icon.svg" alt="Jazeera">
						</div>
						<div class="pl-5" (click)="reorder()">
							{{"orderDetailsComponent.reorder" | translate}}
						</div>
					</div>
				</div>
			</div>
			<div class="order-detail-info pt-20">
				<div class="order-detail-date">
					<div class="common-detail-header">
						{{"orderDetailsComponent.date" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['order_date'] | date : 'MMM dd, YYYY'}}
					</div>
				</div>
				<div class="order-total">
					<div class="common-detail-header">
						{{"orderDetailsComponent.totalOrder" | translate}}
					</div>
					<div class="common-detail">
						{{commonService.currencySymbol}} {{orderData['total_price'] ? (orderData['total_price'] |
						number) :'0'}}
					</div>
				</div>
				<div class="ship-to">
					<div class="common-detail-header">
						{{"orderDetailsComponent.shipTo" | translate}}
					</div>
					<div class="common-detail">
						{{orderData['shipping_address']}} {{orderData['city_en'] ? ',':''}} {{orderData['city_en'] ?
						orderData['city_en'] :''}}{{orderData['region_en'] ? ',':''}}
						{{orderData['region_en'] ? orderData['region_en'] :''}} {{orderData['country_en'] ? ',':''}}
						{{orderData['country_en'] ?
						orderData['country_en'] :''}} {{orderData['zipcode'] ? ',':''}} {{orderData['zipcode'] ?
						orderData['zipcode'] :''}}
						<p class="m-0 p-0">
							{{orderData['mobile_no']}}
						</p>
					</div>
				</div>
				<div class="order-detail-status pl-70">
					<div class="common-detail-header">
						{{"customField.paymentMode" | translate}}
					</div>
					<div>
						<ion-button fill="clear" class="order-status-btn"
							[ngClass]="orderStatus(orderData)">{{orderData.order_status
							== 'CANCELLED'
							? ('cancelled' | translate) :orderData.order_status == 'DISPATCHED' ?
							('dispatched' |
							translate) :orderData.order_status == 'DELIVERED' ? ('delivered' | translate)
							:('confirmed' |
							translate)}}
						</ion-button>
					</div>
				</div>
			</div>
		</ion-col>
	</ion-row>
	<div class="scrollable-container">
		<ion-row class="order-detail-row row-header">
			<ion-col size-md="12" size-lg="8" size-xl="8" class="order-detail-left-col">
				<ion-grid class="order-detail-list">
					<ion-row class="order-detail-list-header scrollable-row">
						<ion-col size="6" class="header-col">
							<div class="order-detail-header">
								{{"orderDetailsComponent.item" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header">
								{{ "orderDetailsComponent.orderQty" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header">
								{{"orderDetailsComponent.deliverQty" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header">
								{{"orderDetailsComponent.invoiceQty" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header">
								{{"orderDetailsComponent.balanceQty" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header">
								{{"orderDetailsComponent.price" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="order-detail-header pl-25">
								{{"orderDetailsComponent.status" | translate}}
							</div>
						</ion-col>
						<ion-col size="2">
							<div class="ion-text-end pe-20 order-detail-header mr-15">
								{{"orderDetailsComponent.subTotal" | translate}}
							</div>
						</ion-col>
					</ion-row>
					<ion-grid class="order-detail-inner-grid " *ngFor="let detailData of supplierData">
						<ion-row class="supplier-name-row scrollable-row">
							<ion-col size="12" class="supplier-name-col pl-0">
								<div class="supplier-name mt-5"
									[ngClass]="commonService.domDirectionLTR ? '' : 'supplier-name-rtl'">
									{{ commonService.domDirectionLTR ? detailData.supplier_legal_name_en :
									detailData.supplier_legal_name_ar}}
								</div>
							</ion-col>
						</ion-row>
						<ng-container *ngIf="detailData.products && detailData.products.length">
							<ion-row *ngFor="let supplierData of detailData.products"
								class="product-details-row pt-15 pb-10 scrollable-row">
								<ion-col size="6" class="product-details-col pl-0">
									<div class="product-container">
										<div class="product-image" (click)="goToProductDetail(supplierData)">
											<img [src]="supplierData.product_cover_image[0].image_url"
												*ngIf="supplierData.product_cover_image && supplierData.product_cover_image.length"
												alt="Jazeera">
											<img src="./assets/images/empty-img.png"
												*ngIf="!supplierData.product_cover_image || (supplierData.product_cover_image && !supplierData.product_cover_image.length)"
												alt="Jazeera">
										</div>
										<div class="product-description pl-20">
											<div class="product-name pb-5" (click)="goToProductDetail(supplierData)">
												{{ commonService.domDirectionLTR ? supplierData.product_name_en :
												supplierData.product_name_ar}}
											</div>
											<!-- <div *ngIf="supplierData.is_sample_product" class="product-info mb-10">
											{{'SAMPLE' | translate}}
										</div> -->
											<ng-container
												*ngIf="supplierData.product_configuration && supplierData.product_configuration.length">
												<div class="product-color pb-5"
													*ngFor="let confg of supplierData.product_configuration">
													<span class="color-text">{{ commonService.domDirectionLTR ?
														confg.configuration_name_en :
														confg.configuration_name_ar}}:</span>
													{{ commonService.domDirectionLTR ? confg.configuration_value_en :
													confg.configuration_value_ar}}
												</div>
											</ng-container>
										</div>
									</div>
								</ion-col>

								<ion-col size="2" class="product-details-col pl-0">
									<div class="product-price">
										{{ supplierData.total_quantity }}
									</div>
								</ion-col>

								<ion-col size="2" class="product-details-col pl-0">
									<div class="product-price">
										{{ supplierData.total_quantity }}
									</div>
								</ion-col>

								<ion-col size="2" class="product-details-col pl-0">
									<div class="product-price">
										{{ supplierData.total_quantity }}
									</div>
								</ion-col>

								<ion-col size="2" class="product-details-col pl-0">
									<div class="product-price">
										{{ supplierData.total_quantity }}
									</div>
								</ion-col>

								<ion-col size="2" class="product-details-col pl-0">
									<div class="product-price">
										{{commonService.currencySymbol}} {{
										supplierData.is_discount_applied ? supplierData.discounted_price_per_product ?
										(supplierData.discounted_price_per_product | number) :'0':
										supplierData.price_per_unit ? (supplierData.price_per_unit | number) : '0'}}
										<span class="product-unit">/{{
											commonService.domDirectionLTR ? supplierData.unit_name_en :
											supplierData.unit_name_ar}}</span>
										<span class="price-units"
											*ngIf="supplierData.unit_id && supplierData.secondary_unit_id && supplierData.unit_id !== supplierData.secondary_unit_id">
											({{calculateSUOM(supplierData.conversation_ratio ,
											supplierData.total_quantity)}})
										</span>
										<!-- <span class="product-unit">
											({{commonService.domDirectionLTR ?
											supplierData.secondary_unit_name_en :supplierData.secondary_unit_name_ar}}) </span> -->
									</div>
								</ion-col>
								<ion-col size="2" class="ion-text-center product-details-col pl-0">
									<div class="product-status">
										<ion-button fill="clear" class="order-status-btn"
											[ngClass]="orderStatus(supplierData,true) "
											*ngIf="supplierData.shipping_status == 'DISPATCHED'">
											{{('dispatched' |translate)}}
										</ion-button>

										<ion-button fill="clear" class="order-status-btn"
											[ngClass]="orderStatus(supplierData,true) "
											*ngIf="supplierData.shipping_status == 'NOT_YET_DELIVERED'">
											{{('notYetDelivered'
											| translate) }}
										</ion-button>

										<ion-button fill="clear" class="order-status-btn"
											[ngClass]="orderStatus(supplierData,true) "
											*ngIf="supplierData.shipping_status == 'NOT_DELIVERED' || supplierData.shipping_status == 'NOT DELIVERED'">
											{{('notYetDelivered'
											| translate) }}
										</ion-button>

										<ion-button fill="clear" class="order-status-btn btn-red product-status-btn"
											*ngIf="supplierData.shipping_status == 'RETURNED'">{{('returned'
											| translate) }}
										</ion-button>

										<ion-button fill="clear" class="order-status-btn btn-red product-status-btn"
											*ngIf="supplierData.shipping_status == 'CANCELLED'">{{('cancelled'
											| translate) }}
										</ion-button>

										<ion-button fill="clear" class="order-status-btn btn-green product-status-btn"
											*ngIf="supplierData.shipping_status == 'DELIVERED'">{{('delivered'
											| translate) }}
										</ion-button>

										<ion-button fill="clear" class="order-status-btn btn-green product-status-btn"
											*ngIf="supplierData.shipping_status == 'CONFIRMED'">{{('confirmed'
											| translate) }}
										</ion-button>

										<ion-button fill="clear"
											class="order-status-btn  btn-orange product-status-btn return-request-btn"
											*ngIf="supplierData.shipping_status == 'CANCELLATION_REQUESTED'">{{"orderDetailsComponent.cancelRequested"
											| translate}}
										</ion-button>

										<ion-button fill="clear"
											class="order-status-btn  btn-orange product-status-btn return-request-btn"
											*ngIf="supplierData.shipping_status == 'RETURN REQUESTED'">{{"orderDetailsComponent.returnRequested"
											| translate}}
										</ion-button>

										<!-- --------- two button cancel and return ----------------- -->
										<ion-button fill="clear"
											class="order-status-btn product-status-btn return-request-btn"
											*ngIf="supplierData.is_cancellation_allowed">
											{{"orderDetailsComponent.cancelProduct" | translate}}
										</ion-button>


										<div *ngIf="supplierData.is_return_allowed" class="cancel-product">
											<span (click)="openCancelProductModal(supplierData,true)">
												{{"orderDetailsComponent.returnProduct" | translate}}
											</span>
										</div>
										<!-- <div *ngIf="supplierData.is_cancellation_allowed" class="cancel-product">
                                        <span (click)="openCancelProductModal(supplierData)">
                                            {{"orderDetailsComponent.cancelProduct" | translate}}
                                        </span>
                                    </div> -->
									</div>
								</ion-col>
								<ion-col size="2" class="ion-text-end product-details-col pr-20">
									<div class="subtotal">
										{{commonService.currencySymbol}} {{ supplierData.total_price ?
										(supplierData.total_price | number) : '0'}}
									</div>
								</ion-col>
								<div class="product-detail-divider mt-5"
									[ngClass]="commonService.domDirectionLTR ? '' : 'product-detail-divider-rtl'">
								</div>
							</ion-row>
						</ng-container>

						<!-- -------------------------- total ---------------------------- -->
						<ion-row>
							<ion-col class="bottom-subtotal-col"
								[ngClass]="!detailData.track_shippment ? 'bottom-shippment-button' : ''" size="12">
								<div *ngIf="detailData.track_shippment" class="shippment-button-container ">
									<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
										title="{{'orderDetailsComponent.trackShippment' | translate}}"
										[isShippmentIcon]="true" [isHideInMobile]="false"
										iconSrc="assets/icon/shippment_icon.svg" [specificWidth]="'208px'"
										[specificHeight]="'38px'" [specificFontSize]="'0.875rem'">
									</app-common-button>
								</div>

								<app-order-summary [currency]="commonService.currencySymbol" class="width-40p"
									[orderSummeryData]="detailData['sub_total_price']" isTotalSummary="false">
								</app-order-summary>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-grid>
			</ion-col>
			<ion-col size-md="12" size-lg="4" size-xl="4" class="order-detail-right-col"
				[ngClass]="commonService.domDirectionLTR ? '' : 'order-detail-right-col-rtl'">
				<div class="order-summery-header pb-20">
					{{"orderDetailsComponent.orderSummery" | translate}}
				</div>
				<div>
					<app-order-summary [currency]="commonService.currencySymbol" [orderTotal]="orderData.total_price"
						[orderSubTotal]="orderData.items_sub_total" [orderDiscount]="orderData.discount_price"
						[orderVAT]="orderData.vat" [vatPercent]="orderData.vat_percent"
						[orderSummeryData]="orderData.grand_total" [discountCode]="orderData.discount_code"
						[orderShipping]="orderData.shipping_charges" isTotalSummary="true"
						[shippingVat]="orderData.shipping_vat_charges"
						[orderShippingWithoutVat]="orderData.shipping_charges_without_vat">
					</app-order-summary>
				</div>
			</ion-col>
		</ion-row>
	</div>

</ion-grid>