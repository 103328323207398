import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../common/shared.module';
import { MobileMenuComponent } from './mobile-menu.component';
import { MobileMenuPageRoutingModule } from './mobile-menu.routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MobileMenuPageRoutingModule,
        SharedModule,
    ],
    declarations: [
        MobileMenuComponent,
    ]
})
export class MobileMenuPageModule { }
