import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartPageRoutingModule } from './cart-page-routing.module';

import { SharedModule } from '../../common/shared.module';
import { CartPage } from './cart-page.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CartPageRoutingModule,
        SharedModule,
    ],
    declarations: [CartPage]
})
export class CartPageModule { }
