import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToastService } from 'src/app/services/toast.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { AddEditAddressPage } from './../../pages/add-edit-address/add-edit-address.page';
import { ApiService } from './../../services/api-data.service';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
	editBtnLabel = "edit";
	@Input() showEditBtn?: any;
	@Input() showTable: boolean;
	title = "My Address";

	componentData = [
		{
			section_name: 'addressComponent.additionaladdressentries',
			section_btn: 'addressComponent.AddNewAddress',
			section_content: '',
			route: '',
			id: '1'
		}
	];

	addressList = [];

	additionalAddressArray = [];
	showHeader = false;
	public isLoading = false;
	private subscription: Subscription = new Subscription();

	countryData: any = [];
	regionData: any = [];
	cityData: any = [];

	constructor(private modalService: ModalService,
		public commonService: CommonService,
		public getPlatformService: GetPlatformService,
		private dataService: ApiService,
		private translate: TranslateService,
		private alertController: AlertController,
		public navCtrl: NavController,
		private toastService: ToastService,
		private router: Router) {

	}

	ngOnInit() {
		this.getAddresses();
		this.getDefaultAddresses();
	}

	getAddresses() {
		this.isLoading = true;
		this.additionalAddressArray = [];
		this.subscription.add(
			this.dataService.get(URLS.getAddressList).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.isLoading = false;
					this.additionalAddressArray = data['data'];
					if (this.additionalAddressArray.length < 1) {
						this.commonService.setEvent('profileData', null);
					}
					this.additionalAddressArray.forEach(element => {
						if (element.is_default_billing_address == true) {
							element.address_label = "addressComponent.billingAddress";
							element.address_type = 'billing';
						}
						else if (element.is_default_shipping_address == true) {
							element.address_label = "addressComponent.shippingAddress";
							element.address_type = 'shipping';
						}
						else {
							element.address_label = "field.Address";
							return;
						}
					});

					if (this.additionalAddressArray.length == 1 &&
						this.additionalAddressArray[0].is_default_billing_address == false &&
						this.additionalAddressArray[0].is_default_shipping_address == false) {
						this.changeShippingAddress(this.additionalAddressArray[0].address_id);
						return;
					}

					let tempArray = [];
					this.additionalAddressArray.forEach((element, index) => {
						if (element.address_type == 'shipping') {
							tempArray.splice(0, 0, element)
						}
						if (element.address_type == 'billing') {
							tempArray.splice(1, 0, element)
						}
						if (element.address_type != 'shipping' && element.address_type != 'billing') {
							tempArray.push(element)
						}
					});

					this.additionalAddressArray = [...tempArray];
				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	getDefaultAddresses() {
		this.isLoading = true;


		this.subscription.add(
			this.dataService.get(URLS.getDefaultAddressList).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.isLoading = false;
					let defaultAddress = data['data']['default_addresses'];

					defaultAddress.forEach((element, index) => {
						if (element.is_default_billing_address == true) {
							element.address_label = "addressComponent.billingAddress";
							element.address_type = 'billing';
						}
						else if (element.is_default_shipping_address == true) {
							element.address_label = "addressComponent.shippingAddress";
							element.address_type = 'shipping';
						}
					});

					if (defaultAddress.length == 1 && (defaultAddress[0].is_default_shipping_address == true || defaultAddress[0].is_default_billing_address == true)) {
						const obj2 = {
							address_label: defaultAddress[0].is_default_shipping_address == true && defaultAddress[0].is_default_billing_address == true ? 'addressComponent.shippingAddress' : defaultAddress[0].is_default_shipping_address == true ? "addressComponent.billingAddress" : "addressComponent.shippingAddress",
							address_type: defaultAddress[0].is_default_shipping_address == true && defaultAddress[0].is_default_billing_address == true ? 'shipping' : defaultAddress[0].is_default_shipping_address == true ? 'billing' : 'shipping',
							address: defaultAddress[0].address,
							address_id: defaultAddress[0].address_id,
							address_title: defaultAddress[0].address_title,
							city_ar: defaultAddress[0].city_ar,
							city_code: defaultAddress[0].city_code,
							city_en: defaultAddress[0].city_en,
							city_id: defaultAddress[0].city_id,
							country_ar: defaultAddress[0].country_ar,
							country_en: defaultAddress[0].country_en,
							country_id: defaultAddress[0].country_id,
							first_name: defaultAddress[0].first_name,
							is_default_billing_address: defaultAddress[0].is_default_billing_address,
							is_default_shipping_address: defaultAddress[0].is_default_shipping_address,
							is_deleted: defaultAddress[0].is_deleted,
							last_name: defaultAddress[0].last_name,
							mobile_no: defaultAddress[0].mobile_no,
							region_ar: defaultAddress[0].region_ar,
							region_code: defaultAddress[0].region_code,
							region_en: defaultAddress[0].region_en,
							region_id: defaultAddress[0].region_id,
							zipcode: defaultAddress[0].zipcode
						}

						defaultAddress.push(obj2);
					}

					this.addressList = defaultAddress;


				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	ionViewWillEnter() {



	}
	goBack() {
		this.navCtrl.back({ animated: false });
	}

	async deleteModal(addressId) {

		const alert = await this.alertController.create({
			header: this.translate.instant("Deleteaddress"),
			message: this.translate.instant("DeleteAddressMsg"),
			buttons: [
				{
					text: this.translate.instant('no'),
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: this.translate.instant('yes'),
					role: 'confirm',
					handler: () => {
						this.deleteAddressAPI(addressId);
					},
				},
			],
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();
	}

	deleteAddressAPI(addressId) {
		const params = {
			"address_id": +addressId,
			"is_deleted": true
		}

		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.updateAddress, params).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.isLoading = false;
					this.getAddresses();
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	async openModal(value?, object?) {
		if (!value && this.additionalAddressArray.length > 9) {
			this.toastService.displayToast("Maximum 10 addresses can be added!", null, 'danger');
			return;
		}

		if (value) {

			let promiseArray = [
				this.getCountryList().then(data => { this.countryData = data }),
				this.getRegionList(object.country_id).then(data => { this.regionData = data }),
				this.getCityList(object.region_id).then(data => { this.cityData = data }),

			];

			Promise.all(promiseArray).then(dataResponse => {
				let componentData = {
					mobileView: true,
					address_id: value,
					countryData: this.countryData,
					cityData: this.cityData,
					regionData: this.regionData,
				}
				let options: any = {
					componentProps: componentData,
					id: "addressModal",
					cssClass: 'location-popover'
				};

				options['component'] = AddEditAddressPage;

				let modal = this.modalService.openModal(options);
				modal.then((data) => {
					if (data['data']['data']) {
						this.getAddresses();
						this.getDefaultAddresses();
					}
				})

			}, error => {
			});
		}

		else {
			let componentData = { mobileView: true }

			let options: any = {
				componentProps: componentData,
				id: "addressModal",
				cssClass: 'location-popover'
			};

			options['component'] = AddEditAddressPage;
			let modal = this.modalService.openModal(options);
			modal.then((data) => {
				if (data['data']['data']) {
					this.getAddresses();
					this.getDefaultAddresses();
				}
			})
		}



	}

	async getCountryList() {
		return new Promise((resolve, reject) => {
			let params = {
				dropdown: true,
			}

			this.subscription.add(
				this.dataService.get(URLS.countryList, params).subscribe(data => {
					if (data['data'] && data['code'] == 200) {
						this.isLoading = false;
						this.countryData = data['data']['result'];
						resolve(this.countryData);
					}
				}, error => {
					reject(error);
					this.toastService.displayToast(error, null, 'danger');
				})
			)
		})

	}

	async getRegionList(countryid) {
		return new Promise((resolve, reject) => {
			let params = {
				dropdown: true,
				countries: +countryid
			}

			this.subscription.add(
				this.dataService.get(URLS.regionList, params).subscribe(data => {
					if (data['data'] && data['code'] == 200) {
						this.regionData = data['data']['result'];
						resolve(this.regionData)
					}
				}, error => {
					reject(error)
					this.toastService.displayToast(error, null, 'danger');
				})
			)
		})
	}

	async getCityList(stateid) {
		return new Promise((resolve, reject) => {
			let params = {
				dropdown: true,
				regions: +stateid
			}

			this.subscription.add(
				this.dataService.get(URLS.cityList, params).subscribe(data => {
					if (data['data'] && data['code'] == 200) {
						this.cityData = data['data']['result'];
						resolve(this.cityData)
					}

				}, error => {
					reject(error)
					this.toastService.displayToast(error, null, 'danger');
				})
			)
		})

	}

	changeShippingAddress(addressId) {
		const params = {
			"address_id": +addressId,
			"is_default_shipping_address": true,
			"is_default_billing_address": true,
		}

		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.updateAddress, params).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					this.isLoading = false;
					this.getDefaultAddresses();
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

}
