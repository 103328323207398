import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductListingPage } from './product-listing.page';
import { ProductDetailPage } from '../product-detail/product-detail.page';

const routes: Routes = [
  {
    path: '',
    component: ProductListingPage
  },
  {
    path: ':category',
    children: [{
      path: 'product-detail/:id',
      loadChildren: () => import('../../pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
    },
    {
      path: '',
      component: ProductListingPage,
      pathMatch: "full"
    }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductListingPageRoutingModule { }
