import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReturnOrderListingComponent } from './return-order-listing.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';

const routes: Routes = [
  {
    path: '',
    component: ReturnOrderListingComponent
  },
  {
    path: "return-request",
    data: {
      breadcrumb: "returnRequest",
      url: "/home/account-details/my-account/return-order-listing/"
    },
    loadChildren: () => import('../return-order-request/return-order-request.module').then(m => m.ReturnOrderRequestModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReturnOrderListingRoutingModule { }
