import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CheckOutStepReviewPageRoutingModule } from './checkout-step-review-routing.module';

import { BankTranferComponent } from 'src/app/components/bank-transfer/bank-transfer.component';
import { PaymentCardComponent } from 'src/app/components/payment-card/payment-card.component';
import { SharedModule } from '../../common/shared.module';
import { CheckOutStepReviewPage } from './checkout-step-review';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		CheckOutStepReviewPageRoutingModule,
		SharedModule,
	],
	declarations: [CheckOutStepReviewPage, PaymentCardComponent, BankTranferComponent]
})
export class CheckOutStepReviewPageModule { }
