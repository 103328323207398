import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CheckOutStepReviewPageRoutingModule } from './checkout-step-review-routing.module';

import { SharedModule } from '../../common/shared.module';
import { CheckOutStepReviewPage } from './checkout-step-review';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CheckOutStepReviewPageRoutingModule,
        SharedModule,
    ],
    declarations: [CheckOutStepReviewPage]
})
export class CheckOutStepReviewPageModule { }
