import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../common/shared.module';
import { OrderPaymentRoutingModule } from './order-payment-routing.module';
import { OrderPaymentComponent } from './order-payment.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        OrderPaymentRoutingModule
    ],
    declarations: [OrderPaymentComponent]
})
export class OrderPaymentModule { }
