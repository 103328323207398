import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { SettingRoutingModule } from './setting-routing.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SharedModule,
        SettingRoutingModule
    ]
})
export class SettingModule { }
