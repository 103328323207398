import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-recommandetion-grid',
    templateUrl: './recommandetion-grid.component.html',
    styleUrls: ['./recommandetion-grid.component.scss'],
})
export class ReccommandetionGridComponent implements OnInit {


    public recommendedData = [
    
    ];


    constructor() {
    }

    ngOnInit() { }

}
