<div class="orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{"voucherComponent.title" | translate}}
		</h1>
		<div class="search-and-filter">
			<div class="filter-div">
				<div class="search-div">
					<!-- <ion-item lines="none"> -->
					<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
						[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (keyup.enter)="search()">
						<img src="./assets/icon/search.svg" slot="start" (click)="search()" alt="Jazeera">
						<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
							alt="Jazeera" (click)="clearSearch()">
					</ion-input>
					<!-- </ion-item> -->
				</div>
			</div>
		</div>
	</div>

	<ion-row class="align-center mb-10 justify-space-beetwen">
		<div class="desktop-visibility-in-flex">
			<div class="printer-icon mr-15">
				<img src="./assets/icon/barcode.svg" (click)="downloadExcel()" class="barcode-icon"
					title="{{'voucherComponent.barcode'|translate}}" alt="Jazeera">
			</div>

			<div class="input-div-container">
				<div class="from-date-div">
					<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
						id="voucherNo">
						<ion-input [(ngModel)]="voucherNumebr" placeholder="{{'voucherComponent.field'
					                                | translate}}" class="ion-text-start"></ion-input>

					</ion-item>
				</div>
			</div>

			<div class="filter-buttons ml-10">
				<div class="apply-button ion-text-end">
					<button class="filter-apply-button" (click)="clearFilter()">{{"redeem" |
						translate}}</button>
				</div>
			</div>
		</div>
		<div class="desktop-visibility-in-flex">
			<ion-row class="align-center mv-5">
				<p class="m-0"> {{"voucherComponent.label"|translate}} <span class="text-bold">SR 23,004</span></p>
				<div class="more-info-tooltip ml-10"><img src="./assets/icon/info.svg" alt="Jazeera" class="info-icon">
					<span class="more-info-tooltiptext more-info-file-tooltip">{{'voucherComponent.tooltipLabel'|
						translate}}</span>
				</div>
			</ion-row>
		</div>


	</ion-row>



	<div class="listing-table">
		<app-loader *ngIf="isLoading"></app-loader>
		<table class="table" *ngIf="!isLoading">
			<thead>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.voucherId"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.amount"|
					translate}}
				</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.expiryDate"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.status"|
					translate}}</th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let order of orderList;index as i" class="table-row">
					<td class="ion-text-center">{{order.voucherId}}</td>
					<td class="ion-text-center">{{commonService.currencySymbol}}{{(order.amount|number) || 0}}</td>
					<td class="ion-text-center">{{order.expiryDate | date :'MMM dd, YYYY'}}</td>
					<td class="ion-text-center">
						<ion-button fill="clear" class="order-status-btn" [ngClass]="orderStatus(order)">
							{{order.order_status == 'PENDING' ? ('pending' | translate)
							:('pending' | translate)}}
						</ion-button>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!orderList.length">
			{{'noOrdersYet' | translate}}
		</div>
	</div>

	<div class="mobile-view-listing">
		<ion-content class="orders-ion-content">
			<div class="order-container">
				<ion-grid>
					<ion-row class="order-card" *ngFor="let order of orderList;index as i">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.voucherId" | translate}}</div>
										<div class="grid-values">{{order.voucherId}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.amount" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}{{order.amount}}
										</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.expiryDate" | translate}}</div>
										<div class="grid-values">{{order.expiryDate}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-values">
											<ion-button fill="clear" class="order-status-btn"
												[ngClass]="orderStatus(order)">
												{{order.order_status == 'PENDING' ? ('pending' | translate)
												:('pending' | translate)}}
											</ion-button>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'lodingMoreData' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</ion-content>
	</div>

	<div class="pagination-wrapper" *ngIf="orderList.length && from != 'dashboard'">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col">
				<ion-list class="perpage-dropdown">
					<ion-item class="ion-card" lines="none">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							interface="popover" [interfaceOptions]="perPageInterface"
							(ionChange)="selectPageLength($event)" [(ngModel)]="itemsPerPage">
							<ion-select-option value="10">10</ion-select-option>
							<ion-select-option value="20">20</ion-select-option>
						</ion-select>
					</ion-item>
					<ion-label class="perpage-title">{{"voucherComponent.per_page" | translate}}</ion-label>
				</ion-list>
			</ion-col>
			<ion-col size="8" class="pagination-col">
				<app-tile class="grid-tile" *ngFor="let post of orderList | paginate :
    { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
    let i = index" [current]="post"></app-tile>
				<pagination-controls class="paginator"
					[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
					previousLabel="{{'voucherComponent.prev' | translate}}"
					nextLabel="{{'voucherComponent.next' | translate}}"
					(pageChange)="onChange($event)"></pagination-controls>
			</ion-col>
		</ion-row>
	</div>
</div>