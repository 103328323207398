<div class="two-fa-container">
	<div class="modal-close-mobile text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close-mobile.svg" alt="Jazeera">
	</div>
	<div class="header-line">
	</div>
	<div class="two-fa-header pb-10">
		{{'sortBy' | translate}}
	</div>
	<div class="sort-by-options">
		<ion-list>
			<ion-item *ngFor="let item of sortByOptions;index as i" lines="none">
				<ion-label class="sort-label" [ngClass]="[ item.checked ? 'label-checked' : 'checkbox-labels']">
					{{item.translateLabel | translate}}</ion-label>
				<ion-checkbox [ngClass]="[ item.checked ? 'checkbox-checked' : 'checkbox-labels']"
					[checked]="item.checked" mode="md" slot="start" (ionChange)="selectCheckbox($event, i)"
					[value]="item.value" [disabled]="item.checked"></ion-checkbox>
			</ion-item>
		</ion-list>
	</div>
</div>