import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from '../../services/modal.service';
@Component({
	selector: 'app-register-now',
	templateUrl: './register-now.component.html',
	styleUrls: ['./register-now.component.scss'],
})
export class RegisterNowComponent implements OnInit {

	constructor(private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		public navCtrl: NavController,
		public translate: TranslateService) { }

	ngOnInit() { }


}
