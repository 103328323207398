import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddEditPaymentCardPageRoutingModule } from './add-edit-payment-card-routing.module';

import { SharedModule } from '../../common/shared.module';
import { AddEditPaymentCardPage } from './add-edit-payment-card.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AddEditPaymentCardPageRoutingModule,
        SharedModule
    ],
    declarations: [AddEditPaymentCardPage]
})
export class AddEditPaymentCardPageModule { }
