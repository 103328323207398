<ion-header class="mobile-visibility">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="gomobileBack()">
			<ion-title mode="ios">{{"cmsComponent.press" | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>

<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="getPlatformService.isDesktop">
	<div class="detail-page">
		<div class="cms-wrapper">
			<div class="page-heading">{{"cmsComponent.press" | translate}}</div>
			<div class="ion-content">
				<ion-grid>
					<ion-row *ngIf="!isLoading && pressGridData && pressGridData.length > 0">
						<ion-col *ngFor="let cardData of pressGridData" size-xs="6" size-sm="3" size-md="4" size-lg="4"
							size-xl="3">
							<div class="slide-container"
								[ngClass]="['productDetailLabel' ? 'detail-slide-container' : '']">
								<div class="product-image-container"
									[ngClass]="categories ? 'categories-image-container' : 'product-image-container'">
									<div class="product-list-image-div">
										<img *ngIf="cardData.media_url && cardData.media_type != 'mp4'"
											[src]="cardData.media_url" width="100%" height="100%" alt="Jazeera">
										<img *ngIf="!cardData.media_url  && cardData.media_type != 'mp4'"
											src="assets/images/no-img1.png" width="100%" height="100%" alt="Jazeera">

										<video width="100%" height="215px" controls #player id="player"
											*ngIf="cardData.media_url && cardData.media_type == 'mp4'"
											poster="assets/icon/companyLogo.svg">
											<source [src]="cardData.media_url" type="video/mp4">
										</video>

									</div>
								</div>
								<div class="press-title text-left"
									[ngClass]="categories ? 'categories-press-title' : ''">
									<span class="text-ellipse">{{commonService.domDirectionLTR ?
										cardData.content_title_en :
										cardData.content_title_ar
										}}</span>
								</div>
								<div class="items-available text-left pt-5" *ngIf="cardData.created_at">
									{{ cardData.created_at ? (cardData.created_at | date:'MMM dd,yyyy'):null }}
								</div>
								<div class="press-description text-left">
									<div class="from">
										{{commonService.domDirectionLTR ? cardData.content_en : cardData.content_ar}}
									</div>
								</div>
								<div class="text-right">
									<p class="view-label pointer" (click)="goToPressDetail(cardData)">
										{{'ViewDetails'|translate}}</p>
								</div>
							</div>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</div>
		<div class="pagination-wrapper" *ngIf="pressGridData && pressGridData.length > 0">
			<ion-row class="pagination-div">
				<ion-col size="12" class="text-center return-orders-pagination-col">
					<app-tile class="grid-tile" *ngFor="let post of pressGridData | paginate :
            { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
            let i = index" [current]="post"></app-tile>
					<pagination-controls class="paginator"
						[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
						previousLabel="{{'prev' | translate}}" nextLabel="{{'next' | translate}}"
						(pageChange)="onChange($event)"></pagination-controls>
				</ion-col>
			</ion-row>
		</div>

		<div *ngIf="pressGridData && pressGridData.length <= 0 && !isLoading">
			<div class="no-record-found ion-text-center">
				{{'noRecordFound' | translate}}
			</div>
		</div>
	</div>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>

<ion-content *ngIf="!getPlatformService.isDesktop">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>

	<div class="detail-page">
		<div class="ion-content" *ngIf="!isLoading && pressGridData && pressGridData.length > 0">
			<div class="mobile-row pv-15 mh-15" *ngFor="let cardData of pressGridData;let i = index">
				<div class="slide-container" [ngClass]="['productDetailLabel' ? 'detail-slide-container' : '']">
					<div class="product-image-container"
						[ngClass]="categories ? 'categories-image-container' : 'product-image-container'">
						<div class="product-list-image-div mr-20">
							<img *ngIf="cardData.media_url && cardData.media_type != 'mp4'" [src]="cardData.media_url"
								width="100%" height="100%" alt="Jazeera">
							<img *ngIf="!cardData.media_url  && cardData.media_type != 'mp4'"
								src="assets/images/no-img1.png" width="100%" height="100%" alt="Jazeera">

							<video width="100%" height="215px" controls
								controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
								disablepictureinpicture #player id="player" poster="assets/icon/companyLogo.svg"
								*ngIf="cardData.media_url && cardData.media_type == 'mp4'">
								<source [src]="cardData.media_url" crossorigin="anonymous" type="video/mp4">
							</video>

						</div>
					</div>
				</div>
				<div class="width-100">
					<div class="press-title pb-5 text-left" [ngClass]=" categories ? 'categories-press-title' : ''">
						<span class="text-ellipse">{{commonService.domDirectionLTR ?
							cardData.content_title_en :
							cardData.content_title_ar
							}}</span>
					</div>
					<div class="items-available pb-5 text-left" *ngIf="cardData.created_at">
						{{ cardData.created_at ? (cardData.created_at | date:'MMM dd,yyyy'):null }}
					</div>
					<div class="press-description text-left">
						{{commonService.domDirectionLTR ? cardData.content_en : cardData.content_ar}}
					</div>
					<div class="text-right">
						<p class="view-label pointer" (click)="goToPressDetail(cardData)">
							{{'ViewDetails'|translate}}</p>
					</div>
				</div>
			</div>
		</div>


		<div *ngIf="pressGridData && pressGridData.length <= 0 && !isLoading">
			<div class="no-record-found ion-text-center">
				{{'noRecordFound' | translate}}
			</div>
		</div>
	</div>

	<ion-infinite-scroll (ionInfinite)="loadData($event)" *ngIf="!getPlatformService.isDesktop">
		<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
			loadingText="{{'lodingMoreData' | translate}}">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</ion-content>