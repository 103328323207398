import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../common/shared.module';
import { PdfViewPageRoutingModule } from './pdf-view-routing.module';
import { PdfViewComponent } from './pdf-view.component';



@NgModule({
    declarations: [PdfViewComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        PdfViewPageRoutingModule
    ]
})
export class PdfViewModule { }
