<div class="home-page app-header">
	<app-header></app-header>
</div>
<ion-content>
	<ion-grid class="detail-page">
		<ion-row>
			<ion-col size="12">
				<div class="career-header">
					{{ 'careerPage.career' | translate }}
				</div>
			</ion-col>
		</ion-row>
		<ion-row [formGroup]="careerFromGroup">
			<ion-col size="12">
				<ion-row>
					<ion-col size="6">
						<div class="company-registration common-input"
							[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic' ]">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop"
									src="assets/icon/location-icon.svg" alt="Jazeera">
								<ion-label position="floating" *ngIf="isJobTypeSelected">{{ 'careerPage.jobType' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-label class="static-label" *ngIf="!isJobTypeSelected">{{ 'careerPage.jobType'
									| translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
									[ngClass]="[ isJobTypeSelected ? 'option-selected' : '']" interface="popover"
									[interfaceOptions]="jobSelect" (ionChange)="jobTypeSelected($event)"
									formControlName="jobType">
									<ion-select-option [value]="1">Job Type 1</ion-select-option>
									<ion-select-option [value]="2">Job Type 2</ion-select-option>
									<ion-select-option [value]="3">Job Type 3</ion-select-option>
								</ion-select>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'jobType', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size="6">
						<div class="company-registration common-input"
							[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic' ]">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop"
									src="assets/icon/location-icon.svg" alt="Jazeera">
								<ion-label position="floating" *ngIf="isJobRoleSelected">{{
									'careerPage.jobRole' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-label class="static-label" *ngIf="!isJobRoleSelected">{{
									'careerPage.jobRole'
									| translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
									[ngClass]="[ isJobRoleSelected ? 'option-selected' : '']" interface="popover"
									[interfaceOptions]="jobSelect" formControlName="jobRole"
									(ionChange)="jobRoleSelected($event)">
									<ion-select-option [value]="1">Job Role 1</ion-select-option>
									<ion-select-option [value]="2">Job Role 2</ion-select-option>
									<ion-select-option [value]="3">Job Role 3</ion-select-option>
								</ion-select>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'jobRole', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>
			</ion-col>
			<ion-col size="12">
				<ion-row>
					<ion-col size="6">
						<div class="first-name common-input">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
									'careerPage.firstName' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'careerPage.first_name' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-input formControlName="firstName" maxlength="50"></ion-input>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'firstName', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size="6">
						<div class="last-name common-input">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
									'careerPage.lastName' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'careerPage.last_name' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-input formControlName="lastName" maxlength="50"></ion-input>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'lastName', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>
			</ion-col>
			<ion-col size="12" class="ph-10">
				<div class="email common-input">
					<ion-item lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/email-icon.svg"
							alt="Jazeera">
						<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'EMAIL' | translate
							}}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Email' | translate
							}}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-input formControlName="email"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': careerFromGroup} }">
					</ng-container>
				</div>
			</ion-col>
			<ion-col size="12">
				<ion-row>
					<ion-col size="6">
						<div class="phone common-input">
							<ion-item class="mobile-phone" lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop"
									src="assets/icon/mobile_phone_icon.svg" alt="Jazeera">
								<label *ngIf="countryCodeLabel && getPlatformService.isDesktop"
									class="countrycode-label" slot="start">+966</label>
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'MOBILE_PHONE' |
									translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'mobile_phone'
									|
									translate }}<sup class="pl-5 required-star">*</sup></ion-label>
								<ion-input maxlength="9" inputmode="numeric"
									[ngClass]="commonService.domDirectionLTR ? 'mobilephone-input' : 'mobilephone-input-rtl'"
									(ionFocus)="onMobilePhoneFocus()" formControlName="mobilePhone"
									(keypress)="phoneClick($event)" (ionBlur)="onMobilePhoneBlur($event)"></ion-input>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'mobilePhone', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size="6">
						<div class="city common-input">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
									'careerPage.city'
									| translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'careerPage.city_titlecase' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-input formControlName="city"></ion-input>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'city', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>
			</ion-col>
			<ion-col size="12">
				<ion-row>
					<ion-col size="6">
						<div class="region common-input">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
									'careerPage.region' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'careerPage.region_titlecase' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-input formControlName="region"></ion-input>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'region', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
					<ion-col size="6">
						<div class="country common-input">
							<ion-item lines="none" mode="md">
								<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
									alt="Jazeera">
								<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{
									'careerPage.country' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{
									'careerPage.country_titlecase' | translate }}<sup class="pl-5 required-star">*</sup>
								</ion-label>
								<ion-input formControlName="country"></ion-input>
							</ion-item>
							<ng-container
								*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'country', 'formName': careerFromGroup} }">
							</ng-container>
						</div>
					</ion-col>
				</ion-row>
			</ion-col>
			<ion-col size="12" class="ph-10">
				<div class="company-registration common-input"
					[ngClass]="[ commonService.domDirectionLTR ? 'new-address-modal' : 'new-address-modal-arabic' ]">
					<ion-item lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/location-icon.svg"
							alt="Jazeera">
						<ion-label position="floating" *ngIf="isQualificationSelected">{{
							'careerPage.qualification' | translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-label class="static-label" *ngIf="!isQualificationSelected">{{
							'careerPage.qualification' | translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							[ngClass]="[ isQualificationSelected ? 'option-selected' : '']" interface="popover"
							[interfaceOptions]="jobSelect" formControlName="qualification"
							(ionChange)="qualificationSelected($event)">
							<ion-select-option [value]="1">Qualification 1</ion-select-option>
							<ion-select-option [value]="2">Qualification 2</ion-select-option>
							<ion-select-option [value]="3">Qualification 3</ion-select-option>
						</ion-select>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'qualification', 'formName': careerFromGroup} }">
					</ng-container>
				</div>
			</ion-col>
			<ion-col size="12" class="ph-10">
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating" [ngClass]="{'item-has-value' : indentityProof}">{{
							'careerPage.identityProof' | translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':indentityProof}" (change)="upload($event)"
							(click)="$event.stopPropagation()" #crNumber>
						<ion-input *ngIf="!indentityProof" readonly class="hidden-input"
							formControlName="indentityProof"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!indentityProof"
							(click)="crNumber.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="indentityProof" (click)="remove($event)">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="indentityProof" #companyCrNoElem>{{indentityProof}}</p>
					</ion-item>
					<ng-container *ngIf="isindentityProof">
						<ion-text color="danger" class="error-msg">
							{{ 'careerPage.identityProofRequired' | translate }}
						</ion-text>
					</ng-container>
				</div>
				<div class="footer-button-container">
					<div class="cancel" (click)="goToHome()">
						{{ 'Cancel' | translate }}
					</div>
					<div class="footer-button pl-25 pt-25 pb-25">
						<app-common-button color="#FFF" background="#0081CA" class="width-100"
							title=" {{ 'Submit' | translate }}" [isIcon]="false" [isHideInMobile]="false"
							[specificWidth]="'135px'" [specificHeight]="'46px'" [specificFontSize]="'0.9375rem'"
							form="loginForm" (buttonClicked)="careerSubmit()">
						</app-common-button>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
				{{validation.message | translate}}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>