import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-order-thank-you',
    templateUrl: './order-thank-you.component.html',
    styleUrls: ['./order-thank-you.component.scss'],
    providers: [NavParams]
})
export class OrderThankYouComponent implements OnInit {


    public isLoading = false;

    public subscription: Subscription = new Subscription();
    dateTime = new Date();

    orderId: any;
    invoiceId: any;
    transactionId: any;
    paymentId: any;
    paymentAmt: any;
    paymentStatus: any = "SADDAD";
    dueDate: any;
    constructor(private modalService: ModalService,
        private navParams: NavParams,
        public router: Router,
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        public navCtrl: NavController,
        public platform: Platform,
        private route: ActivatedRoute,
        public location: PlatformLocation,
        private translateService: TranslateService,
        public cartService: CartService) {

        this.route.queryParams
            .subscribe(params => {
                if (params['orderId']) {
                    this.transactionId = params['transaction_id'] ? params['transaction_id'] : null;
                    this.invoiceId = params['id'] ? params['id'] : null;
                    this.orderId = params['orderId'] ? params['orderId'] : null;
                    this.paymentId = params['payment_id'] ? params['payment_id'] : null;
                    this.paymentAmt = params['payment_amount'] ? params['payment_amount'] : null;
                    this.paymentStatus = params['payment_status'] ? params['payment_status'] : null;
                    this.dueDate = params['due_date'] ? params['due_date'] : null;

                    if (this.dueDate) {
                        this.paymentStatus = "SADDAD";
                    }
                }
            });
    }

    ngOnInit() {

    }

    closeModal() {
        this.modalService.dismissModal();
    }

    goToHome() {
        this.navCtrl.navigateRoot('/'+this.translateService.currentLang +"/home", { animated: false })
    }

    gotoOrderDetails() {
        if (this.getPlatformService.isDesktop) {
            this.router.navigate(['/'+this.translateService.currentLang +'/home/account-details/my-orders/', this.invoiceId],
                {
                    "state": {
                        "breadcrumb": this.translateService.instant('orderDetailsComponent.orders') + this.orderId,
                        "url": '/'+this.translateService.currentLang +'/home/account-details/my-orders/' + this.invoiceId
                    },

                })

        } else {
            this.navCtrl.navigateForward(['/'+this.translateService.currentLang +'/home/order-details-mobile'], {
                queryParams: {
                    invoiceId: this.invoiceId
                }
            })
        }
    }
    goToAccount() {
        if (this.getPlatformService.isDesktop) {
            this.navCtrl.navigateRoot('/'+this.translateService.currentLang +'/home/account-details/my-orders', { animated: false })
        } else {
            this.navCtrl.navigateRoot('/'+this.translateService.currentLang +'/my-account/orders', { animated: false })
        }
    }

    gotoCart() {
        this.navCtrl.navigateBack('/'+this.translateService.currentLang +"/cart", { animated: false })
    }

    goBack() {
        this.navCtrl.navigateBack('/'+this.translateService.currentLang +"/home", { animated: false })
    }


}
