<ion-header class="product-review-header" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="checkout-header">
				{{"checkout" | translate}}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>

<app-cart-header [countIndex]="3"></app-cart-header>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content *ngIf="getPlatformService.isDesktop && !isLoading">
	<ion-row [ngClass]="getPlatformService.isDesktop ? 'p-35-190':'p-20'">
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8" class="pr-30">
			<p *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)"
				class="title1 color-app-black">{{'checkoutStep2.Payment' | translate}}</p>
			<div>
				<div *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)">
					<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep2.Paymentmethod' | translate}}</p>
					<div *ngFor="let data of payment_methods;let i =index" class="border_class">
						<div class="display-div cursor-pointer" (click)="choosePamentMethods(i)">
							<div class="radio-size mr-15">
								<div *ngIf="!data.select" class="radio-div"></div>
								<img *ngIf="data.select" src="/assets/icon/check.svg" alt="Jazeera" />
							</div>
							<div class="display-div width-100">
								<p class="title2 color-app-black p-0 width-inherit">{{data.payment_type_value}}</p>
								<img *ngIf="i == 1" src="/assets/cards/cardicons.png" class="card-icons"
									alt="Jazeera" />
							</div>
						</div>

					</div>

				</div>

				<div>
					<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep2.Billinginfo' | translate}}</p>
					<div *ngIf="billing_address && billing_address.length">
						<div *ngFor="let data of billing_address;let i =index" class="border_class">
							<div class="display-div cursor-pointer" (click)="chooseAddress(data,i)">
								<div size="1" class="mr-15">
									<div *ngIf="!data.select" class="radio-div"></div>
									<img *ngIf="data.select" src="/assets/icon/check.svg" class="radio-size" />
								</div>
								<div>
									<p class="title2 color-app-black">{{'checkoutStep1.Address' |translate}}
										#{{i+1}}
									</p>
									<p class="label2 color-app-black">{{data.address}},
										{{commonService.domDirectionLTR ? data.city_en : data.city_ar}},
										{{commonService.domDirectionLTR ? data.region_en : data.region_ar}},
										{{commonService.domDirectionLTR ? data.country_en : data.country_ar}} -
										{{data.zipcode}}</p>
								</div>
							</div>


						</div>
					</div>

					<div *ngIf="!billing_address.length">
						<div class="no-data">
							{{"noRecordFound" | translate}}
						</div>
					</div>
				</div>


			</div>
		</ion-col>


		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4" class="pl-30">
			<p class="title3 color-app-black">{{'cartPage.Ordersummary' | translate}}</p>

			<app-order-summary *ngIf="this.cartData && this.cartData.grand_total"
				[shippingEliminated]="cartData.is_shipping_cost_eliminated" [eliminatedRates]="cartData.grand_total"
				currency="{{commonService.currencySymbol}}" orderTotal="{{cartData.grand_total.total_price}}"
				orderSubTotal="{{cartData.grand_total.items_sub_total}}"
				orderDiscount="{{cartData.grand_total.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
						                                appliedCouponCode : null}}" orderVAT="{{cartData.grand_total.vat}}"
				[orderSummeryData]="cartData.grand_total" orderShipping="{{cartData.grand_total.shipping_charges}}"
				orderShippingWithoutVat="{{cartData.grand_total.shipping_charges_without_vat}}"
				shippingVat="{{cartData.grand_total.shipping_vat_charges}}"
				vatPercent="{{cartData.grand_total.vat_percentage}}">
			</app-order-summary>

			<div class="cart-modal">
				<ion-row class="row-div" *ngIf="cartProductData">
					<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6">
						<p class="label1 color-app-grey"><span class="color-app-black">{{cartProductData.length}}
								{{cartProductData.length > 1 ? ('cartModal.products'|translate) :
								('cartModal.product'|translate)}}</span>
							<span class="label-in-cart"> {{'cartModal.incart'| translate}}</span>
						</p>
					</ion-col>
				</ion-row>
				<div class="divider-line"></div>

				<div class="scroll-content" *ngIf="cartData && cartData.result && cartData.result.length && !isLoading">
					<div *ngFor="let item of cartData.result;let i =index">

						<ion-row class="head-div">
							<p class="title-2 color-app-black">{{commonService.domDirectionLTR ?
								item.supplier_legal_name_en : item.supplier_legal_name_ar}}</p>
						</ion-row>

						<div *ngFor="let data of item.products;let i =index">
							<ion-row class="row-padding">
								<div class="mr-15 position-rel"
									[ngClass]=" data.is_sold_out ? 'product-image-blur' : null">

									<div *ngIf="data.discount_type &&  data.discount_type != 'PRICE' && !data.is_sold_out && !data.is_sample_product"
										class="discout_div ">
										<span>-</span>{{ data.discount_value }}<span>%</span>
									</div>
									<div *ngIf="data.discount_type &&  data.discount_type == 'PRICE' && !data.is_sold_out && !data.is_sample_product"
										class="discout_div ">
										<span>{{"OnSale" | translate}}</span>
									</div>

									<img *ngIf="data.product_cover_image"
										src="{{data.product_cover_image[0].image_url}}" class="product_img"
										alt="Jazeera" />

									<img *ngIf="!data.product_cover_image" src="./assets/images/empty-img.png"
										alt="Jazeera" class="product_img" />
								</div>
								<div *ngIf="data.is_sold_out" class="product-outofstock">
									<div class="outofstock-label">
										{{"outOfStock" | translate}}
									</div>
								</div>

								<div class="width-col">
									<p class="label3 color-app-black">{{commonService.domDirectionLTR ?
										data.product_name_en
										:
										data.product_name_ar}}</p>
									<div *ngIf="data.is_sample_product == true" class="sample_div ">
										<p>{{'SAMPLE'| translate}}</p>
									</div>

									<p class="label5 color-app-grey display-div cursor-pointer "
										(click)="goToProductDetail(data)">
										{{'ViewDetails' |translate}}
										<!-- <i class="feather icon-chevron-down arrow-icon color-primary ml-5"></i> -->
									</p>

									<div class="rowQty display-div">
										<p class="label3 color-app-black product-price">
											{{commonService.currencySymbol}}{{data.is_sample_product ?
											(data.price_per_unit | number): (data.final_price_per_unit | number)}}
											<span *ngIf="data.unit_name_en && data.is_sample_product != true"
												class="label6 color-app-grey">/{{commonService.domDirectionLTR
												?
												data.unit_name_en:data.unit_name_ar}}</span>
										</p>
										<div class="units-containter">

											<div class="qty_div">
												<p class="label4 color-app-black">{{data.total_quantity}}</p>
												<span class="label6 color-app-grey" [title]="commonService.domDirectionLTR
                                                ?
                                                data.unit_name_en:data.unit_name_ar">{{commonService.domDirectionLTR
													?
													data.unit_name_en:data.unit_name_ar}}</span>
											</div>
											<div class="qty_div suom"
												*ngIf="data.unit_id && data.secondary_unit_id && data.unit_id !== data.secondary_unit_id">
												<p class="label4 color-app-black">
													{{calculateSUOM(data.conversation_ratio,data.total_quantity)}}
												</p>
												<span class="label6 color-app-grey " [title]="commonService.domDirectionLTR ? data.secondary_unit_name_en
                                                :
                                                data.secondary_unit_name_ar">
													{{commonService.domDirectionLTR ? data.secondary_unit_name_en
													:
													data.secondary_unit_name_ar}} </span>
											</div>
										</div>
									</div>
								</div>



								<div class="width-100" *ngIf="i != (item.products.length - 1)">
									<div class="divider-line mv-10 border-right"></div>
								</div>


							</ion-row>

						</div>
					</div>
				</div>

				<div *ngIf="!cartData && !cartData.result.length && !isLoading" class="vertical_center">
					<img src="./assets/icon/emptyCart.svg" alt="Jazeera" class="mb-10" />
					<div class="mb-20">
						<p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
					</div>
				</div>
			</div>



		</ion-col>

	</ion-row>

	<ion-row>
		<div class="mv-25 mh-auto">
			<app-common-button color="#FFF" background="#0081CA" type="solid"
				title="{{'checkoutStep2.ReviewOrder'|translate}}" [isIcon]="false" [isHideInMobile]="false"
				[isFullWidth]="true" (buttonClicked)="gotoChekoutReviewPage()" [specificHeight]="'50px'"
				[specificWidth]="'250px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
				[buttonDisalbed]="buttonDiabledMethod()">
			</app-common-button>
		</div>
	</ion-row>
</ion-content>

<app-footer [showOnlyLowFooter]="true" *ngIf="getPlatformService.isDesktop"></app-footer>

<ion-content *ngIf="!getPlatformService.isDesktop && !isLoading">
	<ion-row class="p-0">

		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9" class="ph-20">
			<div>
				<div *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)">
					<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep2.Paymentmethod' | translate}}</p>
					<div *ngFor="let data of payment_methods;let i =index" class="border_class">
						<div class="display-div cursor-pointer" (click)="choosePamentMethods(i)">
							<div class="radio-size mr-15">
								<div *ngIf="!data.select" class="radio-div"></div>
								<img *ngIf="data.select" src="/assets/icon/check.svg" alt="Jazeera" />
							</div>

							<div class="display-div width-100">
								<div class="width-inherit">
									<p class="title2 color-app-black p-0 width-inherit">{{data.payment_type_value}}</p>
									<img *ngIf="i == 1" src="/assets/cards/cardicons.png" class="card-icons"
										alt="Jazeera" />
								</div>

								<!-- <ion-row class="width-100">
                                    <p class="text-right m-0 width-100" *ngIf="i == payment_methods.length -1">
                                        <i class="feather icon-plus color-primary label5"
                                            (click)="addNewCardDialog()"></i>
                                        <span class=" label5 color-primary"
                                            (click)="addNewCardDialog()">{{'checkoutStep2.AddCard' |
                                            translate}}</span>
                                    </p>
                                </ion-row> -->
							</div>
						</div>

					</div>
				</div>

				<div>
					<p class="title3 color-app-black mv-20-30">{{ 'checkoutStep2.Billinginfo' | translate}}</p>
					<div>
						<div *ngIf="billing_address && billing_address.length">
							<div *ngFor="let data of billing_address;let i =index" class="border_class">
								<div class="display-div cursor-pointer" (click)="chooseAddress(data,i)">
									<div size="1" class="mr-15">
										<div *ngIf="!data.select" class="radio-div"></div>
										<img *ngIf="data.select" src="/assets/icon/check.svg" class="radio-size"
											alt="Jazeera" />
									</div>
									<div>
										<p class="title2 color-app-black">{{'checkoutStep1.Address' |translate}}
											#{{i+1}}
										</p>
										<p class="label2 color-app-black">{{data.address}},
											{{commonService.domDirectionLTR ? data.city_en : data.city_ar}},
											{{commonService.domDirectionLTR ? data.region_en : data.region_ar}},
											{{commonService.domDirectionLTR ? data.country_en : data.country_ar}} -
											{{data.zipcode}}</p>
									</div>
								</div>


							</div>
						</div>

						<div *ngIf="!billing_address.length">
							<div class="no-data">
								{{"noRecordFound" | translate}}
							</div>
						</div>
					</div>
				</div>


			</div>



		</ion-col>

		<div class="divider-line mv-10"></div>
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="ph-20">
			<p class="title3 color-app-black">{{'cartPage.cartItems' | translate}}</p>
			<div class="cart-modal">
				<div *ngIf="cartData && cartData.result && cartData.result.length">
					<div *ngFor="let data of cartData.result;let i =index">
						<ion-row class="head-div">
							<p class="title-2 color-app-black width-70">{{commonService.domDirectionLTR ?
								data.supplier_legal_name_en : data.supplier_legal_name_ar}}</p>

							<p class="title-2 color-app-grey">{{data.products.length}}
								{{data.products.length > 1 ? ('cartModal.products'|translate) :
								('cartModal.product'|translate)}}</p>
						</ion-row>

						<div *ngFor="let item of data.products;let j =index" class="p-0">
							<ion-row class="mt-10">
								<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2" class="p-0">
									<div class="display-flex">
										<div *ngIf="item.discount_type &&  item.discount_type != 'PRICE' && !item.is_sold_out && !item.is_sample_product"
											class="discout_div ">
											<span>-</span>{{ item.discount_value }}<span>%</span>
										</div>
										<div *ngIf="item.discount_type &&  item.discount_type == 'PRICE' && !item.is_sold_out  && !item.is_sample_product"
											class="discout_div ">
											<span>{{"OnSale" | translate}}</span>
										</div>

										<img *ngIf="item.product_cover_image" (click)="goToProductDetail(item)"
											alt="Jazeera" src="{{item.product_cover_image[0].image_url}}"
											class="product-img pointer" />
										<img *ngIf="!item.product_cover_image" (click)="goToProductDetail(item)"
											alt="Jazeera" src="./assets/images/empty-img.png"
											class="product-img pointer" />

										<div class="pl-15 width-100">
											<p class="label-3 color-app-black pointer"
												(click)="goToProductDetail(item)">
												{{commonService.domDirectionLTR ?
												item.product_name_en: item.product_name_ar}}</p>

											<p class="label-5 color-app-grey "
												*ngFor="let config of item.product_configuration">
												{{commonService.domDirectionLTR ? config.configuration_name_en:
												config.configuration_name_ar}}:
												<span class="color-app-black mh-5">
													{{commonService.domDirectionLTR ?
													config.configuration_value_en:
													config.configuration_value_ar}}</span>
											</p>


											<p class="label-5 color-app-grey border-div estimated-delivery"
												*ngIf="item.estimated_delivery_date">
												{{'cartPage.EstimatedDelivery' | translate}}: <span
													class="color-app-grey mh-5 estimated-date">
													{{item.estimated_delivery_date}}</span></p>

											<p class="label-5 color-app-grey border-div estimated-delivery"
												*ngIf="item.delivery_days">
												{{'cartPage.EstimatedDelivery' | translate}}: <span
													class="color-app-grey mh-5 estimated-date">
													{{item.delivery_days}} {{item.delivery_days > 1
													?('cartPage.Days'|translate) : ('cartPage.Day'|translate)}}</span>
											</p>

										</div>
									</div>
								</ion-col>
								<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4"
									class="pos-absolute">
									<div *ngIf="item.is_sample_product == true" class="sample-div">
										<p>{{'SAMPLE'|translate}}</p>
									</div>
								</ion-col>

								<ion-row class="row-div mob-pv-5 mob-ph-0 web-ph-15">

									<div class="units-containter">

										<div class="qty_div">
											<p class="label4 color-app-black">{{item.total_quantity}}</p>
											<span class="label6 color-app-grey" [title]="commonService.domDirectionLTR
                                            ?
                                            item.unit_name_en:item.unit_name_ar">{{commonService.domDirectionLTR
												?
												item.unit_name_en:item.unit_name_ar}}</span>
										</div>
										<div class="qty_div suom"
											*ngIf="item.unit_id && item.secondary_unit_id && item.unit_id !== item.secondary_unit_id">
											<p class="label4 color-app-black">
												{{calculateSUOM(item.conversation_ratio,item.total_quantity)}}
											</p>
											<span class="label6 color-app-grey " [title]="commonService.domDirectionLTR ? item.secondary_unit_name_en
                                            :
                                            item.secondary_unit_name_ar">
												{{commonService.domDirectionLTR ? item.secondary_unit_name_en
												:
												item.secondary_unit_name_ar}} </span>
										</div>
									</div>

									<p class="label-4 color-app-black">X</p>
									<p class="label-3 color-app-black">
										{{ commonService.currencySymbol }}{{item.is_sample_product ?
										(item.price_per_unit | number) : (item.final_price_per_unit | number)}}
										<span *ngIf="item.unit_name_en && !item.is_sample_product"
											class="label-6 color-app-grey">/{{commonService.domDirectionLTR
											?
											item.unit_name_en:item.unit_name_ar}}</span>
									</p>

								</ion-row>
								<ion-row class="row-div  mob-pv-0 mob-ph-0 web-ph-15 width-100">
									<p class="label-4 color-app-black float-end width-100">
										{{commonService.currencySymbol}}{{item.total_price|number}}</p>
								</ion-row>

								<div class="divider-line mv-10" *ngIf="j != (data.products.length - 1)"></div>
							</ion-row>
						</div>
					</div>
				</div>
			</div>

		</ion-col>

		<div class="divider-line mv-10"></div>

		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="ph-20">
			<p class="title3 color-app-black">{{'cartPage.Ordersummary' | translate}}</p>

			<app-order-summary *ngIf="this.cartData && this.cartData.grand_total"
				[shippingEliminated]="cartData.is_shipping_cost_eliminated" [eliminatedRates]="cartData.grand_total"
				currency="{{commonService.currencySymbol}}" orderTotal="{{cartData.grand_total.total_price}}"
				orderSubTotal="{{cartData.grand_total.items_sub_total}}"
				orderDiscount="{{cartData.grand_total.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
						                                appliedCouponCode : null}}" orderVAT="{{cartData.grand_total.vat}}"
				[orderSummeryData]="cartData.grand_total" orderShipping="{{cartData.grand_total.shipping_charges}}"
				orderShippingWithoutVat="{{cartData.grand_total.shipping_charges_without_vat}}"
				shippingVat="{{cartData.grand_total.shipping_vat_charges}}"
				vatPercent="{{cartData.grand_total.vat_percentage}}">
			</app-order-summary>

			<div class="mv-25">
				<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100"
					title="{{'checkoutStep2.ReviewOrder'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[isFullWidth]="true" (buttonClicked)="gotoChekoutReviewPage()" [specificHeight]="'50px'"
					[specificFontSize]="'1rem'" [specificFontWeight]="700" [buttonDisalbed]="buttonDiabledMethod()">
				</app-common-button>
			</div>

		</ion-col>

	</ion-row>
</ion-content>


<ng-template let-formObject #validationMessages>
	<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
		<ion-text color="danger" class="error-msg"
			*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
			{{validation.message | translate }}
		</ion-text>
	</ng-container>
</ng-template>