import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ModalService } from 'src/app/services/modal.service';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';
import { TranslateConfigService } from '../../services/translate-config.service';

@Component({
    selector: 'app-category-page',
    templateUrl: './category-page.page.html',
    styleUrls: ['./category-page.page.scss'],
})
export class CategoryPage implements OnInit {
    isLoading: boolean = false;
    categoryData: any = [];
    groupId: any;
    private subscription: Subscription = new Subscription();

    pagination: any = {
        page: 1,
        limit: 10
    }
    enableNext: boolean = false;

    filterparams: any = {
        page: 1,
        limit: 10
    };
    inerfaceOption = { cssClass: 'sortby-dropdown' };
    groupData: any = [];
    constructor(private translateConfigService: TranslateConfigService,
        public getPlatformService: GetPlatformService,
        public modalService: ModalService,
        public router: Router,
        public cartService: CartService,
        private dataService: ApiService,
        public navCtrl: NavController,
        private translate: TranslateService,
        private alertController: AlertController,
        private toastService: ToastService,
        public zone: NgZone,
        public route: ActivatedRoute,
        public commonService: CommonService) {
        this.translateConfigService.getDefaultLanguage();

        this.groupId = +this.route.snapshot.paramMap.get('id');
        this.filterparams['sort_by'] = 'A-Z';
        this.filterparams['sort_type'] = 'ASC';

    }



    ngOnInit() {
        this.getAllGroups();
        this.getCategory();
    }

    getAllGroups() {
        let params = {
            dropdown: true,
        }
        this.subscription.add(
            this.dataService.get(URLS.allGroupList, params).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        this.groupData = data['data']['result'] ? data['data']['result'] : [];
                    }
                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )

    }

    getCategory(fromPagination?) {

        if (!fromPagination) {
            this.isLoading = true;
        }
        this.filterparams['page'] = this.pagination.page;
        this.filterparams['size'] = this.pagination.limit;
        if (this.groupId) {
            this.filterparams['group_id'] = [this.groupId];
        }

        this.subscription.add(
            this.dataService.get(URLS.allCategoryFromGroup, this.filterparams).subscribe(data => {
                if (data['code'] == 200) {
                    this.isLoading = false;
                    if (!fromPagination) {
                        this.categoryData = [];
                    }
                    if (data.data) {
                        this.enableNext = data.data.enable_next ? data.data.enable_next : false;
                        let list = data.data.result ? data.data.result : [];
                        list.forEach((element, index,) => {
                            let obj = {
                                product_img: element.category_image_url ? element.category_image_url : "",
                                product_description: element.category_description_en ? element.category_description_en : null,
                                product_descrption_ar: element.category_description_ar ? element.category_description_ar : null,
                                product_title: element.category_name_en ? element.category_name_en : null,
                                product_title_ar: element.category_name_ar ? element.category_name_ar : null,
                                product_name: element.category_name_en ? element.category_name_en : null,
                                product_name_ar: element.category_name_ar ? element.category_name_ar : null,
                                category_id: element.category_id ? element.category_id : 0,
                                category_image_url: element.category_image_url ? element.category_image_url : null
                            }
                            this.categoryData.push(obj);
                        });
                    }

                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }

    async goToProductListing(data) {
        let cat_id = data.category_id;
        let catnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let currentLang = this.translate.currentLang;   
        await this.navCtrl.navigateForward('/'+currentLang + '/product-listing/' + catnameUrl + "/" + cat_id, {
            animated: false, "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.product_name : data.product_name_ar,
                "url": '/product-listing/' + catnameUrl + "/" + cat_id,
                id: data.category_id,
                "ar_label": data.product_name_ar,
                "banner_img": data.category_image_url
            }
        });
    }

    loadMoreData() {
        this.pagination.page = this.pagination.page + 1;
        this.getCategory(true)
    }

    selectSorting(event) {
        this.filterparams['sort_by'] = event['detail']['value'];
        if (event['detail']['value'] == 'A-Z') {
            this.filterparams['sort_by'] = 'A-Z';
            this.filterparams['sort_type'] = 'ASC';
        }
        if (event['detail']['value'] == 'Z-A') {
            this.filterparams['sort_by'] = 'Z-A';
            this.filterparams['sort_type'] = 'DESC';
        }
        if (event['detail']['value'] == 'New_To_Old') {
            this.filterparams['sort_by'] = 'New_To_Old';
            this.filterparams['sort_type'] = 'DESC';
        }
        if (event['detail']['value'] == 'Old_To_New') {
            this.filterparams['sort_by'] = 'Old_To_New';
            this.filterparams['sort_type'] = 'ASC';
        }
        this.pagination.page = 1;
        this.pagination.limit = 10;
        this.getCategory();
    }

    selectGroup(event) {
        this.groupId = event.detail.value;
        this.pagination.page = 1;
        this.pagination.limit = 10;
        this.getCategory();
    }
    goBack() {
        this.navCtrl.navigateRoot('/'+this.translate.currentLang +'/home', { animated: false });
    }
}
