<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>
<ion-content *ngIf="getPlatformService.isDesktop">
	<div class="ion-content">
		<div class="two-fa-container">
			<div class="two-fa-header pb-10" *ngIf="getPlatformService.isDesktop">
				{{'ChangePassword' |translate}}
			</div>

			<div *ngIf="!getPlatformService.isDesktop" class="col-center">
				<div class="modal-close text-right" (click)="closeModal()">
					<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
				</div>
				<div class="header-line" *ngIf="!getPlatformService.isDesktop">
				</div>
				<div class="two-fa-header pt-35">
					{{'ChangePassword' |translate}}
				</div>
			</div>
			<div class="horizontal-line" *ngIf="!getPlatformService.isDesktop"></div>
			<!-- -------------------- important code --------------------  -->
			<!-- <div class="two-fa-discription">
            Lorem ipsum dolor sit amet adipiscing consectetur.
        </div> -->

			<div class="code-input">
				<form [formGroup]="changePasswordFromGroup">
					<div class="password">
						<ion-icon name="eye-outline" slot="end" *ngIf="oldPasswordType == 'text'"
							(click)="oldPasswordType = 'password'"
							[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
						<ion-icon name="eye-Off-outline" slot="end" *ngIf="oldPasswordType == 'password'"
							(click)="oldPasswordType = 'text'"
							[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
						<!-- <ion-item lines="none" mode="md"> -->
						<!-- <ion-label position="floating">{{ 'OLDPASSWORD' |
                                translate }}</ion-label> -->
						<ion-input #passwordInput label="{{'OLDPASSWORD' |translate}}" label-placement="floating"
							fill="outline" clearOnEdit="false" class="registration-pass" type="{{ oldPasswordType }}"
							formControlName="oldpassword" (keydown.space)="$event.preventDefault();">
						</ion-input>
						<!-- </ion-item> -->
						<ng-container
							*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'oldpassword', 'formName': changePasswordFromGroup} }">
						</ng-container>
					</div>

					<div class="password common-input">
						<ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
							(click)="passwordType = 'password'"
							[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
						<ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
							(click)="passwordType = 'text'"
							[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
						<!-- <ion-item lines="none" mode="md"> -->
						<!-- <ion-label position="floating">{{ 'NEWPASSWORD' |
                                translate }}</ion-label> -->
						<ion-input label="{{'NEWPASSWORD' |translate}}" label-placement="floating" fill="outline"
							type="{{ passwordType }}" clearOnEdit="false" formControlName="password"
							class="registration-pass" (keydown.space)="$event.preventDefault();">
						</ion-input>
						<!-- </ion-item> -->
						<ng-container
							*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': changePasswordFromGroup} }">
						</ng-container>
					</div>
					<div class="password">
						<ion-icon name="eye-outline" slot="end" *ngIf="repeatPasswordType == 'text'"
							(click)="repeatPasswordType = 'password'"
							[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
						<ion-icon name="eye-Off-outline" slot="end" *ngIf="repeatPasswordType == 'password'"
							(click)="repeatPasswordType = 'text'"
							[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
						<!-- <ion-item lines="none" mode="md"> -->
						<!-- <ion-label position="floating">{{ 'CONFIRMPASSWORD' |
                                translate }}</ion-label> -->
						<ion-input label="{{'CONFIRMPASSWORD' |translate}}" label-placement="floating" fill="outline"
							clearOnEdit="false" clearOnEdit="false" type="{{ repeatPasswordType }}"
							formControlName="repeatPassword" class="registration-pass"
							(keydown.space)="$event.preventDefault();">
						</ion-input>
						<!-- </ion-item> -->
						<ng-container
							*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'repeatPassword', 'formName': changePasswordFromGroup} }">
						</ng-container>
					</div>

				</form>

			</div>

			<div class="login-bottom-container">
				<div class="cancel pr-30" (click)="closeModal()">
					{{ 'Cancel' | translate }}
				</div>
				<div class="login-button">
					<ion-button (click)="savePassword()">
						{{'Save' | translate}}
					</ion-button>
				</div>
			</div>
		</div>

		<div class="pt-12 ph-20 pb-32 " *ngIf="!getPlatformService.isDesktop">
			<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100" (click)="savePassword()"
				title="{{'Save' | translate}}" [isIcon]="false" [isHideInMobile]="false" [isFullWidth]="true"
				[specificFontSize]="'1rem'">
			</app-common-button>
		</div>

		<ng-template let-formObject #validationMessages>
			<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
				<ion-text color="danger" class="error-msg"
					*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
					{{validation.message | translate}}
				</ion-text>
			</ng-container>
		</ng-template>
		<div class="wrapper-block home-page" *ngIf="getPlatformService.isDesktop">
			<div class="app-footer">
				<app-footer></app-footer>
			</div>
		</div>
	</div>
</ion-content>

<div class="ion-content" *ngIf="!getPlatformService.isDesktop">
	<div class="two-fa-container">
		<div class="two-fa-header pb-10" *ngIf="getPlatformService.isDesktop">
			{{'ChangePassword' |translate}}
		</div>

		<div *ngIf="!getPlatformService.isDesktop" class="col-center">
			<div class="modal-close text-right" (click)="closeModal()">
				<img src="assets/icon/modal-close.svg" alt="Close" height="100%" width="100%">
			</div>
			<div class="header-line" *ngIf="!getPlatformService.isDesktop">
			</div>
			<div class="two-fa-header pt-35">
				{{'ChangePassword' |translate}}
			</div>
		</div>
		<div class="horizontal-line" *ngIf="!getPlatformService.isDesktop"></div>
		<!-- -------------------- important code --------------------  -->
		<!-- <div class="two-fa-discription">
            Lorem ipsum dolor sit amet adipiscing consectetur.
        </div> -->

		<div class="code-input">
			<form [formGroup]="changePasswordFromGroup">
				<div class="password">
					<ion-icon name="eye-outline" slot="end" *ngIf="oldPasswordType == 'text'"
						(click)="oldPasswordType = 'password'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-icon name="eye-Off-outline" slot="end" *ngIf="oldPasswordType == 'password'"
						(click)="oldPasswordType = 'text'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating">{{ 'OLDPASSWORD' |
                            translate }}</ion-label> -->
					<ion-input label="{{'OLDPASSWORD' |translate}}" label-placement="floating" fill="outline"
						#passwordInput clearOnEdit="false" class="registration-pass" type="{{ oldPasswordType }}"
						formControlName="oldpassword" (keydown.space)="$event.preventDefault();">
					</ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'oldpassword', 'formName': changePasswordFromGroup} }">
					</ng-container>
				</div>

				<div class="password">
					<ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
						(click)="passwordType = 'password'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
						(click)="passwordType = 'text'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating">{{ 'NEWPASSWORD' |
                            translate }}</ion-label> -->
					<ion-input label="{{'NEWPASSWORD' |translate}}" label-placement="floating" fill="outline"
						type="{{ passwordType }}" clearOnEdit="false" formControlName="password"
						class="registration-pass" (keydown.space)="$event.preventDefault();">
					</ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': changePasswordFromGroup} }">
					</ng-container>
				</div>
				<div class="password">
					<ion-icon name="eye-outline" slot="end" *ngIf="repeatPasswordType == 'text'"
						(click)="repeatPasswordType = 'password'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-icon name="eye-Off-outline" slot="end" *ngIf="repeatPasswordType == 'password'"
						(click)="repeatPasswordType = 'text'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<!-- <ion-item lines="none" mode="md">
                        <ion-label position="floating">{{ 'CONFIRMPASSWORD' |
                            translate }}</ion-label> -->
					<ion-input label="{{'CONFIRMPASSWORD' |translate}}" label-placement="floating" fill="outline"
						clearOnEdit="false" clearOnEdit="false" type="{{ repeatPasswordType }}"
						formControlName="repeatPassword" class="registration-pass"
						(keydown.space)="$event.preventDefault();">
					</ion-input>
					<!-- </ion-item> -->
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'repeatPassword', 'formName': changePasswordFromGroup} }">
					</ng-container>
				</div>

			</form>

		</div>

		<div class="login-bottom-container">
			<div class="cancel pr-30" (click)="closeModal()">
				{{ 'Cancel' | translate }}
			</div>
			<div class="login-button">
				<ion-button (click)="savePassword()">
					{{'Save' | translate}}
				</ion-button>
			</div>
		</div>
	</div>

	<div class="pt-12 ph-20 pb-32 " *ngIf="!getPlatformService.isDesktop">
		<app-common-button color="#FFF" background="#0081CA" type="solid" class="width-100" (click)="savePassword()"
			title="{{'Save' | translate}}" [isIcon]="false" [isHideInMobile]="false" [isFullWidth]="true"
			[specificFontSize]="'1rem'">
		</app-common-button>
	</div>

	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
				{{validation.message | translate}}
			</ion-text>
		</ng-container>
	</ng-template>
	<div class="wrapper-block home-page" *ngIf="getPlatformService.isDesktop">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</div>