<div class="orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{"voucherRedemption" | translate}}
		</h1>
		<div class="search-and-filter">
			<div class="filter-div">
				<app-common-button (click)="addVoucherDialog()" [btnStatus]="btnStatus" color="white"
					background="var(--ion-color-blue-100)" type="solid"
					title="{{ 'voucherComponent.addVoucher' | translate }}" [isIcon]="false"
					[specificPaddingStart]="'20px'" [specificPaddingEnd]="'20px'" [isHideInMobile]="true"
					[specificWidth]="'auto'" [specificHeight]="'50px'" [specificFontWeight]="'bold'"
					[margin]="0"></app-common-button>

				<div class="printer-icon ml-16">
					<app-common-button (click)="downloadExcel()" [btnStatus]="btnStatus" color="white"
						background="var(--ion-color-green-80)" type="solid" title="{{ 'export' | translate }}"
						[isIcon]="true" iconSrc="assets/icon/fi_printer.svg" [specificPaddingStart]="'20px'"
						[specificPaddingEnd]="'20px'" [isHideInMobile]="true" [specificWidth]="'auto'"
						[specificHeight]="'50px'" [specificFontWeight]="'bold'" [margin]="0"></app-common-button>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="addVoucherContainerShow">
		<div class="d-flex align-center justify-space-beetwen">

			<h3 class="text-bold width-100">{{ 'voucherComponent.addVoucher' | translate }}
				<span class="more-info-tooltip ml-10"><img src="./assets/icon/info.svg" alt="Jazeera" class="info-icon">
					<span class="more-info-tooltiptext-left more-info-file-tooltip">{{commonService.domDirectionLTR ?
						'Press
						Enter key or Tab key to add a multiple voucher.' :
						'اضغط على مفتاح الإدخال (Enter) أو مفتاح الجدولة (Tab) لإضافة قسائم متعددة.'}}</span>
				</span>
			</h3>

			<div class="d-flex align-center width-100">
				<ion-row class="align-center mv-5 common-box justify-end">
					<p class="m-0 color-black text-bold"> {{"voucherComponent.label2"|translate}}
						<span class="text-bold color-blue-60">
							{{totalValidVoucher}}</span>
					</p>
				</ion-row>

				<nz-divider nzType="vertical"></nz-divider>

				<ion-row class="align-center mv-5 common-box justify-end">
					<p class="m-0 color-black text-bold"> {{"voucherComponent.label"|translate}}
						<span class="text-bold color-blue-60">{{commonService.currencySymbol}}
							{{addedVoucherTotal|number}}</span>
					</p>
				</ion-row>
			</div>


		</div>

		<div class="bg-gray-90 p-20 mb-20">
			<ion-row class="mb-20" *ngIf="!isVoucherLoading">
				<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input [(ngModel)]="addVoucherNumebr" placeholder="{{'voucherComponent.field1'| translate}}"
						class="ion-text-start" (keyup.enter)="validateVoucher($event)"
						(keydown.tab)="validateVoucher($event)"></ion-input>
				</ion-item>

				<ion-item class="from-date-class ml-16" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input placeholder="{{'voucherComponent.voucherAmount'| translate}}" class="ion-text-start"
						[readonly]="true"></ion-input>

				</ion-item>

				<ion-item class="from-date-class ml-16" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input placeholder="{{'voucherComponent.expiryDate'| translate}}" class="ion-text-start"
						[readonly]="true"> </ion-input>

				</ion-item>

				<ion-item class="from-date-class2 ml-16" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
					<ion-input placeholder="{{'status'| translate}}" class="ion-text-start"
						[readonly]="true"></ion-input>

				</ion-item>
			</ion-row>

			<ion-row *ngIf="isVoucherLoading" class="align-center justify-center ">
				<ion-item lines="none" class="loader-item">
					<ion-spinner name="bubbles"></ion-spinner>
					<p class="ph-20">{{commonService.domDirectionLTR ? 'Loading...':'جارٍ التحميل...'}}</p>
				</ion-item>
			</ion-row>
			<div *ngIf="tempVouchers" class="tempVouchers-container">
				<ng-container *ngFor="let data of tempVouchers;let i =index">
					<ion-row class="mb-20">
						<ion-item class="from-date-class  width-100"
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
							<ion-input [(ngModel)]="data.voucherNumebr" [attr.readonly]="true"
								placeholder="{{'voucherComponent.field1'| translate}}"
								class="ion-text-start"></ion-input>

						</ion-item>

						<ion-item class="from-date-class  width-100 ml-16"
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
							<ion-input [(ngModel)]="data.voucherAmount" [attr.readonly]="true"
								placeholder="{{'voucherComponent.voucherAmount'| translate}}"
								class="ion-text-start"></ion-input>

						</ion-item>

						<ion-item class="from-date-class  width-100 ml-16"
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
							<ion-input [(ngModel)]="data.expiryDate" [attr.readonly]="true"
								placeholder="{{'voucherComponent.expiryDate'| translate}}"
								class="ion-text-start"></ion-input>

						</ion-item>

						<ion-item class="from-date-class  width-100 item-padding-0 ml-16"
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">
							<div class="align-center mv-5 common-box" [attr.readonly]="true"
								[ngClass]="data.msg_type == 'success' ? 'box1':'box3'">
								<p class="m-0 color-black">
									{{data.msg}}
								</p>
							</div>
						</ion-item>

						<img class="delete ml-16 pointer" (click)="deleteTempVoucher($event,data,i)"
							src="assets/icon/deleteIcon.svg" alt="Jazeera">
					</ion-row>
				</ng-container>
			</div>
		</div>

		<div class="d-flex align-center justify-end mb-20">
			<app-common-button (click)="addVoucherDialog()" [btnStatus]="btnStatus" color="white"
				background="var(--ion-color-blue-100)" type="solid" title="{{ 'cancel' | translate }}" [isIcon]="false"
				[specificPaddingStart]="'20px'" [specificPaddingEnd]="'20px'" [isHideInMobile]="true"
				[buttonDisabled]="isVoucherLoading" [specificWidth]="'auto'" [specificHeight]="'50px'"
				[specificFontWeight]="'bold'" [margin]="0"></app-common-button>

			<div class="printer-icon ml-16">
				<app-common-button (click)="submitAllVoucher()" [btnStatus]="btnStatus" color="white"
					background="var(--ion-color-green-80)" type="solid"
					title="{{ 'voucherComponent.submitAllVoucher' | translate }}" [specificPaddingStart]="'20px'"
					[specificPaddingEnd]="'20px'" [isHideInMobile]="true" [specificWidth]="'auto'"
					[buttonDisabled]="isVoucherLoading" [specificHeight]="'50px'" [specificFontWeight]="'bold'"
					[margin]="0"></app-common-button>
			</div>
		</div>

	</div>

	<!-- <ion-row class="align-center mb-10 justify-space-beetwen">
		<div class="desktop-visibility-in-flex">
			<div class="input-div-container">
				<div class="from-date-div">
					<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
						id="voucherNo">
						<ion-input [(ngModel)]="voucherNumebr" placeholder="{{'voucherComponent.field'
					                                | translate}}" class="ion-text-start"></ion-input>

					</ion-item>
				</div>
			</div>

			<div class="filter-buttons ml-10">
				<div class="apply-button ion-text-end">
					<button class="filter-apply-button" [disabled]="!voucherNumebr" (click)="reedemVoucher()">{{"redeem"
						|
						translate}}</button>
				</div>
			</div>
		</div>
	</ion-row> -->

	<div class="section-filter mv-10">
		<div class="search-and-filter">
			<div class="filter-div">
				<div class="search-div">
					<!-- <ion-item lines="none"> -->
					<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
						[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (keyup.enter)="search()"
						(ionChange)="search()" (input)="search()" (ngModelChange)="search()">
						<img src="./assets/icon/search.svg" slot="start" (click)="search()" alt="Jazeera">
						<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
							alt="Jazeera" (click)="clearSearch()">
					</ion-input>
					<!-- </ion-item> -->
				</div>
			</div>
		</div>

		<div class="desktop-visibility-in-flex">
			<ion-row class="align-center mv-5 common-box box2">
				<p class="m-0 color-black text-bold"> {{"voucherComponent.label"|translate}}
					<span class="text-bold color-blue-60">{{commonService.currencySymbol}}
						{{voucherTotal|number}}</span>
				</p>
				<!-- <div class="more-info-tooltip ml-10"><img src="./assets/icon/info.svg" alt="Jazeera" class="info-icon">
					<span class="more-info-tooltiptext more-info-file-tooltip">{{'voucherComponent.tooltipLabel'|
						translate}}</span>
				</div> -->
			</ion-row>
		</div>
	</div>



	<div class="listing-table">
		<app-loader *ngIf="isLoading"></app-loader>
		<table class="table" *ngIf="!isLoading">
			<thead>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.voucherId"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.amount"|
					translate}}
				</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.registerDate"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.expiryDate"|
					translate}}</th>
				<th class="column-heading ion-text-center status-col mw-120">{{"voucherComponent.status"|
					translate}}</th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let order of voucherList;index as i" class="table-row">
					<td class="ion-text-center">{{order.voucher_code}}</td>
					<td class="ion-text-center">{{commonService.currencySymbol}}{{(order.voucher_amount|number) || 0}}
					</td>
					<td class="ion-text-center">{{order.validity_start_date | date :'MMM dd, YYYY hh:mm a'}}</td>
					<td class="ion-text-center">{{order.validity_end_date | date :'MMM dd, YYYY'}}</td>
					<td class="ion-text-center">
						<ion-button fill="clear" class="order-status-btn"
							[ngClass]=" order.voucher_status == 'Active'? 'btn-app-green ':'btn-app-red '">
							{{order.voucher_status == 'Active' ? ('active' | translate)
							:('Expired' | translate)}}
						</ion-button>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!voucherList.length">
			{{'noRecordFound' | translate}}
		</div>
	</div>

	<div class="mobile-view-listing">
		<ion-content class="orders-ion-content">
			<div class="order-container">
				<ion-grid>
					<ion-row class="order-card" *ngFor="let order of voucherList;index as i">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.voucherId" | translate}}</div>
										<div class="grid-values">{{order.voucher_code}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.amount" | translate}}</div>
										<div class="grid-values">
											{{commonService.currencySymbol}}{{order.voucher_amount}}
										</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"voucherComponent.expiryDate" | translate}}</div>
										<div class="grid-values">{{order.validity_end_date}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-values">
											<ion-button fill="clear" class="order-status-btn"
												[ngClass]="orderStatus(order)">
												{{order.voucher_status == 'Active' ? ('active' | translate)
												:('Expired' | translate)}}
											</ion-button>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'lodingMoreData' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</ion-content>
	</div>


	<div class="pagination-wrapper" *ngIf="voucherList.length && from != 'dashboard'">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col">
				<div class="perpage-col">
					<div class="perpage-dropdown">
						<ion-item class="ion-card" lines="none">
							<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								interface="popover" [interfaceOptions]="perPageInterface" fill="" mode="md"
								label-placement="stacked" (ionChange)="selectPageLength($event)"
								[(ngModel)]="itemsPerPage">
								<ion-select-option value="10">10 / {{"ordersComponent.per_page" |
									translate}}</ion-select-option>
								<ion-select-option value="20">20 / {{"ordersComponent.per_page" |
									translate}}</ion-select-option>
							</ion-select>
						</ion-item>
					</div>
				</div>
			</ion-col>
			<ion-col size="8" class="pagination-col">
				<app-tile class="grid-tile" *ngFor="let post of voucherList | paginate :
						    					{ itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
						   						 let i = index" [current]="post"></app-tile>
				<pagination-controls class="paginator"
					[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
					previousLabel="{{'ordersComponent.prev' | translate}}"
					nextLabel="{{'ordersComponent.next' | translate}}"
					(pageChange)="onChange($event)"></pagination-controls>
			</ion-col>
		</ion-row>
	</div>
</div>