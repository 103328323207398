import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-product-grid',
	templateUrl: './product-grid.component.html',
	styleUrls: ['./product-grid.component.scss'],
})
export class ProductGridComponent implements OnInit {

	private subscription: Subscription = new Subscription();
	@Input() productData;
	@Output() loadMore = new EventEmitter();
	productlist1 = "productlist1"
	@Input() showBanner: boolean = false;
	@Input() pageName: any;
	@Input() btnStatus: any;
	public categoryType: any;
	public gridBannerData: any = [];
	constructor(
		public router: Router,
		private navCtrl: NavController,
		private route: ActivatedRoute,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		this.categoryType = this.route.snapshot.params;
		this.getBannerData();
	}

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let catnameUrl: any;
		let currentLang = this.translate.currentLang;
		this.commonService.localStorageSet("sub_class_id", data.sub_class_id);
		if (this.categoryType.category) {
			catnameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.data.display_sub_class_name_en);
			this.navCtrl.navigateForward("/" + currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
					"state": {
						"breadcrumb": this.commonService.domDirectionLTR ? data.product_name_en : data.product_name_ar,
						"url": "/" + currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
					},
				});
		} else {
			catnameUrl = this.commonService.trimUrl(data.display_sub_class_name_en ? data.display_sub_class_name_en : data.display_sub_class_name_en);
			this.navCtrl.navigateForward("/" + currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
					"state": {
						"breadcrumb": this.commonService.domDirectionLTR ? data.product_name_en : data.product_name_ar,
						"url": '/' + currentLang + '/' + data.brand_name_en + '/' + prodnameUrl + '/' + prod_id
					}
				});
		}
	}

	loadMoreData() {
		this.loadMore.emit('more')
	}

	getBannerData() {
		this.subscription.add(
			this.dataService.get(URLS.getBannerData).subscribe(data => {
				if (data['data'] && data['code'] == 200) {
					let bannerData = data['data'];
					bannerData.forEach(element => {
						if (element.banner_location == "Product List Promo" && !element.is_default_banner) {
							element.image_url_ar = element.image_url_ar ? element.image_url_ar : "../../../assets/icon/mobile-banner-image.svg"
							element.image_url_en = element.image_url_en ? element.image_url_en : "../../../assets/icon/mobile-banner-image.svg"
							element.gray_image_url = "../../../assets/icon/gray_bg.svg"
							this.gridBannerData.push(element);
						}
					});
				}
			}, error => {
			})
		)
	}
}
