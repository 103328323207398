import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProductDetailPageRoutingModule } from './product-detail-routing.module';

import { SharedModule } from '../../common/shared.module';
import { ProductDetailPage } from './product-detail.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ProductDetailPageRoutingModule,
        SharedModule
    ],
    declarations: [ProductDetailPage]
})
export class ProductDetailPageModule { }
