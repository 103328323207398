<app-cart-header *ngIf="getPlatformService.isDesktop"></app-cart-header>

<div *ngIf="!getPlatformService.isDesktop" class="text-center mt-30 ">
	<img routerLink="/" src="./assets/icon/companyLogo.svg" alt="company-logo" height="100%" width="100%"
		class="company-logo pointer">
</div>
<ion-content *ngIf="getPlatformService.isDesktop">
	<div class="mt-20">
		<div class="center-div" *ngIf="paymentStatus == 'SADDAD'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.thankyouMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">{{"orderThankYou.yourorder" | translate}}
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.saddadOrderMsg" | translate}} {{"orderThankYou.saddadMsg" | translate}}
				<span class="color-primary order-id">
					{{dueDate| date:'dd-MM-yyyy'}}
				</span>
				{{"orderThankYou.saddadMsgDesc" | translate}}
			</p>

			<div class="pv-10 mt-10">
				<p class="label-payment-6 pb-5 m-0">{{"paymentComponent.PaymentPending" | translate}}
				</p>
			</div>
			<div class="border-bottom">
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.SaddadInvoiceNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{transactionId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.OrderNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right">
						<p class="label-payment-4">#{{orderId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.dueDate" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{dueDate| date :'dd MMM yyyy, hh:mm: a'}}</p>
					</ion-col>
				</ion-row>

			</div>


			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToHome()"
					title="{{'orderThankYou.countinueShopping'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>

		</div>
		<div class="center-div" *ngIf="paymentStatus == 'SUCCESSFUL'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.thankyouMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">{{"orderThankYou.yourorder" | translate}}
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.orderMsg" | translate}}
			</p>

			<div class="pv-10 mt-10">
				<p class="label-payment-6 pb-5 m-0">{{"paymentComponent.Paid" | translate}}
					<span class="label-payment-7">{{commonService.currencySymbol}}{{paymentAmt}}</span>
				</p>
				<p class="label-payment-4 m-0">{{dateTime| date :'dd MMM yyyy, hh:mm: a'}}</p>
			</div>
			<div class="border-bottom">
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.TransactionID" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{transactionId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.OrderNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right">
						<p class="label-payment-4">#{{orderId}}</p>
					</ion-col>
				</ion-row>
			</div>


			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToHome()"
					title="{{'orderThankYou.countinueShopping'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>

		</div>
		<div class="center-div" *ngIf="paymentStatus == 'PENDING'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentOnHold.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.holdMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.holdMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToAccount()"
					title="{{'orderThankYou.holdBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus == 'FAILED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentFail.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.failMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.failMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.failBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus == 'DECLINED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentDeclined.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.declinedMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.declinedMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.declinedBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus!= 'SADDAD' && paymentStatus  != 'SUCCESSFUL' && paymentStatus != 'PENDING' && paymentStatus != 'FAILED'
        && paymentStatus != 'DECLINED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentFail.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.cancelMsg" | translate}} {{paymentStatus |
				translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.cancelMsgDesc" | translate}} {{paymentStatus | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.cancelBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div-2">
			<img src="./assets/icon/thankyou.svg" alt="Jazeera" class="mr-10" />
			<p class="label-3 color-app-grey">{{"orderThankYou.trackMsg" | translate}}</p>
			<p class="label-4 color-primary pointer ml-5" (click)="goToAccount()">{{"orderThankYou.viewaccount" |
				translate}}
			</p>
			<i class="label-4 color-primary feather icon-chevron-right i-icon pointer" (click)="goToAccount()"></i>
		</div>
	</div>
</ion-content>

<app-footer [showOnlyLowFooter]="true" *ngIf="getPlatformService.isDesktop"></app-footer>

<ion-content *ngIf="!getPlatformService.isDesktop">
	<div class="pt-30">
		<div class="center-div" *ngIf="paymentStatus == 'SADDAD'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.thankyouMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">{{"orderThankYou.yourorder" | translate}}
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.saddadOrderMsg" | translate}} {{"orderThankYou.saddadMsg" | translate}}
				<span class="color-primary order-id">
					{{dueDate| date:'dd-MM-yyyy'}}
				</span>
				{{"orderThankYou.saddadMsgDesc" | translate}}
			</p>

			<div class="pv-10 mt-10">
				<p class="label-payment-6 pb-5 m-0">{{"paymentComponent.PaymentPending" | translate}}
				</p>
			</div>
			<div class="border-bottom">
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.SaddadInvoiceNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{transactionId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.OrderNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right">
						<p class="label-payment-4">#{{orderId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.dueDate" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{dueDate| date :'dd MMM yyyy, hh:mm: a'}}</p>
					</ion-col>
				</ion-row>

			</div>


			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToHome()"
					title="{{'orderThankYou.countinueShopping'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>

		</div>
		<div class="center-div" *ngIf="paymentStatus == 'SUCCESSFUL'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.thankyouMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">{{"orderThankYou.yourorder" | translate}}
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.orderMsg" | translate}}
			</p>

			<div class="pv-10 mt-10">
				<p class="label-payment-6 pb-5 m-0">{{"paymentComponent.Paid" | translate}}
					<span class="label-payment-7">{{commonService.currencySymbol}}{{paymentAmt}}</span>
				</p>
				<p class="label-payment-date m-0">{{dateTime| date :'dd MMM yyyy, hh:mm: a'}}</p>
			</div>
			<div class="border-bottom">
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.TransactionID" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-50">
						<p class="label-payment-4">{{transactionId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.OrderNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right">
						<p class="label-payment-4">#{{orderId}}</p>
					</ion-col>
				</ion-row>
			</div>


			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToHome()"
					title="{{'orderThankYou.countinueShopping'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>

		</div>
		<div class="center-div" *ngIf="paymentStatus == 'PENDING'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentOnHold.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.holdMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.holdMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToAccount()"
					title="{{'orderThankYou.holdBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus == 'FAILED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentFail.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.failMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.failMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.failBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus == 'DECLINED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentDeclined.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.declinedMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.declinedMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.declinedBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus != 'SADDAD' && paymentStatus != 'SUCCESSFUL' && paymentStatus != 'PENDING' && paymentStatus != 'FAILED'
                && paymentStatus != 'DECLINED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentFail.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.cancelMsg" | translate}} {{paymentStatus |
				translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.cancelMsgDesc" | translate}} {{paymentStatus | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.cancelBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div-3">

			<p class="label-3 color-app-grey display-class">
				<span><img src="./assets/icon/thankyou.svg" alt="Jazeera" class="mr-5" /></span>
				{{"orderThankYou.trackMsg" | translate}}
			</p>
			<p class="label-4 color-primary" (click)="goToAccount()">{{"orderThankYou.viewaccount" | translate}}
				<i class="label-4 color-primary feather icon-chevron-right i-icon"></i>
			</p>
		</div>
	</div>
</ion-content>