<div class="ion-content" [ngClass]="[ showAll ? 'ion-content-scoll' : '']"
	*ngIf="recommendedData && recommendedData.length">
	<div class="recommended-container">
		<app-home-title title="{{ 'homePage.explore_group' | translate }}"></app-home-title>

		<swiper-container #cateogrySlide *ngIf="getPlatform.isDesktop" pagination="false"
			dir="{{commonService.domDirectionLTR ? 'ltr' :'rtl'}}" [ngClass]="getPlatform.isDesktop ? 'ml-10':''">
			<ng-container *ngIf="recommendedData && recommendedData.length > 0">
				<ng-container *ngFor="let data of recommendedData;index as i">
					<swiper-slide>
						<div class="flex-column width-100 pl-10 pr-10">
							<div class="width-100 group-div" (click)="goToCategory(recommendedData[i])">
								<div class="slide-container">
									<div class="product-image-container">
										<div>
											<div class="ion-text-center">
												<img src="{{ recommendedData[i].product_img }}" alt="Jazeera"
													height="100%" width="100%">
											</div>
											<div class="product-title ion-text-center" [title]="commonService.domDirectionLTR ? recommendedData[i].product_title :
                                                    recommendedData[i].product_title_ar">
												{{ commonService.domDirectionLTR ? recommendedData[i].product_title :
												recommendedData[i].product_title_ar }}
											</div>
											<div class="click-here">
												<div>
													{{'clickHere' | translate}}
												</div>
												<div class="pt-3">
													<img alt="Jazeera" src="/assets/icon/click-arrow.svg">
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>

						</div>
					</swiper-slide>
				</ng-container>
			</ng-container>
			<!-- <ng-container *ngIf="recommendedData && recommendedData.length > 0 && recommendedData.length <= 12 ">
                <swiper-slide *ngFor="let data of recommendedData;index as i">
                    <div class="flex-column width-100">
                        <div class="width-100 group-div" (click)="goToCategory(recommendedData[i])">
                            <div class="slide-container">
                                <div class="product-image-container">
                                    <img *ngIf="recommendedData[i].product_img"
                                        src="{{ recommendedData[i].product_img }}" alt="Jazeera" height="100%"
                                        width="100%">
                                </div>
                            </div>

                        </div>
                    </div>
                </swiper-slide>
            </ng-container> -->
			<ng-container *ngIf="recommendedData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>


		<div (click)="slideToPrev()" *ngIf="recommendedData && recommendedData.length > 0"
			[ngClass]="recommendedData.length <= 6 ? 'previous-arrow-up':'previous-arrow'">
			<ion-icon *ngIf="firstSlide || recommendedData && recommendedData.length < 6" alt="Jazeera"
				src="assets/icon/slider-left-arrow.svg"></ion-icon>
			<ion-icon class="rotate-arrow" *ngIf="!this.firstSlide && !this.lastSlide" alt="Jazeera"
				src="assets/icon/slider-right-arrow.svg"></ion-icon>
		</div>
		<div (click)="slideToNext()" *ngIf="recommendedData && recommendedData.length > 0"
			[ngClass]="recommendedData.length <= 6 ? 'next-arrow-up' :'next-arrow'">
			<ion-icon *ngIf="firstSlide && recommendedData && recommendedData.length > 6" alt="Jazeera"
				src="assets/icon/slider-right-arrow.svg"></ion-icon>
			<ion-icon *ngIf="!firstSlide && !sliderEnd && recommendedData && recommendedData.length > 6" alt="Jazeera"
				src="assets/icon/slider-right-arrow.svg"></ion-icon>
			<ion-icon class="rotate-arrow"
				*ngIf="(sliderEnd && !firstSlide) || (recommendedData && recommendedData.length < 6)" alt="Jazeera"
				src="assets/icon/slider-left-arrow.svg"></ion-icon>
		</div>
	</div>
</div>