import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-press-details',
    templateUrl: './press-details.component.html',
    styleUrls: ['./press-details.component.scss'],
})
export class PressDetailsComponent implements OnInit, OnDestroy {


    private subscription: Subscription = new Subscription();
    public isLoading = false;
    public enableNext = false;
    public pressData: any;
    public pressId: any;
    @ViewChild("playerDetails") playerDetails: ElementRef;

    constructor(
        public router: Router,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        public commonService: CommonService,
        private dataService: ApiService,
        public getPlatformService: GetPlatformService,
        public translateService: TranslateService
    ) {
        // this.route.queryParams
        //     .subscribe(params => {
        //         if (params['pressId']) {
        //             this.pressId = params['pressId'];
        //         }
        //     });

        this.pressId = +this.route.snapshot.paramMap.get('id');
    }
    ngOnInit() {
        this.getPressList();
    }

    getPressList() {
        this.isLoading = true;
        this.pressData = null;
        const params = {
            dropdown: true,
        }
        this.subscription.add(
            this.dataService.get(URLS.pressList, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.isLoading = false;
                    if (data['data']) {
                        let pressGridDataInfinte = data['data']['result'];
                        pressGridDataInfinte.forEach(element => {
                            element.media_type = element.media_url ? element.media_url.substring(element.media_url.lastIndexOf('.') + 1) : null;
                            if (element.press_release_document_id == +this.pressId) {
                                this.pressData = element;
                            }
                        });
                    }

                }
                else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }




    goBack() {
        this.router.navigate(['/home']);
    }

    gomobileBack() {
        this.navCtrl.back({ animated: false });
    }


    handleRefresh(event) {
        setTimeout(() => {
            this.getPressList();
            event.target.complete();
        }, 1000);
    };

    ngOnDestroy() {
        if (this.playerDetails) {
            this.playerDetails.nativeElement.pause();
        }
    }

    ionViewWillLeave() {
        if (this.playerDetails) {
            this.playerDetails.nativeElement.pause();
        }
    }
}
