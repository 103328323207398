<ion-header class="mobile-visibility" mode="md" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBack()">
			<ion-title>{{"newsComponent.title" | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content>
	<div class="page-container">
		<img src="/assets/images/news-banner.png" class="page-banner" />


		<ion-row class="mt-50 pb-50">
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="9">
				<div class="d-flex">
					<div class="date-div">
						<img src="/assets/icon/clock.svg" class="icon-page" />
						<p class="pb-0 ph-10 card-desc2 mb-0">{{newsData.created_at}}</p>
					</div>
					<div class="ml-20 width-100">
						<div class="section-block">
							<img src="/assets/icon/account.svg" class="icon-page2" />
							<p class="pv-0 ph-10 icon-desc2 m-0">{{newsData.created_by}}</p>
						</div>
						<h3 class="pv-15 detail-title  m-0">{{newsData.title}}</h3>

						<div class="pv-15 iframDiv">
							<iframe class="iframeVideo"
								src="https://www.youtube.com/embed/ITVdO9T-kmI?si=kZ1lAvTEtzPMo-nE"
								title="YouTube video player" frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						</div>

						<div [innerHTML]="newsData.desc | safe :'html'"></div>


					</div>
				</div>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="3">
				<div>
					<h4>{{'newsComponent.latestnews'| translate}}</h4>
					<ion-row class="page-row">
						<ion-col *ngFor="let data of this.latestNewsData" class="mb-10 pointer"
							(click)="goToDetails(data)">
							<div class="section-block">
								<img src="{{data.img}}" class="card-img" />
								<div class="ml-15">
									<p class="card-title pv-7 m-0">{{data.title}}</p>
									<ion-row>
										<div class="section-block">
											<img src="/assets/icon/clock.svg" class="icon-page1" />
											<p class="pv-0  ph-10 icon-desc m-0">{{data.created_at}}</p>
										</div>
										<div class="section-block ml-15">
											<img src="/assets/icon/account.svg" class="icon-page1" />
											<p class="pv-0 ph-10 icon-desc m-0">{{data.created_by}}</p>

										</div>
									</ion-row>
								</div>
							</div>

						</ion-col>
					</ion-row>

				</div>
			</ion-col>


		</ion-row>

	</div>

	<div class="wrapper-block home-page" *ngIf="getPlatformService.isDesktop">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>