<ion-tabs>
    <ion-tab-bar class="mobile-visibility-in-flex pb-0" slot="bottom" mode="md">
        <ion-tab-button tab="home" routerLink="'/'+currentLang+/home">
            <i class="feather icon-home tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"Home" | translate}}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="categories" (click)="categoryTabClick()">
            <i class="feather icon-grid tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"categoriesComponent.category" | translate}}</ion-label>
        </ion-tab-button>

        <ion-tab-button  *ngIf="is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)" tab="cart" (click)="cartTabClick()">
            <i class="feather icon-shopping-cart tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"Cart" | translate}}</ion-label>
            <ion-badge class="badge" color="primary"
                *ngIf="cartService.isloggedIn && cartService.cartCount">{{cartService.cartCount}}</ion-badge>
        </ion-tab-button>

        <ion-tab-button  *ngIf="!is_not_sample_product && is_sample_item_and_shipment_free" tab="checkout-step-1" (click)="checkoutTabClick()">
            <i class="feather icon-shopping-cart tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"Cart" | translate}}</ion-label>
            <ion-badge class="badge" color="primary"
                *ngIf="cartService.isloggedIn && cartService.cartCount">{{cartService.cartCount}}</ion-badge>
        </ion-tab-button>
        
        <ion-tab-button tab="my-wishlist" routerLink="'/'+currentLang+/my-wishlist"
            [ngClass]="!getPlatformService.isDesktop && !commonService.domDirectionLTR ? 'wishlist-tab-btn' : ''">
            <i class="feather icon-heart tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"Wishlist" | translate}}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="orders" routerLink="'/'+currentLang+/orders" *ngIf="false">
            <i class="feather icon-file-text tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"Orders" | translate}}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="my-account" routerLink="'/'+currentLang+/my-account">
            <i class="feather icon-user tab-icon-size"></i>
            <ion-label class="tab-font-size">{{"account" | translate}}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>

</ion-tabs>