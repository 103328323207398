import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ContactUsPageRoutingModule } from './contact-us-routing.module';

import { SharedModule } from '../../common/shared.module';
import { ContactUsPage } from './contact-us.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ContactUsPageRoutingModule,
        SharedModule
    ],
    declarations: [ContactUsPage]
})
export class ContactUsPageModule { }
