import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { URLS } from "src/app/_config/api.config";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { ModalService } from "src/app/services/modal.service";
import { ToastService } from "src/app/services/toast.service";
import { CommonService } from "../../services/common.service";
import { NotificationCategoryComponent } from "../notification-category/notification-category.component";
import { ApiService } from "./../../services/api-data.service";
@Component({
	selector: "app-account-dashboard",
	templateUrl: "./account-dashboard.component.html",
	styleUrls: ["./account-dashboard.component.scss"],
})
export class AccountDashboardComponent implements OnInit {
	currency: any = "SR";

	notificationArray: any = [];
	orderArray: any = [];

	wishlistArray: any = [];
	private subscription: Subscription = new Subscription();
	public isLoading = false;
	dashboardData: any;
	currentLang;
	constructor(
		private router: Router,
		private dataService: ApiService,
		public commonService: CommonService,
		public modalService: ModalService,
		public navCtrl: NavController,
		private getPlatformService: GetPlatformService,
		private translateService: TranslateService,
		private toastService: ToastService
	) {
		this.currentLang = this.translateService.currentLang;
	}

	ngOnInit() {
		this.getDashboardCount();
		this.getWishList();
		this.getNotifications();
		this.getOrderList();
	}

	getDashboardCount() {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.dashBoardCount).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.dashboardData = data["data"];
						this.isLoading = false;
					} else {
						this.isLoading = false;
					}
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}
	getWishList() {
		const params = {
			page: 1,
			size: 5,
		};
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getWishList, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let wishListData = data["data"]["result"];
						this.wishlistArray = [];
						wishListData.forEach((element) => {
							let obj = {
								product_description: element.product_name_en
									? element.product_name_en
									: "",
								product_name: element.product_name_en
									? element.product_name_en
									: "",
								product_name_ar: element.product_name_ar
									? element.product_name_ar
									: "",
								category_name_en: element.category_name_en
									? element.category_name_en
									: "",
								category_name_ar: element.category_name_ar
									? element.category_name_ar
									: "",
								brand_name: element.brand_name_en ? element.brand_name_en : "",
								product_currency: "SR",
								product_price: element.price_per_unit
									? element.price_per_unit
									: "",
								price_per_unit: element.price_per_unit
									? element.price_per_unit
									: "",
								final_price: element.final_price ? element.final_price : "",
								product_unit: this.commonService.domDirectionLTR
									? element.unit_name_en
									: element.unit_name_ar,
								product_unit_arb: element.unit_name_ar,
								product_id: element.product_id,
								take_action: true,
								is_wishlist_product: element.is_wishlist_product
									? element.is_wishlist_product
									: false,
								main_product_ref_id: element.main_product_ref_id,
								wishlist_id: element.wishlist_id ? element.wishlist_id : null,
								sold_quantity: element.sold_quantity
									? element.sold_quantity
									: null,
								is_discount_applied: element.is_discount_applied
									? element.is_discount_applied
									: null,
								is_tax_free_product: element.is_tax_free_product
									? element.is_tax_free_product
									: null,
								available_quantity: element.available_quantity
									? element.available_quantity
									: null,
								is_sold_out: element.is_sold_out ? element.is_sold_out : null,
								product_sku: element.product_sku ? element.product_sku : null,
								total_quantity: element.total_quantity
									? element.total_quantity
									: null,
								discount_type: element.discount_type
									? element.discount_type
									: null,
								discount_value: element.discount_value
									? element.discount_value
									: null,
								product_container_img: "assets/icon/paint-container.svg"
							};

							if (this.getPlatformService.isDesktop) {
								obj["product_img"] = element.web_image_url
									? element.web_image_url
									: "assets/images/no-img1.png";
							} else {
								obj["product_img"] = element.mobile_image_url
									? element.mobile_image_url
									: "assets/images/no-img1.png";
							}

							this.wishlistArray.push(obj);
						});
						this.isLoading = false;
					} else {
						this.isLoading = false;
					}
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}

	// Navigate to relevant pages
	goToWiishlistPage() {
		this.router.navigate([`/${this.currentLang}/home/account-details/my-wishlist`]);
	}
	goToNotificationPage() {
		this.router.navigate([`/${this.currentLang}/home/account-details/notification`]);
	}

	gotoMobileWishlist() {
		this.modalService.dismissAllModal();
		this.navCtrl.navigateRoot('/' + this.currentLang + "/tabs/my-wishlist", { animated: false });
	}

	gotoMobileNotification() {
		this.modalService.dismissModal();
		let options: any = {
			componentProps: { mobileView: true },
			id: "addressModal",
		};
		options["component"] = NotificationCategoryComponent;
		this.modalService.openModal(options);
	}

	getNotifications(infiniteScroll?) {
		const params = {
			page: 1,
			limit: 5,
		};
		this.subscription.add(
			this.dataService.get(URLS.notificationList, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let list =
							data["data"] &&
								data["data"]["result"] &&
								data["data"]["result"].length
								? data["data"]["result"]
								: [];
						if (!infiniteScroll) {
							this.notificationArray = [];
						}
						list.forEach((element) => {
							let obj = {
								title: element.notification_en ? element.notification_en : "",
								category: element.notification_type_en
									? element.notification_type_en
									: "",
								titleAr: element.notification_ar ? element.notification_ar : "",
								categoryAR: element.notification_type_ar
									? element.notification_type_ar
									: "",
								time: element.created_at ? element.created_at : "",
								notification_id: element.notification_id,
								notification_type_id: element.notification_type_id,
							};
							this.notificationArray.push(obj);
						});
						this.notificationArray = this.notificationArray;
					} else {
					}
				},
				(error) => { }
			)
		);
	}

	getOrderList() {
		let params = {
			page: 1,
			limit: 5,
		};

		this.subscription.add(
			this.dataService.get(URLS.orderList, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let list =
							data["data"] &&
								data["data"]["result"] &&
								data["data"]["result"].length
								? data["data"]["result"]
								: [];
						list.forEach((element) => {
							let shipTo =
								element.shipping_address +
								(element.shipping_address_city
									? ", " + element.shipping_address_city
									: "") +
								(element.shipping_address_region
									? ", " + element.shipping_address_region
									: "") +
								(element.shipping_address_country
									? ", " + element.shipping_address_country
									: "") +
								(element.shipping_address_zipcode
									? ", " + element.shipping_address_zipcode
									: "");
							let obj = {
								order_status: element.shipping_status
									? element.shipping_status
									: "CONFIRMED",
								order_total: element.total_order_amount
									? element.total_order_amount
									: 0,
								invoice_url: element.invoice_url ? element.invoice_url : null,
								date: element.order_date ? element.order_date : "",
								order_no: element.order_no,
								ship_to: shipTo ? shipTo : "",
								invoice_id: element.invoice_id,
								zipcode: element.zipcode,
								items_count: element.item_count ? element.item_count : "-",
							};

							this.orderArray.push(obj);
						});
					}
					this.isLoading = false;
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}

	closeModal() {
		// setTimeout(() => {
		//     this.modalService.openModal({ component: MyAccountMenuComponent, componentProps: { mobileView: true } })
		// }, 300);
		this.navCtrl.back({ animated: false });
	}

	gotoOrderDetails(id, orderNo) {
		this.router.navigate(['/' + this.currentLang + "/home/account-details/my-orders/", id], {
			state: {
				breadcrumb:
					this.translateService.instant("orderDetailsComponent.orders") +
					orderNo,
				url: "/home/account-details/my-orders/" + id,
			},
		});
	}

	gotoOrderDetailsFromMobile(id, orderNo) {
		this.navCtrl.navigateForward(['/' + this.currentLang + "/home/order-details-mobile"], {
			queryParams: {
				invoiceId: id,
			},
		});
	}

	gotoOrderMobile() {
		this.navCtrl.navigateForward('/' + this.currentLang + "/my-account/orders", { animated: false });
	}

	gotoQuoteMobile() {
		this.navCtrl.navigateForward('/' + this.currentLang + "/my-account/my-quote-request", {
			animated: false,
		});
	}

	gotoReturnOrderMobile() {
		this.navCtrl.navigateForward('/' + this.currentLang + "/my-account/return-order", {
			animated: false,
		});
	}

	gotoInvoiceMobile() {
		this.navCtrl.navigateForward('/' + this.currentLang + "/my-account/my-invoice", { animated: false });
	}

	gotoOrder() {
		this.router.navigate(['/' + this.currentLang + "/home/account-details/my-orders"]);
	}

	gotoQuote() {
		this.router.navigate(['/' + this.currentLang + "/home/account-details/my-quote-request"]);
	}

	gotoInvoice() {
		this.router.navigate(['/' + this.currentLang + "/home/account-details/my-invoice"]);
	}

	gotoReturnOrder() {
		this.router.navigate(['/' + this.currentLang + "/home/account-details/return-order-listing"]);
	}
}
