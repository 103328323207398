<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header [catName]="commonService.domDirectionLTR ? sub_class_name_en : sub_class_name_ar"
		[catUrlName]="sub_class_name_en"></app-header>
</div>
<ion-header class="product-review-header" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="header-name">
				{{ productName }}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<ion-grid class="home-page">
		<!-- <app-banner
			*ngIf="isBannerLoaded && filterSubClassData && this.filterSubClassData.length < 2 && getPlatformService.isDesktop"
			[productlistBanner]="true" [productTitle]="productName" [bannerImage]="banner_img"
			[bannerTitle]="banner_title" [bannerDescription]="banner_description"></app-banner>
		<app-banner
			*ngIf="isBannerLoaded && filterSubClass && this.filterSubClass.length < 2 && !getPlatformService.isDesktop && !productListType"
			[productlistBanner]="true" [productTitle]="productName" [bannerImage]="banner_img"
			[bannerTitle]="banner_title" [bannerDescription]="banner_description"></app-banner>
		<div class=" p-t-0" *ngIf="!isBannerLoaded">
			<ion-skeleton-text name="lines-sharp-small" [animated]="true" class="width-full skeleton-text-animated"
				[ngClass]="getPlatformService.isDesktop ? 'height-500':'height-300'"></ion-skeleton-text>
		</div> -->
		<ion-row class="container-row">
			<ion-col class="filter-col pt-25 pr-40 " size-lg="4" size-xl="3">
				<div class="home-container pr-20 pb-50">
					<div class="home-filter-div">
						<div class="filter-label">
							{{"homePage.filterBy" | translate}}
						</div>
						<div class="filters">
							<div class="ion-text-center" *ngIf="!filterComponentLoaded">
								<ion-skeleton-text class="skeleton-text" [animated]="true"></ion-skeleton-text>
								<ion-skeleton-text class="skeleton-text mt-5" [animated]="true"></ion-skeleton-text>
								<ion-skeleton-text class="skeleton-text mt-5" [animated]="true"></ion-skeleton-text>
								<ion-skeleton-text class="skeleton-text mt-5" [animated]="true"></ion-skeleton-text>
								<ion-skeleton-text class="skeleton-text mt-5" [animated]="true"></ion-skeleton-text>
							</div>
							<ng-template #filterContainer></ng-template>
						</div>
					</div>
				</div>
			</ion-col>
			<ion-col class="main-content-col pr-0" size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="9">
				<div class="main-content">
					<div class="main-label">
						<div *ngIf="filterSubClassData && filterSubClassData.length === 0">
							{{this.totalDataCount}} {{"productsOn" | translate}}&nbsp;<b>
								{{filterparams['class_id'] && filterparams['class_id'].length > 0 ?
								('selectedCategories'|translate)
								:('Products'|translate)}}</b>
						</div>
						<div *ngIf="filterSubClassData && filterSubClassData.length === 1">
							{{this.totalDataCount}} {{"productsOn" | translate}}&nbsp;<b>{{ filterSubClassData[0]}}</b>
						</div>

						<div *ngIf="filterSubClassData && filterSubClassData.length > 1 ">
							{{this.totalDataCount}} {{"productsOn" | translate}}&nbsp;<b>{{
								filterparams['sub_class_id'] ? filterparams['sub_class_id'].length : ''}}
								{{'selectedSubCategories' | translate}}</b>
						</div>
						<div class="sort-by-dropdown">
							<div class="sort-by-header">
								{{"sortBy" | translate}}
							</div>
							<ion-list>
								<ion-item class="sort-by-item">
									<ion-icon class="mt-15 ml-3" src="assets/icon/relevancy_down_arrow.svg"
										alt="Jazeera" slot="end"></ion-icon>
									<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
										(ionChange)="selectSorting($event)" [disabled]="bannerId ? true : false"
										interface="popover" value="A-Z" [interfaceOptions]="inerfaceOption">
										<!-- <ion-select-option value="RELEVANCE">{{'relevance' |
											translate}}</ion-select-option>
										<ion-select-option value="POPULARITY">{{'popularity' |
											translate}}</ion-select-option> -->
										<ion-select-option value="A-Z">{{'A-Z' |
											translate}}</ion-select-option>
										<ion-select-option value="Z-A">{{'Z-A' |
											translate}}</ion-select-option>
										<ion-select-option value="PRICE_ASC">{{'priceASC' |
											translate}}</ion-select-option>
										<ion-select-option value="PRICE_DESC">{{'priceDESC' |
											translate}}</ion-select-option>
										<!-- <ion-select-option value="DISCOUNTED_PRODUCTS">{{'discountedPrice' |
											translate}}</ion-select-option> -->
									</ion-select>
								</ion-item>
							</ion-list>
						</div>
					</div>

					<div class="main-label-mobile">

						<div class="sort-by-mobile" (click)="productListType || bannerId ? null :openSortByModal()">
							<div class="dropdown-icon">
								<img src="assets/icon/sort-mobile-icon.svg" alt="sort-icon">
							</div>
							<div class="filter-icon">
								<ion-icon src="assets/icon/relevancy_down_arrow.svg" alt="downArrow"></ion-icon>
							</div>
							<div class="dropdown-container">
								<div class="sort-by-mobile-header"
									[ngClass]="[ productListType || bannerId ? 'button-disabled' : '']">
									{{"sortBy" | translate}} <dd></dd>
								</div>
								<ion-list>
									<ion-item class="sort-by-item" lines="none">
										<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
											*ngIf="selectedSortBy" [(ngModel)]="selectedSortBy" class="pointer-none"
											[disabled]="productListType || bannerId ? true : false">
											<ion-select-option value="{{ selectedSortBy }}">{{ selectedSortBy |
												translate}}</ion-select-option>
										</ion-select>
										<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
											*ngIf="!selectedSortBy" value="1" class="pointer-none">
											<ion-select-option value="1">0 selected</ion-select-option>
										</ion-select>
									</ion-item>
								</ion-list>
							</div>
						</div>
						<div class="sort-by-mobile" (click)="productListType || bannerId ? null :openFilterModal()">
							<div class="dropdown-icon">
								<img src="assets/icon/filter-mobile-icon.svg" alt="mobileIcon">
							</div>
							<div class="filter-icon">
								<ion-icon src="assets/icon/relevancy_down_arrow.svg" alt="downArrow"></ion-icon>
							</div>
							<div class="dropdown-container">
								<div class="sort-by-mobile-header"
									[ngClass]="[ productListType || bannerId ? 'button-disabled' : '']">
									{{"filterBy" | translate}}
								</div>
								<ion-list>
									<ion-item class="sort-by-item" lines="none">
										<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
											*ngIf="filterCount" [(ngModel)]="filterCount" class="pointer-none"
											[disabled]="productListType || bannerId">
											<ion-select-option value="{{ filterCount }}">{{ filterCount }}
												{{'filters' | translate}}</ion-select-option>
										</ion-select>
									</ion-item>
								</ion-list>
							</div>
						</div>
					</div>

					<!-- loader -->
					<app-loader class="loader" [productListing]="true" *ngIf="isLoading"></app-loader>

					<div *ngIf="productData && productData.length == 0 && !isLoading" class="norecord_height">
						<span>{{'noRecordFound' | translate}}</span>
					</div>

					<!-- loading the component on isloading -->
					<div class="product-grid pb-50" *ngIf="!isLoading && productData && productData.length > 0">
						<app-product-grid [pageName]="productName" [showBanner]="false" [btnStatus]="enableNext"
							[productData]="productData" (loadMore)="loadMoreData($event)"></app-product-grid>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<div class="wrapper-block home-page">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
	<ion-infinite-scroll (ionInfinite)="loadData($event)" *ngIf="enableNext && !getPlatformService.isDesktop">
		<ion-infinite-scroll-content loadingSpinner="circular" loadingText="{{'lodingMoreData' | translate}}">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</ion-content>