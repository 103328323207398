<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>
</div>

<ion-content *ngIf="getPlatformService.isDesktop">
	<div class="mt-20">
		<!-- <div class="center-div" *ngIf="paymentStatus == 'SADDAD'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.thankyouMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">{{"orderThankYou.yourorder" | translate}}
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.saddadOrderMsg" | translate}} {{"orderThankYou.saddadMsg" | translate}}
				<span class="color-primary order-id">
					{{dueDate| date:'dd-MM-yyyy'}}
				</span>
				{{"orderThankYou.saddadMsgDesc" | translate}}
			</p>

			<div class="pv-10 mt-10">
				<p class="label-payment-6 pb-5 m-0">{{"paymentComponent.PaymentPending" | translate}}
				</p>
			</div>
			<div class="border-bottom">
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.SaddadInvoiceNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{transactionId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.OrderNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right">
						<p class="label-payment-4">#{{orderId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3">{{"paymentComponent.dueDate" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4">{{dueDate| date :'dd MMM yyyy, hh:mm: a'}}</p>
					</ion-col>
				</ion-row>

			</div>


			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToHome()"
					title="{{'orderThankYou.countinueShopping'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>

		</div>
		<div class="center-div" *ngIf="paymentStatus == 'SUCCESSFUL'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/mark.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.thankyouMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">{{"orderThankYou.yourorder" | translate}}
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.orderMsg" | translate}}
			</p>

			<div class="pv-10 mt-10" *ngIf="paymentAmt">
				<p class="label-payment-6 pb-5 m-0">{{"paymentComponent.Paid" | translate}}
					<span class="label-payment-7">{{commonService.currencySymbol}}{{paymentAmt}}</span>
				</p>
				<p class="label-payment-4 m-0">{{dateTime| date :'dd MMM yyyy, hh:mm: a'}}</p>
			</div>
			<div class="border-bottom">
				<ion-row class="ph-16" *ngIf="transactionId">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3 mv-10">{{"paymentComponent.TransactionID" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right width-70">
						<p class="label-payment-4 mv-10">{{transactionId}}</p>
					</ion-col>
				</ion-row>
				<ion-row class="ph-16">
					<ion-col class="p-0 text-left">
						<p class="label-payment-3 mv-10">{{"paymentComponent.OrderNo" | translate}}</p>
					</ion-col>
					<ion-col class="p-0 text-right">
						<p class="label-payment-4 mv-10">#{{orderId}}</p>
					</ion-col>
				</ion-row>
			</div>


			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToHome()"
					title="{{'orderThankYou.countinueShopping'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>

		</div>
		<div class="center-div" *ngIf="paymentStatus == 'PENDING'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentOnHold.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.holdMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.holdMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="goToAccount()"
					title="{{'orderThankYou.holdBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus == 'FAILED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentFail.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.failMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.failMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.failBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus == 'DECLINED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentDeclined.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.declinedMsg" | translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.declinedMsgDesc" | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.declinedBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div" *ngIf="paymentStatus!= 'SADDAD' && paymentStatus  != 'SUCCESSFUL' && paymentStatus != 'PENDING' && paymentStatus != 'FAILED'
        && paymentStatus != 'DECLINED'">
			<div class="pt-35">
				<img class="mark-icon" src="assets/icon/PaymentFail.svg" alt="Jazeera">
			</div>
			<p class="label-1 color-app-black mb-12 mt-10">{{"orderThankYou.cancelMsg" | translate}} {{paymentStatus |
				translate}}</p>
			<p class="label-2 color-app-grey msg-div">
				<span class="color-primary order-id" (click)="gotoOrderDetails()">{{orderId}}</span>
				{{"orderThankYou.cancelMsgDesc" | translate}} {{paymentStatus | translate}}
			</p>

			<div class="pb-70 pt-32">
				<app-common-button color="#FFF" background="#0081CA" type="solid" (click)="gotoCart()"
					title="{{'orderThankYou.cancelBtn'|translate}}" [isIcon]="false" [isHideInMobile]="false"
					[specificHeight]="'54px'" [specificFontSize]="'1rem'" [specificFontWeight]="700"
					[specificWidth]="'250px'">
				</app-common-button>
			</div>
		</div>
		<div class="center-div-2">
			<img src="./assets/icon/thankyou.svg" alt="Jazeera" class="mr-10" />
			<p class="label-3 color-app-grey mv-0">{{"orderThankYou.trackMsg" | translate}}</p>
			<p class="label-4 color-primary pointer ml-5 mv-0" (click)="goToAccount()">{{"orderThankYou.viewaccount" |
				translate}}
			</p>
			<i class="label-4 color-primary feather icon-chevron-right i-icon pointer" (click)="goToAccount()"></i>
		</div> -->

		<!-- ------ fail --------- -->
		<div class="center-div-2 display-block" *ngIf="paymentStatus != 'SUCCESSFUL'">
			<div class="center-div-2">
				<img class="confirmed-bg round-bg" src="/assets/images/round-bg.svg">
			</div>
			<p class="label-1 color-text-red text-bold mv-0">{{"orderThankYou.oopsMsg" | translate}}</p>
			<p class="label-msg label-text-msg color-app-black mv-0 mb-12">{{"orderThankYou.trFailedMsg" | translate}}
			</p>
			<div class="center-div-2 ext-class">
				<img class="ext-bg" src="/assets/images/ext-bg.svg">
				<img class="ext-img" src="/assets/images/ext-img.svg">
			</div>
		</div>


		<!-- -------- success ---------- -->
		<div class="center-div-2 display-block order-confirmed-div" *ngIf="paymentStatus == 'SUCCESSFUL'">
			<div class="center-div-2">
				<img class="confirmed-bg round-bg" src="/assets/images/round-bg.svg">
				<img class="confirmed-bg" src="/assets/images/confirmed-bg.svg">
			</div>
			<div class="confirmed-div">
				<p class="label-1 color-text-blue text-bold mv-0">{{"orderThankYou.congratsMsg" | translate}}<img
						class="ml-10" src="/assets/images/check-mark.svg"></p>
				<p class="label-msg color-app-black label-text-msg mv-0 mb-12">{{"orderThankYou.congratsDescription" |
					translate}}
				</p>
			</div>
			<div class="center-div-2 ext-class" *ngIf="this.orderDeatilsData">
				<img class="ext-bg" src="/assets/images/ext-bg.svg">
				<div class="center-div-2 table-class">
					<div>
						<p class="label-msg color-app-black order-summary">{{"orderThankYou.orderSummery" | translate}}
						</p>
					</div>
					<div class="order-div">
						<ion-grid [fixed]="true">
							<ion-row>
								<ion-col class="text-align-start">{{"orderThankYou.orderDate" | translate}}</ion-col>
								<ion-col>{{"orderThankYou.orderNumber" | translate}}</ion-col>
								<ion-col class="text-align-end">{{"orderThankYou.paymentMethod" | translate}}</ion-col>
							</ion-row>
							<ion-row>
								<ion-col class="order-value text-align-start">{{orderDeatilsData.order_date | date
									:'dd MMM yyyy'}}</ion-col>
								<ion-col class="order-value">{{orderDeatilsData.order_no}}</ion-col>
								<ion-col class="order-value text-align-end">{{orderDeatilsData.payment_type||
									'--'}}</ion-col>
							</ion-row>
							<ion-row class="mt-20"
								*ngIf="orderDeatilsData.result && orderDeatilsData.result.length && orderDeatilsData.result[0].products">
								<ion-col class="order-value text-align-start">{{"orderThankYou.orderedItems" |
									translate}}</ion-col>
								<ion-col
									class="order-value text-align-end color-text-blue">{{orderDeatilsData.order_items?.length
									|| '0'}} {{"orderThankYou.items" |
									translate}}</ion-col>
							</ion-row>
						</ion-grid>
					</div>
					<div>
						<div class="white-div-head">
							<p class="fs-18 fw-600 p-0 m-0">Ordered Items</p>
							<p class="fs-16 fw-600 p-0 m-0 color-primary"
								*ngIf="orderDeatilsData && orderDeatilsData.order_items && orderDeatilsData.order_items.length">
								{{orderDeatilsData.order_items.length}} Items</p>
						</div>
						<ion-list lines="none" class="min-height-table"
							*ngIf="orderDeatilsData && orderDeatilsData.order_items && orderDeatilsData.order_items.length">
							<ion-item *ngFor="let data of orderDeatilsData.order_items" class="pv-10">
								<img [src]="data.cover_image" [alt]="Jazeera" class="product-img" />
								<ion-label class="fs-16 ml-10">{{commonService.domDirectionLTR ? data.product_name_en :
									data.product_name_ar}}<br>{{data.product_sku}}</ion-label>
								<ion-label slot="end"><b class="color-app-black">{{commonService.currencySymbol}}
										{{data.price_per_unit}}</b> / {{commonService.domDirectionLTR
									?data.unit_name_en : data.unit_name_ar}}</ion-label>
							</ion-item>

						</ion-list>
					</div>

					<div class="pt-20 shadow">
						<app-order-summary [currency]="commonService.currencySymbol"
							[orderTotal]="orderDeatilsData['total_order_amount']"
							[orderSubTotal]="orderDeatilsData['total_items_sub_total']"
							[orderVAT]="orderDeatilsData['total_vat_amount']"
							[orderDiscount]="orderDeatilsData['discount_applied']"
							[orderShipping]="orderDeatilsData.shipping_charges" isTotalSummary="false"
							[background]="'white'">
						</app-order-summary>
					</div>
					<div class="box"></div>

				</div>
			</div>

		</div>
	</div>
</ion-content>

<app-footer [showOnlyLowFooter]="true" *ngIf="getPlatformService.isDesktop"></app-footer>

<ion-content *ngIf="!getPlatformService.isDesktop">
	<div class="pt-20">
		<div class="center-div-2 display-block" *ngIf="paymentStatus != 'SUCCESSFUL'">
			<div class="center-div-2">
				<img class="confirmed-bg round-bg" src="/assets/images/round-bg.svg">
			</div>
			<p class="label-1 color-text-red text-bold mb-20">{{"orderThankYou.oopsMsg" | translate}}</p>
			<p class="label-msg label-text-msg color-app-black mb-20">{{"orderThankYou.trFailedMsg" | translate}}
			</p>
			<div class="center-div-2 ext-class">
				<img class="ext-bg" src="/assets/images/ext-bg.svg">
				<img class="ext-img" src="/assets/images/ext-img.svg">
			</div>
		</div>

		<!-- -------- success ---------- -->
		<div class="center-div-2 display-block order-confirmed-div" *ngIf="paymentStatus == 'SUCCESSFUL'">
			<div class="center-div-2">
				<img class="confirmed-bg round-bg" src="/assets/images/round-bg.svg">
				<img class="confirmed-bg" src="/assets/images/confirmed-bg.svg">
			</div>
			<div class="confirmed-div">
				<p class="label-1 color-text-blue text-bold mv-0">{{"orderThankYou.congratsMsg" | translate}}<img
						class="ml-10" src="/assets/images/check-mark.svg"></p>
				<p class="label-msg color-app-black label-text-msg mv-0 mb-12">{{"orderThankYou.congratsDescription" |
					translate}}
				</p>
			</div>
			<div class="center-div-2 ext-class-mob " *ngIf="this.orderDeatilsData">
				<img class="ext-bg" src="/assets/images/ext-bg.svg">
				<div class="center-div-2 table-class-mob">
					<div>
						<p class="label-msg color-app-black order-summary">{{"orderThankYou.orderSummery" | translate}}
						</p>
					</div>
					<div class="order-div">
						<ion-grid [fixed]="true">
							<ion-row class="justify-center mt-15">{{"orderThankYou.orderDate" | translate}}</ion-row>
							<ion-row class="order-value justify-center">{{orderDeatilsData.order_date | date
								:'dd MMM yyyy'}}</ion-row>

							<ion-row class="justify-center mt-15">{{"orderThankYou.orderNumber" |
								translate}}</ion-row>
							<ion-row class="order-value justify-center">{{orderDeatilsData.order_no}}</ion-row>

							<ion-row class="justify-center mt-15">{{"orderThankYou.paymentMethod" |
								translate}}</ion-row>
							<ion-row class="order-value justify-center">{{orderDeatilsData.paymemt_mode||
								'--'}}</ion-row>
							<ion-row class="mt-20"
								*ngIf="orderDeatilsData.result && orderDeatilsData.result.length && orderDeatilsData.result[0].products">
								<ion-col class="order-value text-align-start">{{"orderThankYou.orderedItems" |
									translate}}</ion-col>
								<ion-col
									class="order-value text-align-end color-text-blue">{{orderDeatilsData.result[0]?.products?.length
									|| '0'}} {{"orderThankYou.items" |
									translate}}</ion-col>
							</ion-row>
						</ion-grid>
					</div>
					<div>
						<ion-list lines="none"
							*ngIf="orderDeatilsData.result && orderDeatilsData.result.length && orderDeatilsData.result[0].products">
							<ion-item *ngFor="let data of orderDeatilsData.result[0].products" class="pv-10">
								<div>
									<img [src]="data.product_cover_image[0]['image_url']" [alt]="Jazeera"
										class="product-img mr-10" />
									<ion-label class="fs-16">{{commonService.domDirectionLTR ? data.product_name_en :
										data.product_name_ar}}<br>{{data.product_sku}}</ion-label>
								</div>

								<ion-label slot="end"><b class="color-app-black">{{commonService.currencySymbol}}
										{{data.total_price_per_product}}</b> / {{commonService.domDirectionLTR
									?data.unit_name_en : data.unit_name_ar}}</ion-label>
							</ion-item>
						</ion-list>
					</div>
					<div class="pt-20 mb-40">
						<app-order-summary [currency]="commonService.currencySymbol"
							[orderSummeryData]="orderDeatilsData.grand_total" isTotalSummary="false">
						</app-order-summary>
					</div>
				</div>
			</div>
		</div>
	</div>
</ion-content>