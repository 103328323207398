import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ToastService } from "../../services/toast.service";
import { latestNewsData, newsData } from "./news-details-data";

@Component({
	selector: "app-news-details",
	templateUrl: "./news-details.page.html",
	styleUrls: ["./news-details.page.scss"],
})
export class NewsDetailsPage implements OnInit {

	public subscription: Subscription = new Subscription();
	isLoading: boolean = false;
	newsData: any = [];
	latestNewsData: any = latestNewsData
	searchText: any;

	fromDate: any;
	todaysDate = new Date();
	maxFromDate: any;
	selectedSortBy: any;
	btnStatus: any = true;
	stateData: any;
	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title,
		protected sanitizer: DomSanitizer,
		private datePipe: DatePipe,
		private router: Router,

	) {
	}

	ngOnInit() {
		// this.getData();
		this.newsData = newsData;
		this.stateData = this.router.getCurrentNavigation().extras.state;

	}

	ionViewWillEnter() {
		// this.getData();
	}



	getData() {
		this.isLoading = true;
		const params = {
			content_type: "ABOUT_US",
		};
		this.subscription.add(
			this.dataService.get(URLS.getCMSdata, params).subscribe(
				(data) => {

				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}



	goBack() {
		this.navCtrl.back({ animated: false });
	}
}
