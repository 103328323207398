import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';


@Injectable({
    providedIn: 'root'
})
export class CapacitorCookieService {
    @Inject(PLATFORM_ID) private platformId: Object;
    getCookies = () => {
        if (!isPlatformServer(this.platformId)) {
            return document.cookie;
        }
    };

    setCookie(key, value) {
        if (!isPlatformServer(this.platformId)) {
        document.cookie = key + '=' + value;
        }
    };

    setCapacitorCookie = async () => {
        await CapacitorCookies.setCookie({
            // url: 'http://example.com',
            key: 'language',
            value: 'en',
        });
    };

    deleteCookie = async () => {
        await CapacitorCookies.deleteCookie({
            url: 'https://example.com',
            key: 'language',
        });
    };

    clearCookiesOnUrl = async () => {
        await CapacitorCookies.clearCookies({
            url: 'https://example.com',
        });
    };

    clearAllCookies = async () => {
        await CapacitorCookies.clearAllCookies();
    };
}