import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CommonService } from 'src/app/services/common.service';
import { ModalService } from '../../services/modal.service';
import { CustomValidator } from './../../../validators/customvalidators';
import { ApiService } from './../../services/api-data.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { ToastService } from './../../services/toast.service';

@Component({
    selector: 'app-add-review',
    templateUrl: './add-review.component.html',
    styleUrls: ['./add-review.component.scss'],
    providers: [NavParams]
})
export class AddReviewComponent implements OnInit {
    reviewFormGroup: FormGroup;
    public isLoading = false;
    private subscription: Subscription = new Subscription();

    public validation_messages = {
        customer_name: [
            { type: 'required', message: 'addReviewComponent.nameRequired' },
            { type: 'invalidString', message: 'addReviewComponent.nameInvalid' },
        ],
        review_title: [
            { type: 'required', message: 'addReviewComponent.titleRequired' },
            { type: 'invalidString', message: 'addReviewComponent.titleInvalid' },
        ],
        review_description: [
            { type: 'required', message: 'addReviewComponent.descriptionRequired' },
            { type: 'invalidString', message: 'ddReviewComponent.descriptionInvalid' },
        ],

    }
    @Input() product_id: any;
    product_rating: any = 1;
    isLoggedIn: boolean;
    constructor(private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private navParams: NavParams,
        public router: Router) {
        this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
    }

    ngOnInit() {

        this.reviewFormGroup = this.initializeFormGroup();

    }

    closeModal() {
        this.modalService.dismissModal();
    }

    get f1() { return this.reviewFormGroup.controls; }


    ratingChange(event) {
        this.product_rating = event.rating;
    }
    onSubmit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (!this.reviewFormGroup.valid) return;
        if (!this.product_rating) {
            this.toastService.displayToast(this.commonService.domDirectionLTR ? "Please enter rating" : "الرجاء إدخال التصنيف", null, 'danger');
            return;
        }

        let formData =
        {
            "product_id": +this.product_id,
            "customer_rating": this.product_rating,
            "review_title": this.f1['review_title'].value,
            "customer_review": this.f1['review_description'].value,
        }

        if (!this.isLoggedIn) {
            formData['customer_name'] = this.f1['customer_name'].value
        }
        this.isLoading = true;

        this.subscription.add(this.dataService.post(URLS.addReviews, formData).subscribe(data => {
            if (data['code'] == 200) {
                this.modalService.dismissModal(data['data']);
            } else {
                this.toastService.displayToast(data['message'], null, 'danger');
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.toastService.displayToast(error['message'], null, 'danger');
        })
        )


    }

    initializeFormGroup() {
        const token = this.commonService.localStorageGet('accessToken');

        return this.formBuilder.group({
            customer_name: new FormControl(null, {
                validators: Validators.compose([!token ? Validators.required : null, CustomValidator.noWhiteSpaceValidator]),
            }),
            review_title: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
            }),
            review_description: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
            }),
        })
    }
}
