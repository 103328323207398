import { isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { IonContent, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { URLS } from '../../_config/api.config';
import { ReturnOrderMobileFilterComponent } from '../../components/return-order-mobile-filter/return-order-mobile-filter.component';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToastService } from './../../services/toast.service';


@Component({
	selector: 'app-invoice-mobile',
	templateUrl: 'invoice-mobile.page.html',
	styleUrls: ['invoice-mobile.page.scss'],
	providers: []
})
export class InvoiceMobilePage {
	@ViewChild(IonContent) content: IonContent;
	private subscription: Subscription = new Subscription();
	enableNext = false;
	showFilter = false;
	public myOrders = [];
	orderParams = {
		page: 1,
		Size: 100
	}
	currentPage = 1;
	itemsPerPage = 10;
	count = 0;
	filterObj = {};
	isLoggedIn: boolean;
	invoiceList = [];
	backupAllinvoiceList = [];
	statusList = [];
	searchText: any;

	public isLoading = false;
	currentLang;
	constructor(private dataService: ApiService,
		private modalService: ModalService,
		public commonService: CommonService,
		private toastService: ToastService,
		private translateService: TranslateService,
		private navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		public platform: Platform,
		private exportService: ExportExcelService,
		@Inject(PLATFORM_ID) private platformId: Object,



	) {
		this.currentLang = this.translateService.currentLang;
		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'profileData' || data.event == 'logoutCall') {
				const token = this.commonService.localStorageGet('accessToken');
				if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
				if (this.isLoggedIn) {
					// this.getOrderList();  uncomment when integrating API

				}
			}
		});


	}
	ionViewWillEnter() {
		this.statusList = [
			{ value: 'CONFIRMED', label: this.translateService.instant('confirmed') },
			{ value: 'DISPATCHED', label: this.translateService.instant('dispatched') },
			{ value: 'DELIVERED', label: this.translateService.instant('delivered') },
		];
		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn) {
			this.filterObj = {};
			this.currentPage = 1;
			this.getInvoiceList(this.filterObj);

		}
	}


	ngOnInit() {
		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn) {
			this.filterObj = {};
			this.getInvoiceList(this.filterObj);
		}
	}

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let subClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);
		this.navCtrl.navigateForward('/' + this.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id, { animated: false, "state": { "breadcrumb": data.product_name_en, "url": "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id } });
	}


	getInvoiceList(params, infiniteScroll?, download?) {
		if (!download) {
			params = {
				page: this.currentPage,
				limit: +this.itemsPerPage,
				...params,
			};
		}
		return new Promise((resolve, reject) => {
			if (!download) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.get(URLS.invoiceList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {

							this.backupAllinvoiceList = data['data'] || [];
							this.backupAllinvoiceList.forEach(element => {
								if (element.invoice_date == "    -  -  ") element.invoice_date = null;
								if (element.invoice_date == "0000-00-00") element.invoice_date = null;
							});

							this.processAndPaginateData(
								this.backupAllinvoiceList,
								this.itemsPerPage,
								this.currentPage,
								download,
								infiniteScroll
							)
								.then((invoiceList) => {
									resolve(invoiceList);
								})
								.catch((error) => {
									console.error("Error processing data:", error);
									reject(error);
								});
							resolve(this.invoiceList);
						} else {
							reject();
						}
						this.isLoading = false;
					},
					(error) => {
						reject();
						this.isLoading = false;
					}
				)
			);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.processAndPaginateData(this.backupAllinvoiceList, this.itemsPerPage, this.currentPage, false, true, this.filterObj)
					.then((statementList) => {
						event.target.complete();
					})
					.catch((error) => {
						event.target.complete();
					});
			}
		}, 500);
	}

	openFilterByModal() {
		let modal: any;
		let options: any = {
			id: "orderFilterByModal",
			componentProps: { statusList: this.statusList, fromOrdersList: true, filterObj: this.filterObj },
		};
		options['component'] = ReturnOrderMobileFilterComponent;
		modal = this.modalService.openModal(options)
		modal.then((data) => {
			if (data && data['data']) {
				this.filterObj = {
					...data['data']
				};
				if (this.searchText) {
					this.filterObj['search'] = this.searchText;
				}
				this.currentPage = 1;

				this.processAndPaginateData(this.backupAllinvoiceList, this.itemsPerPage, this.currentPage, false, false, this.filterObj)
					.then((statementList) => {
					})
					.catch((error) => {
						console.error("Error processing data:", error);
					});
			}

		})
	}

	openOrderDetailsModal(id) {
		this.navCtrl.navigateForward(['/' + this.currentLang + '/home/order-details-mobile'], {
			queryParams: {
				invoiceId: id
			}
		})
	}

	clearSearch(event) {
		delete this.filterObj['search'];
		this.searchText = null;
		this.currentPage = 1;
		this.content.scrollToTop(500);
		this.processAndPaginateData(this.backupAllinvoiceList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
			});
	}

	handleRefresh(event) {
		setTimeout(() => {
			// code maybe required
			// this.isLoading = false;
			if (this.isLoggedIn) {
				if (this.filterObj['search']) {
					this.processAndPaginateData(this.backupAllinvoiceList, this.itemsPerPage, this.currentPage, false, false)
						.then((statementList) => {
						})
						.catch((error) => {
							console.error("Error processing data:", error);
						});
				} else {
					this.filterObj = {};
					this.currentPage = 1;
					this.processAndPaginateData(this.backupAllinvoiceList, this.itemsPerPage, this.currentPage, false, false)
						.then((statementList) => {
						})
						.catch((error) => {
							console.error("Error processing data:", error);
						});
				}

			}
			event.target.complete();
		}, 1000);
	};

	search(event?: any) {
		if (this.searchText) {
			this.filterObj['search'] = this.searchText;
			this.currentPage = 1;
			this.content.scrollToTop(500);
			this.processAndPaginateData(this.backupAllinvoiceList, this.itemsPerPage, this.currentPage, false, false, null)
				.then((statementList) => {
				})
				.catch((error) => {
					console.error("Error processing data:", error);
				});
		} else {
			this.filterObj['search'] = null
		}

	}

	goBack() {
		this.navCtrl.navigateRoot('/' + this.currentLang + '/my-account', { animated: false });
	}

	orderStatus(orderData, productlevel?) {
		switch (orderData.order_status) {
			case 'NOT_YET_DELIVERED':
			case 'DISPATCHED':
			case 'CONFIRMED':
				if (productlevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
			case 'DELIVERED':
				if (productlevel) {
					return 'btn-black product-status-btn';
				} else {
					return 'btn-black';
				}
			case 'CANCELLED':
				if (productlevel) {
					return 'btn-red product-status-btn';
				} else {
					return 'btn-red';
				}
			default:
				if (productlevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
		}
	}

	async downloadInvoice(event, data) {
		event.preventDefault();
		event.stopPropagation();

		const params = {
			invoice_no: data.invoice_no,
		};
		this.isLoading = true;

		this.subscription.add(
			this.dataService.post(URLS.invoiceDownload, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						if (!isPlatformServer(this.platformId)) {
							window.open(data["data"]);
						}
					} else {
					}
					this.isLoading = false;
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);

		// let invoiceFileName = invoiceUrl.substring(invoiceUrl.lastIndexOf('/') + 1)

		// const options = {
		// 	url: invoiceUrl,
		// 	filePath: invoiceFileName,
		// 	fileDirectory: this.platform.is("android") ? Directory.Documents : Directory.Data,
		// 	progress: true,
		// 	// Optional
		// 	method: 'GET',
		// };

		// // Writes to local filesystem
		// const response: HttpDownloadFileResult = await Http.downloadFile(options);
		// // Then read the file
		// if (response.path) {
		// 	await Filesystem.readFile({
		// 		path: invoiceFileName,
		// 		directory: Directory.Documents,
		// 	}).then(data => {
		// 		const msg = this.commonService.domDirectionLTR ? 'File download successfully, Please check your directory.'
		// 			: 'تم تنزيل الملف بنجاح ، يرجى التحقق من الدليل الخاص بك.'
		// 		this.toastService.displayToast(msg, null, 'success')
		// 	},
		// 		(error) => {
		// 			this.toastService.displayToast(error, null, 'error')
		// 		});


		// }
	}


	processAndPaginateData(data, pageSize, pageIndex, download = false, infiniteScroll, filterPayload?) {
		if (this.filterObj && Object.keys(this.filterObj).length > 0) {
			filterPayload = this.filterObj
		}
		return new Promise((resolve, reject) => {
			if (!data) {
				reject("No data available.");
				return;
			}

			let filterData = [];
			if (filterPayload) {
				// Apply filtering

				filterData = filterData = data.filter((item) => {
					const doc_from_date = filterPayload.from_date ? new Date(filterPayload.from_date) : null;
					const doc_to_date = filterPayload.to_date ? new Date(filterPayload.to_date) : null;


					const documentDate = item.invoice_date && item.invoice_date != "0000-00-00" ? new Date(item.invoice_date) : null;


					const withinDateRange =
						doc_from_date && doc_to_date && documentDate
							? documentDate >= doc_from_date && documentDate <= doc_to_date
							: false;


					// Return true if any of the conditions are satisfied
					return withinDateRange;
				});
			}

			// Apply the search filter if there is a search text
			if (this.searchText) {
				const lowerCaseSearchTerm = this.searchText.toLowerCase();

				filterData = data.filter(item => {
					// Check if any field in the item matches the search term
					return Object.keys(item).some(key => {
						const value = item[key];
						if (value) {
							// Check if the value is a string or a number, and match case-insensitively
							return value.toString().toLowerCase().includes(lowerCaseSearchTerm);
						}
						return false;
					});
				});
			}


			let listData = filterPayload || this.searchText ? filterData : data;


			pageSize = +pageSize;
			// Total items and pages
			const totalCount = listData.length;
			const startIndex = (pageIndex - 1) * pageSize; // Adjust for pageIndex starting from 1
			const endIndex = startIndex + pageSize;
			const paginatedData = listData.slice(startIndex, endIndex);

			if (download) {
				this.isLoading = false
				this.exportService.exportAsExcelFile(listData, "invoice_list");
				resolve([]);
				return;
			}
			const mappedData = paginatedData.map((item) => ({
				invoice_no: item.invoice_no || "--",
				invoice_date: item.invoice_date || "",
				order_total: parseFloat(item.order_total || "0"),
				invoice_status: item.invoice_status || "",
			}));

			if (!infiniteScroll) {
				this.invoiceList = mappedData; // Replace the list
			} else {
				this.invoiceList = [...this.invoiceList, ...mappedData]; // Append for infinite scroll
			}

			this.count = totalCount; // Update total count
			this.enableNext = endIndex < totalCount; // Enable/disable next page;

			resolve(this.invoiceList);
		});
	}
}
