import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { StatementRoutingModule } from './statement-routing.module';


@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StatementRoutingModule,
		SharedModule
	]
})
export class StatementModule { }
