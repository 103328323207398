import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { PressDetailsComponentRoutingModule } from './press-details-routing.module';
import { PressDetailsComponent } from './press-details.component';


@NgModule({
    declarations: [PressDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        PressDetailsComponentRoutingModule
    ]
})
export class PressDetailsModule { }
