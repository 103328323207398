<div class="orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{"invoicesComponent.title" | translate}}
		</h1>
		<!-- <div class="search-and-filter" *ngIf="invoiceList &&invoiceList.length ">
			<div class="filter-div">
				<div class="printer-icon">
					<app-common-button (click)="downloadExcel()" [btnStatus]="btnStatus" color="white"
						background="var(--ion-color-green-80)" type="solid" title="{{ 'export' | translate }}"
						[isIcon]="true" iconSrc="assets/icon/fi_printer.svg" [specificPaddingStart]="'20px'"
						[specificPaddingEnd]="'20px'" [isHideInMobile]="true" [specificWidth]="'auto'"
						[specificHeight]="'50px'" [specificFontWeight]="'bold'" [margin]="0"></app-common-button>
				</div>
			</div>
		</div> -->
	</div>
	<!-- <div>
		<div class="button-block">
			<button class="selected-order-button" [ngClass]="selectedOrder === 'all' ? 'all-selected-button' : ''"
				(click)="updateSelectedOrder('all')">{{"all" | translate}}</button>
			<button class="selected-order-button"
				[ngClass]="selectedOrder === 'outstanding' ? 'outstanding-selected-button' : ''"
				(click)="updateSelectedOrder('outstanding')">{{"invoicesComponent.outstandingInvoices" |
				translate}}</button>
		</div>
	</div> -->
	<form [formGroup]="dateRange">
		<div class="filter-section">
			<div class="filter-header width-100">
				<div>
					<ion-row>
						<ion-col size="6" class="pl-0">
							<div class="search-div">
								<ion-input type="text" fill="outline" CapitalizeFirst
									placeholder="{{'invoiceSearch' | translate}}" [(ngModel)]="searchText"
									[ngModelOptions]="{standalone: true}" (input)="search()" (ngModelChange)="search()"
									(keyup.enter)="search()">
									<img src="./assets/icon/search.svg" slot="start" alt="Jazeera">
									<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end"
										*ngIf="searchText" alt="Jazeera" (click)="clearSearch()">
								</ion-input>
							</div>
						</ion-col>
						<ion-col size="6" class="pr-0">
							<div class="filter-block align-end">
								<div class="input-div-container">
									<div class="status-label">
										<ion-label
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'invoiceDate' |
											translate}}</ion-label>
									</div>
									<div class="from-date-div">
										<ion-item class="from-date-class custom-date"
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="fromDateId">
											<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
										| translate}}" class="ion-text-start"></ion-input>
											<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
												alt="Jazeera"></ion-icon>
											<ion-popover trigger="fromDateId" size="cover" id="datePopover">
												<ng-template>
													<ion-datetime presentation="date" [min]="'2000-01-01'"
														[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
														formControlName="from" #fromdate
														(ionChange)="onDateChange(fromdate)"><span slot="title">{{"from"
															| translate}}</span></ion-datetime>
												</ng-template>
											</ion-popover>
										</ion-item>
									</div>
								</div>


								<div class="input-div-container">
									<div class="to-date-div">
										<ion-item class="to-date-class custom-date"
											[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" id="toDateId">
											<ion-input value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
														| translate}}" class="ion-text-start"></ion-input>
											<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end"
												alt="Jazeera"></ion-icon>
											<ion-popover trigger="toDateId" size="cover" id="datePopover">
												<ng-template>
													<ion-datetime presentation="date"
														[min]="fromDate ? fromDate : '2000-01-01'" [max]="maxDate"
														[(ngModel)]="toDate" formControlName="to" #todate
														(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
															| translate}}</span></ion-datetime>
												</ng-template>
											</ion-popover>
										</ion-item>
									</div>
								</div>


								<div class="filter-buttons">
									<button class="filter-apply-button" (click)="applyBtn()">{{"apply" |
										translate}}</button>
									<button class="filter-apply-button clear-button" (click)="clearFilter()">{{"clear" |
										translate}}</button>
								</div>
							</div>
						</ion-col>


					</ion-row>
				</div>


			</div>




		</div>
	</form>
	<div class="listing-table">
		<app-loader *ngIf="isLoading"></app-loader>
		<table class="table" *ngIf="!isLoading">
			<thead>
				<th class="column-heading order-id-col mw-100 ion-text-center">{{"invoicesComponent.invoice_id"
					| translate}}</th>
				<th class="column-heading date-col mw-120 ion-text-center">{{"invoicesComponent.date"
					| translate}}</th>
				<!-- <th class="column-heading order-id-col mw-120 ion-text-center">{{"paymentComponent.OrderNo"
					| translate}}</th>
				<th class="column-heading order-id-col mw-120 ion-text-center">{{"customField.modeofPayment"
					| translate}}</th> -->
				<th class="column-heading ion-text-center order-total-col mw-120">
					{{"invoicesComponent.order_total"
					| translate}}</th>

				<th class="column-heading ion-text-center status-col mw-120">{{"invoicesComponent.status"|
					translate}}
				</th>
				<th class="column-heading ion-text-center download-col">{{"action" | translate}}</th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let order of invoiceList;index as i" class="table-row">
					<td class="order-id ion-text-center" (click)="gotoOrderDetails(order.invoice_id , order.order_no)">
						{{order.invoice_no}}</td>
					<td class="ion-text-center">{{order.invoice_date | date
						:'MMM dd, YYYY'}}</td>
					<!-- <td class="ion-text-center">{{order.order_no}}</td>
					<td class="ion-text-center">{{order.payment_method || "--"}}</td> -->
					<td class="ion-text-center">
						{{commonService.currencySymbol}} {{order.order_total ? (order.order_total | number) :'-'}}
					</td>
					<td class="ion-text-center">
						<ion-button fill="clear" class="order-status-btn" [ngClass]="'btn-app-blue'">
							{{order.invoice_status |translate}}
						</ion-button>
					</td>
					<td>
						<ion-row class="p-0 justify-center">
							<div class="download-arrow"
								[ngClass]="commonService.domDirectionLTR ? 'justify-end ml-10' : 'justify-start mr-10'">
								<img src="./assets/icon/fi_download.svg" (click)="downloadInvoice($event,order)"
									alt="Jazeera" [title]="('downloadInvoice'|translate)">
							</div>
						</ion-row>

					</td>
				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!invoiceList.length">
			{{'noOrdersYet' | translate}}
		</div>
	</div>

	<div class="mobile-view-listing">
		<ion-content class="orders-ion-content">
			<div class="order-container">
				<ion-grid>
					<ion-row class="order-card" *ngFor="let order of invoiceList;index as i">
						<ion-col size="11">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_id" | translate}}</div>
										<div class="grid-values">{{order.order_id}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_total" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}{{order.order_total}}
										</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.date" | translate}}</div>
										<div class="grid-values">{{order.date}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<!-- <div class="grid-label">{{ordersComponent.status}}</div> -->
										<div class="grid-values">
											<ion-button fill="clear" class="status-btn"
												[ngClass]="{'pending' : order.order_status == 0,'shipped':order.order_status == 1,'canceled':order.order_status == 2}">
												{{order.order_status | translate}}
											</ion-button>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
						<ion-col class="mobile-right-arrow" size="1">
							<div [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
								<img src="./assets/icon/rightArrow.svg" alt="righta-arrow">
							</div>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'lodingMoreData' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</ion-content>
	</div>

	<div class="pagination-wrapper" *ngIf="invoiceList.length && from != 'dashboard'">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col pl-0">
				<div class="perpage-col">
					<div class="perpage-dropdown">
						<ion-item class="ion-card" lines="none">
							<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
								interface="popover" [interfaceOptions]="perPageInterface" fill="" mode="md"
								label-placement="stacked" (ionChange)="selectPageLength($event)"
								[(ngModel)]="itemsPerPage">
								<ion-select-option value="10">10 / {{"ordersComponent.per_page" |
									translate}}</ion-select-option>
								<ion-select-option value="20">20 / {{"ordersComponent.per_page" |
									translate}}</ion-select-option>
							</ion-select>
						</ion-item>
					</div>
				</div>

			</ion-col>
			<ion-col size="8" class="pagination-col align-end">
				<ion-row>
					<div>
						<app-tile class="grid-tile" *ngFor="let post of invoiceList | paginate :
    					{ itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count };
   						 let i = index" [current]="post"></app-tile>
						<pagination-controls class="paginator"
							[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
							previousLabel="{{'ordersComponent.prev' | translate}}"
							nextLabel="{{'ordersComponent.next' | translate}}"
							(pageChange)="onChange($event)"></pagination-controls>
					</div>



				</ion-row>

			</ion-col>
		</ion-row>
	</div>


</div>