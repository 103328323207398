import { isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { IonContent, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../_config/api.config';
import { ReturnOrderMobileFilterComponent } from '../components/return-order-mobile-filter/return-order-mobile-filter.component';
import { ExportExcelService } from '../services/export-excel.service';
import { GetPlatformService } from '../services/get-platform.service';
import { ModalService } from '../services/modal.service';
import { ApiService } from './../services/api-data.service';
import { CommonService } from './../services/common.service';
import { ToastService } from './../services/toast.service';


@Component({
	selector: 'app-tab3',
	templateUrl: 'tab3.page.html',
	styleUrls: ['tab3.page.scss'],
	providers: []
})
export class Tab3Page {
	@ViewChild(IonContent) content: IonContent;
	private subscription: Subscription = new Subscription();
	enableNext = false;
	showFilter = false;
	public myOrders = [];
	orderParams = {
		page: 1,
		Size: 100
	}
	currentPage = 1;
	itemsPerPage = 10;
	count = 0;
	filterObj = {};
	isLoggedIn: boolean;
	orderList = [];
	statusList = [];
	searchText: any;

	public isLoading = false;
	currentLang;
	backUpOrderList = [];
	constructor(private dataService: ApiService,
		private modalService: ModalService,
		public commonService: CommonService,
		private toastService: ToastService,
		private translateService: TranslateService,
		private navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		public platform: Platform,
		@Inject(PLATFORM_ID) private platformId: Object,
		private exportService: ExportExcelService


	) {
		this.currentLang = this.translateService.currentLang;
		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'profileData' || data.event == 'logoutCall') {
				const token = this.commonService.localStorageGet('accessToken');
				if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
				if (this.isLoggedIn) {
					// this.getOrderList();  uncomment when integrating API

				}
			}
		});


	}
	ionViewWillEnter() {
		this.statusList = [
			{ value: 'CONFIRMED', label: this.translateService.instant('confirmed') },
			{ value: 'DISPATCHED', label: this.translateService.instant('dispatched') },
			{ value: 'DELIVERED', label: this.translateService.instant('delivered') },
		];
		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn) {
			this.filterObj = {};
			this.currentPage = 1;
			this.getOrderList(this.filterObj);

		}
	}


	ngOnInit() {
		const token = this.commonService.localStorageGet('accessToken');
		if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

		if (this.isLoggedIn) {
			this.filterObj = {};
			this.getOrderList(this.filterObj);
		}
	}

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let subClassNameURL = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.display_sub_class_name_en : data.display_sub_class_name_en);
		this.navCtrl.navigateForward('/' + this.currentLang + "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id, { animated: false, "state": { "breadcrumb": data.product_name_en, "url": "/" + subClassNameURL + "/" + prodnameUrl + "/" + prod_id } });
	}


	getOrderList(params, infiniteScroll?, download?) {
		if (!download) {
			params = {
				page: this.currentPage,
				limit: +this.itemsPerPage,
				...params,
			};
		}
		return new Promise((resolve, reject) => {
			if (!download) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.get(URLS.orderList, params).subscribe(
					(data) => {
						if (data?.code === 200) {

							this.backUpOrderList = data['data'] || [];
							this.backUpOrderList.forEach(element => {
								if (element.document_date == "0000-00-00") element.document_date = null;
								if (element.document_date == "    -  -  ") element.document_date = null;
								if (element.invoice_date == "    -  -  ") element.invoice_date = null;
								if (element.invoice_date == "0000-00-00") element.invoice_date = null;
							});

							this.processAndPaginateData(
								this.backUpOrderList,
								this.itemsPerPage,
								this.currentPage,
								download,
								infiniteScroll
							)
								.then((orderList) => {
									resolve(orderList);
								})
								.catch((error) => {
									console.error("Error processing data:", error);
									reject(error);
								});
						} else {
							reject(data?.message || "Failed to fetch data");
						}
						this.isLoading = false;
					},
					(error) => {
						reject();
						this.isLoading = false;
					}
				)
			);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, true)
					.then((statementList) => {
						event.target.complete();
					})
					.catch((error) => {
						event.target.complete();
					});
			}
		}, 500);
	}

	openFilterByModal() {
		let modal: any;
		let options: any = {
			id: "orderFilterByModal",
			componentProps: { statusList: this.statusList, fromOrdersList: true, filterObj: this.filterObj },
		};
		options['component'] = ReturnOrderMobileFilterComponent;
		modal = this.modalService.openModal(options)
		modal.then((data) => {
			if (data && data['data']) {
				this.filterObj = {
					...data['data']
				};
				if (this.searchText) {
					this.filterObj['search'] = this.searchText;
				}
				this.currentPage = 1;
				this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false, this.filterObj)
					.then((statementList) => {
					})
					.catch((error) => {
						console.error("Error processing data:", error);
					});
			}

		})
	}

	openOrderDetailsModal(id, data) {
		this.navCtrl.navigateForward(['/' + this.currentLang + '/home/order-details-mobile'], {
			queryParams: {
				invoiceId: id,
				sapID: data.sap_order_no
			}
		})
	}

	clearSearch(event) {
		delete this.filterObj['search']
		this.currentPage = 1;
		this.content.scrollToTop(500);
		this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
			.then((statementList) => {
			})
			.catch((error) => {
			});
	}

	handleRefresh(event) {
		setTimeout(() => {
			// code maybe required
			// this.isLoading = false;
			if (this.isLoggedIn) {
				if (this.filterObj['search']) {
					this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
						.then((statementList) => {
						})
						.catch((error) => {
							console.error("Error processing data:", error);
						});
				} else {
					this.filterObj = {};
					this.currentPage = 1;
					this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false)
						.then((statementList) => {
						})
						.catch((error) => {
							console.error("Error processing data:", error);
						});
				}

			}
			event.target.complete();
		}, 1000);
	};


	search(event) {
		if (event['data']) {
			this.filterObj['search'] = event['data'];
			this.currentPage = 1;
			this.content.scrollToTop(500);
			this.processAndPaginateData(this.backUpOrderList, this.itemsPerPage, this.currentPage, false, false, null)
				.then((statementList) => {
				})
				.catch((error) => {
					console.error("Error processing data:", error);
				});
		} else {
			this.filterObj['search'] = null
		}

	}

	goBack() {
		this.navCtrl.navigateRoot('/' + this.currentLang + '/my-account', { animated: false });
	}

	orderStatus(orderData, productlevel?) {
		switch (orderData.order_status) {
			case 'NOT_YET_DELIVERED':
			case 'DISPATCHED':
			case 'CONFIRMED':
				if (productlevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
			case 'DELIVERED':
				if (productlevel) {
					return 'btn-black product-status-btn';
				} else {
					return 'btn-black';
				}
			case 'CANCELLED':
				if (productlevel) {
					return 'btn-red product-status-btn';
				} else {
					return 'btn-red';
				}
			default:
				if (productlevel) {
					return 'btn-green product-status-btn';
				} else {
					return 'btn-green';
				}
		}
	}

	async downloadInvoice(event, data) {
		event.preventDefault();
		event.stopPropagation();
		const params = {
			invoice_no: data.sap_order_no,
		};

		this.subscription.add(
			this.dataService.post(URLS.invoiceDownload, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						if (!isPlatformServer(this.platformId)) {
							window.open(data["data"]);
						}
					} else {
					}
				},
				(error) => {
				}
			)
		);
	}


	processAndPaginateData(data, pageSize, pageIndex, download = false, infiniteScroll, filterPayload?) {
		if (this.filterObj && Object.keys(this.filterObj).length > 0) {
			filterPayload = this.filterObj
		}
		return new Promise((resolve, reject) => {
			if (!data) {
				reject("No data available.");
				return;
			}

			let filterData = [];
			if (filterPayload) {
				// Apply filtering

				filterData = filterData = data.filter((item) => {
					const doc_from_date = filterPayload.from_date ? new Date(filterPayload.from_date) : null;
					const doc_to_date = filterPayload.to_date ? new Date(filterPayload.to_date) : null;


					const documentDate = item.order_date && item.order_date != "0000-00-00" ? new Date(item.order_date) : null;


					const withinDateRange =
						doc_from_date && doc_to_date && documentDate
							? documentDate >= doc_from_date && documentDate <= doc_to_date
							: false;


					// Return true if any of the conditions are satisfied
					return withinDateRange;
				});
			}

			// Apply the search filter if there is a search text
			if (this.searchText) {
				const lowerCaseSearchTerm = this.searchText.toLowerCase();

				filterData = data.filter(item => {
					// Check if any field in the item matches the search term
					return Object.keys(item).some(key => {
						const value = item[key];
						if (value) {
							// Check if the value is a string or a number, and match case-insensitively
							return value.toString().toLowerCase().includes(lowerCaseSearchTerm);
						}
						return false;
					});
				});
			}


			let listData = filterPayload || this.searchText ? filterData : data;


			pageSize = +pageSize;
			// Total items and pages
			const totalCount = listData.length;
			const startIndex = (pageIndex - 1) * pageSize; // Adjust for pageIndex starting from 1
			const endIndex = startIndex + pageSize;
			const paginatedData = listData.slice(startIndex, endIndex);

			if (download) {
				this.exportService.exportAsExcelFile(listData, "order_list");
				resolve([]);
				return;
			}
			const mappedData = paginatedData.map((item) => ({
				invoice_id: item.invoice_id || null,
				order_no: item.order_no || null,
				order_date: item.order_date || null,
				total_order_amount: parseFloat(item.total_order_amount || "0"),
				order_status: item.order_status || null,
				cx_reference_no: item.cx_reference_no || null,
				sap_order_no: item.sap_order_no || null,
			}));

			if (!infiniteScroll) {
				this.orderList = mappedData; // Replace the list
			} else {
				this.orderList = [...this.orderList, ...mappedData]; // Append for infinite scroll
			}

			this.count = totalCount; // Update total count
			this.enableNext = endIndex < totalCount; // Enable/disable next page
			resolve(this.orderList);
		});
	}
}
