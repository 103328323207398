import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../common/shared.module';
import { AccountDashboardComponent } from './account-dashboard.component';
import { AccountDashboardRoutingModule } from './account-dashboard.routing.module';



@NgModule({
    declarations: [AccountDashboardComponent],
    imports: [
        CommonModule,
        SharedModule,
        AccountDashboardRoutingModule,
        TranslateModule

    ]
})
export class AccountDashboardModule { }
