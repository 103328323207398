<ion-header mode="md">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" slot="start" (click)="closeModal()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			<div class="dashboard-header">
				{{'PDF' | translate}}
			</div>
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div class="h100">
		<ng2-pdfjs-viewer #pdfViewer [pdfSrc]="pdfSrc" [print]="true"
			[download]="this.getPlatformService.isDesktop? true:false" [fullScreen]="false" [openFile]="false"
			[viewBookmark]="false" [showSpinner]="true">
		</ng2-pdfjs-viewer>

		<!-- <ngx-extended-pdf-viewer #pdfViewer [src]="pdfSrc" [useBrowserLocale]="true" [showToolbar]="true"
			style="height: 100%; width: 100%; display: block;">
		</ngx-extended-pdf-viewer> -->

	</div>
</ion-content>