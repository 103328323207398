import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
	@Input() fromCartPopup: boolean = false;
	constructor(public commonService: CommonService) { }

	ngOnInit() { }

	@Input() productListing: boolean = false;

}
