import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { InvoicesRoutingModule } from './invoices-routing.module';


@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		InvoicesRoutingModule,
		SharedModule
	]
})
export class InvoicesModule { }
