<div class="recommended-container">
	<div class="slider-header">
		<h1 class="header ion-text-center">
			{{ productTitle }}
		</h1>
		<hr *ngIf="!relatedProducts">
		<div *ngIf="!categories && !viewAll && sliderData && sliderData.length > 0 " class="view-all"
			(click)="viewAllProducts(sliderData.product_type)">
			{{ "viewAll" | translate }}
		</div>
	</div>


	<!-- recommended, featured, best selling slider for arabic -->
	<ng-container *ngIf="!relatedProducts && !categories && !commonService.domDirectionLTR">
		<swiper-container #productsSlides id="productsSlides" [options]="slideOpts"
			[slidesPerView]="this.getSlidesPerView()" dir="rtl" class="mob-pt-0 mob-ml-20" [spaceBetween]="16">
			<ng-container *ngIf="sliderData && sliderData.length > 0">
				<swiper-slide *ngFor="let data of sliderData">
					<app-product-card (productClicked)="goToProductDetail(data)" [productDetailLabel]="true"
						[cardData]="data" [fromSliderComponent]="true"></app-product-card>
				</swiper-slide>
				<swiper-slide class="view-all-slide" *ngIf="sliderData.length > 9 && this.commonService.isDesktop"
					(click)="viewAllProducts(sliderData.product_type)">
					<div>
						{{ "viewAll" | translate }}
					</div>
				</swiper-slide>
			</ng-container>
			<ng-container *ngIf="sliderData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>
	</ng-container>

	<!-- recommended, featured, best selling slider for english -->
	<ng-container *ngIf="!relatedProducts && !categories && commonService.domDirectionLTR">
		<swiper-container #productsSlides id="productsSlides" class="ml-10 product-slider mob-pt-0 mob-ml-20" dir="ltr"
			[slidesPerView]="this.getSlidesPerView()" [spaceBetween]="16">
			<ng-container *ngIf="sliderData && sliderData.length > 0">
				<swiper-slide *ngFor="let data of sliderData">
					<app-product-card (productClicked)="goToProductDetail(data)" [fromSliderComponent]="true"
						[productDetailLabel]="true" [cardData]="data"></app-product-card>
				</swiper-slide>
				<swiper-slide class="view-all-slide" *ngIf="sliderData.length > 9 && this.commonService.isDesktop"
					(click)="viewAllProducts(sliderData.product_type)">
					<div>
						{{ "viewAll" | translate }}
					</div>
				</swiper-slide>
			</ng-container>
			<ng-container *ngIf="sliderData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>
	</ng-container>


	<!-- relatedProducts RTL-->
	<ng-container *ngIf="relatedProducts && !commonService.domDirectionLTR">
		<swiper-container space-between="12" slides-per-view="5" #productsSlides id="productsSlides"
			[options]="relatedSlideOptions" dir="rtl" class="mob-pt-0 mob-ml-20"
			[slidesPerView]="this.getRelatedSlidesPerView()" [spaceBetween]="16">
			<ng-container *ngIf="sliderData && sliderData.length > 0">
				<swiper-slide class="products-slides" *ngFor="let data of sliderData">
					<app-product-card (productClicked)="goToProductDetail(data)" [fromSliderComponent]="true"
						[productDetailLabel]="true" [cardData]="data"></app-product-card>
				</swiper-slide>
			</ng-container>
			<ng-container *ngIf="sliderData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>
	</ng-container>


	<!-- relatedProducts LTR -->
	<ng-container *ngIf="relatedProducts && commonService.domDirectionLTR">
		<swiper-container #productsSlides id="productsSlides" [options]="relatedSlideOptions" dir="ltr"
			[slidesPerView]="this.getRelatedSlidesPerView()" class="mob-pt-0 mob-ml-20" [spaceBetween]="16">
			<ng-container *ngIf="sliderData && sliderData.length > 0">
				<swiper-slide class="products-slides" *ngFor="let data of sliderData">
					<app-product-card (productClicked)="goToProductDetail(data)" [fromSliderComponent]="true"
						[productDetailLabel]="true" [cardData]="data"></app-product-card>
				</swiper-slide>
			</ng-container>
			<ng-container *ngIf="sliderData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>
	</ng-container>


	<!-- categoriesOpts RTL -->
	<ng-container *ngIf="categories && !commonService.domDirectionLTR">
		<swiper-container #productsSlides id="productsSlides" [options]="categoriesOpts" dir="rtl"
			[slidesPerView]="this.getCateSlidesPerView()" [spaceBetween]="16">
			<ng-container *ngIf="sliderData && sliderData.length > 0">
				<swiper-slide class="products-slides" *ngFor="let data of sliderData">
					<app-product-card (productClicked)="goToCategoryListing(data)" [fromSliderComponent]="true"
						[categories]="true" [cardData]="data"></app-product-card>
				</swiper-slide>
			</ng-container>
			<ng-container *ngIf="sliderData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>
	</ng-container>


	<!-- categoriesOpts LTR -->
	<ng-container *ngIf="categories && commonService.domDirectionLTR">
		<swiper-container #productsSlides id="productsSlides" [ngClass]="[ categories ? 'ml-15' : '']"
			[options]="categoriesOpts" class="mob-pt-0 mob-ml-20" dir="ltr"
			[slidesPerView]="this.getCateSlidesPerView()" [spaceBetween]="16">
			<ng-container *ngIf="sliderData && sliderData.length > 0">
				<swiper-slide class="products-slides" *ngFor="let data of sliderData">
					<app-product-card (productClicked)="goToCategoryListing(data)" [fromSliderComponent]="true"
						[categories]="true" [cardData]="data"></app-product-card>
				</swiper-slide>
			</ng-container>
			<ng-container *ngIf="sliderData == 0">
				<swiper-slide class="no-record-slide">
					<div class="ion-text-center no-record-found pt-80 pb-80">
						{{ "noRecordFound" | translate}}
					</div>
				</swiper-slide>
			</ng-container>
		</swiper-container>
	</ng-container>


	<div (click)="slideToPrev()" *ngIf="sliderData && sliderData.length > 0" [ngClass]="{'related-products-prev-arrow' : relatedProducts && commonService.domDirectionLTR,
                    'related-products-prev-arrow-arabic' : relatedProducts && !commonService.domDirectionLTR,
                    'previous-arrow' : !relatedProducts && commonService.domDirectionLTR,
                    'previous-arrow-rtl' : !relatedProducts && !commonService.domDirectionLTR }">
		<ion-icon *ngIf="firstSlide || sliderData && sliderData.length < 4" alt="Jazeera"
			src="assets/icon/slider-left-arrow.svg"></ion-icon>
		<ion-icon class="rotate-arrow" *ngIf="!this.firstSlide && !this.lastSlide" alt="Jazeera"
			src="assets/icon/slider-right-arrow.svg"></ion-icon>
	</div>
	<div (click)="slideToNext()" *ngIf="sliderData && sliderData.length > 0" [ngClass]="{ 'related-products-next-arrow' : relatedProducts && commonService.domDirectionLTR,
                     'related-products-next-arrow-arabic' : relatedProducts && !commonService.domDirectionLTR,
                     'next-arrow' : !relatedProducts && commonService.domDirectionLTR,
                     'next-arrow-rtl' : !relatedProducts && !commonService.domDirectionLTR }">
		<ion-icon *ngIf="firstSlide && sliderData && sliderData.length > 4" alt="Jazeera"
			src="assets/icon/slider-right-arrow.svg"></ion-icon>
		<ion-icon *ngIf="!firstSlide && !sliderEnd && sliderData && sliderData.length > 4" alt="Jazeera"
			src="assets/icon/slider-right-arrow.svg"></ion-icon>
		<ion-icon class="rotate-arrow" *ngIf="(sliderEnd && !firstSlide) || (sliderData && sliderData.length <= 4)"
			alt="Jazeera" src="assets/icon/slider-left-arrow.svg"></ion-icon>
	</div>
</div>