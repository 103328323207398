<ion-header class="return-request-header" mode="md">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios" class="ion-text-center return-request">
			{{"returnRequestComponent.title" | translate}}
		</ion-title>
	</ion-toolbar>
</ion-header>

<div class="return-request">
	<div class="listing-header" *ngIf="showHeader">
		<div class="title-div">
			{{"returnRequestComponent.title" | translate}}
		</div>
	</div>
	<div class="select-order-container" *ngIf="getPlatformService.isDesktop">
		<div class="common-input ">
			<ion-item lines="none" mode="md">
				<ion-label position="floating" class="select-label">{{
					"returnRequestComponent.selectOrder" |
					translate }}</ion-label>
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" interface="popover"
					[interfaceOptions]="selectInterface" [(ngModel)]="selectedOrder" class="width-100"
					(ionChange)="orderChanged($event)">
					<ion-select-option *ngFor="let order of ordersList" [value]="order.order_id">{{order.name}}
					</ion-select-option>
				</ion-select>
			</ion-item>
		</div>
	</div>
	<ion-row [ngClass]="getPlatformService.isDesktop ? '':'p-20'" *ngIf="selectedOrder && getPlatformService.isDesktop">
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
			<div>
				<ion-row class="row-header">
					<ion-col size-xs="6" size-sm="6" size-md="6" size-lg="8" size-xl="8">
						<p class="row-header-1 color-app-grey pl-45">{{'cartPage.Item'|translate}}</p>
					</ion-col>
					<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="4" size-xl="4">
						<p class="row-header-1 color-app-grey">{{'cartPage.Price'|translate}}</p>
					</ion-col>
				</ion-row>
				<div>
					<div *ngFor="let data of orderData;let i =index">
						<ion-row class="head-div">
							<p class="title-2 color-app-black">{{data.name}}</p>
						</ion-row>
						<ion-row *ngFor="let item of data.product_data;let j =index" class="mt-10">
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8" class="pl-0">
								<div class="flex-row">
									<div class="checkbox-div mr-30">
										<ion-checkbox [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
											slot="start" [value]="item.product_id"
											[checked]="item.isChecked"></ion-checkbox>
									</div>
									<div class="image-container">
										<img src="{{item.product_image}}" class="product-img" alt="Jazeera" />
									</div>
									<div class="flex-column ml-20">
										<p class="label-3 color-app-black m-0">{{item.product_title}}</p>
										<div
											[ngClass]="item.product_is_sample ? 'sample-visible sample-div' : 'sample-hidden sample-div'">
											<p>{{'SAMPLE'|translate}}</p>
										</div>

										<p class="label-5 color-app-grey " *ngFor="let attr of item.attributes">
											{{attr.name}}:
											<span class="color-app-black mh-5" *ngFor="let val of attr.values">
												{{val.value}}</span>
										</p>
									</div>
								</div>
							</ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4">
								<p class="label-3 color-app-black">{{item.product_currency}}{{item.final_price}}
									<span *ngIf="item.product_unit"
										class="label-6 color-app-grey">/{{item.product_unit}}</span>
								</p>

							</ion-col>
							<div class="divider-line mv-10 ml-170"></div>
						</ion-row>
						<ion-row>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="pl-0">
								<div class="reason-container pl-170">
									<form>
										<div class="common-input ">
											<ion-item lines="none" mode="md">
												<ion-label position="floating" class="select-label">{{
													"returnRequestComponent.chooseReason" |
													translate }}</ion-label>
												<ion-select
													[interface]="getPlatformService.isDesktop ? 'popover':'alert'"
													[interfaceOptions]="selectInterface" class="width-100"
													(ionChange)="orderChanged($event)">
													<ion-select-option *ngFor="let reason of reasonsList"
														[value]="reason.id">{{reason.name}}
													</ion-select-option>
												</ion-select>
											</ion-item>
										</div>
										<div class="common-input textarea-input mt-12">
											<ion-item lines="none" mode="md">
												<ion-label position="floating" class="select-label">{{
													"returnRequestComponent.reasonInDetail" |
													translate }}</ion-label>
												<ion-textarea rows="3" maxlength="500"
													[ngModelOptions]="{standalone: true}"></ion-textarea>
											</ion-item>
										</div>
									</form>
									<div class="img-wrap mt-20">
										<h2 class="upload-heading">
											<span>
												{{
												'uploadPicture' |
												translate }}
											</span>
										</h2>
										<div class="uploaded-image-container mb-15">
											<div class="upload-wrap image-card">
												<div>
													<ion-button type="primary" (click)="fileInput.click()">
														{{'upload' | translate}}
													</ion-button>
													<input type="file" accept="application/octet-stream"
														class="file-input" (change)="upload($event ,data)"
														(click)="$event.stopPropagation()"
														accept="image/png, image/gif, image/jpeg" #fileInput multiple>
												</div>
											</div>
											<ng-container *ngFor="let image of data.imagePreview;index as i">
												<div class="image-container image-card">
													<img [src]="image" alt="product" class="preview-image">
													<div class="icon-wrap" (click)="removeImage(i,data)">
														<div><img src="/assets/icon/trash.svg" alt="Jazeera"></div>
													</div>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</ion-col>
						</ion-row>
					</div>
				</div>
				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="pl-0">
						<div class="info-container pl-170">
							<div class="pickup-info child-info-div">
								<div class="icon-div"
									[ngClass]="commonService.domDirectionLTR ? 'justify-end' : 'justify-start'">
									<img src="./assets/icon/edit-review.svg" alt="Jazeera" class="icons">
								</div>
								<div class="details-container pl-15">
									<p class="details-heading"> {{"returnRequestComponent.pickupInfo" | translate}}</p>
									<p class="name m-0">Daniel Stevenson</p>
									<p class="addr-line-1 m-0"> 128 Melody Street</p>
									<p class="addr-line-2 m-0">New York, NY, 901290</p>
									<p class="contact-no m-0"> +1 234 567 8901</p>
								</div>
							</div>
							<div class="return-payment child-info-div">
								<div class="icon-div"
									[ngClass]="commonService.domDirectionLTR ? 'justify-end' : 'justify-start'">
									<img src="assets/icon/more_info.svg" alt="Jazeera" class="icons">
								</div>
								<div class="details-container pl-15">
									<p class="details-heading"> {{"returnRequestComponent.returnPayment" | translate}}
									</p>
									<p class="card-no m-0">SA 8000 0000 6080 1016 7519</p>
									<p class="member-status m-0"> SEPA Member: <span>{{'no' | translate}}</span></p>
									<ion-button fill="clear" class="status-btn returned mt-10">
										{{"verified" | translate}}
									</ion-button>
								</div>

							</div>
						</div>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="pl-0">
						<div class="submit-bottom-container">
							<div class="cancel pr-40">
								{{ 'Cancel' | translate }}
							</div>
							<div class="submit-button">
								<button [disabled]="isLoading">
									<ion-spinner class="spinner-btn" name="lines-sharp-small"
										*ngIf="isLoading"></ion-spinner>
									{{'submit' | translate}}
								</button>
							</div>
						</div>
					</ion-col>
				</ion-row>

			</div>
		</ion-col>
	</ion-row>


</div>
<ion-content class="mobile-view-listing">
	<div class="select-order-container">
		<div class="common-input ">
			<ion-item lines="none" mode="md">
				<ion-label position="floating" class="select-label">{{
					"returnRequestComponent.selectOrder" |
					translate }}</ion-label>
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" interface="popover"
					[interfaceOptions]="selectInterface" [(ngModel)]="selectedOrder" class="width-100"
					(ionChange)="orderChanged($event)">
					<ion-select-option *ngFor="let order of ordersList" [value]="order.order_id">{{order.name}}
					</ion-select-option>
				</ion-select>
			</ion-item>
		</div>
	</div>
	<div *ngIf="selectedOrder">
		<ion-row class="p-0">
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9" class="p-0">
				<div>
					<div>
						<div *ngFor="let data of orderData;let i =index">
							<ion-row class="head-div">
								<p class="title-2 color-app-black">{{data.name}}</p>
							</ion-row>
							<ion-row *ngFor="let item of data.product_data;let j =index;let last = last" class="mt-10">
								<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2" class="ph-20">
									<div class="mobile-card">
										<div class="image-card">
											<img src="{{item.product_image}}" class="product-img" alt="Jazeera" />
											<div class="mobile-checkbox-div">
												<ion-checkbox [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
													slot="start" [value]="item.product_id"
													[checked]="item.isChecked"></ion-checkbox>
											</div>
											<div *ngIf="item.product_is_sample == true"
												class="sample-div mobile-sample-div">
												<p>{{'SAMPLE'|translate}}</p>
											</div>
										</div>

										<div class="pl-15;">
											<p class="label-3 color-app-black">{{item.product_title}}</p>
											<p class="label-5 color-app-grey " *ngFor="let attr of item.attributes">
												{{attr.name}}:
												<span class="color-app-black mh-5" *ngFor="let val of attr.values">
													{{val.value}}</span>
											</p>

											<p class="label-4 color-app-black">{{item.product_qty}} X
												{{item.product_currency}}{{item.product_price}}<span
													*ngIf="item.product_unit"
													class="label-6 color-app-grey">/{{item.product_unit}}</span></p>
										</div>
									</div>
								</ion-col>
								<div class="divider-line mv-10" *ngIf="!last"></div>
							</ion-row>
							<ion-row>
								<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="ph-20">
									<div class="reason-container">
										<form>
											<div class="common-input mt-10">
												<ion-item lines="none" mode="md">
													<ion-label position="floating" class="select-label">{{
														"returnRequestComponent.chooseReason" |
														translate }}</ion-label>
													<ion-select
														[interface]="getPlatformService.isDesktop ? 'popover':'alert'"
														[interfaceOptions]="selectInterface" class="width-100"
														(ionChange)="orderChanged($event)">
														<ion-select-option *ngFor="let reason of reasonsList"
															[value]="reason.id">{{reason.name}}
														</ion-select-option>
													</ion-select>
												</ion-item>
											</div>
											<div class="common-input textarea-input mt-15">
												<ion-item lines="none" mode="md">
													<ion-label position="floating" class="select-label">{{
														"returnRequestComponent.reasonInDetail" |
														translate }}</ion-label>
													<ion-textarea rows="3" maxlength="500"
														[ngModelOptions]="{standalone: true}"></ion-textarea>
												</ion-item>
											</div>
										</form>
									</div>
									<div class="img-wrap mt-20">
										<h2 class="upload-heading">
											<span>
												{{
												'photos' |
												translate }}
											</span>
											<span class="upload-photo" (click)="fileInput.click()">
												{{'upload' | translate}}
											</span>
											<input type="file" accept="application/octet-stream" class="file-input"
												(change)="upload($event ,data)" (click)="$event.stopPropagation()"
												accept="image/png, image/gif, image/jpeg" #fileInput multiple>
										</h2>
										<div class="uploaded-image-container mb-15">
											<ng-container *ngFor="let image of data.imagePreview;index as i">
												<div class="image-container image-card">
													<img [src]="image" alt="product" class="preview-image"
														alt="Jazeera">
													<div class="icon-wrap" (click)="removeImage(i,data)">
														<div><img src="/assets/icon/trash-white.svg" alt="Jazeera">
														</div>
													</div>
												</div>
											</ng-container>
										</div>
									</div>

								</ion-col>
							</ion-row>
						</div>
					</div>
				</div>
				<div class="divider-line mv-10"></div>
				<ion-row>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="pl-0">
						<div class="info-container">
							<div class="pickup-info child-info-div">
								<div class="icon-div"
									[ngClass]="commonService.domDirectionLTR ? 'justify-end' : 'justify-start'">
									<img src="./assets/icon/edit-review.svg" class="icons" alt="Jazeera">
								</div>
								<div class="details-container pl-15">
									<p class="details-heading"> {{"returnRequestComponent.pickupInfo" | translate}}</p>
									<p class="name m-0">Daniel Stevenson</p>
									<p class="addr-line-1 m-0"> 128 Melody Street</p>
									<p class="addr-line-2 m-0">New York, NY, 901290</p>
									<p class="contact-no m-0"> +1 234 567 8901</p>
								</div>
							</div>
							<div class="return-payment child-info-div">
								<div class="icon-div"
									[ngClass]="commonService.domDirectionLTR ? 'justify-end' : 'justify-start'">
									<img src="assets/icon/more_info.svg" class="icons" alt="Jazeera">
								</div>
								<div class="details-container pl-15">
									<p class="details-heading"> {{"returnRequestComponent.returnPayment" | translate}}
									</p>
									<p class="card-no m-0">SA 8000 0000 6080 1016 7519</p>
									<p class="member-status m-0"> SEPA Member: <span>{{'no' | translate}}</span></p>
									<ion-button fill="clear" class="status-btn returned mt-10">
										{{"verified" | translate}}
									</ion-button>
								</div>

							</div>
						</div>
					</ion-col>
				</ion-row>
			</ion-col>
		</ion-row>
	</div>
</ion-content>
<ion-footer class="pt-10 return-request-footer ph-20 pb-50" *ngIf="selectedOrder">
	<div class="footer-button">
		<button class="cancel-btn" (click)="goBack()">{{"cancel" | translate}}</button>
	</div>
	<div class="footer-button">
		<button class="submit-footer-btn">{{"submit" | translate}}</button>
	</div>
</ion-footer>