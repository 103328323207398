<div class="footer" *ngIf="showOnlyLowFooter != true">
	<div class="left-footer">
		<div class="upper-footer container">
			<div class="company-part">
				<a [routerLink]="'/'+currentLang +'/home'">
					<img class="icon" router-direction="none" src="./assets/icon/companyLogo.svg" alt="Jazeera"
						height="100%" width="100%">
				</a>

			</div>
		</div>
		<!-- removing as jira bug because there is no app for this project bug id B2BP-98 -->
		<!-- <div class="lower-footer container pr-40">
			<div class="stores-container">
				<h2 class="downloadapp">{{"footerComponent.downloadApp" | translate}}</h2>
				<div class="stores">
					<a href="#" rel="noopener noreferrer" aria-label="playstore link" class="stores-links">
						<img src="../../../assets/icon/google-stores.svg" alt="playstore logo" width="100%"
							height="100%" />
					</a>
					<a href="#" rel="noopener noreferrer" aria-label="appstore link" class="stores-links">
						<img src="../../../assets/icon/apple-logo.svg" alt="appstore logo" width="100%" height="100%" />
					</a>
				</div>
			</div>
		</div> -->
	</div>
	<div class="right-footer">
		<div class="upper-footer container">
			<div class="linking-wrapper">
				<div class="linking-section">
					<div class="heading">{{"footerComponent.company" | translate}}</div>
					<ul>
						<li>
							<a [routerLink]="'/'+currentLang +'/about-us'">
								{{"footerComponent.aboutUs" | translate}}
							</a>
						</li>
					</ul>
				</div>
				<div class="linking-section">
					<div class="heading">{{"footerComponent.getInformed" | translate}}</div>
					<ul>
						<li>
							<a [routerLink]="'/'+currentLang +'/news'">
								{{"footerComponent.news" | translate}}
							</a>
						</li>
					</ul>
				</div>

				<div class="linking-section">
					<div class="heading">{{"footerComponent.help" | translate}}</div>
					<ul>
						<li>
							<a [routerLink]="'/'+currentLang +'/contact-us'">
								{{"footerComponent.contactUs" | translate}}
							</a>
						</li>
						<li>
							<a [routerLink]="'/'+currentLang +'/faq'">
								{{"footerComponent.faq" | translate}}
							</a>
						</li>
					</ul>
				</div>

				<div class="social-option">
					<span><a href="https://www.facebook.com/JPaintsKSA" target="_blank" rel="noopener"><img
								src="assets/icon/facebook.svg" alt="Jazeera" height="100%" width="100%"></a></span>
					<span> <a href="https://www.instagram.com/jpaintsksa/" target="_blank" rel="noopener"><img
								class="pinterest" src="assets/icon/instagram.svg" alt="Jazeera" height="100%"
								width="100%"></a></span>
					<span> <a href="https://www.linkedin.com/company/jpaintsksa/" target="_blank" rel="noopener"><img
								src="assets/icon/linkedin.svg" alt="Jazeera" height="100%" width="100%"
								rel="noopener noreferrer"></a></span>
				</div>
			</div>

		</div>
		<div class="lower-footer container pr-40">
			<div class="left-section">© {{currentYear}} {{"footerComponent.copyright" | translate}}</div>
			<div class="right-section">
				<ul>
					<li>
						<a [routerLink]="'/'+currentLang +'/terms-of-use'">
							{{"footerComponent.term" | translate}}
						</a>
					</li>
					<li>
						<a [routerLink]="'/'+currentLang +'/privacy-policy'">
							{{"footerComponent.policy" | translate}}
						</a>
					</li>
					<li *ngIf="false">
						<a [routerLink]="'/'+currentLang +'/cookie-policy'">
							{{"footerComponent.cookies" | translate}}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div class="footer2" *ngIf="showOnlyLowFooter == true">
	<div class="lower-footer container pr-40">
		<div class="left-section" class="pointer">
			<a [routerLink]="'/'+currentLang +'/home'">
				{{"footerComponent.copyright" | translate}}
			</a>
		</div>
		<div class="right-section">
			<ul>
				<li class="pointer">
					<a [routerLink]="'/'+currentLang +'/terms-of-use'">
						{{"footerComponent.term" | translate}}
					</a>
				</li>
				<li class="pointer">
					<a [routerLink]="'/'+currentLang +'/privacy-policy'">
						{{"footerComponent.policy" | translate}}
					</a>
				</li>
				<li class="pointer" *ngIf="false">
					<a [routerLink]="'/'+currentLang +'/cookie-policy'">
						{{"footerComponent.cookies" |
						translate}}
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>