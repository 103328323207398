<div class="account-information" *ngIf="salesInfo">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Salesmen Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.salesmen_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Salesmen Name"}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{salesInfo.salesmen_name || '--'}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Distribution Channel Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.distribution_channel_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Distribution Channel Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.distribution_channel_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Office Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_office_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Office Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_office_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Group Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Sales Group Name"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.sales_group_name || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Price Group Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{salesInfo.price_group_code || '--'}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="mobileView">
	<div class="account-info-grid">
		<ion-grid class="p-0">
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/email-icon.svg" alt="emailicon" class="input-icon">
							<ion-label class="input-color p-0">{{contactInformation.customer_email}}</ion-label>
						</ion-item>

					</div>

					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/user.svg" alt="jazeera" class="input-icon">
							<ion-label class="input-color p-0">{{contactInformation.customer_name}}</ion-label>
						</ion-item>

					</div>

					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/password-icon.svg" alt="password" class="input-icon">
							<ion-label class="input-color p-0 lbl-password ">12345678</ion-label>

							<ion-label slot="end" class="btn-label"
								(click)="openModal()">{{"accountInfoComponent.change" |
								translate}}</ion-label>
						</ion-item>


					</div>

				</ion-col>

			</ion-row>
		</ion-grid>
	</div>
</div>