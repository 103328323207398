import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountDashboardComponent } from './account-dashboard.component';

const routes: Routes = [
    {
        path: '',
        component: AccountDashboardComponent,

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountDashboardRoutingModule { }
