import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Http, HttpDownloadFileResult } from '@capacitor-community/http';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { NavController, Platform } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
	selector: 'app-pdf-view',
	templateUrl: './pdf-view.component.html',
	styleUrls: ['./pdf-view.component.scss'],
})
export class PdfViewComponent implements OnInit, AfterViewInit {
	@ViewChild('pdfViewer', { static: false }) public pdfViewer: any;

	pdfSrc: any;
	fileName: string | undefined;

	constructor(
		public getPlatformService: GetPlatformService,
		private http: HttpClient,
		public router: ActivatedRoute,
		public platform: Platform,
		public navCtrl: NavController,
		public commonService: CommonService,
		public toastService: ToastService,
		public route: ActivatedRoute
	) { }

	ngOnInit() { }

	ionViewWillEnter() {
		this.getDownloadUrl();
	}

	ngAfterViewInit() {
		console.log('pdfViewer initialized:', this.pdfViewer);
		this.getDownloadUrl();
	}

	getDownloadUrl() {
		this.route.queryParams.subscribe(params => {
			if (params && params.url) {
				if (this.pdfViewer) {
					this.pdfViewer.pdfSrc = params.url; // pdfSrc can be Blob or Uint8Array
					this.fileName = params.url.split('/').pop();
					this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh PDF
					if (!this.getPlatformService.isDesktop) {
						this.downloadPdf(params.url);
					}
				} else {
					console.error('pdfViewer is not initialized.');
				}
			}
		});
	}

	async downloadPdf(file: string) {
		let invoiceFileName = file.substring(file.lastIndexOf('/') + 1);

		const options = {
			url: file,
			filePath: invoiceFileName,
			fileDirectory: this.platform.is('android') ? Directory.Documents : Directory.Data,
			progress: true,
			method: 'GET',
		};

		// Writes to local filesystem
		const response: HttpDownloadFileResult = await Http.downloadFile(options);

		if (response.path) {
			await Filesystem.readFile({
				path: invoiceFileName,
				directory: Directory.Data,
			}).then(
				data => {
					setTimeout(() => {
						FileOpener.openFile({
							path: response.path,
						});
					}, 500);
					const msg = this.commonService.domDirectionLTR
						? 'File loaded successfully'
						: 'تم تحميل الملف بنجاح';
					this.toastService.displayToast(msg, null, 'success');
				},
				error => {
					this.toastService.displayToast(error, null, 'error');
				}
			);
		}
	}

	closeModal() {
		this.navCtrl.back();
	}
}
