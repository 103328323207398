import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BankTranferComponent } from 'src/app/components/bank-transfer/bank-transfer.component';
import { PaymentCardComponent } from 'src/app/components/payment-card/payment-card.component';
import { CheckOutStepReviewPage } from './checkout-step-review';

const routes: Routes = [
	{
		path: '',
		component: CheckOutStepReviewPage,
	},
	{
		path: 'payment',
		component: PaymentCardComponent
	},
	{
		path: 'banktransfer',
		component: BankTranferComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CheckOutStepReviewPageRoutingModule { }
