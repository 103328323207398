<div class="slide-container" itemscope *ngIf="productlistPage == 'productlist1'" [class.product-border]="!categories"
	[ngClass]="['productDetailLabel' ? commonService.domDirectionLTR ? 'detail-slide-container' :'detail-slide-container-right' : '',productlistPage ? 'product-listing-card':'']"
	(click)="gotToProductDetail()">
	<div class="product-image-container"
		[ngClass]="categories ? 'categories-image-container' : 'product-image-container'">
		<div class="product-fav" (click)="addToWishlist($event, cardData.product_id, this.cardData.is_wishlist_product)"
			*ngIf="isLoggedIn && !wishListPage && !categories && !wishListTab">
			<ion-icon *ngIf="!this.cardData.is_wishlist_product" src="assets/icon/fav.svg" alt="Jazeera"></ion-icon>
			<ion-icon *ngIf="this.cardData.is_wishlist_product" src="assets/icon/fav-liked.svg"
				alt="Jazeera"></ion-icon>
		</div>
		<div class="product-discount" *ngIf="cardData.discount_type && cardData.discount_type != 'PRICE'">
			<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> {{cardData.discount_value }}%
			</div>
		</div>
		<div class="product-discount" *ngIf="cardData.discount_type && cardData.discount_type == 'PRICE'">
			<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> {{"OnSale" | translate}}
			</div>
		</div>
		<div class="product-list-image-div">
			<div class="product-image-div" [ngStyle]="{'background-color': cardData.backgroundColor}"></div>
			<img class="paint-container-img" [ngClass]="cardData.is_sold_out ? 'product-image':null"
				src="{{cardData.product_images_web}}" alt="Jazeera">
		</div>
		<div *ngIf="cardData.is_sold_out" class="product-outofstock">
			<div class="outofstock-label">
				{{"outOfStock" | translate}}
			</div>
		</div>
	</div>
	<div class="detail-container" [ngClass]="cardData.take_action ? 'fixed' :''">
		<div class="upper-details">
			<div class="product-description text-left" itemprop="name"
				[ngClass]="[categories ? 'categories-product-description' : '']">
				{{commonService.domDirectionLTR ? cardData.product_name_en :
				cardData.product_name_ar
				}}
			</div>
			<div class="mv-5 color-app-grey ">{{cardData.product_sku}}</div>
		</div>
		<div class="items-available pt-5 text-left" *ngIf="cardData.items">
			{{ cardData.items }}
		</div>
		<div [ngClass]="[cardData.take_action ? 'h-50' :'', !categories ? 'price-detail' :'' ]">
			<div class="product-price text-left d-flex" *ngIf="!cardData.is_sold_out">
				<div class="product-price-data" itemprop="price">
					<span class="currency">{{commonService.currencySymbol}}</span>
					<span class="price">{{cardData.final_price | number}}</span>
				</div>
				<div class="product-price-data pl-5" itemprop="price">
					<span *ngIf="cardData.is_discount_applied" class="checked-price currency2">
						<span class="currency2">{{commonService.currencySymbol}}</span>
						<span class="price2">{{cardData.price_per_unit | number}}</span>
					</span>
				</div>
			</div>
			<div class="product-price text-left d-flex" *ngIf="cardData.is_sold_out">
				<div class="product-price-data" itemprop="price">
					<span class="currency">{{commonService.currencySymbol}}</span>
					<span class="price">400</span>
				</div>
				<div class="product-price-data pl-5" itemprop="price">
					<span class="checked-price currency2">
						<span class="currency2">{{commonService.currencySymbol}}</span>
						<span class="price2">200</span>
					</span>
				</div>
			</div>
			<!-- <div class="rating-container d-flex">
				<div class="rating-count d-flex mr-5">
					<div class="rating-text">
						4.1
					</div>
					<div class="start-icon d-flex pl-5">
						<img src="assets/icon/star-white.svg" alt="white-star">
					</div>
				</div>
				<div class="pl-5 pr-5">
					(102)
				</div>
			</div> -->
		</div>
		<!-- <div class="attribute-container d-flex">
			<div class="attr-section">
				Express
			</div>
			<div class="attr-section">
				Effective against corona
			</div>
			<div class="attr-section">
				Install tiles without using a spacer pegs
			</div>
			<div class="attr-section">
				Available
			</div>
		</div> -->
		<!-- code required -->
		<!-- <p class="excluding-vat m-0" *ngIf="cardData.is_tax_free_product == true">
        {{'inclusiveVAT' | translate}}
    </p> -->
		<!-- <div class="notify-me" *ngIf="cardData.is_sold_out" (click)="notifyMeClick(cardData.product_id)">
        <app-common-button color="white" background="var(--ion-app-primary)" type="solid"
            title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true" [specificWidth]="'136px'"
            [specificHeight]="'39px'" [specificFontWeight]="'bold'"></app-common-button>
    </div> -->
		<div class="action-block" itemprop="action" *ngIf="cardData.take_action">
			<div class="left-block">
				<!-- code required -->
				<!-- <img class="edit" src="assets/icon/edit-review.svg" alt="Jazeera"> -->
				<img class="delete" (click)="removeFromWishList($event)" src="assets/icon/deleteIcon.svg" alt="Jazeera">
			</div>
			<div class="right-block">
				<span class="move" (click)="addToCart($event,cardData)" *ngIf="cardData.is_sub_product">{{"moveToCart" |
					translate}}</span>
				<span class="move" (click)="gotToProductDetail()" *ngIf="!cardData.is_sub_product">{{"toDetail" |
					translate}}</span>
			</div>
		</div>

		<div class="add-to-cart-container d-flex mt-20" (click)="$event.stopPropagation()">
			<div class="quantity-contaner d-flex mr-10">
				<ion-item lines="none">
					<ion-icon (click)="decreaseQuantity($event)" src="assets/icon/minus.svg"></ion-icon>
					<ion-input [(ngModel)]="quantity" inputmode="numeric" type="number"
						(ionBlur)="checkValidQuantity($event)" min="1" max="1000"></ion-input>
					<ion-icon (click)="increaseQuantity($event)" src="assets/icon/plus.svg"></ion-icon>
				</ion-item>
			</div>
			<div class="add-to-cart-btn">
				<app-common-button (click)="addToCart($event,cardData)" color="white"
					background="var(--ion-color-blue-100)" type="solid" title="{{ 'addToCart' | translate }}"
					[isIcon]="false" [specificPaddingStart]="'16px'" [isPrimaryBtn]="true" [isSecondryBtn]="false"
					[buttonDisabled]="false" [specificPaddingEnd]="'16px'" [isHideInMobile]="false"
					[specificWidth]="'fit-content'" [borderRadius]="'4px'" [specificHeight]="'48px'"
					[borderWidth]="'1px'" [borderStyle]="'solid'"
					borderColor="var(--ion-color-blue-100)"></app-common-button>
			</div>
		</div>

	</div>
</div>



<div class="slide-container" itemscope *ngIf="productlistPage != 'productlist1'" [class.product-border]="!categories"
	[ngClass]="['productDetailLabel' ? commonService.domDirectionLTR ? 'detail-slide-container' :'detail-slide-container-right': '', fromSliderComponent ? 'from-slider-container' : '']">
	<div [ngClass]="categories ? 'categories-image-container' : 'product-image-container'"
		(click)="gotToProductDetail()">
		<div class="product-fav" (click)="addToWishlist($event, cardData.product_id, this.cardData.is_wishlist_product)"
			*ngIf="isLoggedIn && !wishListPage && !categories && !wishListTab">
			<ion-icon *ngIf="!this.cardData.is_wishlist_product" src="assets/icon/fav.svg" alt="Jazeera"></ion-icon>
			<ion-icon *ngIf="this.cardData.is_wishlist_product" src="assets/icon/fav-liked.svg"
				alt="Jazeera"></ion-icon>
		</div>
		<div class="product-discount" *ngIf="cardData.discount_type && cardData.discount_type != 'PRICE'">
			<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> {{cardData.discount_value }}%
			</div>
		</div>
		<div class="product-discount" *ngIf="cardData.discount_type && cardData.discount_type == 'PRICE'">
			<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> {{"OnSale" | translate}}
			</div>
		</div>

		<div class="product-image-div" [ngClass]="searchPage ? 'product-search-image-div' : ''">
			<!-- <img [ngClass]="[cardData.is_sold_out ? 'product-image' : '', wishListPage ? 'wishListImg' : '']"
				src="{{ cardData.product_img }}" alt="Jazeera">
			<img class="paint-container-img"
				[ngClass]="[cardData.is_sold_out ? 'product-image' : '', wishListPage ? 'wishListImg' : '']"
				src="{{ cardData.product_img }}" alt="Jazeera"> -->
			<div class="product-list-image-div">
				<div class="product-image-div" [ngStyle]="{'background-color': cardData.backgroundColor}"></div>
				<img class="paint-container-img" [ngClass]="cardData.is_sold_out ? 'product-image':null"
					src="{{cardData.product_img}}" alt="Jazeera">
			</div>
		</div>

		<div *ngIf="cardData.is_sold_out" class="product-outofstock">
			<div class="outofstock-label">
				{{"outOfStock" | translate}}
			</div>
		</div>
	</div>
	<div class="detail-container" [ngClass]="cardData.take_action ? 'fixed' :''">
		<div class="upper-details">
			<div (click)="gotToProductDetail()" itemprop="name" class="product-description text-left"
				[ngClass]="[categories ? 'categories-product-description' : '']">
				{{ commonService.domDirectionLTR ? cardData.product_name : cardData.product_name_ar}}
			</div>
			<div>{{cardData.product_sku}}</div>
		</div>
		<div (click)="gotToProductDetail()" class="items-available pt-5 text-left" *ngIf="cardData.items">
			{{ cardData.items }}
		</div>
		<div class="price-detail pt-10"
			[ngClass]="[cardData.take_action ? 'h-50' :'', !categories ? 'price-detail' :'' ]">
			<div class="product-price text-left d-flex" *ngIf="!cardData.is_sold_out && cardData.product_price">
				<div class="product-price-data" itemprop="price">
					<span class="currency">{{ commonService.currencySymbol }}</span>
					<span class="price">{{cardData.final_price| number }}</span>
				</div>
				<div class="product-price-data pl-5" itemprop="price">
					<span class="checked-price currency2">
						<span class="currency2">{{commonService.currencySymbol}}</span>
						<span class="price2">200</span>
					</span>
				</div>
				<!-- code required -->
				<!-- <p class="excluding-vat m-0" *ngIf="cardData.is_tax_free_product == true">
            {{'inclusiveVAT' | translate}}
        </p> -->
			</div>
			<!-- code in future required -->
			<!-- <div class="rating-container d-flex">
				<div class="rating-count d-flex mr-5">
					<div class="rating-text">
						4.1
					</div>
					<div class="start-icon d-flex pl-5">
						<img src="assets/icon/star-white.svg" alt="white-star">
					</div>
				</div>
				<div class="pl-5 pr-5">
					(102)
				</div>
			</div> -->
		</div>
		<!-- code in future required -->
		<!-- <div class="attribute-container d-flex">
			<div class="attr-section">
				Express
			</div>
			<div class="attr-section">
				Effective against corona
			</div>
			<div class="attr-section">
				Install tiles without using a spacer pegs
			</div>
			<div class="attr-section">
				Available
			</div>
		</div> -->

		<!-- <div class="notify-me" *ngIf="cardData.is_sold_out && !cardData.take_action"
        (click)="notifyMeClick(cardData.product_id)">
        <app-common-button color="white" background="var(--ion-app-primary)" type="solid"
            title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true" [specificWidth]="'136px'"
            [specificHeight]="'39px'" [specificFontWeight]="'bold'"></app-common-button>
    </div> -->
		<div class="add-to-cart-container d-flex mt-20" (click)="$event.stopPropagation()">
			<div class="quantity-contaner d-flex mr-10">
				<ion-item lines="none">
					<ion-icon (click)="decreaseQuantity($event)" src="assets/icon/minus.svg"></ion-icon>
					<ion-input [(ngModel)]="quantity" inputmode="numeric" type="number"
						(ionBlur)="checkValidQuantity($event)" min="1" max="1000"></ion-input>
					<ion-icon (click)="increaseQuantity($event)" src="assets/icon/plus.svg"></ion-icon>
				</ion-item>
			</div>
			<div class="add-to-cart-btn">
				<app-common-button (click)="addToCart($event,cardData)" color="white"
					background="var(--ion-color-blue-100)" type="solid" title="{{ 'addToCart' | translate }}"
					[isIcon]="false" [specificPaddingStart]="'16px'" [isPrimaryBtn]="true" [isSecondryBtn]="false"
					[buttonDisabled]="false" [specificPaddingEnd]="'16px'" [isHideInMobile]="false"
					[specificWidth]="'fit-content'" [borderRadius]="'4px'" [specificHeight]="'48px'"
					[borderWidth]="'1px'" [borderStyle]="'solid'"
					borderColor="var(--ion-color-blue-100)"></app-common-button>
			</div>
		</div>
	</div>
</div>