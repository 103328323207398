import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ToastService } from "../../services/toast.service";
import { newsData } from "./news-data";

@Component({
	selector: "app-news",
	templateUrl: "./news.page.html",
	styleUrls: ["./news.page.scss"],
})
export class NewsPage implements OnInit {

	public subscription: Subscription = new Subscription();
	isLoading: boolean = false;
	newsData: any = [];
	searchText: any;

	fromDate: any;
	todaysDate = new Date();
	maxFromDate: any;
	selectedSortBy: any;
	btnStatus: any = true;
	currentLang;

	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private metaService: Meta,
		private setTitleService: Title,
		protected sanitizer: DomSanitizer,
		private datePipe: DatePipe,
		public translate: TranslateService,

	) {
		this.currentLang = this.translate.currentLang;
		this.initializeDateForm();
	}

	ngOnInit() {
		// this.getData();
		this.newsData = newsData;

	}

	ionViewWillEnter() {
		// this.getData();
	}

	initializeDateForm() {
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate());
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}


	getData() {
		this.isLoading = true;
		const params = {
			content_type: "ABOUT_US",
		};
		this.subscription.add(
			this.dataService.get(URLS.getCMSdata, params).subscribe(
				(data) => {

				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}

	search() {

	}

	goBack() {
		this.navCtrl.back({ animated: false });
	}

	selectSorting($event) {

	}

	reset() {

	}

	goToDetails(data) {
		this.navCtrl.navigateForward('/' + this.currentLang + '/news-details/' + this.commonService.trimUrl(data.title),
			{
				animated: false, "state":
				{
					"breadcrumb": data.title,
					"url": '/news-details/' + this.commonService.trimUrl(data.title),
					id: data.id,
				}
			});

	}
}
