import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { MyQuoteRequestRoutingModule } from './my-quote-request-routing.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MyQuoteRequestRoutingModule,
        SharedModule
    ]
})
export class MyQuoteRequestModule { }
