import { Component, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IonContent, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ToastService } from 'src/app/services/toast.service';
import { ReturnOrderMobileFilterComponent } from '../return-order-mobile-filter/return-order-mobile-filter.component';



@Component({
    selector: 'app-quote-list-mobile',
    templateUrl: 'quote-list-mobile.page.html',
    styleUrls: ['quote-list-mobile.page.scss'],
    providers: []
})
export class QuoteListMobilePage {
    @ViewChild(IonContent) content: IonContent;
    private subscription: Subscription = new Subscription();
    enableNext = false;
    showFilter = false;
    public myQuotes = [];
    quoteParams = {
        page: 1,
        Size: 100
    }
    currentPage = 1;
    itemsPerPage = 10;
    count = 0;
    filterObj = {};
    isLoggedIn: boolean;
    quoteList = [];
    statusList = [];
    searchText: any;

    public isLoading = false;
    constructor(private dataService: ApiService,
        private modalService: ModalService,
        public commonService: CommonService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private navCtrl: NavController,
        public getPlatformService: GetPlatformService,
        public router: Router,
        public quoteService: QuoteService,
        public cartService: CartService
    ) {
        this.commonService.eventObservable.subscribe(data => {
            if (data.event == 'profileData' || data.event == 'logoutCall') {
                const token = this.commonService.localStorageGet('accessToken');
                if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
                if (this.isLoggedIn) {
                    // this.getQuoteList();  uncomment when integrating API

                }
            }
        });


    }
    ionViewWillEnter() {
        this.statusList = [
            { value: 'true', label: this.translateService.instant('myQuoteComponent.completed') },
            { value: 'false', label: this.translateService.instant('myQuoteComponent.requested') },
        ];
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

        if (this.isLoggedIn) {
            this.filterObj = {};
            this.currentPage = 1;
            this.getQuoteList(this.filterObj);

        }
    }


    ngOnInit() {
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

        if (this.isLoggedIn) {
            this.filterObj = {};
            this.getQuoteList(this.filterObj);
        }
    }

    getQuoteList(params, infiniteScroll?) {
        if (this.isLoading) {
            return
        }

        if (params['status']) {
            params['is_fulfilled'] == params['status']
        }
        else {
            params['is_fulfilled'] == params['status']
        }
        params = {
            page: this.currentPage,
            limit: +this.itemsPerPage,
            ...params
        }
        return new Promise((resolve, reject) => {
            if (!infiniteScroll) {
                this.isLoading = true;
            }
            this.subscription.add(
                this.dataService.get(URLS.quoteList, params).subscribe(data => {
                    if (data && data['code'] == 200) {
                        this.enableNext = data['data']['enable_next'] ? true : false;
                        this.count = data['data'] && data['data']['total_count'] ? +data['data']['total_count'] : 0;
                        let list = data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
                        if (!infiniteScroll) {
                            this.quoteList = [];
                        }
                        list.forEach(element => {
                            this.quoteList.push(element);
                        });
                        resolve(this.quoteList)
                    } else {
                        reject();
                    }
                    this.isLoading = false;
                }, error => {
                    reject();
                    this.isLoading = false;
                })
            )
        })
    }

    loadData(event) {
        setTimeout(() => {
            if (this.enableNext) {
                this.currentPage += 1;
                this.getQuoteList(this.filterObj, true).then((data) => {
                    event.target.complete();
                }, err => {
                    event.target.complete();
                })
            }
        }, 500);
    }

    openFilterByModal() {
        let modal: any;
        let options: any = {
            id: "quoteFilterByModal",
            componentProps: { statusList: this.statusList, fromQuotesList: true, filterObj: this.filterObj },
        };
        options['component'] = ReturnOrderMobileFilterComponent;
        modal = this.modalService.openModal(options)
        modal.then((data) => {
            if (data && data['data']) {
                this.filterObj = {
                    ...data['data']
                };
                if (this.searchText) {
                    this.filterObj['search'] = this.searchText;
                }
                this.currentPage = 1;
                this.getQuoteList(this.filterObj)
            }

        })
    }

    openQuoteDetailsModal(data) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                value: 'pdf',
                quoteId: data.quotation_request_id,
                from: 'list'
            },
        };
        this.router.navigate(['/'+this.translateService.currentLang+'/home/rfq-mobile'], navigationExtras)
    }

    clearSearch(event) {
        delete this.filterObj['search']
        this.currentPage = 1;
        this.content.scrollToTop(500);
        this.getQuoteList(this.filterObj)

    }

    handleRefresh(event) {
        setTimeout(() => {
            // code maybe required
            // this.isLoading = false;
            if (this.isLoggedIn) {
                if (this.filterObj['search']) {
                    this.getQuoteList(this.filterObj);
                } else {
                    this.filterObj = {};
                    this.currentPage = 1;
                    this.getQuoteList(this.filterObj);
                }

            }
            event.target.complete();
        }, 1000);
    };

    search(event) {
        if (event['data']) {
            this.filterObj['search'] = event['data'];
            this.currentPage = 1;
            this.content.scrollToTop(500);
            this.getQuoteList(this.filterObj)
        } else {
            this.filterObj['search'] = null
        }

    }

    goBack() {
        this.navCtrl.navigateBack('/'+this.translateService.currentLang +'/my-account', { animated: false });
    }

    quoteStatus(quoteData, productlevel?) {
        switch (quoteData.is_fulfilled) {
            case true:
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
            case false:
                if (productlevel) {
                    return 'btn-red product-status-btn';
                } else {
                    return 'btn-red';
                }
            default:
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
        }
    }
    openPopup(data, event) {
        event.preventDefault();
        event.stopPropagation();
        data.isOpen = !data.isOpen;
    }

    async proceedToOrderQuote(quote, event) {
        event.preventDefault();
        event.stopPropagation();

        quote.products.forEach(product => {
            let finalQuantity = product.is_in_cart == true && product.total_quantity_per_product != product.cart_quantity ?
                product.total_quantity_per_product > product.cart_quantity ?
                    product.total_quantity_per_product - product.cart_quantity :
                    product.cart_quantity - product.total_quantity_per_product
                : product.total_quantity_per_product;

            if (!product.is_in_cart) {
                this.cartService.addIntoCart(product, finalQuantity, null).then((data) => {
                });
            }
            else if (finalQuantity != product.total_quantity_per_product) {
                this.cartService.addIntoCart(product, finalQuantity, null).then((data) => {
                });
            }
        });

        this.commonService.localStorageSet('tempQuoteId', quote.quotation_request_id);
        this.router.navigate(['/'+this.translateService.currentLang+'/cart']);

    }
}
