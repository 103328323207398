import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderDetailsMobileComponent } from './order-details-mobile.component';


const routes: Routes = [
    {
        path: '',
        component: OrderDetailsMobileComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OrderDetailsMobileRoutingModule { }
