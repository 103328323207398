<ion-header class="mobile-visibility app-header">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="wishlist-header">
				{{'myWishListPage.wishList' | translate}}
			</div>
		</ion-title>
		<div *ngIf="isLoggedIn" class="section-block header-filter-block" slot="end" (click)="openFilterByModal()">
			<div class="filter-icon">
				<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
			</div>
			<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''">{{"filters"
				| translate}}
			</div>
		</div>

	</ion-toolbar>
	<div class="searchbar-container mv-12 mh-14" *ngIf="isLoggedIn && !isLoading">
		<div class="select-btn-block">
			<div class="select-block">
				<ion-label class="ml-10 mb-5">{{"myWishListPage.categoryName" | translate}}</ion-label>
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" mode="md"
					placeholder="{{'selectOne'|translate}}" class="ml-10" [(ngModel)]="selectedCategory"
					[interfaceOptions]="{'header': ('myWishListPage.categoryName' | translate)}"
					(ionChange)="categoyChanged($event)">
					<ion-select-option [value]="0">
						{{'all'|translate}}
					</ion-select-option>
					<ion-select-option *ngFor="let cat of catagoryListData" [value]="cat.sub_class_id">

						{{commonService.domDirectionLTR ? cat.display_sub_class_name_en :
						cat.display_sub_class_name_ar}}
					</ion-select-option>
				</ion-select>
			</div>
		</div>
		<div class="select-btn-block">
			<div class="select-block">
				<ion-label class="ml-10 mb-5">{{"myWishListPage.subClass" | translate}}</ion-label>
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
					[disabled]="!selectedCategory && subClassArray.length < 1" mode="md"
					placeholder="{{'selectOne'|translate}}" class="ml-10" [(ngModel)]="selectedSubClass"
					[interfaceOptions]="{'header': ('myWishListPage.subClass' | translate)}"
					(ionChange)="subClassChanged($event)">

					<ion-select-option *ngFor="let subClass of subClassArray" [value]="subClass.sub_class_id">
						{{commonService.domDirectionLTR ? subClass.sub_class_name_en :subClass.sub_class_name_ar }}
					</ion-select-option>
				</ion-select>
			</div>
		</div>

	</div>
</ion-header>
<app-loader *ngIf="isLoading"></app-loader>
<ion-content *ngIf="!isLoading">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>

	<div *ngIf="isLoggedIn">
		<ion-row class="row-alignment" *ngIf="myWishlist.length > 0">
			<ion-col class="pb-40 mob-pb-20" *ngFor="let data of myWishlist;index as i" size-xs="5.5" size-sm="5.8"
				size-md="4" size-lg="3" size-xl="3">
				<app-product-card (productClicked)="goToProductDetail(data)"
					(deleteFromWishList)="presentAlert(data, i)" [cardData]="data" [productDetailLabel]="true"
					[wishListTab]="true"></app-product-card>
			</ion-col>
		</ion-row>

		<div *ngIf="myWishlist.length == 0" class="vertical_center">
			<img src="./assets/icon/myWishilistIcon.svg" alt="Jazeera" class="mb-30" />
			<div class="mb-20">
				<p class="label-3 color-app-black ">No favorite items</p>
			</div>
		</div>

	</div>
	<app-login-prompt *ngIf="!isLoggedIn"></app-login-prompt>
	<ion-infinite-scroll (ionInfinite)="loadData($event)" *ngIf="!getPlatformService.isDesktop">
		<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
			loadingText="{{'lodingMoreData' | translate}}">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</ion-content>