<ion-header class="mobile-visibility" mode="md" *ngIf="!getPlatformService.isDesktop">
	<ion-toolbar mode="md">
		<div class="title-block">
			<img src="assets/icon/leftArrow.svg" class="back-icon" alt="Jazeera" (click)="goBack()">
			<ion-title>{{"newsComponent.title" | translate}}</ion-title>
		</div>
	</ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="getPlatformService.isDesktop">
	<app-header></app-header>

	<h1 class="page-container title-div m-0">
		{{"newsComponent.title" | translate}}
	</h1>
</div>

<app-loader *ngIf="isLoading"></app-loader>

<ion-content>
	<div class="page-container">
		<ion-row class="header-row">
			<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="5" size-xl="5">
				<div class="search-div m-0 width-100">
					<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'Search' | translate}}"
						[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (input)="search()"
						(ngModelChange)="search()" (keyup.enter)="search()" class="m-0">
						<img src="./assets/icon/search.svg" slot="start" (click)="search()" alt="Jazeera">
						<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
							alt="Jazeera" (click)="clearSearch()">
					</ion-input>
				</div>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3">
				<div class="from-date-div pl-7">
					<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
						id="fromDateId">
						<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}"
							placeholder="{{'statementComponent.post_date' | translate}}"
							class="ion-text-start"></ion-input>
						<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="Jazeera"></ion-icon>
						<ion-popover trigger="fromDateId" size="cover" id="datePopover">
							<ng-template>
								<ion-datetime presentation="date" [min]="'2000-01-01'"
									[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate" #fromdate
									(ionBlur)="onDateChange(fromdate)">
									<span slot="title">{{"statementComponent.post_date" |
										translate}}</span></ion-datetime>
							</ng-template>
						</ion-popover>
					</ion-item>
				</div>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="3" size-xl="3">
				<div class="section-block pl-7">
					<div class="input-border height-50">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" mode="md" fill=""
							label-placement="stacked" [(ngModel)]="selectedSortBy" class="pointer-none"
							placeholder="{{'sortBy' | translate}}"
							[interfaceOptions]="{ 'header': ('sortBy' | translate)}"
							(ngModelChange)="selectSorting($event)">
							<ion-select-option value="ASC">{{'A-Z' |
								translate}}</ion-select-option>
							<ion-select-option value="DESC">{{'Z-A' |
								translate}}</ion-select-option>
							<ion-select-option value="new_to_old">{{'New To Old' |
								translate}}</ion-select-option>
							<ion-select-option value="old_to_new">{{'Old To New' |
								translate}}</ion-select-option>
						</ion-select>
					</div>
				</div>

			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="1" size-xl="1">
				<app-common-button color="var(--ion-app-primary)" background="transparent" type="outline"
					title="{{'Reset' |translate}}" [isHideInMobile]="false" [isFullWidth]="true" [boxshadow]="false"
					[specificHeight]="'50px'" [specificFontWeight]="'bold'" [borderRadius]="'10px'"
					(click)="reset()"></app-common-button>
			</ion-col>

		</ion-row>


		<ion-row class="page-row" *ngIf="newsData && newsData.length > 0">
			<ion-col *ngFor="let data of newsData" class="mb-30 pointer" (click)="goToDetails(data)">
				<img [src]="commonService.domDirectionLTR ?  data.press_banner_image_en : data.press_banner_image_ar"
					class="card-img" />
				<p class="card-title pv-7 m-0">{{commonService.domDirectionLTR ?data.content_title_en
					:data.content_title_ar}}</p>
				<p class="card-desc pt-0 pb-10 m-0"
					[innerHTML]="commonService.domDirectionLTR ? data.content_en: data.content_ar"></p>
				<ion-row>
					<div class="section-block">
						<img src="/assets/icon/clock.svg" class="icon-page" />
						<p class="pv-0  ph-10 card-desc2 m-0">{{data.created_at | date: 'dd MMM yyyy' }}</p>
					</div>
					<div class="section-block ml-15">
						<img src="/assets/icon/account.svg" class="icon-page" />
						<p class="pv-0 ph-10 card-desc2 m-0">{{data.created_by}}</p>

					</div>
				</ion-row>
			</ion-col>
		</ion-row>

		<ion-row class="load-more-row ion-justify-content-center mb-50" *ngIf="newsData.length > 0 && enableNext">
			<app-common-button *ngIf="btnStatus" (click)="loadMoreData()" [btnStatus]="btnStatus" color="white"
				background="var(--ion-color-blue-100)" type="solid"
				title="{{ 'productGridComponent.Load more' | translate }}" [specificPaddingStart]="'16px'"
				[isPrimaryBtn]="true" [isSecondryBtn]="false" [buttonDisabled]="false" [specificPaddingEnd]="'16px'"
				[isHideInMobile]="true" [specificWidth]="'fit-content'" [borderRadius]="'4px'" [specificHeight]="'44px'"
				[borderWidth]="'1px'" [borderStyle]="'solid'"
				borderColor="var(--ion-color-blue-100)"></app-common-button>
		</ion-row>

	</div>

	<div *ngIf="newsData && newsData.length <= 0 && !isLoading">
		<div class="no-record-found ion-text-center">
			{{'noRecordFound' | translate}}
		</div>
	</div>
	<div class="wrapper-block home-page" *ngIf="getPlatformService.isDesktop">
		<div class="app-footer">
			<app-footer></app-footer>
		</div>
	</div>
</ion-content>