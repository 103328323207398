<ion-header class="mobile-visibility app-header">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">
		<ion-title mode="ios">
			<div class="order-header">
				{{'invoices' | translate}}
			</div>
		</ion-title>
		<div class="section-block header-filter-block" slot="end" (click)="openFilterByModal()" *ngIf="isLoggedIn">
			<div class="filter-icon">
				<img src="./assets/icon/filterIcon.svg" alt="Jazeera">
			</div>
		</div>
	</ion-toolbar>


	<div class="searchbar-container mh-16 mv-12" *ngIf="isLoggedIn">
		<div class="search-div width-auto mr-0">
			<ion-input type="text" fill="outline" CapitalizeFirst placeholder="{{'invoiceSearch' | translate}}"
				[(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (input)="search()"
				(ngModelChange)="search()" (keyup.enter)="search()">
				<img src="./assets/icon/search.svg" slot="start" alt="Jazeera">
				<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText" alt="Jazeera"
					(click)="clearSearch()">
			</ion-input>
		</div>
	</div>
</ion-header>
<app-loader *ngIf="isLoading"></app-loader>

<ion-content [fullscreen]="true">
	<ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
		(ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<div class="orders-listing mh-20" *ngIf="isLoggedIn && !isLoading">
		<div class="mobile-view-listing" *ngIf="invoiceList.length">
			<div class="order-container">
				<ion-grid class="p-0 m-0">
					<ion-row class="order-card" *ngFor="let order of invoiceList;index as i">
						<ion-col size="12">
							<ion-grid>
								<ion-row>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"invoicesComponent.invoice_id" | translate}}</div>
										<div class="grid-values">{{order.invoice_no || "--"}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.date" | translate}}</div>
										<div class="grid-values">{{order.invoice_date ? (order.invoice_date | date
											:'MMM dd, YYYY') : "--"}}</div>
									</ion-col>


									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"ordersComponent.order_total" | translate}}</div>
										<div class="grid-values">{{commonService.currencySymbol}}{{order.order_total ?(
											order.order_total | number): '0'}}</div>
									</ion-col>
									<ion-col size="6" class="order-card-col">
										<div class="grid-label">{{"invoicesComponent.status" | translate}}</div>
										<div class="displayFlex">
											<div [ngClass]="'color-blue-60'">
												{{order.invoice_status |translate}}
											</div>
											<div class=" mr-5"
												[ngClass]="commonService.domDirectionLTR ? 'justify-end ml-10' : 'justify-start mr-10'">
												<img src="./assets/icon/fi_download.svg"
													(click)="downloadInvoice($event,order)" alt="Jazeera"
													[title]="('downloadInvoice'|translate)">
											</div>
										</div>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
					</ion-row>
					<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
						<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
							loadingText="{{'loadMoreOrders' | translate}}">
						</ion-infinite-scroll-content>
					</ion-infinite-scroll>
				</ion-grid>
			</div>
		</div>

		<div *ngIf="!invoiceList.length" class="no-orders">
			<img src="./assets/icon/no-order.svg" alt="Jazeera" class="mb-30" />
			<div class="mb-20">
				<p class="label-3 color-app-black ">{{'noRecordFound' | translate}}</p>
			</div>
		</div>


	</div>
	<app-login-prompt *ngIf="!isLoggedIn && !isLoading"></app-login-prompt>
</ion-content>